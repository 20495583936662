import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TConfigAction } from '../core/config/common.types';
import { i18link } from '../locales/formatters';
import { ga4Event, TEventProps, TGaConfigActions, TGaEvent } from '../utils/ga';
import { openLink, withSlash } from '../utils/url';

export type TLinkActionsOptions = {
  gaProps: TEventProps;
};

const isExternal = (link: TConfigAction['link']): boolean => /^(http|https):/.test(link);
const getGaEvent = (gaEvent: TConfigAction['ga'], gaProps?: TEventProps): [TGaConfigActions | null, TEventProps] => {
  if (!gaEvent) return [null, { placeholders: {} }];
  if (typeof gaEvent === 'string') return [gaEvent, gaProps || { placeholders: {} }];

  const props: TEventProps = gaProps || { placeholders: {} };
  if (gaEvent.placeholders) props.placeholders = { ...props.placeholders, ...gaEvent.placeholders };
  return [gaEvent.eventName, props];
};

const useLinkActions = (): ((action: TConfigAction, options?: Partial<TLinkActionsOptions>) => void) => {
  const navigate = useNavigate();

  return React.useCallback(
    (action: TConfigAction, options?: Partial<TLinkActionsOptions>) => {
      const [gaEvent, gaProps] = getGaEvent(action.ga, options?.gaProps);
      if (gaEvent) {
        ga4Event(gaEvent, gaProps as TGaEvent[typeof gaEvent]);
      }

      const linkExternal: boolean = isExternal(action.link);
      if (linkExternal) {
        openLink(i18link(action.link));
      } else {
        navigate(withSlash(action.link));
      }
    },
    [navigate],
  );
};

export default useLinkActions;
