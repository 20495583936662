import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM_NAME, KEYCLOAK_RESPONSE_FRAGMENT } from '../constants/keycloak';
import { generateCodeVerifier, generatePkceChallenge } from '../utils/generators';

export const generateTotpUrl = (redirect: string): string => {
  const pkce = generatePkceChallenge('S256', generateCodeVerifier(96));
  return `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${KEYCLOAK_REALM_NAME}/protocol/openid-connect/auth?client_id=${KEYCLOAK_CLIENT_ID}&redirect_uri=${redirect}&response_mode=${KEYCLOAK_RESPONSE_FRAGMENT}&response_type=code&scope=openid&kc_action=CONFIGURE_TOTP&code_challenge_method=S256&code_challenge=${pkce}`;
};

export const generateLoginUrl = (redirect: string): string => {
  const pkce = generatePkceChallenge('S256', generateCodeVerifier(96));
  return `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${KEYCLOAK_REALM_NAME}/protocol/openid-connect/auth?client_id=${KEYCLOAK_CLIENT_ID}&redirect_uri=${redirect}&response_mode=${KEYCLOAK_RESPONSE_FRAGMENT}&response_type=code&scope=openid&code_challenge_method=S256&code_challenge=${pkce}`;
};
