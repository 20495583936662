import ReactGA from 'react-ga4';

import { gaData } from './data';
import { referrer } from './functions';
import { TEventPropsPlaceholders, TGaEvent } from './ga4.types';

/**
 * Дока старая https://docs.google.com/document/d/1NhbG6Kz96KqYVLlNLGg7Ik9Qcv6659Z93pT_Z1ZMyJo/edit#
 * Дока новая https://docs.google.com/document/d/1NhbG6Kz96KqYVLlNLGg7Ik9Qcv6659Z93pT_Z1ZMyJo/edit#heading=h.pfw6el2xgjzb
 */

/**
 * отправляется при изменении языка
 * @param locale
 */
const ga4LangChange = (locale: string): void => {
  gaData.sendEvent4('click_language', {
    event_option1: 'account;my details',
    event_option2: 'header',
    event_option4: locale,
    locale,
  });
};

/**
 * просмотр страницы, отправляется 1 раз на страницу
 * @param locale
 * @param path
 */
const ga4PageView = (locale: string, path: string): void => {
  if (!gaData.check(() => ga4PageView(locale, path))) return;

  gaData.trackers.forEach((item) => {
    ReactGA.send({
      ...gaData.enrichEvent4(item.trackingId),
      hitType: 'pageview',
      page_location: document.location.href,
      page_referrer: referrer(),
      event_option1: 'undefined',
      event_option2: 'undefined',
    });
  });
};

const replacePlaceholder = (
  str: string,
  placeholders?: Record<string, string>,
  defaultValue = 'undefined',
  startTag = '${',
  endTag = '}',
): string => {
  let result: string = str;

  const startId = str.indexOf(startTag);
  const endId = str.indexOf(endTag);
  if (startId > -1 && endId > -1 && endId > startId) {
    result = str.slice(0, startId);
    const placeholder = str.slice(startId + startTag.length, endId);
    const value = placeholders?.[placeholder] || defaultValue;
    result = result + value + str.slice(endId + endTag.length);
  }

  if (startId !== str.lastIndexOf(startTag)) {
    result = replacePlaceholder(result, placeholders, defaultValue, startTag, endTag);
  }

  return result;
};

const ga4Event = <T extends keyof TGaEvent>(action: T, props?: TGaEvent[T]) => {
  const event = gaData.config[action];
  if (!event) return;

  const resultData = event.data;

  const propsPlaceholders: TEventPropsPlaceholders<Record<string, string>>['placeholders'] | undefined =
    typeof props === 'object' && 'placeholders' in props && props?.placeholders ? props.placeholders : undefined;

  resultData.name = replacePlaceholder(resultData.name, propsPlaceholders);
  (Object.keys(resultData.params) as Array<keyof typeof resultData.params>).forEach((param) => {
    resultData.params[param] = replacePlaceholder(resultData.params[param], propsPlaceholders);
  });

  const { name, params } = resultData;
  gaData.sendEvent4(name, params);
};

export { ga4Event, ga4LangChange, ga4PageView };
