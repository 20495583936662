import { useOnceEffect } from 'library-react-hooks';
import React, { ClassAttributes, ReactNode, SVGAttributes } from 'react';
import { CSSObject } from 'tss-react';

import { sentryException } from '../../utils/sentry';
import { arrToStr } from '../../utils/string';

type TSvgProps = {
  src: string;
  base?: JSX.Element;
  props?: React.SVGProps<SVGSVGElement>;
};

const domParser = new DOMParser();

const request = async (url: string, options?: RequestInit): Promise<string> => {
  try {
    const response = await fetch(url, options);
    const contentType = response.headers.get('content-type');
    const [fileType] = (contentType ?? '').split(/ ?; ?/);

    if (response.status > 299) {
      console.error('Not found');
      return '';
    }

    if (!['image/svg+xml', 'text/plain'].some((d) => fileType.includes(d))) {
      console.error(`Content type isn't valid: ${fileType}`);
      return '';
    }

    return await response.text();
  } catch {
    console.error(`Can't load svg: ${url}`);
    sentryException(`Can't load svg: ${url}`, 'warning');
  }
  return '';
};

const toUpperCase = (str: string, splitter = ''): string => {
  return str.split(splitter).reduce((prev, item, id) => {
    return id === 0 ? prev + item : prev + item[0].toUpperCase() + item.slice(1);
  }, '');
};
const cssToJs = (style: string): CSSObject => {
  return (
    style
      .split(';')
      .map((item) => item.split(':'))
      // eslint-disable-next-line unicorn/prefer-object-from-entries
      .reduce((prev, [key, value]) => {
        return { ...prev, [toUpperCase(key, '-')]: value };
      }, {})
  );
};

const createChild = (childNodes: NodeListOf<ChildNode>): ReactNode[] => {
  const nodes: ReactNode[] = [];
  childNodes.forEach((item) => {
    if (item.nodeType === Node.TEXT_NODE && !item.nodeValue?.trim()) return;
    const content = item.hasChildNodes() ? createChild(item.childNodes) : [item.nodeValue];
    let props = {};
    // if (item.nodeName === 'mask') return;
    if (item.nodeType !== Node.TEXT_NODE) {
      props = (item as SVGElement).getAttributeNames().reduce((prev, name) => {
        const value = (item as SVGElement).getAttribute(name);
        if (name === 'style' && value) return { ...prev, style: cssToJs(value) };
        return value ? { ...prev, [toUpperCase(name, '-')]: value } : prev;
      }, props);
    }
    const el = React.createElement(item.nodeName, props, ...content);
    nodes.push(el);
  });
  return nodes;
};
const createReactSvg = (element: SVGSVGElement, attrs: React.SVGProps<SVGSVGElement>): React.ReactSVGElement => {
  const classNames = attrs?.className?.split(' ').filter((item) => item !== 'undefined') || [];
  const props: ClassAttributes<SVGElement> & SVGAttributes<SVGElement> = element
    .getAttributeNames()
    // eslint-disable-next-line unicorn/prefer-object-from-entries
    .reduce((prev, name) => {
      const value = element.getAttribute(name);
      if (name === 'style' && value) return { ...prev, style: cssToJs(value) };
      return value ? { ...prev, [toUpperCase(name, '-')]: value } : prev;
    }, {});
  (Object.keys(attrs) as Array<keyof typeof attrs>).forEach((key) => {
    const value = attrs[key];
    switch (key) {
      case 'width':
      case 'height': {
        if (typeof value === 'string' || typeof value === 'number') props.width = value;
        break;
      }
      case 'onClick': {
        if (typeof value === 'function') {
          // @ts-ignore
          props.onClick = value;
        }
        break;
      }
      case 'color': {
        if (typeof value === 'string') props.color = value;
        break;
      }
      default: {
        break;
      }
    }
  });
  props.className = arrToStr([element.classList.value, ...classNames], ' ');
  return React.createElement('svg', props, ...createChild(element.childNodes));
};

// https://github.com/gilbarbara/react-inlinesvg
const Svg: React.VFC<TSvgProps> = ({ src, base, props }) => {
  const refProps = React.useRef<React.SVGProps<SVGSVGElement>>(props || {});
  const refBase = React.useRef<JSX.Element | null>(base || null);
  const [svgEl, setSvgEl] = React.useState<null | React.ReactSVGElement | JSX.Element>(null);

  useOnceEffect(() => {
    (async function () {
      const svgHtml = await request(src);
      const element = domParser.parseFromString(svgHtml, 'image/svg+xml').querySelectorAll('svg')[0];

      if (element) {
        const el = createReactSvg(element, refProps.current);
        setSvgEl(el);
      } else setSvgEl(refBase.current);
    })();
  }, [src]);

  return <React.Fragment>{svgEl}</React.Fragment>;
};

export default Svg;
