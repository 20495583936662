import { API_RECAPTCHA_ACTION_PHONE, API_RECAPTCHA_ACTION_TOTP, TApiSecurityFlowActions } from '../constants/api';
import { TDeleteReason } from '../constants/deleteReason';
import { httpInit } from '../hooks/http/http.hook';
import {
  DELETE_ACCOUNT,
  DELETE_PHONE,
  DELETE_SESSION,
  DELETE_SESSIONS,
  DELETE_SOCIAL_LINK,
  DELETE_TOTP_CREDENTIAL,
  GET_AVAILABLE_PROVIDERS,
  GET_CREDENTIALS,
  GET_FULFILL_URL,
  GET_LOGIN_HISTORY,
  GET_SESSIONS,
  GET_TOTP_ATTEMPT,
  GET_USER_URL,
  PATCH_CONTACTS,
  PATCH_PROFILE,
  POST_CONFIRM_ADD_EMAIL,
  POST_CONFIRM_EMAIL,
  POST_CONFIRM_PHONE,
  POST_FULFILL_URL,
  POST_REMOVE_TOTP,
  POST_SECURITY_FLOW,
  POST_SOCIAL_LINK,
  PUT_ADD_EMAIL,
  PUT_EMAIL,
  PUT_LOCALE,
  PUT_PASSWORD,
  PUT_PHONE,
  PUT_UPDATE_EMAIL,
} from './urls';

export type RequestPostFulfillBody = {
  country?: string;
  birthday?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
};
export type RequestPatchProfile = Partial<{
  firstName: string;
  lastName: string;
  birthday: string;
  country: string;
  middleName: string;
}>;
export type RequestPatchContacts = Partial<{
  phone: string;
  whatsApp: string;
  telegram: string;
  viber: string;
  skype: string;
  icq: string;
}>;
export type RequestPostCheckPasswordBody = { password?: string };

const apiInit = ({ mocked }: { mocked: boolean }): void => {
  return httpInit({
    loader: true,
    toast: true,
    blockRepeatWhenPending: true,
    toastWhenBackendUnavailable: false,
    mocked,
    tokens: {
      main: null,
    },
    responseFormat: (data) => {
      if (data?.data) {
        return data.data;
      }

      return data;
    },
    requests: {
      getUser: () => ({
        url: GET_USER_URL,
        token: 'main',
        toastRules: (httpData) => {
          return httpData.statusCode !== 403;
        },
      }),
      postFulfill: ({ body = {} }: { body: RequestPostFulfillBody }) => ({
        method: 'POST',
        url: POST_FULFILL_URL,
        body,
        // answer: true,
        // successMsg,
        token: 'main',
      }),
      getFulfill: () => ({
        url: GET_FULFILL_URL,
        token: 'main',
      }),
      getCredentials: () => ({
        url: GET_CREDENTIALS,
        token: 'main',
      }),
      deleteTotpCredential: ({ id = '', actionToken }: { id: string; actionToken: string }) => ({
        url: DELETE_TOTP_CREDENTIAL,
        method: 'DELETE',
        query: {
          'credential-id': id,
        },
        headers: {
          'action-token': actionToken,
        },
        token: 'main',
      }),
      getAvailableProviders: () => ({
        url: GET_AVAILABLE_PROVIDERS,
        token: 'main',
      }),
      deleteSocialLink: ({ idp = '', actionToken }: { idp: string; actionToken: string }) => ({
        url: DELETE_SOCIAL_LINK,
        method: 'DELETE',
        query: {
          idp,
        },
        headers: {
          'action-token': actionToken,
        },
        token: 'main',
      }),
      getSessions: () => ({
        url: GET_SESSIONS,
        token: 'main',
      }),
      putLocale: ({ tag = '' }: { tag: string }) => ({
        method: 'PUT',
        url: PUT_LOCALE,
        body: {
          locale: tag,
        },
        toast: false,
        loader: false,
        token: 'main',
      }),
      putPassword: ({
        actionToken,
        currentPassword,
        newPassword,
        confirmNewPassword,
      }: {
        actionToken: string;
        currentPassword: string | null;
        newPassword: string;
        confirmNewPassword: string;
      }) => ({
        method: 'PUT',
        url: PUT_PASSWORD,
        headers: {
          'action-token': actionToken,
        },
        body: {
          currentPassword,
          newPassword,
          confirmNewPassword,
        },
        token: 'main',
        toastWhenCode: false,
      }),
      putUpdateEmail: ({ editToken = '', email = '' }: { editToken: string; email: string }) => ({
        method: 'PUT',
        url: PUT_UPDATE_EMAIL,
        body: {
          email,
        },
        headers: {
          'action-token': editToken,
        },
        token: 'main',
      }),
      postConfirmEmail: ({ actionToken }: { actionToken: string }) => ({
        method: 'POST',
        url: POST_CONFIRM_EMAIL,
        headers: {
          'email-token': actionToken,
        },
        token: 'main',
      }),
      postConfirmAddEmail: ({ actionToken }: { actionToken: string }) => ({
        method: 'POST',
        url: POST_CONFIRM_ADD_EMAIL,
        headers: {
          'email-token': actionToken,
        },
        token: 'main',
      }),
      deleteAccount: ({
        actionToken = '',
        reasons,
        comment = '',
      }: {
        actionToken: string;
        reasons: TDeleteReason[];
        comment: string;
      }) => ({
        method: 'DELETE',
        url: DELETE_ACCOUNT,
        headers: {
          'action-token': actionToken,
        },
        body: {
          type: reasons,
          comment,
        },
        token: 'main',
      }),
      patchProfile: ({ body = {}, successMsg = '' }: { body: RequestPatchProfile; successMsg?: string }) => ({
        method: 'PATCH',
        url: PATCH_PROFILE,
        body,
        token: 'main',
        answer: true,
        successMsg,
      }),
      postSecurityFlow: ({
        body,
        headers,
      }: {
        body: Partial<{ action: TApiSecurityFlowActions; data: unknown }>;
        headers: { 'action-token'?: string };
      }) => ({
        method: 'POST',
        token: 'main',
        url: POST_SECURITY_FLOW,
        headers,
        body,
        toastWhenCode: false,
      }),
      putEmail: ({ email, actionToken }: { email: string; actionToken: string }) => ({
        method: 'PUT',
        token: 'main',
        url: PUT_EMAIL,
        headers: {
          'action-token': actionToken,
        },
        body: {
          email,
        },
        toastWhenCode: false,
      }),
      putAddEmail: ({ email, actionToken }: { email: string; actionToken: string }) => ({
        method: 'PUT',
        token: 'main',
        url: PUT_ADD_EMAIL,
        headers: {
          'action-token': actionToken,
        },
        body: {
          email,
        },
        toastWhenCode: false,
      }),
      postSocialLink: ({ idp, actionToken }: { idp: string; actionToken: string }) => ({
        method: 'POST',
        token: 'main',
        url: POST_SOCIAL_LINK,
        headers: {
          'action-token': actionToken,
        },
        body: {
          idp,
        },
      }),
      getLoginHistory: ({ page, max = 5 }: { page: number; max?: 5 | 10 | 50 }) => ({
        method: 'GET',
        token: 'main',
        url: GET_LOGIN_HISTORY,
        query: {
          page,
          max,
        },
      }),
      deleteSession: ({ actionToken, sessionId }: { actionToken: string; sessionId: string }) => ({
        method: 'DELETE',
        token: 'main',
        url: DELETE_SESSION,
        headers: {
          'action-token': actionToken,
        },
        query: {
          'session-id': sessionId,
        },
      }),
      deleteSessions: ({ actionToken }: { actionToken: string }) => ({
        method: 'DELETE',
        token: 'main',
        url: DELETE_SESSIONS,
        headers: {
          'action-token': actionToken,
        },
      }),
      getTotpAttempt: ({ totpId, recaptcha }: { totpId: string; recaptcha: string }) => ({
        method: 'GET',
        url: GET_TOTP_ATTEMPT,
        query: {
          totpId,
        },
        headers: { recaptcha, action: API_RECAPTCHA_ACTION_TOTP },
        toastWhenCode: false,
        loader: true,
      }),
      postRemoveTotp: ({
        resetCode,
        totpId,
        attemptId,
        emailCode,
      }: {
        resetCode: string;
        totpId: string;
        attemptId: string;
        emailCode: string;
      }) => ({
        method: 'POST',
        url: POST_REMOVE_TOTP,
        body: {
          resetCode,
          totpId,
          attemptId,
          emailCode,
        },
        toastWhenCode: false,
        loader: true,
      }),
      putPhone: ({ actionToken, recaptcha, phone }: { actionToken: string; recaptcha: string; phone: string }) => ({
        method: 'PUT',
        token: 'main',
        url: PUT_PHONE,
        headers: {
          'action-token': actionToken,
          recaptcha,
          action: API_RECAPTCHA_ACTION_PHONE,
        },
        body: {
          phone,
        },
        toastWhenCode: false,
      }),
      postConfirmPhone: ({
        attemptId,
        code,
        actionToken,
      }: {
        attemptId: string;
        code: string;
        actionToken: string;
      }) => ({
        method: 'POST',
        url: POST_CONFIRM_PHONE,
        headers: {
          'state-token': actionToken,
        },
        body: {
          attemptId,
          code,
        },
        token: 'main',
        toastWhenCode: false,
      }),
      deletePhone: ({ actionToken }: { actionToken: string }) => ({
        method: 'DELETE',
        url: DELETE_PHONE,
        headers: {
          'action-token': actionToken,
        },
        token: 'main',
      }),
      patchContacts: ({ body = {}, successMsg = '' }: { body: RequestPatchContacts; successMsg?: string }) => ({
        method: 'PATCH',
        url: PATCH_CONTACTS,
        body,
        token: 'main',
        answer: true,
        successMsg,
      }),
    },
  });
};

export default apiInit;
