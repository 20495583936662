import { useTimer, useToast } from 'library-react-hooks';
import { isNumber, isObject } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AnswerPostConfirmAddEmail,
  AnswerPostConfirmEmail,
  AnswerPutAddEmail,
  AnswerPutEmail,
} from '../../api/answer.types';
import { API_ERROR_CODE_EMAIL_ALREADY_VERIFIED } from '../../constants/api';
import {
  ROUTE_FULL_EMAIL_CHANGED,
  ROUTE_FULL_EMAIL_CODE,
  ROUTE_FULL_EMAIL_EDIT,
  ROUTE_FULL_EMAIL_SENT,
  ROUTE_FULL_EMAIL_VERIFIED,
  ROUTE_FULL_EMAIL_VERIFY,
  ROUTE_FULL_EMAIL_VIEW,
  ROUTE_SECURITY,
} from '../../constants/routes';
import useHttp from '../../hooks/http';
import usePrivatePages, { privatePagesSet } from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { sentryException } from '../../utils/sentry';
import { withSlash } from '../../utils/url';
import { ALERT_GROUP_FLOW, TIMER_NAME_FLOW } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, IEmailChangeData, IFlowEmailChange } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';
import { resetSecuritySteps } from '../resetFlows';

const initialParams: IEmailChangeData['_props'] = {
  emailNew: '',
  status: '', // 'success' | 'fail'
};
const data: IEmailChangeData = {
  _props: { ...initialParams },
  getProp(name) {
    return this._props[name];
  },
  setProp(name, value) {
    this._props[name] = value;
  },
  reset() {
    this._props = { ...initialParams };
  },
};

const usingPrivatePages: { [K in 'view' | 'sent' | 'code' | 'edit' | 'changed' | 'verify' | 'verified']: string } = {
  view: ROUTE_FULL_EMAIL_VIEW,
  sent: ROUTE_FULL_EMAIL_SENT,
  code: ROUTE_FULL_EMAIL_CODE,
  edit: ROUTE_FULL_EMAIL_EDIT,
  changed: ROUTE_FULL_EMAIL_CHANGED,
  verify: ROUTE_FULL_EMAIL_VERIFY,
  verified: ROUTE_FULL_EMAIL_VERIFIED,
};

export const flowEmailChangeReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
  data.reset();
};

const useFlowEmailChange = (): IFlowEmailChange => {
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { requestByName, ready, errorCode } = useHttp();
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();
  const { clear } = useToast();
  const { cancelTimer } = useTimer();
  const { update } = useStore([]);

  // Ключи текстов
  const [error, setError] = useState<IFlowEmailChange['error']>(null);
  useEffect(() => {
    if (errorCode?.putEmail) {
      setError(errorCode.putEmail);
    }
    if (errorCode?.postConfirmEmail) {
      setError(errorCode.postConfirmEmail);
    }
    if (errorCode?.putAddEmail) {
      setError(errorCode.putAddEmail);
    }
    if (errorCode?.postConfirmAddEmail) {
      setError(errorCode.postConfirmAddEmail);
    }
  }, [errorCode]);

  const clearTimers = useCallback(() => {
    cancelTimer(TIMER_NAME_FLOW);
    clear(ALERT_GROUP_FLOW);
  }, [cancelTimer, clear]);

  const sendEmailChange = useCallback(
    async (emailNew: string) => {
      data.setProp('emailNew', emailNew);

      const token = flowsData.getToken(EFlowsDataStep.flow);
      if (ready('putEmail') && token) {
        const fetched = (await requestByName('putEmail', {
          actionToken: token,
          email: emailNew,
        })) as AnswerPutEmail;
        if (isObject(fetched) && fetched.success) {
          clearTimers();
          setPage(usingPrivatePages.sent, { available: true });
          navigate(usingPrivatePages.sent);
        }
      } else sentryException('FlowEmailChange hook: putEmail api is not ready', 'warning');
    },
    [clearTimers, navigate, ready, requestByName, setPage],
  );
  const sendConfirmChange = useCallback(
    async (actionToken: string) => {
      flowsData.setBasePage(withSlash(ROUTE_SECURITY)); // Т к был разрыв

      if (!actionToken) {
        navigate(withSlash(ROUTE_SECURITY));
        console.error('В URL не обнаружен токен');
        sentryException('FlowEmailChange hook: URL haven`t token', 'warning');
        return;
      }
      if (ready('postConfirmEmail')) {
        const fetched = (await requestByName('postConfirmEmail', {
          actionToken,
        })) as AnswerPostConfirmEmail;

        if (isObject(fetched) && fetched.success) {
          data.setProp('status', 'success');
          update('user');
        } else {
          data.setProp('status', 'fail');
        }
        setPage(usingPrivatePages.changed, { available: true });
        navigate(usingPrivatePages.changed);
      } else sentryException('FlowEmailChange hook: postConfirmEmail api is not ready', 'warning');
    },
    [navigate, ready, requestByName, setPage, update],
  );

  const sendEmailAdd = useCallback(
    async (emailNew: string) => {
      data.setProp('emailNew', emailNew);

      const token = flowsData.getToken(EFlowsDataStep.flow);
      if (ready('putAddEmail') && token) {
        const fetched = (await requestByName('putAddEmail', {
          actionToken: token,
          email: emailNew,
        })) as AnswerPutAddEmail;
        if (isObject(fetched) && fetched.success) {
          clearTimers();
          setPage(usingPrivatePages.sent, { available: true });
          navigate(usingPrivatePages.sent);
        }
      } else sentryException('FlowEmailVerify hook: putEmail api is not ready', 'warning');
    },
    [clearTimers, navigate, ready, requestByName, setPage],
  );
  const sendConfirmAdd = useCallback(
    async (actionToken: string) => {
      flowsData.setBasePage(withSlash(ROUTE_SECURITY)); // Т к был разрыв

      if (!actionToken) {
        navigate(withSlash(ROUTE_SECURITY));
        console.error('В URL не обнаружен токен');
        sentryException('FlowEmailVerify hook: URL haven`t token', 'warning');
        return;
      }
      if (ready('postConfirmAddEmail')) {
        const fetched = (await requestByName('postConfirmAddEmail', {
          actionToken,
        })) as AnswerPostConfirmAddEmail;

        if (
          (isObject(fetched) && fetched.success) ||
          (isNumber(fetched) && fetched === API_ERROR_CODE_EMAIL_ALREADY_VERIFIED)
        ) {
          update('user');
          data.setProp('status', 'success');
        } else {
          data.setProp('status', 'fail');
        }
        setPage(usingPrivatePages.verified, { available: true });
        navigate(usingPrivatePages.verified);
      } else sentryException('FlowEmailVerify hook: postConfirmEmail api is not ready', 'warning');
    },
    [navigate, ready, requestByName, setPage, update],
  );

  const reset = useCallback(async () => {
    resetSecuritySteps();
    data.reset();
    clearTimers();
    await resetNavigate(() => setPages(false));
  }, [clearTimers, resetNavigate, setPages]);

  return {
    sendConfirmChange,
    error,
    reset,
    sendEmailChange,
    emailNew: useMemo(() => data.getProp('emailNew'), []),
    status: useMemo(() => data.getProp('status'), []),
    sendEmailAdd,
    sendConfirmAdd,
  };
};

export default useFlowEmailChange;
