import { TFunction } from 'i18next';
import { DependencyList, Dispatch, EffectCallback, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { secondsShorted, timeRound } from '../utils/time';

export const useUpdateState = <S>(initial: S | (() => S)): [S, Dispatch<SetStateAction<S>>] => {
  const [state, setState] = useState<S>(initial);
  useEffect(() => setState(initial), [initial]);
  return [state, setState];
};

// Плохо работает
export const useOnceEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
  condition = true,
  clean: () => void | undefined = () => {},
): void => {
  const [done, setDone] = useState<boolean>(false);
  useEffect(() => {
    if (!done && condition) {
      effect();
      setDone(true);
    }
  }, [deps, effect, done, condition]);
  useEffect(() => {
    return () => {
      if (!condition) clean();
    };
  }, [clean, condition]);
};

const stringJoin = (str1: string, str2: string): string => {
  if (!str1) return str2;
  return str1 + ' ' + str2;
};
export const useTime = (): ((
  seconds: number,
  props?: Partial<{ short: boolean; round: boolean; fn: TFunction<'translation', undefined>; lng: string }>,
) => string) => {
  const { t, i18n } = useTranslation();
  return useCallback(
    (
      seconds: number,
      props?: Partial<{ short: boolean; round: boolean; fn: TFunction<'translation', undefined>; lng: string }>,
    ): string => {
      const lng = props?.lng || i18n.language;
      const fn = props?.fn || t;
      let timeObj = secondsShorted(seconds);
      if (props?.round) timeObj = timeRound(timeObj);
      let result = '';

      if (timeObj.days) result = fn('dimension.day', { count: timeObj.days, lng });
      if (timeObj.hours || (timeObj.days && !props?.short))
        result = stringJoin(result, fn('dimension.hour', { count: timeObj.hours, lng }));
      if (timeObj.minutes || ((timeObj.hours || timeObj.days) && !props?.short))
        result = stringJoin(result, fn('dimension.minute', { count: timeObj.minutes, lng }));

      if (!props?.short) result = stringJoin(result, fn('dimension.second', { count: timeObj.seconds, lng }));
      return result;
    },
    [i18n.language, t],
  );
};
