import { Button, ButtonThemes, ButtonTypes, ButtonVariant } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../containers/Page';
import { useFlowDeleteAccount } from '../../scenarios/flows';
import styles from './DeleteAccountDeleted.module.scss';
import DeletedSuccess from './DeletedSuccess';

const DeleteAccountDeleted: React.FC = () => {
  const { t } = useTranslation();
  const { reset } = useFlowDeleteAccount();

  return (
    <Page menu subHead={{ title: t('page.accountActions.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <DeletedSuccess />
        <div className={classNames(styles.common_margin__m, styles.common_buttons)}>
          <Button
            text={t('action.toSecurity').toString()}
            type={ButtonTypes.button}
            onClick={() => reset()}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.labelsSecondary}
          />
        </div>
      </div>
    </Page>
  );
};

export default React.memo(DeleteAccountDeleted);
