import { DataI, THttpTranslationFn } from './http.types';

const initialTranslationFn: THttpTranslationFn = (key, _options) => key;

const data: DataI = {
  _listeners: [],
  _event(e) {
    this._listeners.forEach((listener) => listener(e));
  },
  loader: true,
  toast: true,
  blockRepeatWhenPending: false,
  toastWhenBackendUnavailable: true,
  mocked: false,
  tokens: {},
  requests: {},
  mocks: {},
  errors: {},
  errorCodes: {},
  states: {},
  onError(_response) {},
  responseFormat(d) {
    return d;
  },
  setOnError(fn) {
    this.onError = fn;
  },
  setInit(props) {
    if ('loader' in props && typeof props.loader === 'boolean') this.loader = props.loader;
    if ('toast' in props && typeof props.toast === 'boolean') this.toast = props.toast;
    if ('tokens' in props && typeof props.tokens === 'object') this.tokens = props.tokens;
    if ('requests' in props && typeof props.requests === 'object') this.requests = props.requests;
    if ('onError' in props && typeof props.onError === 'function') this.onError = props.onError;
    if ('errors' in props && typeof props.errors === 'object') this.errors = props.errors;
    if ('errorCodes' in props && typeof props.errorCodes === 'object') this.errorCodes = props.errorCodes;
    if ('responseFormat' in props && typeof props.responseFormat === 'function')
      this.responseFormat = props.responseFormat;
    if ('blockRepeatWhenPending' in props && typeof props.blockRepeatWhenPending === 'boolean')
      this.blockRepeatWhenPending = props.blockRepeatWhenPending;
    if ('toastWhenBackendUnavailable' in props && typeof props.toastWhenBackendUnavailable === 'boolean')
      this.toastWhenBackendUnavailable = props.toastWhenBackendUnavailable;
    if ('mocked' in props && typeof props.mocked === 'boolean') this.mocked = props.mocked;
    if ('mocks' in props && typeof props.mocks === 'object') this.mocks = props.mocks;
  },
  getLoader() {
    return this.loader;
  },
  getToast() {
    return this.toast;
  },
  getToken(name) {
    return Object.keys(this.tokens).find((key) => key === name);
  },
  setToken(name, value) {
    if (name in this.tokens) {
      this.tokens[name] = value;
      this._event({ action: 'token', type: 'change', status: !!value });
    }
  },
  getError(status) {
    return this.errors[status];
  },
  getErrorCode(code: number) {
    return this.errorCodes[code];
  },
  getRequest(name, props) {
    if (name in this.requests) return this.requests[name](props);
    return;
  },
  getTokens() {
    return this.tokens;
  },
  checkReady(requestName) {
    if (!(requestName in this.requests)) return true;
    const tokenName = this.requests[requestName]({}).token;
    if (!tokenName) return true;
    return !!this.tokens[tokenName];
  },
  setState(url, state) {
    this.states[url] = state;
  },
  getState(url) {
    return this.states?.[url];
  },
  errorHandler: null,
  translationFn: initialTranslationFn,
  setTranslationFn(fn) {
    this.translationFn = fn;
  },
  on(fn) {
    this._listeners.push(fn);
    return () => (this._listeners = this._listeners.filter((listener) => listener !== fn));
  },
  reset() {
    this._listeners = [];
    this.loader = true;
    this.toast = true;
    this.tokens = {};
    this.requests = {};
    this.errors = {};
    this.states = {};
    this.onError = (_response) => {};
    this.responseFormat = (d) => {
      return d;
    };
    this.translationFn = initialTranslationFn;
  },
};

export default data;
