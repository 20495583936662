import { useLoader, useUnmount } from 'library-react-hooks';
import { useEffect, useState } from 'react';

// FIXME
const useOnLoad = (loaded: boolean, _debugName?: string) => {
  const { loaderOn, loaderOff } = useLoader();

  const [state, setState] = useState<null | 'started' | 'done'>(null);
  useEffect(() => {
    if (state === null && !loaded) {
      // console.log('loaderOn');
      loaderOn();
      setState('started');
    }
    if (state === 'started' && loaded) {
      // console.log('loaderOff done');
      loaderOff();
      setState('done');
    }
  }, [loaded, loaderOff, loaderOn, state]);
  useUnmount(() => {
    if (state === 'started' && !loaded) {
      // console.log('loaderOff cleared');
      loaderOff();
      setState('done');
    }
  });
};

export default useOnLoad;
