import { ReducerWithoutAction, useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { AnswerGetLoginHistory } from '../api/answer.types';
import { sentryException } from '../utils/sentry';
import useHttp from './http';

export interface ILoginHistory {
  client: string;
  ipAddress: string;
  time: string;
}
export type TUserLoginHistory = {
  loginHistory: ILoginHistory[] | null;
  moreExists: boolean;
  showMore: () => void;
};

const cleanDuplicate = <T>(array: T[]): T[] => {
  return [...new Set(array.map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));
};

/**
 * Сначала грузим defaultValue (например 5)
 * По клику на показать еще подгружаем 50 (включая первые 5) и добавляем еще 5 в видимые
 * @param defaultValue
 */
const useLoginHistory = (defaultValue: 5 | 10 | 50): TUserLoginHistory => {
  const { requestByName, ready } = useHttp();

  const [loginHistory, setLoginHistory] = useState<TUserLoginHistory['loginHistory']>(null);
  const [canDownloadMore, setCanDownloadMore] = useState<TUserLoginHistory['moreExists']>(false);
  const [pages, setPages] = useReducer<ReducerWithoutAction<number>>((prev) => prev + 1, 0);
  const [visibleHistory, setVisibleHistory] = useState<TUserLoginHistory['loginHistory']>(null);
  const moreExists = useMemo(
    () => (loginHistory?.length || 0) > (visibleHistory?.length || 0) || canDownloadMore,
    [loginHistory, canDownloadMore, visibleHistory],
  );

  const updateVisibleHistory = useCallback(
    (loadedHistory: ILoginHistory[], type: 'update' | 'enrich' = 'enrich') => {
      setVisibleHistory((prev) => {
        const recent = prev || [];
        const length = recent.length;
        if (type === 'update') return loadedHistory.slice(0, length + defaultValue);
        return [...recent, ...loadedHistory.slice(length, length + defaultValue)];
      });
    },
    [defaultValue],
  );

  const getLoginHistory = useCallback(
    async (type: 'update' | 'enrich' = 'update', page = 1, max = defaultValue) => {
      const fetched = (await requestByName('getLoginHistory', { page, max })) as AnswerGetLoginHistory;
      if (Array.isArray(fetched)) {
        setCanDownloadMore(!(fetched.length === 0 || fetched.length % max !== 0));
        let newHistory = fetched;
        setLoginHistory((prev) => {
          if (!prev || prev.length === 0 || type === 'update') return fetched;
          newHistory = cleanDuplicate<ILoginHistory>([...prev, ...fetched]);
          return newHistory;
        });
        updateVisibleHistory(newHistory, type);
      } else {
        console.error('Что-то пошло не так', fetched);
        sentryException('loginHistory hook: something went wrong', 'warning');
      }
    },
    [defaultValue, requestByName, updateVisibleHistory],
  );

  const getMore = useCallback(async () => {
    if (moreExists) {
      await getLoginHistory(pages === 0 ? 'update' : 'enrich', pages + 1, 10);
      setPages();
    }
  }, [getLoginHistory, moreExists, pages]);

  const showMore = useCallback(async () => {
    const loginLength = loginHistory?.length;
    const visibleLength = visibleHistory?.length;
    if (loginLength && visibleLength && moreExists) {
      if (loginLength <= visibleLength) {
        await getMore();
      } else updateVisibleHistory(loginHistory, 'enrich');
    }
  }, [getMore, loginHistory, moreExists, updateVisibleHistory, visibleHistory?.length]);

  useEffect(() => {
    if (loginHistory === null && ready('getLoginHistory')) getLoginHistory('update', 1, defaultValue);
  }, [defaultValue, getLoginHistory, loginHistory, ready]);

  return { loginHistory: visibleHistory, moreExists, showMore };
};

export default useLoginHistory;
