import { useCallback } from 'react';

import { EFlowsDataStep, EFlowType, IMapper } from '../flows.types';
import useMapperEmailCode from './mapperEmailCode.hook';
import useMapperFlow from './mapperFlow.hook';
import useMapperInputCode from './mapperInputCode.hook';
import useMapperTotp from './mapperTotp.hook';

const useMapper = (): IMapper => {
  const { clearTotp, goVerifyTotp } = useMapperTotp();
  const { clearEmailCode, goVerifyEmailCode } = useMapperEmailCode();
  const { clearInputCode, goVerifyInputCode } = useMapperInputCode();
  const { clearFlow, goFlow } = useMapperFlow();

  const goInputCode = useCallback(
    (type, expired) => {
      goVerifyInputCode(type, expired);
      clearTotp();
      clearEmailCode();
    },
    [clearEmailCode, clearTotp, goVerifyInputCode],
  );
  const goNext: IMapper['goNext'] = useCallback(
    (type, { step, expired }) => {
      switch (step) {
        case EFlowsDataStep.totp: {
          goVerifyTotp(expired);
          break;
        }
        case EFlowsDataStep.emailCode: {
          goVerifyEmailCode(type, expired);
          clearTotp();
          break;
        }
        case EFlowsDataStep.flow: {
          goFlow(type, expired);
          clearTotp();
          clearInputCode();
          // Для флоу, не стартующих с этой же страницы
          if (type !== EFlowType.removePhone) clearEmailCode();
          break;
        }
        default: {
          break;
        }
      }
    },
    [clearEmailCode, clearInputCode, clearTotp, goFlow, goVerifyEmailCode, goVerifyTotp],
  );

  const clear = useCallback(() => {
    clearTotp();
    clearEmailCode();
    clearInputCode();
    clearFlow();
  }, [clearEmailCode, clearFlow, clearInputCode, clearTotp]);

  return { goNext, goFlow, goInputCode, clear };
};

export default useMapper;
