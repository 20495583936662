import { Icon, IconSizes, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import AppContext from '../../context/AppContext';
import styles from './DeleteAccountDeleted.module.scss';

const DeletedSuccess: React.VFC = () => {
  const { t } = useTranslation();
  const { config } = React.useContext(AppContext);

  return (
    <div className={classNames(styles.page_block, styles.page_block__border)}>
      <div className={styles.this_iconBlock}>
        <Icon
          IconComponent={IconCheckmark}
          size={IconSizes.l}
          color={TextAndIconColors.actionConfirmPrimary}
          className={styles.page_iconBlock__icon}
        />
        <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
          {t('page.deleteAccountDeleted.success.subTitle')}
        </Text>
      </div>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__xs}>
        {t('page.deleteAccountDeleted.success.description')}
      </Text>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__s}>
        {t('page.deleteAccountDeleted.success.forCancel') + ' '}
        <Link
          text={config.supportEmail}
          href={'mailto:' + config.supportEmail}
          underline={false}
          color={TextAndIconColors.interfaceAccentTertiary}
          variant={TextVariants.body3}
        />
      </Text>
    </div>
  );
};

export default DeletedSuccess;
