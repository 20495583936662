import { useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback } from 'react';

import { AnswerDeleteTotpCredential } from '../../api/answer.types';
import { ROUTE_FULL_TOTP_REMOVE } from '../../constants/routes';
import useHttp from '../../hooks/http';
import { privatePagesSet } from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { sentryException } from '../../utils/sentry';
import flowsData from '../flows.data';
import { EFlowsDataStep, IFlowTotp } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';
import { resetSecuritySteps } from '../resetFlows';

const usingPrivatePages: { [K in 'remove']: string } = {
  remove: ROUTE_FULL_TOTP_REMOVE,
};

export const flowTotpReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useFlowTotp = (): IFlowTotp => {
  const { requestByName, ready } = useHttp();
  const { alert } = useToast();
  const { update } = useStore(['credentials']);
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();

  const reset = useCallback(async () => {
    resetSecuritySteps();
    await resetNavigate(() => setPages(false));
  }, [resetNavigate, setPages]);

  const actionToContinueRemove = useCallback(async () => {
    const token = flowsData.getToken(EFlowsDataStep.flow);
    const totpId = flowsData.getProp('totpId');
    if (ready('deleteTotpCredential') && token && totpId) {
      const fetched = (await requestByName('deleteTotpCredential', {
        id: totpId,
        actionToken: token,
      })) as AnswerDeleteTotpCredential;
      if (isObject(fetched) && fetched.success) {
        await update('credentials');
        await update('user');
        alert({
          type: 'success',
          titleData: { key: 'message.successTotpRemove' },
        });
      }
      await reset();
    } else sentryException('FlowTotp hook: deleteTotpCredential api is not ready', 'warning');
  }, [ready, requestByName, reset, update, alert]);

  return { reset, actionToContinueRemove };
};

export default useFlowTotp;
