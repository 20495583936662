import { sentryException } from './sentry';

const getPairs = (obj: Record<string, unknown>, keys = []) =>
  Object.entries(obj).reduce((pairs, [key, value]) => {
    if (typeof value === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pairs.push(...getPairs(value, [...keys, key]));
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pairs.push([[...keys, key], value]);
    }
    return pairs;
  }, []);

export const convertQuery = (object: unknown): string => {
  if (typeof object === 'string') return object;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getPairs(object)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .map(([[key0, ...keysRest], value]) => `${key0}${keysRest.map((a) => `[${a}]`).join('')}=${value}`)
      .join('&')
  );
};

export const openLink = (href: string): void => {
  window.open(href, '_self');
};

export const openLinkBlank = (href: string): void => {
  window.open(href, '_blank');
};

export const hashParse = (hash: string): Record<string, string> =>
  Object.fromEntries(
    hash
      .replaceAll('#', '')
      .split('&')
      .map((item) => item.split('='))
      .filter((item) => item[0] && item[1]),
  );

export const searchParse = (search: string): Record<string, string> =>
  Object.fromEntries(
    search
      .replaceAll('?', '')
      .split('&')
      .map((item) => item.split('='))
      .filter((item) => item[0] && item[1]),
  );

const beginSlash = (data: string) => (data[0] === '/' ? data : `/${data}`);

export const withSlash = (data: string | Array<string | undefined>): string => {
  if (Array.isArray(data)) {
    return data.reduce((result: string, item) => (item ? result + beginSlash(item) : result), '').replaceAll('//', '/');
  }
  return beginSlash(data);
};

export const cleanDomain = (data: string): string => {
  if (/^(http|https|mailto):\/\//.test(data)) {
    try {
      const url = new URL(data);
      return url.pathname;
    } catch {
      return data;
    }
  } else {
    return withSlash(data);
  }
};

const getParams = (search: string): Record<string, string> => {
  const list: Record<string, string> = {};
  search
    .replace('?', '')
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=');
      if (key && value) {
        list[key] = value;
      }
    });
  return list;
};
export const getParamsValueByKey = (url: string, key: string): string | null => {
  let result: string | null = '';
  try {
    const urlObject = new URL(url);

    // urlObject.searchParams.get(key) декодируют '+'
    const params = getParams(urlObject.search);
    if (params[key]) {
      result = decodeURIComponent(params[key]);
    } else if (urlObject.search.includes(key)) {
      result = null;
    }
  } catch {
    result = null;
    sentryException(`Invalid URL: ${url}`, 'warning');
  }
  return result;
};

export const getMainPath = (pathname: string): string => {
  return pathname.split('/').find(Boolean) || '';
};
