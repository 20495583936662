import MockRequests from 'mock-requests';

import {
  AnswerGetAvailableProviders,
  AnswerGetCredentials,
  AnswerGetFulfill,
  AnswerGetLoginHistory,
  AnswerGetSessions,
  AnswerGetTotpAttempt,
  AnswerGetUser,
  AnswerPostConfirmPhone,
  AnswerPutPhone,
  TAnswerSuccess,
  TPostSecurityFlow,
} from './answer.types';
import {
  DELETE_ACCOUNT,
  DELETE_PHONE,
  DELETE_SESSION,
  DELETE_SESSIONS,
  DELETE_SOCIAL_LINK,
  DELETE_TOTP_CREDENTIAL,
  GET_AVAILABLE_PROVIDERS,
  GET_CREDENTIALS,
  GET_FULFILL_URL,
  GET_LOGIN_HISTORY,
  GET_SESSIONS,
  GET_TOTP_ATTEMPT,
  GET_USER_URL,
  PATCH_PROFILE,
  POST_CONFIRM_EMAIL,
  POST_CONFIRM_PHONE,
  POST_FULFILL_URL,
  POST_REMOVE_TOTP,
  POST_SECURITY_FLOW,
  POST_SOCIAL_LINK,
  PUT_EMAIL,
  PUT_LOCALE,
  PUT_PASSWORD,
  PUT_PHONE,
  PUT_UPDATE_EMAIL,
} from './urls';

const success: { data: TAnswerSuccess } = { data: { success: true } };
const getUser: { data: AnswerGetUser } = {
  data: {
    birthday: '1990-01-01',
    country: 'RU',
    email: 'a****@admitad.com',
    emailVerified: true,
    firstName: 'Anna',
    id: '79206386-ce48-4db7-b492-14f2e8018fdc',
    languages: ['ru'],
    lastName: 'Bystrova',
    // @ts-ignore
    socialLinks: [{ identityProvider: 'google', userName: 'a.bystrova@admitad.com' }],
    // socialLinks: [],
    totp: false,
    username: 'a.bystrova@admitad.com',
    middleName: '',
    locale: 'ru',
    phone: '+7********88',
    phoneVerified: true,
    contacts: {
      phone: '+79061232323',
      phoneVerified: true,
      whatsApp: '+79061232323',
      whatsAppVerified: true,
      telegram: '@my_name',
      telegramVerified: true,
      viber: '123',
      viberVerified: true,
      skype: '',
      skypeVerified: false,
      icq: '12345678',
      icqVerified: true,
    },
  },
};
const getAvailableProviders: { data: AnswerGetAvailableProviders } = {
  data: { identityProviders: ['google', 'facebook', 'yandex', 'linkedin-openid-connect', 'vk'] },
};
const getCredentials: { data: AnswerGetCredentials } = {
  data: {
    totp: [
      {
        id: '1226bc69-f893-4351-8507-5efb9aa7cecc',
        userLabel: '1',
        createdDate: '2023-03-28T07:55:21.558Z',
      },
      {
        id: 'de902ff2-b3c6-48ba-99d8-7adc1ee9ca10',
        userLabel: '2',
        createdDate: '2023-03-28T07:55:44.721Z',
      },
    ],
    password: {
      enabled: true,
      createdDate: '2023-03-01T13:53:55.888Z',
    },
  },
};
const getSessions: { data: AnswerGetSessions } = {
  data: {
    sessions: [
      {
        id: 'd0135e39-5637-4329-81fb-20c4be6e2498',
        ipAddress: '172.20.0.1',
        start: '2023-03-22T20:38:19.000Z',
        lastAccess: '2023-03-22T21:05:45.000Z',
        clients: ['passport-client'],
      },
      {
        id: 'd49f13fc-b80c-4452-b316-b258a399b0b5',
        ipAddress: '172.20.0.1',
        start: '2023-03-22T21:05:25.000Z',
        lastAccess: '2023-03-22T21:05:25.000Z',
        clients: ['passport-client'],
      },
    ],
  },
};
const getLoginHistory: { data: AnswerGetLoginHistory } = {
  data: [
    {
      client: 'passport-client',
      ipAddress: '172.20.0.1',
      time: '2023-03-22T21:06:14.758Z',
    },
    {
      client: 'passport-client',
      ipAddress: '172.20.0.1',
      time: '2023-03-22T21:06:14.594Z',
    },
    {
      client: 'passport-client',
      ipAddress: '172.20.0.1',
      time: '2023-03-22T21:05:45.724Z',
    },
    {
      client: 'passport-client',
      ipAddress: '172.20.0.1',
      time: '2023-03-22T21:05:42.255Z',
    },
    {
      client: 'passport-client',
      ipAddress: '172.20.0.1',
      time: '2023-03-22T21:05:29.887Z',
    },
  ],
};
const postSecurityFlow: { data: TPostSecurityFlow } = {
  data: {
    done: true,
    actionToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb3JBY3Rpb24iOiJDSEFOR0VfRU1BSUwiLCJmbG93UG9zaXRpb24i',
  },
};
const getFulfill: { data: AnswerGetFulfill } = {
  data: {
    firstName: null,
    lastName: null,
    country: null,
    birthday: null,
  },
};
const getTotpAttempt: { data: AnswerGetTotpAttempt } = {
  data: {
    attemptId: '9efdceb9-8be6-4320-ac1b-14e0486ade1d',
    email: 'm*****@mail.ru',
    label: 'My Phone',
  },
};
const putPhone: { data: AnswerPutPhone } = {
  data: {
    stateToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb3JBY3Rpb24iOiJDSEFOR0VfRU1BSUwiLCJmbG93UG9zaXRpb24i',
    attemptId: '9efdceb9-8be6-4320-ac1b-14e0486ade1d',
    phone: '+798******69',
  },
};
const postConfirmPhone: { data: AnswerPostConfirmPhone } = { ...success };

export const mockRequests = () => {
  // add: all or partial
  MockRequests.configure({
    [GET_USER_URL]: { ...getUser },
    [GET_AVAILABLE_PROVIDERS]: getAvailableProviders,
    [GET_CREDENTIALS]: getCredentials,
    [GET_SESSIONS]: { ...getSessions },
    [DELETE_ACCOUNT]: success,
    [DELETE_SESSION]: success,
    [DELETE_SESSIONS]: success,
    [DELETE_SOCIAL_LINK]: success,
    [DELETE_TOTP_CREDENTIAL]: success,
    [GET_FULFILL_URL]: getFulfill,
    [GET_LOGIN_HISTORY + '?page=1&max=5']: { ...getLoginHistory },
    [PATCH_PROFILE]: success,
    [POST_CONFIRM_EMAIL]: success,
    [POST_FULFILL_URL]: success,
    [POST_SECURITY_FLOW]: postSecurityFlow,
    [POST_SOCIAL_LINK]: success,
    [PUT_EMAIL]: success,
    [PUT_LOCALE]: success,
    [PUT_PASSWORD]: success,
    [PUT_UPDATE_EMAIL]: success,
    [GET_TOTP_ATTEMPT + '?totpId=abbbaca0-f521-4d09-a06d-df149c950655']: { ...getTotpAttempt },
    [POST_REMOVE_TOTP]: success,
    [PUT_PHONE]: { ...putPhone },
    [POST_CONFIRM_PHONE]: { ...postConfirmPhone },
    [DELETE_PHONE]: success,
  });
  MockRequests.setMockUrlResponse(GET_USER_URL, { ...getUser });
  MockRequests.setMockUrlResponse(GET_SESSIONS, { ...getSessions });
};
