import { SocialTypesE } from '../components/Social';
import { LocaleE } from '../constants/locale.types';
import { IdpRules } from '../core/config/common.types';
import { IDataStore } from '../hooks/store/store.types';

export const idpProvidersFilter = (
  backendProviders: IDataStore['identityProviders'],
  frontProviders: SocialTypesE[],
  configRules: IdpRules | null,
  language: LocaleE,
): SocialTypesE[] => {
  if (!backendProviders || backendProviders.length === 0) return [];

  const availableProviders = backendProviders.filter((item) => frontProviders.includes(item));
  // Если нет доп. правил из конфига, то вернуть список всех с бэка & список доступных на фронте(иконки+тексты)
  if (!configRules) return availableProviders;

  return availableProviders.filter((item) => {
    const ruleForIdp: undefined | boolean | Array<LocaleE> = configRules[item];
    // Если соц. сеть не указана или разрешено отображение, то показать
    if (ruleForIdp === undefined || ruleForIdp === true) return true;
    // Если скрытие для всех локалей, то скрыть
    if (ruleForIdp === false) return false;
    // Если текущая локаль находится в списке доступных к показу, то показать
    return ruleForIdp.includes(language);
  });
};
