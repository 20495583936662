import ReactGA from 'react-ga4';
import { v4 as uuidV4 } from 'uuid';

import { LOCALE_DEFAULT } from '../../constants/locale';
import { intervalListener } from '../listeners';
import gaConfig from './config.json';
import { clientId, datetimeGreenwich, hitId, urlTags } from './functions';
import { TGaConfig } from './ga4.types';

// @ts-ignore
const config: TGaConfig = gaConfig;

type TTracker = { trackingId: string; gtagOptions: any };

export type TGaData = {
  config: TGaConfig;
  inited: boolean;
  trackers: TTracker[];
  needSend: boolean | null;
  keycloakId: string | null;
  clientId: string | undefined;
  locale: string;
  businessName: string;
  queue: Array<() => void>;
  enrichEvent4(trackingId: string): Record<string, unknown>;
  listen(): void;
  setInit(needSend: boolean): void;
  init(trackers: TTracker[]): void;
  setKeycloakId(keycloakId: string): void;
  setLocale(locale: string): void;
  setBusinessName(businessName: string): void;
  check(fn: () => void): boolean;
  sendEvent4(name: string, data: Record<string, unknown>): void;
  updateConfig(additionalConfig: Partial<TGaConfig>): void;
};
export const gaData: TGaData = {
  config,
  inited: false,
  trackers: [],
  needSend: null,
  keycloakId: null,
  clientId: 'undefined',
  locale: LOCALE_DEFAULT,
  businessName: 'undefined',
  queue: [],
  enrichEvent4(trackingId: string): Record<string, unknown> {
    return {
      send_to: trackingId,
      event_option3: this.businessName,
      event_option4: 'undefined',
      client_id_ga: clientId() + '.',
      hit_id: hitId(),
      uu_id: uuidV4(),
      uu_id_2: uuidV4(),
      datetime_greenwich: datetimeGreenwich(),
      datetime_greenwich_2: datetimeGreenwich(),
      locale: this.locale,
      keycloak_id: this.keycloakId || 'undefined',
      url_tags: urlTags(),
      ab_test: 'undefined',
      page_params: 'undefined',
      referrer_without_login_page: 'undefined',
      sendbox_id: 'undefined',
      program_id: 'undefined',
      advertiser_id: 'undefined',
      advcampaign_id: 'undefined',
      publisher_id: 'undefined',
      website_id: 'undefined',
      user_id: this.keycloakId || 'undefined', // только когда пользователь авторизован и у него есть keycloak_id ???
    };
  },
  listen() {
    intervalListener(
      () => Boolean('gtag' in global && clientId() && this.keycloakId), // keycloakId || 'undefined'
      () => {
        this.inited = true;
        this.clientId = clientId();
        this.queue.forEach((fn) => {
          fn();
        });
        this.queue = [];
      },
      5,
      1000,
    );
    this.listen = () => {};
  },
  setInit(needSend) {
    this.needSend = needSend;
    if (needSend) this.listen();
  },
  init(trackers) {
    ReactGA.initialize(trackers, {
      legacyDimensionMetric: false,
    });
    trackers.forEach((item) => {
      ReactGA.event('initialize_cookie', {
        send_to: item.trackingId,
        non_interaction: true,
        // event_callback: function () {
        //   console.log('Custom event sent, _ga cookie should be set.');
        // },
      });
    });

    this.trackers = trackers;
    this.setInit(true);
  },
  setKeycloakId(keycloakId) {
    this.keycloakId = keycloakId;
  },
  setLocale(locale: string) {
    this.locale = locale;
  },
  setBusinessName(businessName: string) {
    this.businessName = businessName.toLowerCase();
  },
  check(fn) {
    if (this.needSend === false) return false;
    if (this.needSend && this.inited) return true;

    // Если пока не понятно нужно ли отправлять и инит еще не произошел, то сохраняем функцию.
    this.queue.push(fn);
    return false;
  },
  sendEvent4(name, data) {
    if (!this.check(() => this.sendEvent4(name, data))) return;

    this.trackers.forEach((item) => {
      ReactGA.event(name, {
        ...this.enrichEvent4(item.trackingId),
        ...data,
      });
    });
  },
  updateConfig(additionalConfig: Partial<TGaConfig>) {
    this.config = { ...this.config, ...additionalConfig };
  },
};

export const gaSetKeycloakId = (keycloakId: string): void => gaData.setKeycloakId(keycloakId);
export const gaSetLocale = (locale: string) => gaData.setLocale(locale);
export const gaInit = (trackers: TTracker[]): void => gaData.init(trackers);
export const gaNotSendEvents = (): void => gaData.setInit(false);
export const setBusinessName = (businessName: string) => gaData.setBusinessName(businessName);
export const gaUpdateConfig = (additionalConfig: Partial<TGaConfig>) => gaData.updateConfig(additionalConfig);
