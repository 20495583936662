import './locales';

import React, { lazy, useMemo } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';

import styles from './App.module.scss';
import Loader from './components/Loader';
import Toast from './components/Toast';
import { LocaleE } from './constants/locale.types';
import { ModeT } from './constants/mode';
import { ROUTE_EXTERNAL_LIST } from './constants/routes';
import AppContext from './context/AppContext';
import { IConfig } from './core';
import { TEnvironmentProps, withExternal, withInternal } from './hocs/Environment';
import { HistoryHash } from './hocs/History';
import ScrollToTop from './hocs/ScrollToTop';
import usePath from './hooks/path.hook';
import useStandardErrors from './hooks/standardErrors.hook';
import { componentLoader } from './utils/componentLoader';
import { getMainPath } from './utils/url';

const MockMenu = lazy(() => componentLoader(() => import('./modules/DebugMenu/DebugMenu')));

const AppComponent: React.VFC<TEnvironmentProps> = ({ routesConfig, ready, showMenu }) => {
  const routes = useRoutes(routesConfig);
  return (
    <>
      <div className={styles.wrapper}>
        {ready && routes}
        <Loader />
      </div>
      {showMenu && <MockMenu />}
    </>
  );
};

const ExternalApp = withExternal(AppComponent);
const InternalApp = withInternal(AppComponent);

type TAppProps = {
  mode: ModeT;
  config: IConfig;
  supportedLocales: Array<LocaleE>;
};
const App: React.VFC<TAppProps> = ({ mode, config, supportedLocales }) => {
  const path = usePath();
  const isExternal: boolean = useMemo(() => ROUTE_EXTERNAL_LIST.includes(getMainPath(path)), [path]);

  useStandardErrors();
  // useImagePreloader(
  //   {
  //     'errors40x.png': () => import('./assets/images/errors40x.png'),
  //     'errors50x.png': () => import('./assets/images/errors50x.png'),
  //     'forbidden.png': () => import('./assets/images/forbidden.png'),
  //     'notFound.png': () => import('./assets/images/notFound.png'),
  //   },
  //   'base64',
  // );

  return (
    <AppContext.Provider value={{ mode, config, supportedLocales }}>
      <BrowserRouter>
        {isExternal ? <ExternalApp /> : <InternalApp />}
        <HistoryHash />
        <ScrollToTop />
        <Toast />
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
