import * as Sentry from '@sentry/browser';
import { SeverityLevel } from '@sentry/browser';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

type Environments = 'local' | 'development' | 'production';
const ENVIRONMENT = process.env.REACT_APP_SENTRY_ENV as Environments;
const ENV_LOCAL: Environments = 'local';

type SentryStorageTypes = {
  data: {
    language: string;
    status: string;
  };
  put(key: keyof SentryStorageTypes['data'], value: string): void;
  get(key: keyof SentryStorageTypes['data']): string;
};
export const sentryStorage: SentryStorageTypes = {
  data: {
    language: '',
    status: '',
  },
  put(key, value) {
    if (key in this.data) {
      this.data[key] = value;
    }
  },
  get(key) {
    return this.data[key];
  },
};

export const sentryInit = (version?: string): void => {
  if (ENVIRONMENT !== ENV_LOCAL) {
    Sentry.init({
      allowUrls: [/https?:\/\/((cdn|www)\.)?(stage-current.|develop.|stage-next.)?id.(giftmio|mitgo)\.com/],
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: version,
      // debug: true,
      beforeSend: (event) => {
        event.extra = {
          ...event.extra,
          // mode: MODE,
          url: document.location.href,
          language: sentryStorage.get('language'),
          status: sentryStorage.get('status'),
        };
        return event;
      },
    });
  }
};

export const sentrySetUser = (user: { id?: string; email?: string } | null): void => {
  Sentry.setUser(user);
};

export const sentryException = (
  text: string,
  level: SeverityLevel = 'error',
  extra?: Record<string, unknown>,
): void => {
  Sentry.captureException(new Error(text), { level, extra });
};
