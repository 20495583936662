import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';

import { emailAvatar, nameAvatar } from './Avatar.functions';
import styles from './Avatar.module.scss';
import { AvatarBackgroundColors, AvatarSizes, IAvatarProps } from './Avatar.types';

const Avatar: React.VFC<IAvatarProps> = ({
  name,
  email,
  className,
  initials,
  size = AvatarSizes.m,
  color = AvatarBackgroundColors.backgroundTertiary,
}) => {
  if (!(name || email || initials)) return null;

  const avatarInitials: string = initials || nameAvatar(name) || emailAvatar(email);

  const avatarClassName = classNames(styles.avatar, {
    [styles[`color_${color}`]]: color,
    [styles[`size_${size}`]]: size,
  });

  const labelText = name || email;

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={avatarClassName}>
        <Text color={TextAndIconColors.white} variant={TextVariants.body01bold}>
          {avatarInitials}
        </Text>
      </div>
      {labelText && (
        <Text variant={TextVariants.body1} color={TextAndIconColors.labelsSecondary} className={styles.cropText}>
          {labelText}
        </Text>
      )}
    </div>
  );
};

export default Avatar;
