import { LocaleE } from './locale.types';

export const LOCALE_DEFAULT: LocaleE = LocaleE.en;
export const LOCALE_SUPPORTED: LocaleE[] = Object.values(LocaleE);
// export const LOCALE_DEFAULT_LANGUAGE = 'English';
const checkLocale = (locale: string): boolean => LOCALE_SUPPORTED.includes(<LocaleE>locale);
export const localeNormalize = (key: string | undefined | null): LocaleE => {
  if (!key) return LOCALE_DEFAULT;

  let locale = key;
  if (checkLocale(locale)) return <LocaleE>locale;
  locale = key.toLowerCase();
  if (locale === 'pt' || locale === 'pt-br') return LocaleE.pt;
  if (checkLocale(locale)) return <LocaleE>locale;

  return LOCALE_DEFAULT;
};

type TLocale = `${LocaleE}`;
type TRule = Record<TLocale, string>;
const defaultRule = Object.fromEntries(
  Object.values<TLocale>(LocaleE).map<[TLocale, TLocale]>((item) => [item, item]),
) as TRule;
export const localeRules: Record<'full' | 'lowercase' | 'short' | 'default', Record<TLocale, string>> = {
  full: {
    [LocaleE.ru]: 'ru-ru',
    [LocaleE.en]: 'en-us',
    [LocaleE.de]: 'de-de',
    [LocaleE.es]: 'es-es',
    [LocaleE.pl]: 'pl-pl',
    [LocaleE.pt]: 'pt-br',
    [LocaleE.cs]: 'cs-cs',
    [LocaleE.uk]: 'uk-ua',
  },
  lowercase: Object.values(LocaleE).reduce((prev, value) => ({ ...prev, [value]: value.toLowerCase() }), defaultRule),
  short: Object.values(LocaleE).reduce((prev, value) => ({ ...prev, [value]: value.split('-')[0] }), defaultRule),
  default: Object.assign({}, defaultRule),
};

// Валидация слов(символов) для существующих локалей. Работает, но не нужно
// https://github.com/validatorjs/validator.js/blob/master/src/lib/alpha.js
// const regExpByLocale: {
//   [K in LocaleE]: string;
// } = {
//   [LocaleE.ru]: 'А-ЯЁ',
//   [LocaleE.en]: 'A-Z',
//   [LocaleE.de]: 'A-ZÄÖÜß',
//   [LocaleE.es]: 'A-ZÁÉÍÑÓÚÜ',
//   [LocaleE.pl]: 'A-ZĄĆĘŚŁŃÓŻŹ',
//   [LocaleE.pt]: 'A-ZÃÁÀÂÄÇÉÊËÍÏÕÓÔÖÚÜ',
// };
// export const LOCALE_SYMBOLS: string = Object.values(regExpByLocale).reduce((prev, item) => prev + item, '');
// export const LOCALE_WORD_REGEXP = new RegExp('^[' + LOCALE_SYMBOLS + ']+$', 'i');
