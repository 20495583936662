import { isArray } from 'lodash';

import { ICredentials, IData, IDataStore, IUser, TDataState } from './store.types';

const userInitial: IUser = {
  id: null,
  username: null,
  firstName: null,
  lastName: null,
  totp: null,
  country: null,
  birthday: null,
  locale: null,
  languages: null,
  email: null,
  emailVerified: null,
  socialLinks: [],
  middleName: null,
  phone: null,
  phoneVerified: null,
};

const credentialsInitial: ICredentials = {
  totp: null,
  password: null,
};

const storeInitial: IDataStore = {
  user: { ...userInitial },
  credentials: { ...credentialsInitial },
  sessions: null,
  identityProviders: null,
};

const stateInitial: TDataState = {
  user: null,
  credentials: null,
  sessions: null,
  identityProviders: null,
};

const data: IData = {
  _listeners: [],
  _state: { ...stateInitial },
  _store: { ...storeInitial },
  _event(e) {
    this._listeners.forEach((listener) => listener(e));
  },
  get state() {
    return this._state;
  },
  get store() {
    return this._store;
  },
  setState(type, state) {
    this._state[type] = state;
  },
  update(type, state, fetched) {
    this._store[type] = fetched;
    this._state[type] = state;
    this._event({ type, action: 'updated', state });
  },
  enrich(type, state, fetched) {
    this._state[type] = state;
    const recent = this._store[type];
    if (recent && isArray(recent)) {
      // @ts-ignore
      this._store[type] = [...recent, ...fetched];
    } else if (recent && typeof recent === 'object') {
      this._store[type] = { ...recent, ...fetched };
    } else if (!recent) {
      this._store[type] = fetched;
    }
    this._event({ type, action: 'updated', state });
  },
  on(fn) {
    this._listeners.push(fn);
    return () => (this._listeners = this._listeners.filter((listener) => listener !== fn));
  },
  reset() {
    this._listeners = [];
    this._store = { ...storeInitial };
    this._state = { ...stateInitial };
  },
};

export default data as Readonly<IData>;
