import { Icon, IconSizes, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';

import { ReactComponent as DoneIcon } from '../../assets/icons/done.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile.svg';
import { ITotpCredential } from '../../hooks/store/store.types';
import styles from './Form2Fa.module.scss';

type TDevice = {
  otpCredential: ITotpCredential;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
};
const Device: React.VFC<TDevice> = ({ otpCredential, selected, setSelected }) => {
  return (
    <div
      key={otpCredential.id}
      className={classNames(styles.this_card, {
        [styles.this_card__selected]: otpCredential.id === selected,
      })}
      onClick={() => setSelected(otpCredential.id)}
    >
      {otpCredential.id === selected && (
        <Icon
          IconComponent={DoneIcon}
          color={TextAndIconColors.brandTertiary}
          height={12}
          width={12}
          className={styles.this_iconDone}
        />
      )}
      <input
        type='hidden'
        value={otpCredential.id}
        name={selected === otpCredential.id ? 'selectedCredentialId' : ''}
      />
      <div className={styles.this_cardContent}>
        <Icon
          IconComponent={MobileIcon}
          color={TextAndIconColors.brandTertiary}
          size={IconSizes.l}
          className={styles.this_iconDevice}
        />
        <Text variant={TextVariants.header9} className={styles.this_label}>
          {otpCredential.userLabel}
        </Text>
      </div>
    </div>
  );
};

export default Device;
