import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInputThemes,
  Input,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { isObject } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RequestPatchProfile } from '../../api';
import { AnswerPatchProfile } from '../../api/answer.types';
import { COUNTRY_WITH_MIDDLENAME } from '../../constants/country';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import Page from '../../containers/Page/Page';
import useHttp from '../../hooks/http';
import useInput from '../../hooks/input.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { withSlash } from '../../utils/url';
import { validationFirstname, validationLastname } from '../../utils/validation';
import styles from './NameEdit.module.scss';

const NameEdit: React.VFC = () => {
  const { t } = useTranslation();
  const { requestByName, ready, meta } = useHttp();
  const navigator = useNavigate();
  const { user, update, state } = useStore([]);

  const showMiddlename = useMemo(
    () => user?.middleName || COUNTRY_WITH_MIDDLENAME.has(user?.country || ''),
    [user?.country, user?.middleName],
  );
  const [firstname, changeFirstname, { error: errorFirstname, setError: setErrorFirstname }] = useInput<string>(
    user.firstName || '',
    { translate: t },
  );
  const [lastname, changeLastname, { error: errorLastname, setError: setErrorLastname }] = useInput<string>(
    user.lastName || '',
    { translate: t },
  );
  const [middlename, changeMiddlename, { error: errorMiddlename, setError: setErrorMiddlename }] = useInput<string>(
    user?.middleName || '',
    {
      translate: t,
    },
  );
  React.useEffect(() => {
    if (meta?.firstName) setErrorFirstname('message.errorValidationFirstname');
    if (meta?.lastName) setErrorLastname('message.errorValidationLastname');
    if (meta?.middleName) setErrorMiddlename('message.errorValidationMiddlename');
  }, [meta, setErrorFirstname, setErrorLastname, setErrorMiddlename]);

  const updateName = useCallback(async () => {
    let valid = true;
    if (!validationFirstname(firstname)) {
      valid = false;
      setErrorFirstname('message.errorValidationFirstname');
    }
    if (!validationLastname(lastname)) {
      valid = false;
      setErrorLastname('message.errorValidationLastname');
    }

    if (valid && ready('patchProfile')) {
      const body: RequestPatchProfile = {
        firstName: firstname,
        lastName: lastname,
      };
      if (showMiddlename) body.middleName = middlename;
      const fetched = (await requestByName('patchProfile', {
        body,
        successMsg: 'message.successSaved',
      })) as AnswerPatchProfile;
      if (isObject(fetched) && fetched.success) {
        ga4Event('mainInfoSaveClick', { placeholders: { element: 'name' } });
        await update('user');
        navigator(withSlash(ROUTE_PERSONAL_DATA));
      }
    }
  }, [
    firstname,
    lastname,
    middlename,
    navigator,
    ready,
    requestByName,
    setErrorFirstname,
    setErrorLastname,
    showMiddlename,
    update,
  ]);

  const buttonDisabled: boolean = !firstname || !lastname || Boolean(errorFirstname) || Boolean(errorLastname);

  useEffect(() => {
    const listener = listenKeydown('Enter', updateName, buttonDisabled);
    return () => {
      listener();
    };
  }, [updateName, buttonDisabled]);

  useOnLoad(Boolean(state.user), 'NameEdit');

  return (
    <Page menu subHead={{ title: t('page.nameEdit.title'), backLink: withSlash(ROUTE_PERSONAL_DATA) }}>
      <div className={classNames(styles.page_wrapper)}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.nameEdit.description')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
            {t('page.nameEdit.subTitle')}
          </Text>
          <div className={classNames(styles.page_block, styles.common_margin__l)}>
            <Input
              placeholder={t('field.lastname')}
              value={lastname}
              onChange={changeLastname}
              theme={errorLastname ? EInputThemes.error : EInputThemes.base}
              errorMessage={errorLastname}
              required
            />
            <Input
              placeholder={t('field.firstname')}
              value={firstname}
              onChange={changeFirstname}
              theme={errorFirstname ? EInputThemes.error : EInputThemes.base}
              errorMessage={errorFirstname}
              required
              className={styles.common_margin__m}
            />
            {showMiddlename && (
              <Input
                placeholder={t('field.middlename')}
                value={middlename}
                onChange={changeMiddlename}
                theme={errorMiddlename ? EInputThemes.error : EInputThemes.base}
                className={styles.common_margin__m}
                errorMessage={errorMiddlename}
              />
            )}
          </div>
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.cancel').toString()}
              type={ButtonTypes.button}
              onClick={() => {
                ga4Event('mainInfoCancelClick', { placeholders: { element: 'name' } });
                navigator(withSlash(ROUTE_PERSONAL_DATA));
              }}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
            />
            <Button
              text={t('action.save').toString()}
              type={ButtonTypes.button}
              onClick={updateName}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default NameEdit;
