import { useTimer, useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { AnswerPutPassword } from '../../api/answer.types';
import { ROUTE_FULL_PASSWORD_ADD, ROUTE_FULL_PASSWORD_EDIT } from '../../constants/routes';
import useHttp from '../../hooks/http';
import { privatePagesSet } from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { sentryException } from '../../utils/sentry';
import { ALERT_GROUP_FLOW, TIMER_NAME_FLOW } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, EFlowType, IFlowPassword } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';
import { resetSecuritySteps } from '../resetFlows';

const usingPrivatePages: { [K in 'add' | 'edit']: string } = {
  add: ROUTE_FULL_PASSWORD_ADD,
  edit: ROUTE_FULL_PASSWORD_EDIT,
};

export const flowPasswordReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useFlowPassword = (): IFlowPassword => {
  const { requestByName, ready, errorCode } = useHttp();
  const { update } = useStore(['credentials']);
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();
  const { alert, clear } = useToast();
  const { cancelTimer } = useTimer();

  // Ключи текстов
  const [error, setError] = useState<IFlowPassword['error']>(null);
  useEffect(() => {
    if (errorCode?.putPassword) {
      setError(errorCode.putPassword);
    }
  }, [errorCode.putPassword]);

  const reset = useCallback(async () => {
    resetSecuritySteps();
    cancelTimer(TIMER_NAME_FLOW);
    clear(ALERT_GROUP_FLOW);
    await resetNavigate(() => setPages(false));
  }, [cancelTimer, clear, resetNavigate, setPages]);

  const putPassword = useCallback(
    async (currentPassword: string | null, newPassword: string, confirmNewPassword: string) => {
      const token = flowsData.getToken(EFlowsDataStep.flow);
      if (ready('putPassword') && token) {
        const fetched = (await requestByName('putPassword', {
          actionToken: token,
          currentPassword,
          newPassword,
          confirmNewPassword,
        })) as AnswerPutPassword;
        if (isObject(fetched) && fetched.success) {
          await update('credentials');
          const type = flowsData.getType();
          alert({
            type: 'success',
            titleData: {
              key: type === EFlowType.passwordAdd ? 'message.successPasswordAdd' : 'message.successPasswordEdit',
            },
            textData: { key: 'message.successPasswordText' },
          });
          await reset();
        }
      } else sentryException('FlowPassword hook: putPassword api is not ready', 'warning');
    },
    [ready, requestByName, update, alert, reset],
  );

  return { reset, error, actionToContinue: putPassword };
};

export default useFlowPassword;
