import data from './data';
import { IDataStore, TDataState } from './store.types';

export const stateInitial = (): TDataState => ({ ...data.state });

export const userInitial = (): IDataStore['user'] => ({ ...data.store.user });
export const credentialsInitial = (): IDataStore['credentials'] => ({ ...data.store.credentials });
export const sessionsInitial = (): IDataStore['sessions'] => (data.store.sessions ? [...data.store.sessions] : null);
export const identityProvidersInitial = (): IDataStore['identityProviders'] =>
  data.store.identityProviders ? [...data.store.identityProviders] : null;
