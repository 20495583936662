import React from 'react';

import { isContact, isContactValue, SocialTypesE, SUPPORTED_CONTACTS } from '../components/Social';
import { IUser } from './store';

// export type TContact = 'phone' | 'telegram' | 'whatsapp' | 'skype' | 'icq';

export type TUseContacts = {
  availableContacts: SocialTypesE[];
  connectedContacts: { name: SocialTypesE; value: string; verified: boolean }[];
};

const useContacts = (userContacts: IUser['contacts']): TUseContacts => {
  const availableContacts = React.useMemo(
    () =>
      userContacts
        ? [...SUPPORTED_CONTACTS].filter((item) => Object.entries(userContacts).some(([i, v]) => i === item && !v))
        : [],
    [userContacts],
  );
  const connectedContacts = React.useMemo(() => {
    const result: TUseContacts['connectedContacts'] = [];
    if (!userContacts) return result;
    Object.entries(userContacts).forEach(([name, value]) => {
      if (isContact(name) && isContactValue(value)) {
        const verifKey = `${name}Verified` as keyof IUser['contacts'];
        const verified: boolean = verifKey in userContacts && userContacts?.[verifKey];
        result.push({ name, value, verified });
      }
    });
    return result;
  }, [userContacts]);

  return { availableContacts, connectedContacts };
};

export default useContacts;
