import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../components/Table/Table';
import { ITableList } from '../../components/Table/Table.types';
import { getSessionStr } from '../../utils/date';
import styles from './Tables.module.scss';
import { IHistoryTable } from './Tables.types';

const styleRules: Record<string, string> = {
  '1': classNames(styles.twoColumnsTable_column1__xs),
  '2': classNames(styles.twoColumnsTable_column2__xs),
};

const HistoryTable: React.VFC<IHistoryTable> = ({ className, loginHistory }) => {
  const { t, i18n } = useTranslation();

  const list = useMemo<ITableList[]>(
    () =>
      loginHistory.map((item) => ({
        data: {
          '1': (
            <Text
              variant={TextVariants.header9}
              color={TextAndIconColors.labelsSecondary}
              className={styles.common_cropText}
            >
              {item.client}
            </Text>
          ),
          '2': (
            <div className={styles.history_column}>
              <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary}>
                {t('field.IpAddress') + ' ' + item.ipAddress}
              </Text>
              <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary}>
                {t('block.sessions.dateLogin') + ' ' + getSessionStr(item.time, i18n.language)}
              </Text>
            </div>
          ),
        },
      })),
    [i18n.language, loginHistory, t],
  );

  return (
    <Table
      list={list}
      styleRules={styleRules}
      divider
      className={className}
      columns={['1', '2']}
      classNameCells={styles.twoColumnsTable_cells__xs}
    />
  );
};

export default HistoryTable;
