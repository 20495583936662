import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocaleE } from 'src/constants/locale.types';

import personalDataImage from '../../assets/images/personalData.png';
import personalDataImageRetina from '../../assets/images/personalData@2x.png';
import Social, { SocialSizeE, SocialTypesE, SocialVariantE, SUPPORTED_PROVIDERS } from '../../components/Social';
import { MODE_READONLY } from '../../constants/mode';
import { ROUTE_FULL_CONTACTS } from '../../constants/routes';
import Page from '../../containers/Page';
import AppContext from '../../context/AppContext';
import useContacts from '../../hooks/contacts.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { ISocialTableProvider, MainInfoTable, SocialTable } from '../../modules/Tables';
import ContactsTable from '../../modules/Tables/ContactsTable';
import { EFlowType, useStartFlowStep } from '../../scenarios';
import { ga4Event } from '../../utils/ga';
import { idpProvidersFilter } from '../../utils/idpProviders';
import { isRetina } from '../../utils/retina';
import styles from './PersonalData.module.scss';

const PersonalDataImage = isRetina() ? personalDataImageRetina : personalDataImage;

const FEATURE_HIDE_COUNTRY = process.env.REACT_APP_FEATURE_HIDE_COUNTRY === 'true';

const PersonalData: React.VFC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { identityProviders, credentials, user, state } = useStore(['identityProviders', 'credentials']);
  const { mode, config } = useContext(AppContext);
  const { startFlow } = useStartFlowStep();
  const { connectedContacts, availableContacts } = useContacts(user.contacts);
  const readonly = useMemo(() => mode === MODE_READONLY, [mode]);

  const connectedProviders: ISocialTableProvider[] = React.useMemo(() => {
    const available = idpProvidersFilter(identityProviders, SUPPORTED_PROVIDERS, null, LocaleE.en);
    return (user.socialLinks as Array<{ identityProvider: SocialTypesE; userName: string }>).filter(
      ({ identityProvider }) => available.includes(identityProvider as SocialTypesE),
    );
  }, [identityProviders, user.socialLinks]);

  const availableProviders: SocialTypesE[] = React.useMemo(() => {
    const available = idpProvidersFilter(
      identityProviders,
      SUPPORTED_PROVIDERS,
      config.features.idpRules,
      i18n.language as LocaleE,
    );
    return available.filter((item) => !user.socialLinks.some(({ identityProvider }) => item === identityProvider));
  }, [config.features.idpRules, i18n.language, identityProviders, user.socialLinks]);

  const deleteSocialLink = useCallback(
    async (idp: string) => startFlow(EFlowType.deleteSocial, { socialName: idp }),
    [startFlow],
  );

  const editContact = useCallback(
    (name?: string) => {
      // const to = ROUTE_FULL_CONTACTS + name ? `/?edit=${name}` : '';
      navigate(ROUTE_FULL_CONTACTS);
    },
    [navigate],
  );

  useOnLoad(Boolean(state.identityProviders && state.credentials && state.user), 'PersonalData');

  return (
    <Page menu>
      <div className={styles.page_wrapper}>
        <div className={classNames(styles.page_imageBlock)}>
          <div className={classNames(styles.page_block)}>
            <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary}>
              {t('page.personalData.title')}
            </Text>
            <Text
              variant={TextVariants.body1}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('page.personalData.description')}
            </Text>
          </div>
          <img
            className={styles.page_imageBlock__image}
            src={PersonalDataImage}
            srcSet={personalDataImageRetina}
            alt='personalDataImage'
          />
        </div>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <div className={styles.page_block}>
            <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
              {t('block.mainInfo.title')}
            </Text>
            <Text
              variant={TextVariants.body2}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('block.mainInfo.description')}
            </Text>
          </div>
          <MainInfoTable
            className={styles.common_margin__xl}
            user={user}
            readonly={readonly}
            hideCountry={FEATURE_HIDE_COUNTRY}
          />
        </div>
        {(connectedContacts.length > 0 || availableContacts.length > 0) && (
          <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
            <div className={classNames(styles.page_block)}>
              <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
                {t('block.contactData.title')}
              </Text>
              <Text
                variant={TextVariants.body2}
                color={TextAndIconColors.labelsTertiary}
                className={styles.common_margin__xs}
              >
                {t('block.contactData.description')}
              </Text>
            </div>
            {connectedContacts.length > 0 && (
              <ContactsTable className={styles.common_margin__xl} contacts={connectedContacts} onEdit={editContact} />
            )}
            {availableContacts.length > 0 && (
              <div className={classNames(styles.page_block, styles.common_margin__l)}>
                <Text variant={TextVariants.header6} color={TextAndIconColors.labelsSecondary}>
                  {t('block.contactData.subTitle')}
                </Text>
                <div className={classNames(styles.common_margin__s, styles.this_profilesList)}>
                  {availableContacts.map((item, id) => (
                    <Social
                      key={id}
                      variant={SocialVariantE.var2}
                      type={item}
                      size={SocialSizeE.m}
                      clickable
                      hoverVariant={SocialVariantE.var1}
                      onClick={() => editContact(item)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {(connectedProviders.length > 0 || availableProviders.length > 0) && (
          <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
            <div className={classNames(styles.page_block)}>
              <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
                {t('block.social.title')}
              </Text>
              <Text
                variant={TextVariants.body2}
                color={TextAndIconColors.labelsTertiary}
                className={styles.common_margin__xs}
              >
                {t('block.social.description')}
              </Text>
            </div>
            {connectedProviders.length > 0 && (
              <SocialTable
                className={styles.common_margin__xl}
                providers={connectedProviders}
                onDelete={deleteSocialLink}
                passwordEnabled={Boolean(credentials.password?.enabled)}
              />
            )}
            {availableProviders.length > 0 && (
              <div className={classNames(styles.page_block, styles.common_margin__l)}>
                <Text variant={TextVariants.header6} color={TextAndIconColors.labelsSecondary}>
                  {t('block.social.addSocial')}
                </Text>
                <div className={classNames(styles.common_margin__s, styles.this_profilesList)}>
                  {availableProviders.map((item, id) => (
                    <Social
                      key={id}
                      variant={SocialVariantE.var2}
                      type={item}
                      size={SocialSizeE.m}
                      clickable
                      hoverVariant={SocialVariantE.var1}
                      onClick={() => {
                        ga4Event('mainInfoSocialClick', { placeholders: { element: item } });
                        startFlow(EFlowType.addSocial, { socialName: item });
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

export default React.memo(PersonalData);
