import { Icon, IconSizes, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import { ISession } from '../../hooks/store';
import { getSessionStr } from '../../utils/date';
import { arrToStr } from '../../utils/string';
import styles from './Tables.module.scss';
import { ISessionsList } from './Tables.types';

type TListItemProps = {
  divider: boolean;
  action: (id: string) => void;
  isCurrent: boolean;
  counter: number;
  session: ISession;
  length: number;
};
const ListItem: React.VFC<TListItemProps> = ({ divider, action, isCurrent, counter, session, length }) => {
  const { t, i18n } = useTranslation();

  const wrapperClass = classNames(styles.list_row, {
    [styles.list_row__divider]: divider,
    [styles.list_row__last]: Boolean(counter === length),
  });
  return (
    <div className={wrapperClass}>
      <div className={styles.sessionsList_head}>
        <Text variant={TextVariants.header9} color={TextAndIconColors.labelsSecondary}>
          {t('block.sessions.session') + ' ' + counter}
        </Text>
        {isCurrent ? (
          <Text
            variant={TextVariants.body2}
            color={TextAndIconColors.labelsTertiary}
            className={styles.sessionsList_currentSession}
          >
            {t('status.currentSession')}
            <Icon
              IconComponent={IconCheckmark}
              size={IconSizes.l}
              color={TextAndIconColors.actionConfirmPrimary}
              className={styles.page_iconBlock__icon}
            />
          </Text>
        ) : (
          <Link
            variant={TextVariants.body2}
            color={TextAndIconColors.interfaceAccentSecondary}
            onClick={() => action(session.id)}
            underline={false}
          >
            {t('action.complete')}
          </Link>
        )}
      </div>
      <Text variant={TextVariants.body3} color={TextAndIconColors.labelsTertiary} className={styles.sessionsList_item}>
        {arrToStr(session.clients)}
      </Text>
      <Text
        variant={TextVariants.body3}
        color={TextAndIconColors.labelsQuaternary}
        className={styles.sessionsList_item}
      >
        {t('field.IpAddress') + ' ' + session?.ipAddress}
      </Text>
      <Text
        variant={TextVariants.body3}
        color={TextAndIconColors.labelsQuaternary}
        className={classNames(styles.sessionsList_item)}
      >
        {t('block.sessions.lastLogin') + ' ' + getSessionStr(session?.lastAccess, i18n.language)}
      </Text>
      <Text
        variant={TextVariants.body3}
        color={TextAndIconColors.labelsQuaternary}
        className={classNames(styles.sessionsList_item, styles.sessionsList_item__last)}
      >
        {t('block.sessions.firstLogin') + ' ' + getSessionStr(session?.start, i18n.language)}
      </Text>
    </div>
  );
};

const SessionsList: React.VFC<ISessionsList> = ({ sessions, deleteSession, className }) => {
  return (
    <div className={classNames(styles.list_wrapper, className)}>
      {sessions.map((item, id) => (
        <ListItem
          key={id}
          divider={Boolean(id !== 0)}
          action={deleteSession}
          isCurrent={Boolean(id === 0)}
          counter={id + 1}
          session={item}
          length={sessions.length}
        />
      ))}
    </div>
  );
};

export default SessionsList;
