import { Icon, IconSizes, Input, TextAndIconColors } from '@design-system/ui-kit';
import React, { forwardRef, useState } from 'react';

import { ReactComponent as EyeClosed } from '../../assets/icons/eyeClosed.svg';
import { ReactComponent as EyeOpened } from '../../assets/icons/eyeOpened.svg';
import { InputPasswordProps } from './InputPassword.types';

const InputPassword: React.VFC<InputPasswordProps> = forwardRef(({ hideEye, value, errorMessage, ...props }, ref) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = (): void => {
    setPasswordShown(!passwordShown);
  };

  const EyeIconComponent = (
    <div onClick={togglePasswordVisibility}>
      <Icon
        IconComponent={passwordShown ? EyeOpened : EyeClosed}
        color={TextAndIconColors.interfaceSecondary}
        size={IconSizes.l}
      />
    </div>
  );

  return (
    <Input
      {...props}
      endContent={hideEye ? undefined : value ? EyeIconComponent : undefined}
      value={value}
      ref={ref}
      errorMessage={errorMessage}
      type={passwordShown ? 'text' : 'password'}
    />
  );
});

export default React.memo(InputPassword);
