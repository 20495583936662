import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { localeNormalize } from '../../constants/locale';
import { MODE_MOCKED } from '../../constants/mode';
import { QUERY_PARAMS_LOCALE } from '../../constants/queryParams';
import AppContext from '../../context/AppContext';
import useLanguage from '../../hooks/language.hook';
import { chooseRoutes, ERoutes } from '../../routes';
import { ga4LangChange, gaSetKeycloakId, gaSetLocale } from '../../utils/ga';
import { sentryStorage } from '../../utils/sentry';
import { getParamsValueByKey } from '../../utils/url';
import { TEnvironmentProps } from './Enviroment.types';

const withExternal = (Component: React.ComponentType<TEnvironmentProps>) => () => {
  const { mode, supportedLocales } = useContext(AppContext);
  const { i18n } = useTranslation();

  const routesConfig = useMemo(() => chooseRoutes(mode === MODE_MOCKED ? ERoutes.mock : ERoutes.external), [mode]);

  const onChange = useCallback((tag: string) => {
    ga4LangChange(tag);
    sentryStorage.put('language', tag);
    gaSetLocale(tag);
  }, []);
  const onDetected = useCallback(
    (tag: string) => {
      i18n.changeLanguage(tag);
      sentryStorage.put('language', tag);
      gaSetLocale(tag);
      // once the language is defined
      // gaPageView(tag);
    },
    [i18n],
  );
  useLanguage(
    true,
    onChange,
    onDetected,
    supportedLocales,
    localeNormalize(getParamsValueByKey(window.location.href, QUERY_PARAMS_LOCALE)),
    false,
  );
  useEffect(() => {
    gaSetKeycloakId('undefined');
  }, []);

  return <Component showMenu={mode === MODE_MOCKED} ready={true} routesConfig={routesConfig} />;
};

export default withExternal;
