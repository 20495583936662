import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTE_LOGOUT } from '../../constants/routes';
import { resetFlows } from '../../scenarios';
import { withSlash } from '../../utils/url';

// https://stackoverflow.com/questions/12381563/how-can-i-stop-the-browser-back-button-using-javascript
// https://stackoverflow.com/questions/25806608/how-to-detect-browser-back-button-event-cross-browser
const HistoryHash: React.VFC = () => {
  const { key, pathname } = useLocation();

  const [prevKeys, setPrevKeys] = useState<[string, string]>(['default', 'default']);

  useEffect(() => {
    if (key) setPrevKeys((prev) => (key === prev[0] ? prev : [prev[1], key]));
  }, [key]);

  useEffect(() => {
    if (key !== 'default' && key === prevKeys[0]) {
      resetFlows();
    }
  }, [key, prevKeys]);

  useEffect(() => {
    if (pathname) {
      window.passportReferrer = document.location.href;
    }
    if (pathname && pathname !== withSlash(ROUTE_LOGOUT)) {
      window.passportLastPath = pathname;
    }
  }, [pathname]);

  return null;
};

export default React.memo(HistoryHash);
