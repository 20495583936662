import { useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback, useMemo } from 'react';

import { API_ERROR_CODE_EMAIL_UNVERIFIED } from '../../constants/api';
import useHttp from '../../hooks/http';
import { sentryException } from '../../utils/sentry';
import flowsData from '../flows.data';
import { getEmailActionByType, getExpiredFromToken } from '../flows.functions';
import { EFlowsDataStep, IEmailCodeFlowStep, TSecurityPostProps } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSecurity from '../hooks/security.hook';
import useMapper from '../mappers/mapper.hook';

interface IData {
  _tokenCode: null | string;
  _attemptId: null | string;
  setToken(token: string): void;
  getToken(): string | null;
  setAttemptId(attemptId: string): void;
  getAttemptId(): string | null;
  reset(): void;
}

const data: IData = {
  _tokenCode: null,
  _attemptId: null,
  setToken(token: string) {
    this._tokenCode = token;
  },
  getToken() {
    return this._tokenCode;
  },
  setAttemptId(attemptId: string) {
    this._attemptId = attemptId;
  },
  getAttemptId() {
    return this._attemptId;
  },
  reset() {
    this._tokenCode = null;
    this._attemptId = null;
  },
};

const useEmailCodeFlowStep = (): IEmailCodeFlowStep => {
  const { ready } = useHttp();
  const { goInputCode, goNext, clear } = useMapper();
  const { requestSecurity, error } = useSecurity();
  const { alert } = useToast();
  const resetNavigate = useResetNavigate();

  const onError = useCallback(
    (code: number | undefined) => {
      if (!code)
        sentryException('useEmailCodeFlowStep hook: requestFlow unexpected response from the backend', 'warning');
      if (code === API_ERROR_CODE_EMAIL_UNVERIFIED) {
        alert({
          type: 'error',
          titleData: {
            key: 'standardErrors.10029.title',
          },
          textData: {
            key: 'standardErrors.10029.text',
          },
        });
      }
    },
    [alert],
  );

  const actionEmail = useMemo(() => {
    const type = flowsData.getType();
    if (!type) return '';
    const actionKey = getEmailActionByType(type);
    return actionKey || '';
  }, []);

  const checkConditions = useCallback(
    async (token: boolean, type: boolean, httpReady: boolean, attemptId = true): Promise<boolean> => {
      if (!token || !type || !attemptId) {
        alert({
          type: 'warning',
          titleData: {
            key: 'message.warningExpirationTitle',
          },
          textData: {
            key: 'message.warningExpirationDescription',
          },
        });
        data.reset();
        await resetNavigate(() => clear());
        return false;
      }

      if (!httpReady) {
        console.error('useEmailCodeFlowStep hook: postSecurityFlow not ready');
        sentryException('useEmailCodeFlowStep hook: postSecurityFlow not ready', 'warning');
        return false;
      }
      return true;
    },
    [alert, clear, resetNavigate],
  );

  // FIXME: isInputPage временный костыль. Уйдет, когда полностью перейдем на форму
  const sendEmail = useCallback(
    async (isInputPage = false): Promise<void> => {
      const token = flowsData.getToken(EFlowsDataStep.emailCode);
      const type = flowsData.getType();

      const checked = await checkConditions(Boolean(token), Boolean(type), ready('postSecurityFlow'));
      if (!checked) return;

      const props: TSecurityPostProps = {
        body: {},
        headers: { 'action-token': token! },
      };
      const answer = await requestSecurity(props);
      if (!isObject(answer) || !answer.data?.attemptId) {
        if (typeof answer === 'number') onError(answer);
        return;
      }

      const expired = getExpiredFromToken(answer.actionToken);

      data.setToken(answer.actionToken);
      data.setAttemptId(answer.data.attemptId);
      if (!isInputPage) goInputCode(type!, expired);
    },
    [checkConditions, goInputCode, onError, ready, requestSecurity],
  );

  const confirmCode = useCallback(
    async (code: string) => {
      const type = flowsData.getType();
      const token = data.getToken();
      const attemptId = data.getAttemptId();

      const checked = await checkConditions(
        Boolean(token),
        Boolean(type),
        ready('postSecurityFlow'),
        Boolean(attemptId),
      );
      if (!checked) return;

      const props: TSecurityPostProps = {
        body: { data: { attemptId, code } },
        headers: { 'action-token': token! },
      };
      const answer = await requestSecurity(props);
      if (!isObject(answer)) return;

      const expired = getExpiredFromToken(answer.actionToken);
      flowsData.setToken(EFlowsDataStep.flow, answer.actionToken);
      data.reset();
      goNext(type!, { step: EFlowsDataStep.flow, expired, actionToken: answer.actionToken });
    },
    [checkConditions, goNext, ready, requestSecurity],
  );

  const reset = useCallback(async () => {
    data.reset();
    await resetNavigate(() => clear());
  }, [clear, resetNavigate]);

  return {
    sendEmail,
    confirmCode,
    error,
    reset,
    actionEmail,
  };
};

export default useEmailCodeFlowStep;
