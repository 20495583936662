export const TIMER_NAME_TOTP = 'verifyTotp';
export const ALERT_GROUP_TOTP = 'verifyTotp';

export const TIMER_NAME_EMAIL_CODE = 'verifyEmailCode';
export const ALERT_GROUP_EMAIL_CODE = 'verifyEmailCode';

export const TIMER_NAME_INPUT_CODE = 'verifyInputCode';
export const ALERT_GROUP_INPUT_CODE = 'verifyInputCode';

export const TIMER_NAME_FLOW = 'startFlow';
export const ALERT_GROUP_FLOW = 'startFlow';
