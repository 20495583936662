import React, { useLayoutEffect, useReducer } from 'react';

import { LoaderComponent } from '../../components/Loader';
import { QUERY_PARAMS_TOKEN } from '../../constants/queryParams';
import useHttp from '../../hooks/http';
import useFlowEmailChange from '../../scenarios/flows/flowEmailChange.hook';
import { getParamsValueByKey } from '../../utils/url';

/**
 * Во флоу по верификации email
 */
const ConfirmAddEmail: React.VFC = () => {
  const { ready } = useHttp();
  const { sendConfirmAdd } = useFlowEmailChange();

  const [once, setOnce] = useReducer(() => true, false);

  useLayoutEffect(() => {
    if (ready('postConfirmAddEmail') && !once) {
      sendConfirmAdd(getParamsValueByKey(window.location.href, QUERY_PARAMS_TOKEN) || '');
      setOnce();
    }
  }, [sendConfirmAdd, once, ready]);

  return <LoaderComponent />;
};

export default ConfirmAddEmail;
