import { useLoader } from 'library-react-hooks';
import { useCallback, useEffect, useState } from 'react';

import { IAuthContext } from '../context/AuthContext';
import useHttp from './http';
import useKeycloak from './keycloak';
import { cleanForcedLocale } from './language.hook';
import useStore from './store';

export enum EAuthStatus {
  notReady = 'notReady',
  error = 'error',
  userReceived = 'userReceived',
  notFulfilled = 'notFulfilled',
  // errorNotFound404 = 'errorNotFound404',
}
export type TAuthStatus = {
  type: EAuthStatus;
  code: number | null;
};
export interface IAuth {
  // token: IAuthContext['token'];
  // login(): void;
  logout: IAuthContext['logout'];
  // ready: null | boolean;
  profile: IAuthContext['profile'];
  refresh: IAuthContext['refresh'];
  // error: number | null;
  status: TAuthStatus;
}

// TODO: when http answer code 401 (Unauthorized) then do refresh
const useAuth = ({ mocked, forceFulfillProfile }: { mocked: boolean; forceFulfillProfile: boolean }): IAuth => {
  const { kToken, kLogout, kLogin, kProfile, kRefresh } = useKeycloak({ mocked });
  const { setToken: setTokenHttp } = useHttp();
  const { update, storeError } = useStore([]);
  const { loaderOn, loaderOff } = useLoader();

  const [status, setStatus] = useState<TAuthStatus>({ type: EAuthStatus.notReady, code: null });
  useEffect(() => {
    if (status.type === EAuthStatus.notReady) {
      loaderOn();
    } else loaderOff();
  }, [loaderOff, loaderOn, status]);

  useEffect(() => {
    if (storeError?.getUser?.code) {
      setStatus({ type: EAuthStatus.error, code: storeError?.getUser?.code });
    }
  }, [setStatus, storeError]);

  const logout = useCallback(
    async (redirectUri?: string) => {
      setStatus({ type: EAuthStatus.notReady, code: null });
      cleanForcedLocale();
      await kLogout(redirectUri);
    },
    [setStatus, kLogout],
  );

  const refresh = useCallback(async () => {
    setStatus({ type: EAuthStatus.notReady, code: null });
    await kRefresh();
  }, [kRefresh]);

  useEffect(() => {
    if (kToken === null) kLogin();
  }, [kLogin, kToken]);
  useEffect(() => {
    if (kToken === false) setStatus({ type: EAuthStatus.error, code: 403 });
  }, [kToken]);
  useEffect(() => {
    if (typeof kToken === 'string' && kProfile) {
      setTokenHttp('main', kToken);
      if ((forceFulfillProfile && kProfile?.is_profile_fulfill) || !forceFulfillProfile) {
        update('user');
        setStatus({ type: EAuthStatus.userReceived, code: null });
      } else setStatus({ type: EAuthStatus.notFulfilled, code: null });
    }
  }, [kProfile, kToken, setTokenHttp, update, forceFulfillProfile]);

  return { logout, profile: kProfile, refresh, status };
};

export default useAuth;
