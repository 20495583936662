import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Icon,
  IconSizes,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'src/containers/Page';

import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import { ROUTE_FULL_PHONE_VIEW, ROUTE_SECURITY } from '../../constants/routes';
import usePrivatePages from '../../hooks/privatePages.hook';
import useFlowPhones from '../../scenarios/flows/flowPhones.hook';
import { withSlash } from '../../utils/url';
import styles from './PhoneRemoveComplete.module.scss';

const PhoneRemoveComplete: React.VFC = () => {
  const { t } = useTranslation();
  const { reset } = useFlowPhones();
  const { setPage } = usePrivatePages();

  const onDeleted = useCallback(
    async (resetPage: string) => {
      setPage(ROUTE_FULL_PHONE_VIEW, { available: false });
      await reset(resetPage);
    },
    [reset, setPage],
  );

  // page.removePhoneSuccess.description2 не используется
  return (
    <Page menu subHead={{ title: t('page.addPhone.title') }} callback={() => onDeleted(ROUTE_FULL_PHONE_VIEW)}>
      <div className={styles.page_wrapper}>
        <div className={classNames(styles.page_block, styles.page_block__border)}>
          <div className={styles.this_iconBlock}>
            <Icon
              IconComponent={IconCheckmark}
              size={IconSizes.l}
              color={TextAndIconColors.actionConfirmPrimary}
              className={styles.page_iconBlock__icon}
            />
            <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
              {t('page.removePhoneSuccess.subTitle')}
            </Text>
          </div>
          <Text
            variant={TextVariants.body2}
            color={TextAndIconColors.labelsTertiary}
            className={styles.common_margin__xs}
          >
            {t('page.removePhoneSuccess.description')}
          </Text>
        </div>
        <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
          <Button
            text={t('action.toSecurity').toString()}
            type={ButtonTypes.button}
            onClick={() => onDeleted(withSlash(ROUTE_SECURITY))}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.labelsSecondary}
          />
          <Button
            text={t('action.addPhone').toString()}
            type={ButtonTypes.button}
            onClick={() => onDeleted(ROUTE_FULL_PHONE_VIEW)}
          />
        </div>
      </div>
    </Page>
  );
};

export default PhoneRemoveComplete;
