// https://developer.mozilla.org/ru/docs/Web/HTTP/Status
export const ERROR_CODES = [
  400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 500, 501, 502, 503, 504,
  505,
];

export const ERROR_CODES_TYPE1 = [
  400, 401, 402, 403, 404, 405, 406, 407, 409, 410, 411, 412, 413, 414, 415, 416, 417, 500, 501, 502, 505,
];
export const ERROR_CODES_TYPE2 = [408];
export const ERROR_CODES_TYPE3 = [503, 504];
