import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInfoBlockVariant,
  Icon,
  InfoBlock,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconPhone } from '../../assets/icons/phone.svg';
import { listenKeydown } from '../../utils/keyboard';
import { phoneMask } from '../../utils/string';
import styles from './PhoneView.module.scss';

type TPhoneVerifyProps = {
  showError: boolean;
  phone: string;
  onClick: () => Promise<void>;
  onReject: () => Promise<void>;
};
const PhoneVerify: React.VFC<TPhoneVerifyProps> = ({ showError, phone, onClick, onReject }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const listener = listenKeydown('Enter', onClick, false);
    return () => {
      listener();
    };
  }, [onClick]);

  return (
    <div className={styles.page_wrapper}>
      {showError && (
        <InfoBlock
          variant={EInfoBlockVariant.error}
          title={t(`page.phoneVerify.error.title`)}
          description={t(`page.phoneVerify.error.text`)}
        />
      )}
      <Text
        variant={TextVariants.body2}
        color={TextAndIconColors.labelsTertiary}
        className={classNames({
          [styles.common_margin__m]: showError,
        })}
      >
        {t('page.phoneVerify.description.part1')}
      </Text>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__s}>
        {t('page.phoneVerify.description.part2')}
      </Text>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__s}>
        {t('page.phoneVerify.description.part3')}
      </Text>
      <div
        className={classNames(styles.page_block, styles.page_block__border, styles.this_phone, styles.common_margin__m)}
      >
        <div className={styles.this_phoneContent}>
          <Icon
            IconComponent={IconPhone}
            height={24}
            width={24}
            color={TextAndIconColors.interfaceAccentSecondary}
            className={styles.page_iconBlock__icon}
          />
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsPrimary}>
            {phoneMask(phone)}
          </Text>
        </div>
        <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary}>
          {t('status.phoneNoVerify')}
        </Text>
      </div>
      <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
        <Button
          text={t('action.remove').toString()}
          type={ButtonTypes.button}
          onClick={onReject}
          variant={ButtonVariant.outlined}
          theme={ButtonThemes.labelsSecondary}
        />
        <Button text={t('action.confirm').toString()} type={ButtonTypes.button} onClick={onClick} />
      </div>
    </div>
  );
};

export default PhoneVerify;
