import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import { useWindowWidth } from '@react-hook/window-size';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SocialSizeE, SocialTypesE, SocialVariantE } from '../../components/Social';
import Social from '../../components/Social/Social';
import Table from '../../components/Table/Table';
import { ETableAction, ETableIcon, ITableList } from '../../components/Table/Table.types';
import styles from './Tables.module.scss';
import type { IContactsTable } from './Tables.types';

const styleRules: Record<string, string> = {
  '1': classNames(styles.socialTable_column1),
  '2': classNames(styles.socialTable_column2),
};

const ContactsTable: React.VFC<IContactsTable> = ({ contacts, onEdit, className }) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const isMobileLayout = useMemo(
    () => (windowWidth <= 1000 && windowWidth >= 768) || windowWidth <= 680,
    [windowWidth],
  );

  const getName = React.useCallback(
    (name: SocialTypesE) => {
      if (name === SocialTypesE.phone) return t('field.telephone');
      if (name === SocialTypesE.whatsApp) return t('social.whatsapp');
      return t(`social.${name}`);
    },
    [t],
  );

  const list = useMemo<ITableList[]>(
    () =>
      contacts.map((item) => ({
        data: isMobileLayout
          ? {
              '1': (
                <div className={styles.social_icon}>
                  <Social variant={SocialVariantE.var4} type={item.name} size={SocialSizeE.s} />
                </div>
              ),
              '2': (
                <div className={styles.social_contentColumn}>
                  <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
                    {getName(item.name)}
                  </Text>
                  <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
                    {item.value}
                  </Text>
                </div>
              ),
            }
          : {
              '1': (
                <div className={styles.social_comboColumn}>
                  <Social variant={SocialVariantE.var4} type={item.name} size={SocialSizeE.s} />
                  <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
                    {getName(item.name)}
                  </Text>
                </div>
              ),
              '2': (
                <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
                  {item.value}
                </Text>
              ),
            },
        action: () => onEdit(item.name),
      })),
    [contacts, getName, isMobileLayout, onEdit],
  );

  return (
    <Table
      list={list}
      styleRules={styleRules}
      divider
      rowHover
      className={className}
      endIcon={ETableIcon.open}
      actionType={ETableAction.row}
      columns={['1', '2']}
      classNameCells={classNames(styles.socialTable_cells)}
    />
  );
};

export default ContactsTable;
