import { decodeJwt } from 'jose';

import {
  API_ADD_EMAIL,
  API_ADD_PHONE,
  API_ADD_SOCIAL_ACCOUNT,
  API_CHANGE_EMAIL,
  API_CHANGE_PASSWORD,
  API_DELETE_ACCOUNT,
  API_DELETE_SESSION,
  API_DELETE_SESSIONS,
  API_DELETE_SOCIAL_ACCOUNT,
  API_DELETE_TOTP,
  API_REMOVE_PHONE,
  TApiSecurityFlowActions,
} from '../constants/api';
import {
  ROUTE_FULL_DELETE_ACCOUNT,
  ROUTE_FULL_PHONE_VIEW,
  ROUTE_FULL_SESSIONS,
  ROUTE_FULL_TOTP,
  ROUTE_PERSONAL_DATA,
  ROUTE_RESET_TOTP,
  ROUTE_SECURITY,
} from '../constants/routes';
import { withSlash } from '../utils/url';
import { E2FaAction, EEmailAction, EFlowType } from './flows.types';

// Флоу с проверками безопсности
export const flowsWithSecurity = new Set([
  EFlowType.deleteAccount,
  EFlowType.emailChange,
  EFlowType.passwordAdd,
  EFlowType.passwordChange,
  EFlowType.deleteSession,
  EFlowType.deleteSessions,
  EFlowType.addSocial,
  EFlowType.deleteSocial,
  EFlowType.deleteTotp,
  EFlowType.addPhone,
  EFlowType.removePhone,
  EFlowType.verifyPhone,
  EFlowType.emailAdd,
]);

// Группа флоу стартующих со стр. Безопасность
export const flowsWithBasePageSecurity = new Set([
  EFlowType.deleteAccount,
  EFlowType.emailChange,
  EFlowType.passwordAdd,
  EFlowType.passwordChange,
  EFlowType.deleteSession,
  EFlowType.deleteSessions,
  EFlowType.addTotp,
  EFlowType.deleteTotp,
  EFlowType.addPhone,
  EFlowType.verifyPhone,
  EFlowType.emailAdd,
]);

export const getBestBasePage = (type: EFlowType | null): string => {
  switch (type) {
    // Внутренние
    case EFlowType.deleteAccount: {
      return ROUTE_FULL_DELETE_ACCOUNT;
    }
    case EFlowType.addTotp:
    case EFlowType.deleteTotp: {
      return ROUTE_FULL_TOTP;
    }
    case EFlowType.deleteSession:
    case EFlowType.deleteSessions: {
      return ROUTE_FULL_SESSIONS;
    }
    case EFlowType.removePhone:
    case EFlowType.verifyPhone: {
      return ROUTE_FULL_PHONE_VIEW;
    }
    // Внешние
    case EFlowType.resetTotp: {
      return withSlash(ROUTE_RESET_TOTP);
    }
    default: {
      if (type && flowsWithBasePageSecurity.has(type)) return withSlash(ROUTE_SECURITY);
      return withSlash(ROUTE_PERSONAL_DATA);
    }
  }
};

// Для flowsWithSecurity отправляется на бэк тип-константа
export const getActionByType = (type: EFlowType): TApiSecurityFlowActions | undefined => {
  switch (type) {
    case EFlowType.deleteAccount: {
      return API_DELETE_ACCOUNT;
    }
    case EFlowType.emailAdd: {
      return API_ADD_EMAIL;
    }
    case EFlowType.emailChange: {
      return API_CHANGE_EMAIL;
    }
    case EFlowType.passwordAdd:
    case EFlowType.passwordChange: {
      return API_CHANGE_PASSWORD;
    }
    case EFlowType.deleteSession: {
      return API_DELETE_SESSION;
    }
    case EFlowType.deleteSessions: {
      return API_DELETE_SESSIONS;
    }
    case EFlowType.addSocial: {
      return API_ADD_SOCIAL_ACCOUNT;
    }
    case EFlowType.deleteSocial: {
      return API_DELETE_SOCIAL_ACCOUNT;
    }
    case EFlowType.deleteTotp: {
      return API_DELETE_TOTP;
    }
    case EFlowType.addPhone:
    case EFlowType.verifyPhone: {
      return API_ADD_PHONE;
    }
    case EFlowType.removePhone: {
      return API_REMOVE_PHONE;
    }
  }
};

// Для flowsWithSecurity на стр. ввода кода 2фа есть текст, который меняется в зависимости от флоу
export const get2FaActionByType = (type: EFlowType): E2FaAction | undefined => {
  switch (type) {
    case EFlowType.deleteAccount: {
      return E2FaAction.removeAccount;
    }
    case EFlowType.emailChange: {
      return E2FaAction.changeEmail;
    }
    case EFlowType.emailAdd: {
      return E2FaAction.addEmail;
    }
    case EFlowType.passwordAdd: {
      return E2FaAction.addPassword;
    }
    case EFlowType.passwordChange: {
      return E2FaAction.changePassword;
    }
    case EFlowType.deleteSession:
    case EFlowType.deleteSessions: {
      return E2FaAction.removeSessions;
    }
    case EFlowType.addSocial: {
      return E2FaAction.addSocial;
    }
    case EFlowType.deleteSocial: {
      return E2FaAction.removeSocial;
    }
    case EFlowType.deleteTotp: {
      return E2FaAction.remove2Fa;
    }
    case EFlowType.addPhone: {
      return E2FaAction.addPhone;
    }
    case EFlowType.removePhone: {
      return E2FaAction.removePhone;
    }
    case EFlowType.verifyPhone: {
      return E2FaAction.verifyPhone;
    }
  }
};

// Для flowsWithSecurity на стр. ввода кода из email есть текст, который меняется в зависимости от флоу
export const getEmailActionByType = (type: EFlowType): EEmailAction | undefined => {
  switch (type) {
    case EFlowType.addPhone: {
      return EEmailAction.addPhone;
    }
    case EFlowType.removePhone: {
      return EEmailAction.removePhone;
    }
    case EFlowType.verifyPhone: {
      return EEmailAction.verifyPhone;
    }
  }
};

export const getExpiredFromToken = (token: string): number => {
  const actionToken = decodeJwt(token) as { action: TApiSecurityFlowActions; exp: number };
  return actionToken.exp - Math.floor(Date.now() / 1000);
};
