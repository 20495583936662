import { Button, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DeleteAccount.module.scss';

type TFullDelete = {
  action: () => void;
};
const FullDelete: React.VFC<TFullDelete> = ({ action }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.page_block, styles.page_block__border)}>
      <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
        {t('page.accountActions.full.subTitle')}
      </Text>
      <div className={classNames(styles.page_block, styles.common_margin__m)}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.accountActions.full.description')}
        </Text>
        <Text
          variant={TextVariants.header7}
          color={TextAndIconColors.labelsTertiary}
          className={styles.common_margin__xs}
        >
          {t('page.accountActions.full.list.title')}
        </Text>
        <ul className={styles.this_htmlList}>
          <li className={classNames(styles.this_htmlListItem)}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('page.accountActions.full.list.item0')}
            </Text>
          </li>
          <li className={classNames(styles.this_htmlListItem)}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('page.accountActions.full.list.item1')}
            </Text>
          </li>
          <li className={styles.this_htmlListItem}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('page.accountActions.full.list.item2')}
            </Text>
          </li>
        </ul>
      </div>
      <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
        <Button text={t('action.start').toString()} onClick={action} />
      </div>
    </div>
  );
};

export default FullDelete;
