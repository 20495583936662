import { ReactComponent as BigTelegram } from '../../assets/icons/social/bigTelegram.svg';
import { ReactComponent as BigWhatsapp } from '../../assets/icons/social/bigWhatsapp.svg';
import { ReactComponent as ColoredGitLab } from '../../assets/icons/social/coloredGitlab.svg';
import { ReactComponent as ColoredGoogle } from '../../assets/icons/social/coloredGoogle.svg';
import { ReactComponent as ColoredInstagram } from '../../assets/icons/social/coloredInstagram.svg';
import { ReactComponent as ColoredMicrosoft } from '../../assets/icons/social/coloredMicrosoft.svg';
import { ReactComponent as ColoredPayPal } from '../../assets/icons/social/coloredPaypal.svg';
import { ReactComponent as ColoredStackOverflow } from '../../assets/icons/social/coloredStackOverflow.svg';
import { ReactComponent as ColoredTikTok } from '../../assets/icons/social/coloredTiktok.svg';
import { ReactComponent as Facebook } from '../../assets/icons/social/facebook.svg';
import { ReactComponent as GitHub } from '../../assets/icons/social/github.svg';
import { ReactComponent as GitLab } from '../../assets/icons/social/gitlab.svg';
import { ReactComponent as Google } from '../../assets/icons/social/google.svg';
import { ReactComponent as Icq } from '../../assets/icons/social/icq.svg';
import { ReactComponent as Instagram } from '../../assets/icons/social/instagram.svg';
import { ReactComponent as Linkedin } from '../../assets/icons/social/linkedin.svg';
import { ReactComponent as Microsoft } from '../../assets/icons/social/microsoft.svg';
import { ReactComponent as Mitgo } from '../../assets/icons/social/mitgo.svg';
import { ReactComponent as Ok } from '../../assets/icons/social/ok.svg';
import { ReactComponent as PayPal } from '../../assets/icons/social/paypal.svg';
import { ReactComponent as Phone } from '../../assets/icons/social/phone.svg';
import { ReactComponent as Question } from '../../assets/icons/social/question.svg';
import { ReactComponent as Skype } from '../../assets/icons/social/skype.svg';
import { ReactComponent as StackOverflow } from '../../assets/icons/social/stackOverflow.svg';
import { ReactComponent as Telegram } from '../../assets/icons/social/telegram.svg';
import { ReactComponent as TikTok } from '../../assets/icons/social/tiktok.svg';
import { ReactComponent as Twitter } from '../../assets/icons/social/twitter.svg';
import { ReactComponent as Vkontakte } from '../../assets/icons/social/vk.svg';
import { ReactComponent as Whatsapp } from '../../assets/icons/social/whatsapp.svg';
import { ReactComponent as Yandex } from '../../assets/icons/social/yandex.svg';
import { SocialTypesE, SocialVariantE } from './Social.types';

const COLORED_ICONS = new Set<SocialVariantE>([SocialVariantE.var1, SocialVariantE.var4, SocialVariantE.var5]);
export const getIconByType = (type: SocialTypesE, variant: SocialVariantE) => {
  switch (type) {
    case SocialTypesE.google: {
      if (COLORED_ICONS.has(variant)) {
        return ColoredGoogle;
      }
      return Google;
    }
    case SocialTypesE.github: {
      return GitHub;
    }
    case SocialTypesE.facebook: {
      return Facebook;
    }
    case SocialTypesE.linkedin:
    case SocialTypesE.linkedinNew: {
      return Linkedin;
    }
    case SocialTypesE.instagram: {
      if (COLORED_ICONS.has(variant)) {
        return ColoredInstagram;
      }
      return Instagram;
    }
    case SocialTypesE.tiktok: {
      if (variant === SocialVariantE.var2) {
        return TikTok;
      }
      return ColoredTikTok;
    }
    case SocialTypesE.microsoft: {
      if (COLORED_ICONS.has(variant)) {
        return ColoredMicrosoft;
      }
      return Microsoft;
    }
    // case SocialTypesE.bitbucket:
    //   return <Icon IconComponent={BitBucket} width={32} height={32} color={TextAndIconColors.black} />;
    case SocialTypesE.twitter: {
      return Twitter;
    }
    case SocialTypesE.gitlab: {
      if (COLORED_ICONS.has(variant)) {
        return ColoredGitLab;
      }
      return GitLab;
    }
    case SocialTypesE.paypal: {
      if (COLORED_ICONS.has(variant)) {
        return ColoredPayPal;
      }
      return PayPal;
    }
    case SocialTypesE.stackOverflow: {
      if (COLORED_ICONS.has(variant)) {
        return ColoredStackOverflow;
      }
      return StackOverflow;
    }
    case SocialTypesE.yandex: {
      return Yandex;
    }
    case SocialTypesE.vk: {
      return Vkontakte;
    }
    case SocialTypesE.ok: {
      return Ok;
    }
    case SocialTypesE.mitgo: {
      return Mitgo;
    }
    case SocialTypesE.phone: {
      return Phone;
    }
    case SocialTypesE.telegram: {
      if (variant === SocialVariantE.var3) return BigTelegram;
      return Telegram;
    }
    case SocialTypesE.whatsApp: {
      if (variant === SocialVariantE.var3) return BigWhatsapp;
      return Whatsapp;
    }
    case SocialTypesE.skype: {
      return Skype;
    }
    case SocialTypesE.icq: {
      return Icq;
    }
    default: {
      return Question;
    }
  }
};

export const getIconColorByType = (type: SocialTypesE, variant: SocialVariantE) => {
  switch (variant) {
    case SocialVariantE.var2: {
      return 'tertiary';
    }
    case SocialVariantE.var3: {
      if (type === SocialTypesE.icq) return type;
      return 'white';
    }
    default: {
      return type;
    }
  }
};

export const getBackgroundColorByType = (type: SocialTypesE, variant: SocialVariantE) => {
  switch (variant) {
    case SocialVariantE.var3: {
      if (type === SocialTypesE.icq) return 'icqCustom';
      return type;
    }
    case SocialVariantE.var5: {
      return 'white';
    }
    default: {
      return 'inherit';
    }
  }
};

export const SUPPORTED_PROVIDERS: SocialTypesE[] = Object.values(SocialTypesE);
export const SUPPORTED_CONTACTS = new Set([
  SocialTypesE.phone,
  SocialTypesE.telegram,
  SocialTypesE.whatsApp,
  SocialTypesE.skype,
  SocialTypesE.icq,
]);
export const isContact = (str: string): str is SocialTypesE => SUPPORTED_CONTACTS.has(str as SocialTypesE);
export const isContactValue = (str: string | boolean): str is string => typeof str === 'string' && !!str;

export const checkSupportedProviders = (array: string[]): SocialTypesE[] => {
  return array.filter((item) => SUPPORTED_PROVIDERS.includes(item as SocialTypesE)) as SocialTypesE[];
};
