import { headAppend } from './documentHtml';
import { intervalListener } from './listeners';

export type TConsentToolsVendors = Array<{ name: string; callback(): void; inited: boolean; id: string }>;

const onConsentChange = (vendors: TConsentToolsVendors, vendorConsents: Record<string, boolean>): void => {
  vendors.forEach((vendor) => {
    if (!vendor.inited && vendor.id in vendorConsents && vendorConsents[vendor.id]) {
      vendor.inited = true;
      vendor.callback();
    }
  });
};

const afterScriptLoaded = (vendors: TConsentToolsVendors): void => {
  if (__cmp('consentStatus')?.consentData) {
    onConsentChange(vendors, __cmp('getCMPData').vendorConsents);
  } else {
    // вызывает виджет, но автоматически проставляет согласие
    // __cmp('showScreen');
  }

  __cmp(
    'addEventListener',
    [
      'consent',
      () => {
        onConsentChange(vendors, __cmp('getCMPData').vendorConsents);
      },
      false,
    ],
    null,
  );
};

// https://help.consentmanager.net/books/cmp/chapter/javascript-api
export const consentTools = (src: string, host: string, vendors: TConsentToolsVendors): void => {
  headAppend(src, true, false, {
    'data-cmp-ab': '1',
    'data-cmp-host': host,
    'data-cmp-cdn': 'cdn.consentmanager.net',
    'data-cmp-codesrc': '1',
  });
  intervalListener(
    () => '__cmp' in global,
    () => afterScriptLoaded(vendors),
    5,
    1000,
  );
};
