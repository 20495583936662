import cn from 'classnames';
import React, { useMemo, useState } from 'react';

import DataTestId from '../../utils/tests';
import Svg from '../Svg';
import { getBackgroundColorByType, getIconByType, getIconColorByType } from './Social.functions';
import styles from './Social.module.scss';
import { SocialProps, SocialTypesE, SocialVariantE } from './Social.types';

const Social: React.FC<SocialProps> = ({
  type,
  variant,
  size,
  className,
  clickable,
  hoverVariant,
  onClick,
  onMouseOver,
  onMouseLeave,
  clickVariant,
  src,
}: SocialProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const iconVariant: SocialVariantE =
    clickable && hoverVariant && isHovered
      ? hoverVariant
      : clickable && clickVariant && isActive
        ? clickVariant
        : variant;
  const IconComponent = useMemo(() => getIconByType(type, iconVariant), [type, iconVariant]);
  const color = useMemo(() => getIconColorByType(type, iconVariant), [type, iconVariant]);
  const background = useMemo(() => getBackgroundColorByType(type, iconVariant), [type, iconVariant]);

  /**
   * Обработчики событий для ховера и клика обертки
   */
  const onMouseOverHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (!clickable) return;
    setIsHovered(true);
    onMouseOver?.(event);
  };

  const onMouseLeaveHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (!clickable) return;
    setIsHovered(false);
    onMouseLeave?.(event);
  };

  const onMouseDownHandler = () => {
    if (!clickable) return;
    setIsActive(true);
  };

  const onMouseUpHandler = () => {
    if (!clickable) return;
    setIsActive(false);
  };

  const onClickHandler = () => {
    if (!clickable) return;
    onClick?.();
  };

  const wrapperClassName = cn(styles.wrapper, className, {
    [styles[`size_${size}`]]: size,
    // @ts-ignore
    [styles[`color_b_${background}`]]: background,
    [styles[`border_${iconVariant}`]]: iconVariant,
    // @ts-ignore
    [styles.wrapper_clickable]: clickable,
  });
  const iconClassName = cn(styles.icon, {
    // @ts-ignore
    [styles[`color_${color}`]]: color,
    [styles[`size_i_${size}`]]: size,
  });

  const BaseIcon = <IconComponent className={iconClassName} />;
  const Icon =
    type === SocialTypesE.unknown && src ? (
      <Svg src={src} props={{ className: iconClassName }} base={BaseIcon} />
    ) : (
      BaseIcon
    );
  return (
    <div
      className={wrapperClassName}
      onClick={onClickHandler}
      onMouseUp={onMouseUpHandler}
      onMouseDown={onMouseDownHandler}
      onMouseLeave={onMouseLeaveHandler}
      onMouseOver={onMouseOverHandler}
      data-test-id={DataTestId.config.social?.[type]}
    >
      {Icon}
    </div>
  );
};

export default React.memo(Social);
