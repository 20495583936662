import { TFunction } from 'i18next';
import { useTimer, useToast } from 'library-react-hooks';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { MODE_DEBUG } from '../../constants/mode';
import { ROUTE_FULL_DELETE_ACCOUNT_CODE, ROUTE_FULL_EMAIL_CODE } from '../../constants/routes';
import AppContext from '../../context/AppContext';
import { useTime } from '../../hooks/helper.hook';
import usePrivatePages, { privatePagesSet } from '../../hooks/privatePages.hook';
import { ALERT_GROUP_INPUT_CODE, TIMER_NAME_INPUT_CODE } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, EFlowType, IMapperInputCode } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';

const usingPrivatePages: { [K in 'deleteAccountCode' | 'emailCode']: string } = {
  deleteAccountCode: ROUTE_FULL_DELETE_ACCOUNT_CODE,
  emailCode: ROUTE_FULL_EMAIL_CODE,
};

export const mapperInputCodeReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useMapperInputCode = (): IMapperInputCode => {
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { alert, clear } = useToast();
  const time = useTime();
  const { setTimer, cancelTimer } = useTimer();
  const { mode } = useContext(AppContext);
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();

  const onExpiredInputCode = useCallback(async () => {
    alert({
      type: 'warning',
      titleData: {
        key: 'message.warningExpirationTitle',
      },
      textData: {
        key: 'message.warningExpirationDescription',
      },
      sticky: true,
    });
    if (mode !== MODE_DEBUG) await resetNavigate(() => setPages(false));
  }, [alert, mode, resetNavigate, setPages]);
  const goVerifyInputCode = useCallback(
    (type: EFlowType, expired: number) => {
      switch (type) {
        case EFlowType.deleteAccount: {
          setPage(usingPrivatePages.deleteAccountCode, { available: true });
          navigate(usingPrivatePages.deleteAccountCode);
          break;
        }
        case EFlowType.emailChange: {
          setPage(usingPrivatePages.emailCode, { available: true });
          navigate(usingPrivatePages.emailCode);
          break;
        }
        default: {
          return;
        }
      }
      alert({
        type: 'info',
        textData: {
          key: 'message.infoExpiration',
          options: { time: (fn: TFunction<'translation', undefined>) => time(expired, { short: true, fn }) },
        },
        group: ALERT_GROUP_INPUT_CODE,
      });
      setTimer(expired, { name: TIMER_NAME_INPUT_CODE, callback: onExpiredInputCode, autoFinish: true });
    },
    [alert, navigate, onExpiredInputCode, setPage, setTimer, time],
  );

  // Вызывается при прохождении вперед/назад
  const clearInputCode = useCallback(() => {
    setPages(false);
    cancelTimer(TIMER_NAME_INPUT_CODE);
    flowsData.setToken(EFlowsDataStep.emailCode, null);
    clear(ALERT_GROUP_INPUT_CODE);
  }, [cancelTimer, clear, setPages]);

  return { clearInputCode, goVerifyInputCode };
};

export default useMapperInputCode;
