export enum LocaleE {
  ru = 'ru',
  en = 'en',
  de = 'de',
  es = 'es',
  pl = 'pl',
  pt = 'pt-BR',
  cs = 'cs',
  uk = 'uk',
}
