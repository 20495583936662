import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Secure.module.scss';
import { ISecureProps, TConfig, TValidLevels } from './Secure.types';

const config: TConfig = {
  1: {
    text: 'password.notSecure',
    color: TextAndIconColors.actionErrorSecondary,
  },
  2: {
    text: 'password.average',
    color: TextAndIconColors.actionPromoSecondary,
  },
  3: {
    text: 'password.secure',
    color: TextAndIconColors.actionConfirmPrimary,
  },
};

const Secure: React.VFC<ISecureProps> = ({ level, className }) => {
  const { t } = useTranslation();

  if (level < 1) return null;

  const classProgress = classNames(styles.progress, {
    [styles[`progress_${level as TValidLevels}`]]: level,
  });

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.scale}>
        <div className={classProgress} />
      </div>
      <Text variant={TextVariants.body5} color={config[level as TValidLevels].color} className={styles.description}>
        {t(config[level as TValidLevels].text)}
      </Text>
    </div>
  );
};

export default React.memo(Secure);
