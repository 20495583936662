import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';

import useLinkActions from '../../hooks/linkActions.hook';
import CustomIcon from '../CustomIcon';
import styles from './ProfileMenu.module.scss';
import { IProfileMenuProps } from './ProfileMenu.types';

const ProfileMenu: React.VFC<IProfileMenuProps> = ({ list, className }) => {
  const linkAction = useLinkActions();

  return (
    <div className={classNames(styles.list, className)}>
      {list.map(({ text, icon, action }, id) => (
        <div key={id} className={styles.item} onClick={() => linkAction(action)}>
          <CustomIcon icon={icon} size={16} />
          <Text variant={TextVariants.body3} color={TextAndIconColors.labelsSecondary}>
            {text}
          </Text>
        </div>
      ))}
    </div>
  );
};

export default ProfileMenu;
