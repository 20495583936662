import React from 'react';

import { TElementaryUrl } from './History.types';

const elementaryURL = new URL(document.location.href).pathname;

const withElementaryUrl = (Component: React.ComponentType<TElementaryUrl>) => () => (
  <Component elementaryURL={elementaryURL} />
);

export default withElementaryUrl;
