import { Button, ButtonTypes, ButtonVariant, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import phoneImage from '../../assets/images/phone.png';
import phoneImageRetina from '../../assets/images/phone@2x.png';
import { ga4Event } from '../../utils/ga';
import { isRetina } from '../../utils/retina';
import { phoneMask } from '../../utils/string';
import styles from './Notifications.module.scss';
import { INotificationPhone } from './Notifications.types';

const PhoneImage = isRetina() ? phoneImageRetina : phoneImage;

const NotificationPhone: React.VFC<INotificationPhone> = ({ className, onClick, onReject, phone }) => {
  const { t } = useTranslation();

  const clickAction = useCallback(() => {
    ga4Event('securityPhoneNotificationClick', { placeholders: { element: 'confirm' } });
    onClick?.();
  }, [onClick]);
  const rejectAction = useCallback(() => {
    ga4Event('securityPhoneNotificationClick', { placeholders: { element: 'delete' } });
    onReject?.();
  }, [onReject]);

  return (
    <div className={classNames(styles.notification_wrapper, className)}>
      <div className={styles.notification_content}>
        <Text
          variant={TextVariants.header5}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__xs}
        >
          {t('block.verifyPhone.title')}
        </Text>
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsTertiary}
          className={styles.common_margin__xs}
        >
          {t('block.verifyPhone.description')}
        </Text>
        {phone && (
          <Text
            variant={TextVariants.body01bold}
            color={TextAndIconColors.labelsTertiary}
            className={styles.common_margin__s}
          >
            {phoneMask(phone)}
          </Text>
        )}
        <div className={classNames(styles.notification_buttons, styles.common_margin__l)}>
          <Button
            text={t('action.remove').toString()}
            type={ButtonTypes.button}
            onClick={rejectAction}
            variant={ButtonVariant.outlined}
          />
          <Button
            text={t('action.verifyPhone').toString()}
            type={ButtonTypes.button}
            onClick={clickAction}
            variant={ButtonVariant.filled}
          />
        </div>
      </div>
      <img className={styles.notification_image} src={PhoneImage} srcSet={phoneImage} alt='securityPhone' />
    </div>
  );
};

export default NotificationPhone;
