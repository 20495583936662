import { createContext } from 'react';

import { LOCALE_SUPPORTED } from '../constants/locale';
import { LocaleE } from '../constants/locale.types';
import { MODE_DEFAULT, ModeT } from '../constants/mode';
import { baseConfig, IConfig } from '../core';

export interface IModeContext {
  mode: ModeT;
  config: IConfig;
  supportedLocales: Array<LocaleE>;
}

const AppContext = createContext<IModeContext>({
  mode: MODE_DEFAULT,
  config: baseConfig,
  supportedLocales: LOCALE_SUPPORTED,
});

export default AppContext;
