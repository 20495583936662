import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Dropdown,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { isObject } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AnswerPatchProfile } from '../../api/answer.types';
import { COUNTRY_DEFAULT } from '../../constants/country';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import Page from '../../containers/Page/Page';
import useCountries from '../../hooks/countries.hook';
import { useUpdateState } from '../../hooks/helper.hook';
import useHttp from '../../hooks/http';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { withSlash } from '../../utils/url';
import styles from './CountryEdit.module.scss';

const CountryEdit: React.VFC = () => {
  const { t, i18n } = useTranslation();
  const { requestByName, ready } = useHttp();
  const navigator = useNavigate();
  const { user, update, state } = useStore([]);
  const { dropdownCountries, getBestCountry } = useCountries(i18n.language);

  const bestCountry = useMemo(() => getBestCountry(), [getBestCountry]);
  const [country, setCountry] = useUpdateState<string>(user.country || bestCountry);

  const updateCountry = useCallback(async () => {
    if (ready('patchProfile')) {
      const fetched = (await requestByName('patchProfile', {
        body: { country },
        successMsg: 'message.successSaved',
      })) as AnswerPatchProfile;
      if (isObject(fetched) && fetched.success) {
        ga4Event('mainInfoSaveClick', { placeholders: { element: 'country' } });
        update('user');
        navigator(withSlash(ROUTE_PERSONAL_DATA));
      }
    }
  }, [country, navigator, ready, requestByName, update]);

  useEffect(() => {
    const listener = listenKeydown('Enter', updateCountry);
    return () => {
      listener();
    };
  }, [updateCountry]);

  useOnLoad(Boolean(state.user), 'CountryEdit');

  return (
    <Page menu subHead={{ title: t('page.countryEdit.title'), backLink: withSlash(ROUTE_PERSONAL_DATA) }}>
      <div className={classNames(styles.page_wrapper)}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.countryEdit.description')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
            {t('page.countryEdit.subTitle')}
          </Text>
          <div className={classNames(styles.page_block, styles.common_margin__l)}>
            <Dropdown
              placeholder={t('field.country')}
              value={country}
              options={dropdownCountries}
              onChange={(value) => setCountry(value ? value.toString() : COUNTRY_DEFAULT)}
              fluid
              withSearch
            />
          </div>
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.cancel').toString()}
              type={ButtonTypes.button}
              onClick={() => {
                ga4Event('mainInfoCancelClick', { placeholders: { element: 'country' } });
                navigator(withSlash(ROUTE_PERSONAL_DATA));
              }}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
            />
            <Button text={t('action.save').toString()} type={ButtonTypes.button} onClick={updateCountry} />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default CountryEdit;
