import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInfoBlockVariant,
  EInputThemes,
  InfoBlock,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { CountryCallingCode } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'src/containers/Page';

import { GoogleReCaptcha, ReCapthaExecute } from '../../components/GoogleReCaptcha';
import PhoneInput from '../../components/PhoneInput';
import {
  API_ERROR_ACTION_TYPE_LIMIT,
  API_ERROR_ADD_PHONE_CAPTCHA,
  API_ERROR_ADD_PHONE_RATE_LIMIT,
} from '../../constants/api';
import { useOnceEffect } from '../../hooks/helper.hook';
import useInput from '../../hooks/input.hook';
import useStore from '../../hooks/store';
import useFlowPhones from '../../scenarios/flows/flowPhones.hook';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { getPhoneOnlyNumber, validationPhone } from '../../utils/validation';
import styles from './PhoneAdd.module.scss';

const PhoneAdd: React.VFC = () => {
  const { t } = useTranslation();
  const { addPhone, reset, error, fullPhone } = useFlowPhones();
  const { user } = useStore([]);

  const showVerifyVariant = Boolean(fullPhone) && Boolean(user.phoneVerified);

  // useEffect(() => {
  //   const phoneInput = document.querySelector('#phone');
  //   const handlePast = (event: Event) => {
  //     console.log('ev', event.target);
  //   };
  //   if (phoneInput) phoneInput.addEventListener('paste', handlePast);
  //   return () => {
  //     if (phoneInput) phoneInput.removeEventListener('paste', handlePast);
  //   };
  // }, []);

  useOnceEffect(() => {
    if (error?.code) window.scrollTo(0, 0);
  }, [error]);

  const [phone, changePhone, { error: errorPhone, setError: setErrorPhone }] = useInput<string>(
    showVerifyVariant ? getPhoneOnlyNumber(fullPhone) || '' : '',
    {
      translate: t,
    },
  );
  const [country, setCountry] = useState<[CountryCode, CountryCallingCode] | undefined>();

  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = useCallback(() => {
    let valid = true;
    if (!country) return;
    let phoneWithCode = '+' + country[1] + phone;
    const validation = validationPhone(phoneWithCode);
    if (!validation[0]) {
      valid = false;
      setErrorPhone('message.errorValidationPhone');
    } else if (validation[1]) phoneWithCode = validation[1];

    if (valid) {
      setLoading(true);
      ReCapthaExecute(async (token) => {
        ga4Event('addPhoneClick', { placeholders: { element: 'next' } });
        await addPhone(phoneWithCode, token);
        setLoading(false);
      }, 'phone');
    }
  }, [addPhone, country, phone, setErrorPhone]);

  const buttonDisabled =
    !phone || Boolean(error?.code === API_ERROR_ADD_PHONE_RATE_LIMIT || error?.code === API_ERROR_ACTION_TYPE_LIMIT);

  useEffect(() => {
    const listener = listenKeydown('Enter', onSubmit, buttonDisabled);
    return () => {
      listener();
    };
  }, [onSubmit, buttonDisabled]);

  return (
    <Page menu subHead={{ title: t('page.addPhone.title') }} callback={reset}>
      <div className={styles.page_wrapper}>
        <GoogleReCaptcha />
        {(error?.code === API_ERROR_ADD_PHONE_RATE_LIMIT || error?.code === API_ERROR_ACTION_TYPE_LIMIT) && (
          <InfoBlock
            variant={EInfoBlockVariant.error}
            title={t('page.addPhone.error.title')}
            description={t('page.addPhone.error.text')}
          />
        )}
        {error?.code === API_ERROR_ADD_PHONE_CAPTCHA && (
          <InfoBlock
            variant={EInfoBlockVariant.error}
            title={t(error.title)}
            description={error.text ? t(error.text) : undefined}
          />
        )}
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsTertiary}
          className={classNames({
            [styles.common_margin__m]: error?.code,
          })}
        >
          {t(showVerifyVariant ? 'page.phoneVerify.confirmPhone' : 'page.addPhone.description')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
            {showVerifyVariant ? t('page.phoneVerify.subTitle') : t('page.addPhone.subTitle')}
          </Text>
          <PhoneInput
            id={'phone'}
            placeholder={t('field.phone')}
            value={phone}
            onChange={changePhone}
            className={styles.common_margin__l}
            theme={errorPhone ? EInputThemes.error : EInputThemes.base}
            errorMessage={errorPhone}
            autoFocus
            disabled={showVerifyVariant}
            fullPhone={fullPhone}
            changeCountry={setCountry}
          />
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.cancel').toString()}
              type={ButtonTypes.button}
              onClick={() => {
                ga4Event('addPhoneClick', { placeholders: { element: 'cancel' } });
                reset();
              }}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
            />
            <Button
              text={t('action.next').toString()}
              type={ButtonTypes.button}
              onClick={onSubmit}
              disabled={buttonDisabled}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PhoneAdd;
