import config from './config.json';

const HIDE_DATA_TEST_ID = process.env.REACT_APP_HIDE_DATA_TEST_ID === 'true';

type PartialInternal<T> = {
  [P in keyof T]: T[P] extends Record<string, unknown> ? PartialInternal<T[P]> : T[P] | undefined;
};
const isObject = (item: unknown): item is Record<string, unknown> =>
  typeof item === 'object' && item !== null && !Array.isArray(item);
const cleanObj = <T extends Record<string, unknown>>(obj: T): PartialInternal<T> => {
  return Object.entries(obj).reduce((prev, [key, value]) => {
    if (isObject(value)) return { ...prev, [key]: cleanObj(value) };
    return { ...prev, [key]: undefined };
  }, obj);
};

type TDataTestId = typeof config | PartialInternal<typeof config>;
class DataTestId {
  static #instance: TDataTestId;

  private constructor() {
    if (!DataTestId.#instance) {
      DataTestId.#instance = HIDE_DATA_TEST_ID ? cleanObj(config) : config;
    }
  }

  static get config(): TDataTestId {
    if (!DataTestId.#instance) {
      new DataTestId();
    }

    return DataTestId.#instance;
  }
}

export default DataTestId;
