import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import error40xImage from '../../assets/images/errors40x.png';
import Page from '../../containers/Page';
import { sentryException } from '../../utils/sentry';
import styles from './ErrorServiceUnavailable.module.scss';

/**
 * Error 400 - 4**
 */
const ErrorServiceUnavailable: React.FC<{ code?: number }> = ({ code }) => {
  const { t } = useTranslation();
  // const { images } = useImagePreloader();

  const errorCode: number = useMemo(() => {
    return code && Number(code) >= 400 && Number(code) < 500 ? Number(code) : 400;
  }, [code]);

  useEffect(() => {
    sentryException('Service Unavailable page', 'warning');
  }, []);

  return (
    <Page>
      <div className={classNames(styles.page_wrapper, styles.this_pageWrapper)}>
        <div className={styles.this_wrapper}>
          <div className={styles.this_content}>
            <Text variant={TextVariants.head0} color={TextAndIconColors.labelsPrimary} className={styles.this_header}>
              {t('page.errorCommon.title', { errorCode })}
            </Text>
            <Text
              variant={TextVariants.body1}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('page.errorCommon.description')}
            </Text>
          </div>
          {/*<img className={styles.page_image} src={images['errors40x.png']?.base64} alt='serviceUnavailableImage' />*/}
          <img className={styles.page_image} src={error40xImage} alt='serviceUnavailableImage' />
        </div>
      </div>
    </Page>
  );
};

export default ErrorServiceUnavailable;
