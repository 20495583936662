import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInputThemes,
  ELinkTarget,
  Link,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { ToastPropsT, useToast } from 'library-react-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputPassword from '../../components/InputPassword';
import Secure from '../../components/Secure/Secure';
import { API_ERROR_CODE_USE_PREVIOUS_PASSWORD, API_ERROR_CODE_WRONG_PASSWORD } from '../../constants/api';
import Page from '../../containers/Page/Page';
import AppContext from '../../context/AppContext';
import useInput from '../../hooks/input.hook';
import useFlowPassword from '../../scenarios/flows/flowPassword.hook';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { validationPassword, validationPasswordSecure } from '../../utils/validation';
import styles from '../PasswordAdd/PasswordAdd.module.scss';

const PasswordEdit: React.VFC = () => {
  const { t } = useTranslation();
  const { actionToContinue, error, reset } = useFlowPassword();
  const { alert } = useToast();
  const { config } = React.useContext(AppContext);

  const [password, changePassword, { error: errorPassword, setError: setErrorPassword }] = useInput<string>('', {
    translate: t,
  });
  const [passwordNew, changePasswordNew, { error: errorPasswordNew, setError: setErrorPasswordNew }] = useInput<string>(
    '',
    { translate: t },
  );
  const [passwordConfirm, changePasswordConfirm, { error: errorPasswordConfirm, setError: setErrorPasswordConfirm }] =
    useInput<string>('', { translate: t });
  useEffect(() => {
    if (passwordNew) setErrorPasswordConfirm('');
  }, [passwordNew, setErrorPasswordConfirm]);
  useEffect(() => {
    if (error?.code === API_ERROR_CODE_WRONG_PASSWORD) {
      setErrorPassword(error.title);
    } else if (error?.code === API_ERROR_CODE_USE_PREVIOUS_PASSWORD) {
      setErrorPasswordNew(error.title);
    } else if (error?.code) {
      const props: ToastPropsT = { type: 'error', titleData: { key: error.title } };
      if (error.text) props.textData = { key: error.text };
      alert(props);
    }
  }, [alert, error, setErrorPassword, setErrorPasswordNew]);

  const [secure, setSecure] = useState<-1 | 0 | 1 | 2 | 3>(0);
  useEffect(() => {
    setSecure(validationPasswordSecure(passwordNew));
  }, [passwordNew]);

  const sendData = useCallback(async () => {
    let valid = true;
    const result = validationPassword(passwordNew, passwordConfirm);
    if (!result.equals) {
      setErrorPasswordConfirm('message.passwordDontMatch');
      valid = false;
    }
    if (result.secure === -1) {
      setErrorPasswordNew('password.maxLength');
      valid = false;
    } else if (result.secure < 2) {
      setErrorPasswordNew('message.passwordRules');
      valid = false;
    }
    if (valid) {
      ga4Event('passwordChangeClick', { placeholders: { element: 'next' } });
      await actionToContinue(password, passwordNew, passwordConfirm);
    }
  }, [actionToContinue, password, passwordConfirm, passwordNew, setErrorPasswordConfirm, setErrorPasswordNew]);

  const buttonDisabled: boolean =
    !passwordNew ||
    !passwordConfirm ||
    secure === 0 ||
    secure === 1 ||
    Boolean(errorPasswordNew) ||
    Boolean(errorPasswordConfirm) ||
    !password ||
    Boolean(errorPassword);

  useEffect(() => {
    const listener = listenKeydown('Enter', sendData, buttonDisabled);
    return () => {
      listener();
    };
  }, [sendData, buttonDisabled]);

  return (
    <Page menu subHead={{ title: t('page.passwordAdd.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.passwordEdit.description')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
            {t('page.passwordEdit.subTitle')}
          </Text>
          <div className={classNames(styles.page_block, styles.common_margin__l)}>
            <InputPassword
              placeholder={t('field.passwordCurrent')}
              value={password}
              onChange={changePassword}
              theme={errorPassword ? EInputThemes.error : EInputThemes.base}
              errorMessage={errorPassword}
              autoComplete
            />
            <InputPassword
              placeholder={t('field.passwordNew')}
              value={passwordNew}
              onChange={changePasswordNew}
              theme={errorPasswordNew ? EInputThemes.error : EInputThemes.base}
              errorMessage={errorPasswordNew}
              className={styles.common_margin__m}
            />
            <Secure level={secure} className={styles.common_margin__xs} />
            <Text
              className={styles.common_margin__m}
              variant={TextVariants.body4}
              color={TextAndIconColors.labelsTertiary}
            >
              {t('password.minLength')}
            </Text>
            <InputPassword
              placeholder={t('field.passwordConfirm')}
              value={passwordConfirm}
              onChange={changePasswordConfirm}
              theme={errorPasswordConfirm ? EInputThemes.error : EInputThemes.base}
              errorMessage={errorPasswordConfirm}
              className={styles.common_margin__m}
            />
            <Text
              color={TextAndIconColors.labelsQuaternary}
              variant={TextVariants.body3}
              className={styles.common_margin__m}
            >
              {t('action.forgotPassword') + ' ' + t('action.doContactSupport') + ': '}
              <Link
                href={'mailto:' + config.supportEmail}
                target={ELinkTarget.blank}
                underline={false}
                variant={TextVariants.body3}
                color={TextAndIconColors.interfaceAccentSecondary}
              >
                {config.supportEmail}
              </Link>
            </Text>
          </div>
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.cancel').toString()}
              type={ButtonTypes.button}
              onClick={() => {
                ga4Event('passwordChangeClick', { placeholders: { element: 'cancel' } });
                reset();
              }}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
            />
            <Button
              text={t('action.next').toString()}
              type={ButtonTypes.button}
              onClick={sendData}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PasswordEdit;
