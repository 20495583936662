type StorageType = 'localStorage' | 'sessionStorage';
export const LOCAL_STORAGE: StorageType = 'localStorage';
export const SESSION_STORAGE: StorageType = 'sessionStorage';

class Storage {
  type: StorageType;

  available: boolean;

  constructor(type: StorageType) {
    this.type = type;
    this.available = Storage.storageAvailable(type);
  }

  getItem(key: string): any {
    if (this.available) {
      return window[this.type].getItem(key);
    }
    return null;
  }

  setItem(key: string, value: any) {
    if (this.available) {
      window[this.type].setItem(key, value);
    }
  }

  removeItem(key: string) {
    if (this.available) {
      window[this.type].removeItem(key);
    }
  }

  static storageAvailable(type: StorageType): boolean {
    try {
      return type in window && typeof window[type].getItem === 'function' && typeof window[type].setItem === 'function';
    } catch {
      return false;
    }
  }
}

export default Storage;
