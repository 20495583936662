export type ModeT = 'mock' | 'readonly' | 'debug' | 'default';

export const MODE_DEFAULT: ModeT = 'default';
// Режим работы без бэка. Все страницы доступны для просмотра сразу
export const MODE_MOCKED: ModeT = 'mock';
// Ограниченный режим
export const MODE_READONLY: ModeT = 'readonly';
// Режим работы без бэка. Для доступа к странице, до нее нужно дойти
export const MODE_DEBUG: ModeT = 'debug';

export const withMockModes: ModeT[] = [MODE_MOCKED, MODE_DEBUG];
