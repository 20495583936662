import { RouteObject } from 'react-router-dom';

import { ROUTE_PERSONAL_DATA } from '../constants/routes';
import withErrorPage from '../hocs/Errors';
import { privatePagesInit } from '../hooks/privatePages.hook';
import { privatePagesConfig } from './privatePages.config';
import {
  cabinetConfig,
  cabinetReadonlyConfig,
  externalConfig,
  fulfillConfig,
  mockConfig,
  notAuthConfig,
  notFoundConfig,
  serverErrorConfig,
  serverUnavailableConfig,
} from './Routes.config';
import { ERoutes } from './Routes.types';

const routesInit = () => privatePagesInit(ROUTE_PERSONAL_DATA, privatePagesConfig);

routesInit();

export const chooseRoutes = (route: ERoutes, errorCode?: number | null): RouteObject[] => {
  switch (route) {
    case ERoutes.mock: {
      return mockConfig;
    }
    case ERoutes.external: {
      return externalConfig;
    }
    // case ERoutes.debug:
    //   return <RoutesMock />;
    case ERoutes.cabinet: {
      return cabinetConfig;
    }
    case ERoutes.readonly: {
      return cabinetReadonlyConfig;
    }
    case ERoutes.fulfill: {
      return fulfillConfig;
    }
    case ERoutes.notAuth: {
      return notAuthConfig;
    }
    case ERoutes.serverError: {
      return withErrorPage(serverErrorConfig, errorCode);
    }
    case ERoutes.serverUnavailable: {
      return withErrorPage(serverUnavailableConfig, errorCode);
    }
    case ERoutes.notFound: {
      return notFoundConfig;
    }
    default: {
      return [];
    }
  }
};
