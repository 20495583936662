const getPairs = (obj: Record<string, unknown>, keys = []) =>
  Object.entries(obj).reduce((pairs, [key, value]) => {
    if (typeof value === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pairs.push(...getPairs(value, [...keys, key]));
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pairs.push([[...keys, key], value]);
    }
    return pairs;
  }, []);

export const convertQuery = (object: unknown): string => {
  if (typeof object === 'string') return object;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getPairs(object)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .map(([[key0, ...keysRest], value]) => `${key0}${keysRest.map((a) => `[${a}]`).join('')}=${value}`)
      .join('&')
  );
};
