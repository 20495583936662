import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ga4PageView } from '../../utils/ga';

const withPageView =
  <T extends Record<string, unknown>>(Component: React.ComponentType<T>) =>
  (props: T) => {
    const { pathname } = useLocation();
    const { i18n } = useTranslation();

    useEffect(() => {
      if (pathname) {
        ga4PageView(i18n.language, pathname);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps, unicorn/no-abusive-eslint-disable
    }, [pathname]);

    return <Component {...props} />;
  };

export default withPageView;
