import flowsData from './flows.data';
import { flowDeleteAccountReset } from './flows/flowDeleteAccount.hook';
import { flowEmailChangeReset } from './flows/flowEmailChange.hook';
import { flowPasswordReset } from './flows/flowPassword.hook';
import { flowPhonesReset } from './flows/flowPhones.hook';
import { flowResetTotpReset } from './flows/flowResetTotp.hook';
import { flowSessionsReset } from './flows/flowSessions.hook';
import { flowSocialReset } from './flows/flowSocial.hook';
import { flowTotpReset } from './flows/flowTotp.hook';
import { mapperEmailCodeReset } from './mappers/mapperEmailCode.hook';
import { mapperFlowReset } from './mappers/mapperFlow.hook';
import { mapperInputCodeReset } from './mappers/mapperInputCode.hook';
import { mapperTotpReset } from './mappers/mapperTotp.hook';

export const resetSecuritySteps = (): void => {
  mapperEmailCodeReset();
  mapperInputCodeReset();
  mapperTotpReset();
};

export const resetFlows = (): void => {
  flowsData.resetBasePage();
  flowDeleteAccountReset();
  flowEmailChangeReset();
  flowPasswordReset();
  flowSessionsReset();
  flowSocialReset();
  flowTotpReset();
  flowResetTotpReset();
  flowPhonesReset();
  resetSecuritySteps();
  mapperFlowReset();
  flowsData.reset();
};
