import { ComponentType } from 'react';

// HACK: на iphone не всегда подтягиваются чанки js или css
export const componentLoader = <T extends ComponentType<any>>(
  lazyComponent: () => Promise<{ default: T }>,
  attemptsLeft = 3,
  interval = 1500,
): Promise<{ default: T }> =>
  new Promise<{ default: T }>((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

export const elementLoader = <T extends JSX.Element>(
  lazyElement: () => Promise<{ default: T }>,
  attemptsLeft = 3,
  interval = 1500,
): Promise<{ default: T }> =>
  new Promise<{ default: T }>((resolve, reject) => {
    lazyElement()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          elementLoader(lazyElement, attemptsLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
