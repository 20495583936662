import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInputThemes,
  Input,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { CountryCallingCode } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';
import { isObject } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Page from 'src/containers/Page';

import { RequestPatchContacts } from '../../api';
import { AnswerPatchContacts } from '../../api/answer.types';
import PhoneInput, { getPhoneWithoutCode } from '../../components/PhoneInput';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import useHttp from '../../hooks/http';
import useInput from '../../hooks/input.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { listenKeydown } from '../../utils/keyboard';
import { sentryException } from '../../utils/sentry';
import { withSlash } from '../../utils/url';
import { validationPhone } from '../../utils/validation';
import styles from './Contacts.module.scss';

const Contacts: React.VFC = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const { requestByName, ready } = useHttp();
  const { user, state, update } = useStore([]);

  const [whatsappCountry, setWhatsappCountry] = useState<[CountryCode, CountryCallingCode] | undefined>();
  const [whatsapp, changeWhatsapp, { error: errorWhatsapp, setError: setErrorWhatsapp }] = useInput<string>('', {
    translate: t,
  });
  const [telegram, changeTelegram, { error: errorTelegram }] = useInput<string>('', {
    translate: t,
  });
  const [phoneCountry, setPhoneCountry] = useState<[CountryCode, CountryCallingCode] | undefined>();
  const [phone, changePhone, { error: errorPhone, setError: setErrorPhone }] = useInput<string>('', {
    translate: t,
  });
  const [skype, changeSkype, { error: errorSkype }] = useInput<string>('', {
    translate: t,
  });
  const [icq, changeIcq, { error: errorIcq }] = useInput<string>('', {
    translate: t,
  });
  React.useEffect(() => {
    if (state.user && user) {
      changeWhatsapp(getPhoneWithoutCode(user.contacts?.whatsApp) || '');
      changeTelegram(user.contacts?.telegram || '');
      changePhone(getPhoneWithoutCode(user.contacts?.phone) || '');
      changeSkype(user.contacts?.skype || '');
      changeIcq(user.contacts?.icq || '');
    }
  }, [changeIcq, changePhone, changeSkype, changeTelegram, changeWhatsapp, state.user, user]);

  const patchContacts = useCallback(
    async (body: RequestPatchContacts) => {
      if (ready('patchContacts')) {
        const fetched = (await requestByName('patchContacts', {
          body,
          successMsg: t('message.successContactAdd'),
        })) as AnswerPatchContacts;
        if (isObject(fetched) && fetched.success) {
          update('user');
          navigator(withSlash(ROUTE_PERSONAL_DATA));
        } else {
          console.log('fetched', fetched);
        }
      } else sentryException('Contacts: http hook is not ready', 'warning');
    },
    [ready, requestByName, t, update, navigator],
  );

  const onSaveContacts = React.useCallback(() => {
    if (!phoneCountry || !whatsappCountry) return;
    let valid = true;

    let phoneWithCode = '';
    let whatsappWithCode = '';

    if (phone && phoneCountry) {
      phoneWithCode = '+' + phoneCountry[1] + phone;
      const validation = validationPhone(phoneWithCode);
      if (!validation[0]) {
        valid = false;
        setErrorPhone('message.errorValidationPhone');
      } else if (validation[1]) phoneWithCode = validation[1];
    }

    if (whatsapp && whatsappCountry) {
      whatsappWithCode = '+' + whatsappCountry[1] + whatsapp;
      const validation = validationPhone(whatsappWithCode);
      if (!validation[0]) {
        valid = false;
        setErrorWhatsapp('message.errorValidationPhone');
      } else if (validation[1]) whatsappWithCode = validation[1];
    }

    if (valid) {
      const body: RequestPatchContacts = {};
      if (user.contacts?.whatsApp !== whatsappWithCode) body.whatsApp = whatsappWithCode;
      if (user.contacts?.telegram !== telegram) body.telegram = telegram;
      if (user.contacts?.phone !== phoneWithCode) body.phone = phoneWithCode;
      if (user.contacts?.skype !== skype) body.skype = skype;
      if (user.contacts?.icq !== icq) body.icq = icq;
      patchContacts(body);
    }
  }, [
    icq,
    patchContacts,
    phone,
    phoneCountry,
    setErrorPhone,
    setErrorWhatsapp,
    skype,
    telegram,
    user.contacts,
    whatsapp,
    whatsappCountry,
  ]);

  const buttonDisabled =
    !(
      (user.contacts?.whatsApp && user.contacts?.whatsApp !== '+' + whatsappCountry?.[1] + whatsapp) ||
      (!user.contacts?.whatsApp && whatsapp) ||
      user.contacts?.telegram !== telegram ||
      (user.contacts?.phone && user.contacts?.phone !== '+' + phoneCountry?.[1] + phone) ||
      (!user.contacts?.phone && phone) ||
      user.contacts?.skype !== skype ||
      user.contacts?.icq !== icq
    ) ||
    !!errorWhatsapp ||
    !!errorPhone ||
    !!errorSkype ||
    !!errorTelegram ||
    !!errorIcq;

  useEffect(() => {
    const listener = listenKeydown('Enter', onSaveContacts, buttonDisabled);
    return () => {
      listener();
    };
  }, [onSaveContacts, buttonDisabled]);

  useOnLoad(Boolean(state.user), 'Contacts');

  return (
    <Page menu subHead={{ title: t('block.contactData.title'), backLink: withSlash(ROUTE_PERSONAL_DATA) }}>
      <div className={styles.page_wrapper}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.contactData.description')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
            {t('page.contactData.subTitle')}
          </Text>
          <div className={classNames(styles.page_block, styles.common_margin__l)}>
            <table>
              <tbody>
                <tr className={styles.this_table_tr}>
                  <td>
                    <Text className={styles.this_text}>{t('social.whatsapp')}:</Text>
                  </td>
                  <td>
                    <PhoneInput
                      id={'whatsapp'}
                      placeholder={t('field.whatsappNumber')}
                      value={whatsapp}
                      onChange={changeWhatsapp}
                      theme={errorWhatsapp ? EInputThemes.error : EInputThemes.base}
                      errorMessage={errorWhatsapp}
                      changeCountry={setWhatsappCountry}
                      fullPhone={user.contacts?.whatsApp}
                    />
                  </td>
                </tr>
                <tr className={classNames(styles.this_table_tr, styles.this_table_pad)}>
                  <td className={styles.this_table__td}>
                    <Text className={styles.this_text}>{t('social.telegram')}:</Text>
                  </td>
                  <td className={styles.this_table__td}>
                    <Input
                      id={'telegram'}
                      placeholder={t('field.yourAccount')}
                      value={telegram}
                      onChange={changeTelegram}
                      theme={errorTelegram ? EInputThemes.error : EInputThemes.base}
                      errorMessage={errorTelegram}
                    />
                  </td>
                </tr>
                <tr className={classNames(styles.this_table_tr, styles.this_table_pad)}>
                  <td className={styles.this_table__td}>
                    <Text className={styles.this_text}>{t('field.telephone')}:</Text>
                  </td>
                  <td className={styles.this_table__td}>
                    <PhoneInput
                      id={'phone'}
                      placeholder={t('field.phone')}
                      value={phone}
                      onChange={changePhone}
                      theme={errorPhone ? EInputThemes.error : EInputThemes.base}
                      errorMessage={errorPhone}
                      // autoFocus
                      changeCountry={setPhoneCountry}
                      fullPhone={user.contacts?.phone}
                    />
                  </td>
                </tr>
                <tr className={classNames(styles.this_table_tr, styles.this_table_pad)}>
                  <td className={styles.this_table__td}>
                    <Text className={styles.this_text}>{t('social.skype')}:</Text>
                  </td>
                  <td className={styles.this_table__td}>
                    <Input
                      id={'skype'}
                      placeholder={t('field.yourAccount')}
                      value={skype}
                      onChange={changeSkype}
                      theme={errorSkype ? EInputThemes.error : EInputThemes.base}
                      errorMessage={errorSkype}
                    />
                  </td>
                </tr>
                <tr className={classNames(styles.this_table_tr, styles.this_table_pad)}>
                  <td className={styles.this_table__td}>
                    <Text className={styles.this_text}>{t('social.icq')}:</Text>
                  </td>
                  <td className={styles.this_table__td}>
                    <Input
                      id={'icq'}
                      placeholder={t('field.yourAccount')}
                      value={icq}
                      onChange={changeIcq}
                      theme={errorIcq ? EInputThemes.error : EInputThemes.base}
                      errorMessage={errorIcq}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.return').toString()}
              type={ButtonTypes.button}
              onClick={() => {
                navigator(withSlash(ROUTE_PERSONAL_DATA));
              }}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
            />
            <Button
              text={t('action.save').toString()}
              type={ButtonTypes.button}
              onClick={onSaveContacts}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Contacts;
