import { Button, ButtonTypes, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GoogleReCaptcha, ReCapthaExecute } from '../../components/GoogleReCaptcha';
import {
  API_ERROR_CODE_RESET_TOTP_FORBIDDEN,
  API_ERROR_RECOVERY_TOTP_USER_ATTEMPT_RATE_LIMIT,
  API_ERROR_RECOVERY_TOTP_WRONG_ATTEMPT,
} from '../../constants/api';
import { QUERY_PARAMS_OTP_DEVICE_ID } from '../../constants/queryParams';
import Form from '../../containers/Form';
import { FormProps } from '../../containers/Form/Form';
import AppContext from '../../context/AppContext';
import { useTime } from '../../hooks/helper.hook';
import { i18link } from '../../locales/formatters';
import useFlowResetTotp from '../../scenarios/flows/flowResetTotp.hook';
import { ga4Event } from '../../utils/ga';
import { getParamsValueByKey } from '../../utils/url';
import { validateDeviceId } from './ResetTotp.functions';
import styles from './ResetTotp.module.scss';
import ResetTotpError from './ResetTotpError';

const ResetTotp: React.VFC = () => {
  const { t } = useTranslation();
  const { requestCode, error } = useFlowResetTotp();
  const time = useTime();
  const { config } = React.useContext(AppContext);

  const [isErrorPage, setIsErrorPage] = useState<boolean>(false);
  const deviceId = useMemo(() => {
    const param = getParamsValueByKey(window.location.href, QUERY_PARAMS_OTP_DEVICE_ID);
    setIsErrorPage(!validateDeviceId(param));
    return param;
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = useCallback(() => {
    setLoading(true);
    ReCapthaExecute(async (token) => {
      ga4Event('resetTotpClick');
      await requestCode(deviceId || '', token);
      setLoading(false);
    }, 'totp');
  }, [deviceId, requestCode]);
  const notification: FormProps['notification'] = useMemo(() => {
    if (error?.code === API_ERROR_RECOVERY_TOTP_USER_ATTEMPT_RATE_LIMIT) {
      window.scrollTo(0, 0);
      return { title: t(error.title), description: error.text ? t(error.text) : undefined };
    } else return;
  }, [error, t]);
  const buttonDisabled = Boolean(notification);

  if (
    isErrorPage ||
    error?.code === API_ERROR_RECOVERY_TOTP_WRONG_ATTEMPT ||
    error?.code === API_ERROR_CODE_RESET_TOTP_FORBIDDEN
  )
    return <ResetTotpError code={error?.code || API_ERROR_RECOVERY_TOTP_WRONG_ATTEMPT} />;

  return (
    <Form user={false} notification={notification} logoClickable={false}>
      <div className={styles.form_wrapper}>
        <GoogleReCaptcha />
        <div className={styles.form_head}>
          <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary}>
            {t('page.resetTotp.title')}
          </Text>
        </div>
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__l}
        >
          {t('page.resetTotp.description')}
        </Text>
        <Text
          variant={TextVariants.header7}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__s}
        >
          {t('page.resetTotp.step1')}
        </Text>
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__s}
        >
          {t('page.resetTotp.ifNoKey') + ' '}
          <Link
            text={config.supportEmail}
            href={'mailto:' + config.supportEmail}
            underline
            color={TextAndIconColors.labelsSecondary}
            variant={TextVariants.body2}
          />
        </Text>
        <Text
          variant={TextVariants.header7}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__s}
        >
          {t('page.resetTotp.step2')}
        </Text>
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__s}
        >
          {t('page.resetTotp.codeExpiration', { time: time(5 * 60, { short: true }) })}
        </Text>
        <div className={styles.common_buttons}>
          <Button
            text={t('action.requestCode').toString()}
            type={ButtonTypes.button}
            onClick={onSubmit}
            disabled={buttonDisabled}
            loading={loading}
          />
        </div>
        {config.documentationLinks.resetTotp && (
          <Link
            text={t('page.resetTotp.instruction')}
            href={i18link(config.documentationLinks.resetTotp)}
            underline={false}
            color={TextAndIconColors.labelsQuaternary}
            variant={TextVariants.body3}
            className={classNames(styles.this_footer, styles.common_margin__l)}
          />
        )}
      </div>
    </Form>
  );
};

export default ResetTotp;
