import { useEffect, useState } from 'react';

const usePath = (): string => {
  const [path, setPath] = useState<string>(window.location.pathname);
  const listenToPopstate = (): void => {
    setPath(window.location.pathname);
  };
  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate);
    return () => {
      window.removeEventListener('popstate', listenToPopstate);
    };
  }, []);

  return path;
};

export default usePath;
