import { TFunction } from 'i18next';
import { useTimer, useToast } from 'library-react-hooks';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { MODE_DEBUG } from '../../constants/mode';
import { ROUTE_FULL_DELETE_ACCOUNT_SENT, ROUTE_FULL_EMAIL, ROUTE_FULL_EMAIL_VIEW } from '../../constants/routes';
import AppContext from '../../context/AppContext';
import { useTime } from '../../hooks/helper.hook';
import usePrivatePages, { privatePagesSet } from '../../hooks/privatePages.hook';
import { ALERT_GROUP_EMAIL_CODE, TIMER_NAME_EMAIL_CODE } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, EFlowType, IMapperEmailCode } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';

const usingPrivatePages: { [K in 'deleteAccountSent' | 'emailView' | 'formEmail']: string } = {
  deleteAccountSent: ROUTE_FULL_DELETE_ACCOUNT_SENT,
  emailView: ROUTE_FULL_EMAIL_VIEW,
  formEmail: ROUTE_FULL_EMAIL,
};

export const mapperEmailCodeReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useMapperEmailCode = (): IMapperEmailCode => {
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { alert, clear } = useToast();
  const time = useTime();
  const { setTimer, cancelTimer } = useTimer();
  const { mode } = useContext(AppContext);
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();

  const onExpiredEmailCode = useCallback(async () => {
    alert({
      type: 'warning',
      titleData: {
        key: 'message.warningExpirationTitle',
      },
      textData: {
        key: 'message.warningExpirationDescription',
      },
      sticky: true,
    });
    if (mode !== MODE_DEBUG) await resetNavigate(() => setPages(false));
  }, [alert, mode, resetNavigate, setPages]);
  const goVerifyEmailCode = useCallback(
    (type: EFlowType, expired: number) => {
      switch (type) {
        case EFlowType.deleteAccount: {
          setPage(usingPrivatePages.deleteAccountSent, { available: true });
          navigate(usingPrivatePages.deleteAccountSent);
          break;
        }
        case EFlowType.emailChange: {
          alert({
            type: 'info',
            textData: {
              key: 'message.infoExpiration',
              options: { time: (fn: TFunction<'translation', undefined>) => time(expired, { short: true, fn }) },
            },
            group: ALERT_GROUP_EMAIL_CODE,
          });
          setTimer(expired, { name: TIMER_NAME_EMAIL_CODE, callback: onExpiredEmailCode, autoFinish: true });
          setPage(usingPrivatePages.emailView, { available: true });
          navigate(usingPrivatePages.emailView);
          break;
        }
        case EFlowType.addPhone:
        case EFlowType.removePhone:
        case EFlowType.verifyPhone: {
          alert({
            type: 'info',
            textData: {
              key: 'message.infoExpiration',
              options: { time: (fn: TFunction<'translation', undefined>) => time(expired, { short: true, fn }) },
            },
            group: ALERT_GROUP_EMAIL_CODE,
          });
          setTimer(expired, { name: TIMER_NAME_EMAIL_CODE, callback: onExpiredEmailCode, autoFinish: true });
          setPage(usingPrivatePages.formEmail, { available: true });
          navigate(usingPrivatePages.formEmail);
          break;
        }
        default: {
          return;
        }
      }
    },
    [alert, navigate, onExpiredEmailCode, setPage, setTimer, time],
  );

  // Вызывается при прохождении вперед/назад
  const clearEmailCode = useCallback(() => {
    setPages(false);
    cancelTimer(TIMER_NAME_EMAIL_CODE);
    flowsData.setToken(EFlowsDataStep.emailCode, null);
    clear(ALERT_GROUP_EMAIL_CODE);
  }, [cancelTimer, clear, setPages]);

  return { clearEmailCode, goVerifyEmailCode };
};

export default useMapperEmailCode;
