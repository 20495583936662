export type TApiSecurityFlowActions =
  | 'ADD_EMAIL'
  | 'CHANGE_EMAIL'
  | 'CHANGE_PASSWORD'
  | 'ADD_SOCIAL_ACCOUNT'
  | 'DELETE_SOCIAL_ACCOUNT'
  | 'DELETE_ACCOUNT'
  | 'DELETE_SESSION'
  | 'DELETE_SESSIONS'
  | 'DELETE_TOTP'
  | 'ADD_PHONE'
  | 'REMOVE_PHONE';
export const API_ADD_EMAIL: TApiSecurityFlowActions = 'ADD_EMAIL';
export const API_CHANGE_EMAIL: TApiSecurityFlowActions = 'CHANGE_EMAIL';
export const API_CHANGE_PASSWORD: TApiSecurityFlowActions = 'CHANGE_PASSWORD';
export const API_ADD_SOCIAL_ACCOUNT: TApiSecurityFlowActions = 'ADD_SOCIAL_ACCOUNT';
export const API_DELETE_SOCIAL_ACCOUNT: TApiSecurityFlowActions = 'DELETE_SOCIAL_ACCOUNT';
export const API_DELETE_ACCOUNT: TApiSecurityFlowActions = 'DELETE_ACCOUNT';
export const API_DELETE_SESSION: TApiSecurityFlowActions = 'DELETE_SESSION';
export const API_DELETE_SESSIONS: TApiSecurityFlowActions = 'DELETE_SESSIONS';
export const API_DELETE_TOTP: TApiSecurityFlowActions = 'DELETE_TOTP';
export const API_ADD_PHONE: TApiSecurityFlowActions = 'ADD_PHONE';
export const API_REMOVE_PHONE: TApiSecurityFlowActions = 'REMOVE_PHONE';

export type TApiRecaptchaActions = 'request_remove_totp' | 'request_check_phone';
export const API_RECAPTCHA_ACTION_TOTP: TApiRecaptchaActions = 'request_remove_totp';
export const API_RECAPTCHA_ACTION_PHONE: TApiRecaptchaActions = 'request_check_phone';

// Профиль недозаполнен. Его надо заполнить.
export const API_ERROR_CODE_FULFILL_PROFILE = 10_000;
// Пользователь авторизовался не через passport-client (токен от другого приложения).
export const API_ERROR_CODE_INVALID_AZP = 10_001;
// Это ошибка от попытки повторного дозаполнения
export const API_ERROR_CODE_PROFILE_ALREADY_FULFILLED = 10_002;
// На бэк пришел невалидный токен. Токен истек или он невалидный.
export const API_ERROR_CODE_VALIDATION_TOKEN = 10_003;
// Невалидный токен на какое-то действие. То есть истекло время, отсутствие токена, или невалидное содержимое токена
export const API_ERROR_CODE_ACTION_TOKEN = 10_004;
// Достигнут лимит на проверку пароля (n раз в минуту)
export const API_ERROR_CODE_PASSWORD_RATE_LIMIT = 10_005;
// Неправильный пароль
export const API_ERROR_CODE_WRONG_PASSWORD = 10_006;
// Процесс удаления аккаунта уже был запущен
export const API_ERROR_CODE_ALREADY_SENT_TO_DELETED = 10_007;
// Нельзя менять пароль на текущий
export const API_ERROR_CODE_USE_PREVIOUS_PASSWORD = 10_008;
// Текущее мыло нельзя изменить на введенное, т к мыло уже кем-то занято
export const API_ERROR_CODE_EMAIL_ALREADY_USED = 10_009;
// текущую сессию удалять нельзя
export const API_ERROR_CODE_DELETE_CURRENT_SESSION = 10_010;
// Неправильный проверочный код от email (больше не используется)
export const API_ERROR_CODE_WRONG_EMAIL_CODE = 10_011;
// Достигнуто ограничение на отправку кода в день на конкретный email (больше не используется)
export const API_ERROR_CODE_FOR_CURRENT_EMAIL_RATE_LIMIT = 10_012;
// Flow(сейчас только totp и теперь email) не пройден (неправильный код)
export const API_ERROR_CODE_SECURITY_FLOW_NOT_PASS = 10_013;
// Достигнут лимит на проверку totp (n раз в минуту)
export const API_ERROR_CODE_TOTP_RATE_LIMIT = 10_014;
// Достигнут лимит на запрос кода email (n раз в минуту)
export const API_ERROR_CODE_REQUEST_EMAIL_CODE_RATE_LIMIT = 10_015;
// Достигнут лимит на проверку кода email (n раз в минуту)
export const API_ERROR_CODE_CHECK_EMAIL_CODE_RATE_LIMIT = 10_016;
// Такая соц. сеть уже привязана
export const API_ERROR_CODE_IDENTITY_PROVIDER_ALREADY_USED = 10_017;
// Эту соц. сеть привязать нельзя
export const API_ERROR_CODE_IDENTITY_PROVIDER_NOT_FOUND = 10_018;
// Нельзя удалить последнюю соц. сеть, если не задан пароль
export const API_ERROR_CODE_IDENTITY_PROVIDER_LAST_LINK = 10_019;
// Приходит при ограничении количества раз на выполнение флоу
// Например: изменение email 5 раз в сутки (может прийти в четырех местах)
export const API_ERROR_ACTION_TYPE_LIMIT = 10_020;
// Ошибка при попытке сбросить 2фа - попробуйте снова
// (Не найден или не валидный device id от totp)
export const API_ERROR_RECOVERY_TOTP_WRONG_ATTEMPT = 10_021;
// Превышено кол-во попыток (3 в день) запроса кода на сброс totp
export const API_ERROR_RECOVERY_TOTP_USER_ATTEMPT_RATE_LIMIT = 10_022;
// Ключ или код неверные
export const API_ERROR_RECOVERY_TOTP_WRONG_CODES = 10_023;
// Превышено кол-во попыток (3 в день) ввести пару кодов
export const API_ERROR_RECOVERY_TOTP_USER_CHECK_CODES_RATE_LIMIT = 10_024;
// Превышено кол-во попыток на отправку кода для подтверждения номера телефона
export const API_ERROR_ADD_PHONE_RATE_LIMIT = 10_025;
// Не пройдена капча при добавлении телефона
export const API_ERROR_ADD_PHONE_CAPTCHA = 10_026;
// Неверный код или истекший из смс при добавлении телефона
export const API_ERROR_ADD_PHONE_WRONG_CODE = 10_027;
// Закончились попытки отправки кода (или запросили код слишком рано)
export const API_ERROR_ADD_PHONE_CODE_RATE_LIMIT = 10_028;
// Нельзя запустить какое-то флоу из-за неверифицированного email
export const API_ERROR_CODE_EMAIL_UNVERIFIED = 10_029;
// Закрывает эндпоинты по добавлению(изменению) email
export const API_ERROR_CODE_EMAIL_ALREADY_VERIFIED = 10_030;
// Ошибка при попытке сбросить 2фа - функционал не доступен
// (email не верифицирован)
export const API_ERROR_CODE_RESET_TOTP_FORBIDDEN = 10_031;
