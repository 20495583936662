import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import servicesImage from '../../assets/images/services.png';
import servicesImageRetina from '../../assets/images/services@2x.png';
import ServiceCard, { IServiceCardProps } from '../../components/ServiceCard';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import Page from '../../containers/Page/Page';
import AppContext from '../../context/AppContext';
import { i18link } from '../../locales/formatters';
import { isRetina } from '../../utils/retina';
import { separationByQuantity } from '../../utils/sort';
import styles from './Services.module.scss';

const ServicesImage = isRetina() ? servicesImageRetina : servicesImage;

// TODO: цвет
const Services: React.VFC = () => {
  const { t } = useTranslation();
  const { config } = React.useContext(AppContext);
  const navigator = useNavigate();

  const cards = React.useMemo(
    () =>
      separationByQuantity<IServiceCardProps, null>(
        config.products.map((item) => ({
          title: t(item.name),
          description: t(item.text),
          link: i18link(item.link),
          linkText: t('action.goToWebsite'),
          logoLink: item.logo,
        })),
        2,
        null,
      ),
    [t, config.products],
  );
  React.useEffect(() => {
    if (config?.products.length < 1) {
      navigator(ROUTE_PERSONAL_DATA);
    }
  }, [config.products.length, navigator]);

  return (
    <Page menu>
      <div className={styles.page_wrapper}>
        <div className={classNames(styles.page_imageBlock)}>
          <div className={classNames(styles.page_block)}>
            <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary}>
              {t('page.services.title')}
            </Text>
            <Text
              variant={TextVariants.body1}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('page.services.description')}
            </Text>
          </div>
          <img
            className={styles.page_imageBlock__image}
            src={ServicesImage}
            srcSet={servicesImageRetina}
            alt='servicesImage'
          />
        </div>
        <div className={classNames(styles.this_list, styles.common_margin__m)}>
          {cards.map(([item1, item2], id) => (
            <div className={styles.this_listRow} key={id}>
              {item1 && <ServiceCard {...item1} className={item2 ? '' : styles.this_singleItem} />}
              {item2 && <ServiceCard {...item2} />}
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default Services;
