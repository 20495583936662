import { useToast } from 'library-react-hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ERROR_CODES, ERROR_CODES_TYPE2, ERROR_CODES_TYPE3 } from '../constants/errorCodes';
import useHttp from './http';

const getErrorKeyByCode = (code: number): string => {
  if (ERROR_CODES_TYPE2.includes(code)) return 'standardErrors.type2';
  if (ERROR_CODES_TYPE3.includes(code)) return 'standardErrors.type3';
  return 'standardErrors.type1';
};

const useStandardErrors = () => {
  const { t } = useTranslation();
  const { setErrors, setErrorCodes } = useHttp();
  const { setTypes, setTranslationFn } = useToast();

  useEffect(() => {
    setTypes({
      error: { title: t('message.errorTitle', { errorCode: '' }) },
      warning: { title: t('message.warningTitle') },
      info: { title: t('message.infoTitle') },
      success: { title: t('message.successTitle') },
    });
  }, [setTypes, t]);

  useEffect(() => {
    if (t) setTranslationFn(t);
  }, [setTranslationFn, t]);

  useEffect(() => {
    const errorsObj: { [K in number]: string } = {};
    ERROR_CODES.forEach((code) => {
      errorsObj[code] = getErrorKeyByCode(code);
    });

    // eslint-disable-next-line unicorn/prefer-object-from-entries
    // const messages: DataI['errors'] = ERROR_CODES.reduce(function (target: DataI['errors'], key) {
    //   target[key] = t(`standardErrors.${key}`).toString() || 'Something went wrong';
    //   return target;
    // }, {});

    setErrors(errorsObj);
  }, [setErrors]);

  useEffect(() => {
    const errorsObj: { [K in number]: { title: string; text?: string } } = {
      10_000: { title: 'standardErrors.10000.title', text: 'standardErrors.10000.text' },
      10_001: { title: 'standardErrors.10001.title', text: 'standardErrors.10001.text' },
      10_002: { title: 'standardErrors.10002.title' },
      10_003: { title: 'standardErrors.10003.title', text: 'standardErrors.10003.text' },
      10_004: { title: 'standardErrors.10004.title', text: 'standardErrors.10004.text' },
      10_005: { title: 'standardErrors.10005.title', text: 'standardErrors.10005.text' },
      10_006: { title: 'standardErrors.10006.title' },
      10_007: { title: 'standardErrors.10007.title' },
      10_008: { title: 'standardErrors.10008.title' },
      10_009: { title: 'standardErrors.10009.title' },
      10_010: { title: 'standardErrors.10010.title' },
      10_011: { title: 'standardErrors.10011.title' },
      10_012: { title: 'standardErrors.10012.title', text: 'standardErrors.10012.text' },
      10_013: { title: 'standardErrors.10013.title' },
      10_014: { title: 'standardErrors.10014.title', text: 'standardErrors.10014.text' },
      10_015: { title: 'standardErrors.10015.title', text: 'standardErrors.10015.text' },
      10_016: { title: 'standardErrors.10016.title', text: 'standardErrors.10016.text' },
      10_017: { title: 'standardErrors.10017.title' },
      10_018: { title: 'standardErrors.10018.title', text: 'standardErrors.10018.text' },
      10_019: { title: 'standardErrors.10019.title', text: 'standardErrors.10019.text' },
      10_020: { title: 'page.emailView.error.title', text: 'page.emailView.error.description' },
      10_021: { title: 'standardErrors.10021.title', text: 'standardErrors.10021.text' },
      10_022: { title: 'standardErrors.10022.title', text: 'standardErrors.10022.text' },
      10_023: { title: 'standardErrors.10023.title' },
      10_024: { title: 'standardErrors.10024.title', text: 'standardErrors.10024.text' },
      10_025: { title: 'standardErrors.10025.title', text: 'standardErrors.10025.text' },
      10_026: { title: 'standardErrors.10026.title', text: 'standardErrors.10026.text' },
      10_027: { title: 'standardErrors.10027.title' },
      10_028: { title: 'standardErrors.10028.title', text: 'standardErrors.10028.text' },
      10_029: { title: 'standardErrors.10029.title', text: 'standardErrors.100209.text' },
      10_030: { title: 'standardErrors.10030.title' },
      10_031: { title: 'standardErrors.10031.title', text: 'standardErrors.10031.text' },
    };
    setErrorCodes(errorsObj);
  }, [setErrorCodes]);

  // useEffect(() => {
  //   setErrorHandler((response, message) => {
  //     alert({ text: message, type: 'error', title: t('message.errorTitle', { errorCode: response.status || '' }) });
  //   });
  // }, [alert, setErrorHandler, t]);
};

export default useStandardErrors;
