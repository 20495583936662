import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'src/containers/Page';

import { LoaderComponent } from '../../components/Loader';
import { API_ERROR_ACTION_TYPE_LIMIT } from '../../constants/api';
import { ROUTE_SECURITY } from '../../constants/routes';
import AuthContext from '../../context/AuthContext';
import { useOnceEffect } from '../../hooks/helper.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { EFlowType, useStartFlowStep } from '../../scenarios';
import { withSlash } from '../../utils/url';
import PhoneRemove from './PhoneRemove';
import PhoneVerify from './PhoneVerify';

const PhoneView: React.VFC = () => {
  const { t } = useTranslation();
  const { startFlow, error } = useStartFlowStep();
  const { user, state } = useStore([]);
  const { profile } = useContext(AuthContext);

  const removePhone = useCallback(async () => {
    await startFlow(EFlowType.removePhone);
  }, [startFlow]);
  const verifyPhone = useCallback(async () => {
    await startFlow(EFlowType.verifyPhone, { fullPhone: profile?.phone });
  }, [profile?.phone, startFlow]);

  useOnceEffect(() => {
    if (error?.code) window.scrollTo(0, 0);
  }, [error]);
  useOnLoad(Boolean(state.user), 'PhoneView');

  if (!user.phone) return <LoaderComponent />;

  return (
    <Page menu subHead={{ title: t('page.addPhone.title'), backLink: withSlash(ROUTE_SECURITY) }}>
      {user.phoneVerified ? (
        <PhoneRemove showError={error?.code === API_ERROR_ACTION_TYPE_LIMIT} phone={user.phone} onClick={removePhone} />
      ) : (
        <PhoneVerify
          showError={error?.code === API_ERROR_ACTION_TYPE_LIMIT}
          phone={user.phone}
          onClick={verifyPhone}
          onReject={removePhone}
        />
      )}
    </Page>
  );
};

export default PhoneView;
