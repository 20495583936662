import { Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import { ga4Event } from '../../utils/ga';
import { openLinkBlank } from '../../utils/url';
import CustomIcon from '../CustomIcon';
import styles from './ServiceCard.module.scss';
import { IServiceCardProps } from './ServiceCard.types';

const ServiceCard: React.VFC<IServiceCardProps> = ({ title, description, link, linkText, className, logoLink }) => {
  const serviceClick = useCallback(() => {
    ga4Event('productsClick', { placeholders: { element: title } });
    openLinkBlank(link);
  }, [link, title]);
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.content}>
        {logoLink ? <CustomIcon icon={logoLink} alt={title} /> : <div />}
        {/*{logoLink ? <img src={logoLink} alt={title} /> : <div />}*/}
        <Text variant={TextVariants.header6} color={TextAndIconColors.interfacePrimary} className={styles.margin_m}>
          {title}
        </Text>
        <Text variant={TextVariants.body2} color={TextAndIconColors.interfaceSecondary} className={styles.margin_s}>
          {description}
        </Text>
      </div>
      <Link underline={false} className={styles.footer} color={TextAndIconColors.brandTertiary} onClick={serviceClick}>
        {linkText}
      </Link>
    </div>
  );
};

export default ServiceCard;
