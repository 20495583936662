import {
  Button,
  ButtonTypes,
  EInputThemes,
  InputPassword,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomIcon, { ECustomIconName } from '../../components/CustomIcon';
import Form from '../../containers/Form';
import AppContext from '../../context/AppContext';
import useStore from '../../hooks/store';
import styles from './FormPassword.module.scss';

const FormPassword = () => {
  const { user } = useStore([]);
  const { t } = useTranslation();
  const { config } = React.useContext(AppContext);

  const [error, setError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const changePassword = useCallback(
    (newValue: string) => {
      setPassword(newValue);
      if (error) setError(false);
    },
    [setPassword, error],
  );

  return (
    <Form>
      <div className={styles.form_wrapper}>
        <div className={styles.form_head}>
          <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary}>
            {t('{{serviceName}}')}
          </Text>
          <CustomIcon
            icon={ECustomIconName.userDefault}
            size={80}
            className={styles.common_margin__m}
            color={config.styles?.theme.colors?.interfaceAdPrimary}
          />
          <Text
            variant={TextVariants.header5}
            color={TextAndIconColors.labelsSecondary}
            className={styles.common_margin__m}
          >
            {user.email as string}
          </Text>
        </div>
        <div className={classNames(styles.common_column, styles.common_margin__l)}>
          <Text variant={TextVariants.body2} color={TextAndIconColors.labelsSecondary}>
            {t('passwordConfirm.description')}
          </Text>
          <InputPassword
            name='password'
            type='text'
            onChange={changePassword}
            value={password}
            placeholder={t('field.inputPassword')}
            autoComplete={true}
            errorMessage={error ? t('message.errorPassword') : undefined}
            theme={error ? EInputThemes.error : EInputThemes.base}
            className={styles.common_margin__s}
          />
        </div>
        <div className={styles.common_buttons}>
          {/*{backUrl && (*/}
          {/*  <Button*/}
          {/*    text={t('action.back').toString()}*/}
          {/*    type={ButtonTypes.button}*/}
          {/*    onClick={() => navigator(backUrl)}*/}
          {/*    variant={ButtonVariant.outlined}*/}
          {/*    theme={ButtonThemes.labelsSecondary}*/}
          {/*  />*/}
          {/*)}*/}
          <Button
            text={t('action.next').toString()}
            type={ButtonTypes.button}
            // onClick={() => checkPassword(password, () => setError(true))}
            onClick={() => {}}
          />
        </div>
      </div>
    </Form>
  );
};

export default React.memo(FormPassword);
