import { getCountryForTimezone } from 'countries-and-timezones';
import csCountries from 'i18n-iso-countries/langs/cs.json';
import deCountries from 'i18n-iso-countries/langs/de.json';
import enCountries from 'i18n-iso-countries/langs/en.json';
import esCountries from 'i18n-iso-countries/langs/es.json';
import plCountries from 'i18n-iso-countries/langs/pl.json';
import ptCountries from 'i18n-iso-countries/langs/pt.json';
import ruCountries from 'i18n-iso-countries/langs/ru.json';
import ukCountries from 'i18n-iso-countries/langs/uk.json';
import { useCallback } from 'react';

import { COUNTRY_DEFAULT, COUNTRY_TIMEZONE_DEFAULT } from '../constants/country';
import { localeNormalize } from '../constants/locale';
import { LocaleE } from '../constants/locale.types';
import { arrToStr, getFirstItem } from '../utils/string';

type CountriesByLocaleT = {
  [K in LocaleE]: Record<string, string | string[]>;
};
export type UseCountriesT = {
  countriesList: CountriesByLocaleT;
  countries: CountriesByLocaleT[LocaleE];
  countryByCode: (code: string) => string;
  officialCountryByCode: (code: string) => string;
  dropdownCountries: { value: string; text: string }[];
  getBestCountry: () => string;
  getCountryByCode: (id: string) => string;
};

const countriesList: CountriesByLocaleT = {
  [LocaleE.en]: enCountries.countries,
  [LocaleE.ru]: ruCountries.countries,
  [LocaleE.de]: deCountries.countries,
  [LocaleE.es]: esCountries.countries,
  [LocaleE.pl]: plCountries.countries,
  [LocaleE.pt]: ptCountries.countries,
  [LocaleE.cs]: csCountries.countries,
  [LocaleE.uk]: ukCountries.countries,
};

const collator = new Intl.Collator();
const sortCountries = (x: UseCountriesT['dropdownCountries'][0], y: UseCountriesT['dropdownCountries'][0]): number => {
  return collator.compare(x.text, y.text);
};

const useCountries = (locale?: string): UseCountriesT => {
  const language: LocaleE = localeNormalize(locale);
  const country =
    getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone || COUNTRY_TIMEZONE_DEFAULT)?.id ||
    COUNTRY_DEFAULT;
  const getCountryByCode = useCallback(
    (id: string) => {
      // https://en.wikipedia.org/wiki/ISO_3166-2:SH
      let code = id;
      if (id === 'AC' || id === 'HL' || id === 'TA') {
        code = 'SH';
      }
      const findCountry = countriesList[language][code];
      if (findCountry) return getFirstItem(findCountry);
      // return id;
      return '';
    },
    [language],
  );
  return {
    countriesList: countriesList,
    countries: countriesList[language],
    countryByCode: (code) => arrToStr(countriesList[language][code]),
    officialCountryByCode: (code) => getFirstItem(countriesList[language][code]),
    dropdownCountries: Object.keys(countriesList[language])
      .map((key) => ({
        value: key,
        text: arrToStr(countriesList[language][key]),
      }))
      .sort(sortCountries),
    getBestCountry: () => Object.keys(countriesList.de).find((key) => key === country) || COUNTRY_DEFAULT,
    getCountryByCode,
  };
};

export default useCountries;
