import 'normalize.css';
import './index.scss';
import '@design-system/ui-kit/dist/main.css';

import { EThemeProviderThemes, ThemeProvider } from '@design-system/ui-kit';
import React, { Suspense } from 'react';
import { appendStyles } from 'react-append-styles';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import sourceConfig from './config.json';
import { MODE_DEFAULT, ModeT } from './constants/mode';
import Core from './core';
import { generateColors } from './hooks/colors/colors.hook';
import i18n from './locales';

const mode = (process.env.REACT_APP_MODE as ModeT) || MODE_DEFAULT;
const appVersion = process.env.REACT_APP_VERSION;
const { config, supportedLocales } = Core.init(mode, appVersion, sourceConfig);

if (config.styles?.theme.colors) {
  const colors = generateColors(config.styles?.theme.colors);
  // data-theme="convertSocialLight" div[data-ui-kit-root]
  // appendStylesSimple(colors, 'div[data-theme]', 'customColors', false);
  // @ts-ignore
  appendStyles({ 'div[data-theme]': colors });
}

const app = (
  <React.StrictMode>
    <ThemeProvider theme={EThemeProviderThemes.convertSocialLight} style={{ height: '100%' }}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div></div>}>
          <App mode={mode} config={config} supportedLocales={supportedLocales} />
        </Suspense>
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

const root = document.querySelector('#root');

ReactDOM.render(app, root);
