import { Icon, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconEmail } from '../../assets/icons/email.svg';
import Page from '../../containers/Page';
import AppContext from '../../context/AppContext';
import useFlowEmailChange from '../../scenarios/flows/flowEmailChange.hook';
import styles from './EmailSent.module.scss';

const EmailSent: React.VFC = () => {
  const { t } = useTranslation();
  const { emailNew, reset } = useFlowEmailChange();
  const { config } = React.useContext(AppContext);

  return (
    <Page menu subHead={{ title: t('page.emailView.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <div className={classNames(styles.page_iconBlock, styles.page_iconBlock__border, styles.page_iconBlock__top)}>
          <Icon
            IconComponent={IconEmail}
            width={24}
            height={24}
            color={TextAndIconColors.interfaceAccentSecondary}
            className={styles.page_iconBlock__icon}
          />
          <Text variant={TextVariants.header9} color={TextAndIconColors.labelsSecondary} className={styles.this_email}>
            {t('page.emailSent.subTitle', { email: emailNew })}
          </Text>
        </div>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__m}>
          {t('page.emailSent.description.variant2')}
        </Text>
        <div className={classNames(styles.page_darkBlock, styles.common_margin__m)}>
          <Text variant={TextVariants.header9} color={TextAndIconColors.labelsPrimary}>
            {t('helper.emailNotArrive.head')}
          </Text>
          <ul className={classNames(styles.common_htmlList, styles.common_margin__xxs)}>
            <li className={classNames(styles.common_htmlListItem)}>
              <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
                {t('helper.emailNotArrive.wait')}
              </Text>
            </li>
            <li className={styles.common_htmlListItem}>
              <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
                {t('helper.emailNotArrive.checkSpam')}
              </Text>
            </li>
            <li className={styles.common_htmlListItem}>
              <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
                {t('helper.emailNotArrive.checkEmail') + ' '}
                <Link
                  underline={false}
                  color={TextAndIconColors.interfaceAccentSecondary}
                  onClick={() => reset()}
                  variant={TextVariants.body2}
                >
                  {t('action.processRestart')}
                </Link>
                {'.'}
              </Text>
            </li>
          </ul>
          <Text
            variant={TextVariants.body2}
            color={TextAndIconColors.labelsTertiary}
            className={styles.common_margin__xs}
          >
            {t('action.orContactSupport') + ' '}
            <Link
              underline={false}
              color={TextAndIconColors.interfaceAccentSecondary}
              variant={TextVariants.body2}
              className={styles.common_margin__xs}
              href={'mailto:' + config.supportEmail}
            >
              {config.supportEmail}
            </Link>
            {'.'}
          </Text>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(EmailSent);
