import {
  Button,
  ButtonTypes,
  Dropdown,
  EInputThemes,
  Input,
  TDropdownOptionValue,
  Text,
  TextAndIconColors,
  TextVariants,
  ValidationTooltipWrapper,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { isObject } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RequestPostFulfillBody } from '../../api';
import { AnswerGetFulfill, AnswerPostFulfill } from '../../api/answer.types';
import CustomIcon, { ECustomIconName } from '../../components/CustomIcon';
import { COUNTRY_WITH_MIDDLENAME } from '../../constants/country';
import { MODE_DEBUG } from '../../constants/mode';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import Form from '../../containers/Form';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import { TElementaryUrl } from '../../hocs/History';
import withElementaryUrl from '../../hocs/History/elementaryUrl';
import useCountries from '../../hooks/countries.hook';
import useHttp from '../../hooks/http';
import useInput from '../../hooks/input.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useSelect from '../../hooks/select.hook';
import { getMonths, objToISO } from '../../utils/date';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { emailMask } from '../../utils/string';
import { withSlash } from '../../utils/url';
import { validationBirthday, validationFirstname, validationLastname } from '../../utils/validation';
import styles from './FormFulfillProfile.module.scss';
import { DropdownOptions, FieldsType } from './FormFulfillProfile.types';

const initialFields: FieldsType = {
  birthday: null,
  country: null,
  firstname: null,
  lastname: null,
  middlename: null,
};
const initialDebugFields: FieldsType = {
  birthday: true,
  country: true,
  firstname: true,
  lastname: true,
  middlename: true,
};

const FormFulfillProfile: React.VFC<TElementaryUrl> = ({ elementaryURL }) => {
  const { t, i18n } = useTranslation();
  const { refresh, profile } = useContext(AuthContext);
  const { dropdownCountries, getBestCountry } = useCountries(i18n.language);
  const { requestByName, ready, meta } = useHttp();
  const navigator = useNavigate();
  const monthsList: DropdownOptions = useMemo(() => getMonths(i18n.language), [i18n.language]);
  const { mode, config } = useContext(AppContext);

  const bestCountry = useMemo(() => getBestCountry(), [getBestCountry]);
  const email = useMemo(() => emailMask(profile?.email), [profile?.email]);
  const [fields, setFields] = useState<FieldsType>(() => (mode === MODE_DEBUG ? initialDebugFields : initialFields));
  const [showMiddlename, setShowMiddlename] = useState<boolean>(COUNTRY_WITH_MIDDLENAME.has(bestCountry));
  const [firstname, changeFirstname, { error: errorFirstname, setError: setErrorFirstname, blur: blurFirstname }] =
    useInput<string>('', {
      translate: t,
      onBlurChanged: () => ga4Event('fulfillFields', { placeholders: { element: 'first_name' } }),
    });
  const [lastname, changeLastname, { error: errorLastname, setError: setErrorLastname, blur: blurLastname }] =
    useInput<string>('', {
      translate: t,
      onBlurChanged: () => ga4Event('fulfillFields', { placeholders: { element: 'last_name' } }),
    });
  const [middlename, changeMiddlename, { error: errorMiddlename, setError: setErrorMiddlename, blur: blurMiddlename }] =
    useInput<string>('', {
      translate: t,
      onBlurChanged: () => ga4Event('fulfillFields', { placeholders: { element: 'middle_name' } }),
    });
  const [day, changeDay, { error: errorDay, setError: setErrorDay, blur: blurDay }] = useInput<string>('', {
    translate: t,
    onBlurChanged: () => ga4Event('fulfillFields', { placeholders: { element: 'birthday_day' } }),
    onAutoComplete: () => ga4Event('fulfillFields', { placeholders: { element: 'birthday_day' } }),
  });
  const [month, changeMonth] = useSelect<string, TDropdownOptionValue | null>('', {
    onChanged: () => {
      setErrorDay('');
      ga4Event('fulfillFields', { placeholders: { element: 'birthday_month' } });
    },
  });
  const [year, changeYear, { blur: blurYear }] = useInput<string>('', {
    onChanged: () => setErrorDay(''),
    translate: t,
    onBlurChanged: () => ga4Event('fulfillFields', { placeholders: { element: 'birthday_year' } }),
    onAutoComplete: () => ga4Event('fulfillFields', { placeholders: { element: 'birthday_year' } }),
  });
  const [country, changeCountry] = useSelect<string, TDropdownOptionValue | null>(bestCountry, {
    onChanged: (value) => {
      setShowMiddlename(COUNTRY_WITH_MIDDLENAME.has(value));
      ga4Event('fulfillFields', { placeholders: { element: 'country' } });
    },
  });

  const getAttributes = useCallback(async () => {
    const fetched = (await requestByName('getFulfill')) as AnswerGetFulfill;
    if (isObject(fetched)) {
      setFields({
        birthday: !fetched.birthday,
        country: !fetched.country,
        firstname: !fetched.firstName,
        lastname: !fetched.lastName,
        middlename: !fetched.middleName,
      });
      if (fetched.country) setShowMiddlename(COUNTRY_WITH_MIDDLENAME.has(fetched.country));
    }
  }, [requestByName]);
  useEffect(() => {
    if (ready('getFulfill') && mode !== MODE_DEBUG) getAttributes();
  }, [getAttributes, mode, ready]);
  React.useEffect(() => {
    if (meta?.firstName) setErrorFirstname('message.errorValidationFirstname');
    if (meta?.lastName) setErrorLastname('message.errorValidationLastname');
    if (meta?.middleName) setErrorMiddlename('message.errorValidationMiddlename');
  }, [meta, setErrorFirstname, setErrorMiddlename, setErrorLastname]);

  const validation = useCallback((): boolean => {
    let valid = true;
    if (fields.firstname && !validationFirstname(firstname)) {
      valid = false;
      setErrorFirstname('message.errorValidationFirstname');
    }
    if (fields.lastname && !validationLastname(lastname)) {
      valid = false;
      setErrorLastname('message.errorValidationLastname');
    }
    if (fields.birthday && !validationBirthday(day, month, year)) {
      valid = false;
      setErrorDay('message.errorValidationBirthday');
    }
    return valid;
  }, [
    day,
    fields.birthday,
    fields.firstname,
    fields.lastname,
    firstname,
    lastname,
    month,
    setErrorDay,
    setErrorFirstname,
    setErrorLastname,
    year,
  ]);

  const postData = useCallback(async () => {
    if (!validation()) {
      return;
    }

    const body: Partial<RequestPostFulfillBody> = {};
    if (fields.birthday) body.birthday = objToISO({ day, month: month.toString(), year });
    if (fields.country) body.country = country;
    if (fields.firstname) body.firstName = firstname;
    if (fields.lastname) body.lastName = lastname;
    if (fields.middlename && middlename && showMiddlename) body.middleName = middlename;

    const fetched = (await requestByName('postFulfill', {
      body,
    })) as AnswerPostFulfill;
    if (isObject(fetched) && fetched?.success) {
      ga4Event('fulfillNextClick');
      await refresh();
      navigator(withSlash(elementaryURL || ROUTE_PERSONAL_DATA));
    }
  }, [
    country,
    day,
    elementaryURL,
    fields.birthday,
    fields.country,
    fields.firstname,
    fields.lastname,
    fields.middlename,
    firstname,
    lastname,
    middlename,
    month,
    navigator,
    refresh,
    requestByName,
    validation,
    year,
    showMiddlename,
  ]);

  const buttonDisabled: boolean =
    (fields.firstname ? !firstname : false) ||
    (fields.lastname ? !lastname : false) ||
    (fields.birthday ? !day : false) ||
    (fields.birthday ? !month : false) ||
    (fields.birthday ? !year : false) ||
    Boolean(errorFirstname) ||
    Boolean(errorLastname) ||
    Boolean(errorDay);

  useEffect(() => {
    const listener = listenKeydown('Enter', postData, buttonDisabled);
    return () => {
      listener();
    };
  }, [postData, buttonDisabled]);

  useOnLoad(Boolean(profile?.email && fields.birthday !== null), 'FormFulfillProfile');

  return (
    <Form logoClickable={false}>
      <div className={styles.form_wrapper}>
        <div className={styles.form_head}>
          <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary}>
            {t('{{serviceName}}')}
          </Text>
          <CustomIcon
            icon={ECustomIconName.userDefault}
            size={80}
            className={styles.common_margin__m}
            color={config.styles?.theme.colors?.interfaceAdPrimary}
          />
          {Boolean(email) && (
            <Text
              variant={TextVariants.header5}
              color={TextAndIconColors.labelsSecondary}
              className={styles.common_margin__m}
            >
              {email}
            </Text>
          )}
        </div>
        <Text
          variant={TextVariants.header6}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__l}
        >
          {t('page.fulfillProfile.title')}
        </Text>
        <div className={classNames({ [styles.common_margin__s]: true })}>
          {fields.lastname && (
            <Input
              placeholder={t('field.lastname')}
              value={lastname}
              onChange={changeLastname}
              className={classNames(styles.common_margin__s)}
              theme={errorLastname ? EInputThemes.error : EInputThemes.base}
              errorMessage={errorLastname}
              onBlur={blurLastname}
              required
            />
          )}
          {fields.firstname && (
            <Input
              placeholder={t('field.firstname')}
              value={firstname}
              onChange={changeFirstname}
              className={classNames(styles.common_margin__s)}
              theme={errorFirstname ? EInputThemes.error : EInputThemes.base}
              errorMessage={errorFirstname}
              onBlur={blurFirstname}
              required
            />
          )}
          {fields.middlename && showMiddlename && (
            <Input
              placeholder={t('field.middlename')}
              value={middlename}
              onChange={changeMiddlename}
              className={classNames(styles.common_margin__s)}
              theme={errorMiddlename ? EInputThemes.error : EInputThemes.base}
              onBlur={blurMiddlename}
              errorMessage={errorMiddlename}
            />
          )}
          {fields.birthday && (
            <ValidationTooltipWrapper errorMessage={errorDay}>
              <div className={classNames(styles.common_margin__s)}>
                <Text variant={TextVariants.body2} color={TextAndIconColors.labelsSecondary}>
                  {t('field.birthday')}
                </Text>
                <div className={classNames(styles.common_row, styles.common_margin__s)}>
                  <Input
                    placeholder={t('field.day')}
                    value={day}
                    onChange={changeDay}
                    theme={errorDay ? EInputThemes.error : EInputThemes.base}
                    onBlur={blurDay}
                    required
                  />
                  <div
                    className={classNames(styles.this_comboItem, {
                      [styles.this_dropdown__error]: Boolean(errorDay),
                      [styles.this_dropdown__restriction]: true,
                    })}
                  >
                    <Dropdown
                      placeholder={t('field.month')}
                      value={month}
                      options={monthsList}
                      onChange={changeMonth}
                      fluid
                      required
                      optionsListMaxHeight={200}
                    />
                  </div>
                  <Input
                    placeholder={t('field.year')}
                    value={year}
                    onChange={changeYear}
                    theme={errorDay ? EInputThemes.error : EInputThemes.base}
                    className={styles.this_comboItem}
                    onBlur={blurYear}
                    required
                  />
                </div>
              </div>
            </ValidationTooltipWrapper>
          )}
          {fields.country && (
            <div className={classNames(styles.common_margin__s)}>
              <Dropdown
                placeholder={t('field.country')}
                value={country}
                options={dropdownCountries}
                onChange={changeCountry}
                fluid
                withSearch
                required
              />
            </div>
          )}
        </div>
        <div className={styles.common_buttons}>
          <Button
            text={t('action.next').toString()}
            type={ButtonTypes.button}
            onClick={postData}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </Form>
  );
};

export default withElementaryUrl(FormFulfillProfile);
