import classNames from 'classnames';
import React, { useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MODE_DEBUG } from '../../constants/mode';
import { ROUTE_FULL_DELETE_ACCOUNT, ROUTE_FULL_DELETE_ACCOUNT_ALL, ROUTE_SECURITY } from '../../constants/routes';
import Page from '../../containers/Page';
import AppContext from '../../context/AppContext';
import useOnLoad from '../../hooks/onLoad.hook';
import usePrivatePages from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { withSlash } from '../../utils/url';
import styles from './DeleteAccount.module.scss';
import FullDelete from './FullDelete';
import PartialDelete from './PartialDelete';

// FIXME: проблема с useStore ессли его вызвать в рут компоненте и в дочернем, связано с подпиской on
const DeleteAccount: React.FC = () => {
  const { t } = useTranslation();
  const { user, state } = useStore([]);
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { mode, config } = useContext(AppContext);

  const showPartial = React.useMemo(() => config.products.some((item) => item.deletingGuide), [config.products]);

  useLayoutEffect(() => {
    if (user.deleteRequestTimestamp !== undefined && mode !== MODE_DEBUG) {
      navigate(withSlash(ROUTE_SECURITY));
      setPage(ROUTE_FULL_DELETE_ACCOUNT, { available: false });
      setPage(ROUTE_FULL_DELETE_ACCOUNT_ALL, { available: false });
    }
  }, [mode, navigate, setPage, user.deleteRequestTimestamp]);

  useOnLoad(Boolean(state.user), 'DeleteAccount');

  return (
    <Page menu subHead={{ title: t('page.accountActions.title'), backLink: withSlash(ROUTE_SECURITY) }}>
      <div className={classNames(styles.page_wrapper)}>
        <FullDelete action={() => navigate(ROUTE_FULL_DELETE_ACCOUNT_ALL)} />
        {showPartial && <PartialDelete className={styles.common_margin__m} products={config.products} />}
      </div>
    </Page>
  );
};

export default React.memo(DeleteAccount);
