import {
  Button,
  ButtonTypes,
  ButtonVariant,
  EButtonSizes,
  Link,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTE_SECURITY } from '../../constants/routes';
import Page from '../../containers/Page';
import AppContext from '../../context/AppContext';
import useLoginHistory from '../../hooks/loginHistory.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { HistoryTable, SessionsList } from '../../modules/Tables';
import { EFlowType, useStartFlowStep } from '../../scenarios';
import { sortByLastAccess } from '../../utils/session';
import { withSlash } from '../../utils/url';
import styles from './Sessions.module.scss';

const defaultItems = 5;

const Sessions: React.FC = () => {
  const { t } = useTranslation();
  const { sessions } = useStore(['sessions']);
  const { loginHistory, moreExists, showMore } = useLoginHistory(defaultItems);
  const { startFlow } = useStartFlowStep();
  const { config } = React.useContext(AppContext);

  const list = useMemo(() => sortByLastAccess(sessions) || [], [sessions]);
  // const showAll = useCallback(() => {
  //   if (sessions?.length) {
  //     setList(sortByLastAccess(sessions));
  //   }
  // }, [sessions]);

  const endAll = useCallback(async () => {
    await startFlow(EFlowType.deleteSessions);
  }, [startFlow]);
  const endOne = useCallback(
    async (id: string) => {
      await startFlow(EFlowType.deleteSession, { sessionId: id });
    },
    [startFlow],
  );

  useOnLoad(Boolean(list?.length && loginHistory?.length), 'Sessions');

  return (
    <Page menu subHead={{ title: t('page.sessions.title'), backLink: withSlash(ROUTE_SECURITY) }}>
      <div className={classNames(styles.page_wrapper)}>
        <Text color={TextAndIconColors.labelsSecondary} variant={TextVariants.header5}>
          {t('block.sessions.title')}
        </Text>
        {Boolean(list?.length && list.length > 1) && (
          <Text
            variant={TextVariants.body2}
            color={TextAndIconColors.labelsTertiary}
            className={styles.common_margin__m}
          >
            {t('block.sessions.suspiciousIP') + ' '}
            <Link
              text={config.supportEmail}
              href={'mailto:' + config.supportEmail}
              underline={false}
              color={TextAndIconColors.interfaceAccentTertiary}
              variant={TextVariants.body3}
            />
          </Text>
        )}
        {Boolean(list?.length) && (
          <div className={classNames(styles.page_block, styles.page_block__border, styles.common_margin__m)}>
            <SessionsList sessions={list} deleteSession={endOne} />
          </div>
        )}
        {Boolean(list?.length && list.length > 1) && (
          <Button
            size={EButtonSizes.l}
            variant={ButtonVariant.filled}
            text={t('action.closeAllSessions').toString()}
            onClick={endAll}
            className={styles.common_margin__s}
            type={ButtonTypes.button}
          />
        )}
        <Text
          color={TextAndIconColors.labelsSecondary}
          variant={TextVariants.header5}
          className={classNames(styles.common_margin__l)}
        >
          {t('page.sessions.subTitle')}
        </Text>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__m}>
          {t('page.sessions.description')}
        </Text>
        {Boolean(loginHistory && loginHistory.length > 0) && (
          <div className={classNames(styles.page_block, styles.page_block__smallBorder, styles.common_margin__m)}>
            <HistoryTable loginHistory={loginHistory!} />
            {/* TODO: отступы */}
            {moreExists && (
              <Button
                size={EButtonSizes.xs}
                variant={ButtonVariant.text}
                text={t('action.showMore').toString()}
                onClick={showMore}
                className={classNames(styles.common_margin__xs, styles.this_watchMore, styles.this_marginBottom)}
              />
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

export default Sessions;
