import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { useLoader } from 'library-react-hooks';
import React, { useCallback, useContext, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SocialSizeE, SocialTypesE, SocialVariantE } from '../../components/Social';
import Social from '../../components/Social/Social';
import { MODE_DEBUG } from '../../constants/mode';
import Page from '../../containers/Page/Page';
import AppContext from '../../context/AppContext';
import useStore from '../../hooks/store';
import useFlowSocial from '../../scenarios/flows/flowSocial.hook';
import { ga4Event } from '../../utils/ga';
import { sentryException } from '../../utils/sentry';
import styles from './SocialAdd.module.scss';

const mockedSocialName = SocialTypesE.google;

const SocialAdd: React.VFC = () => {
  const { t } = useTranslation();
  const { actionToContinueAdd, reset, socialName } = useFlowSocial();
  const { user } = useStore([]);
  const { loaderOn } = useLoader();
  const { mode } = useContext(AppContext);

  const name = useMemo(
    () => (!socialName && mode === MODE_DEBUG ? mockedSocialName : (socialName as SocialTypesE)),
    [mode, socialName],
  );
  useLayoutEffect(() => {
    if (!name) {
      reset();
      console.error('Не сохранилось название соц. сети');
      sentryException('SocialAdd page: the name of the social network has not been preserved', 'warning');
    }
  }, [name, reset]);
  const addSocial = useCallback(() => {
    ga4Event('socialAddClick', { placeholders: { element: name } });
    loaderOn();
    // GA4 BUG: Далее будет редирект и ГА4 не успевает отправиться
    setTimeout(() => {
      actionToContinueAdd();
    }, 500);
  }, [actionToContinueAdd, loaderOn, name]);
  const socialText = t(`social.${name}`);
  const buttonDisabled = !name;

  return (
    <Page menu subHead={{ title: t('page.socialAdd.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <div className={classNames(styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
            {t('page.socialAdd.description', { socialName: socialText })}
          </Text>
          <div className={classNames(styles.this_socialRow, styles.common_margin__l)}>
            <Social variant={SocialVariantE.var3} type={name} size={SocialSizeE.s} />
            <Text variant={TextVariants.body1} color={TextAndIconColors.labelsPrimary}>
              {user.email}
            </Text>
          </div>
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.cancel').toString()}
              type={ButtonTypes.button}
              onClick={() => {
                ga4Event('socialAddCancelClick', { placeholders: { element: name } });
                reset();
              }}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
            />
            <Button
              text={t('action.bind').toString()}
              type={ButtonTypes.button}
              onClick={addSocial}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SocialAdd;
