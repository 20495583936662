export const updateDomLang = (language: string): void => {
  const html = document.querySelectorAll('html')[0];
  html.setAttribute('lang', language);
};

export const getDomLang = (): string | null => {
  const html = document.querySelectorAll('html')[0];
  return html.getAttribute('lang');
};

export const headAppend = (src: string, async?: boolean, defer?: boolean, attrs?: Record<string, string>): void => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  if (async) script.async = true;
  if (defer) script.defer = true;
  if (attrs) {
    (Object.keys(attrs) as Array<keyof typeof attrs>).forEach((key) => {
      script.setAttribute(key, attrs[key]);
    });
  }

  document.head.append(script);
};
