import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'src/context/AuthContext';

import { LocaleE } from '../../constants/locale.types';
import { MODE_DEBUG, MODE_MOCKED, MODE_READONLY, ModeT } from '../../constants/mode';
import AppContext from '../../context/AppContext';
import useAuth, { EAuthStatus, TAuthStatus } from '../../hooks/auth.hook';
import useLanguage from '../../hooks/language.hook';
import { chooseRoutes, ERoutes } from '../../routes';
import { ga4LangChange, gaSetKeycloakId, gaSetLocale } from '../../utils/ga';
import { sentrySetUser, sentryStorage } from '../../utils/sentry';
import { TEnvironmentProps } from './Enviroment.types';

const routesByStatus = (status: TAuthStatus, mode?: ModeT): ERoutes => {
  // if (mode === MODE_DEBUG) return ERoutes.debug;
  if (mode === MODE_MOCKED) return ERoutes.mock;
  switch (status.type) {
    case EAuthStatus.notReady: {
      // Пока не обработано
      return ERoutes.null;
    }
    case EAuthStatus.error: {
      // 403 Не удалось авторизовать
      if (status.code === 403) return ERoutes.notAuth;
      // Что-то с бэком
      if (status.code === 500) return ERoutes.serverError;
      // Что-то с бэком
      if (status.code === 503) return ERoutes.serverUnavailable;
      // Невалидные аттрибуты у юзера в КК
      if (status.code === 422) return ERoutes.serverUnavailable;

      return ERoutes.notFound;
    }
    case EAuthStatus.userReceived: {
      // Можно в кабинет
      if (mode === MODE_READONLY) return ERoutes.readonly;
      return ERoutes.cabinet;
    }
    case EAuthStatus.notFulfilled: {
      // Нужно дозаполнить профиль
      return ERoutes.fulfill;
    }
    default: {
      // Что-то пошло не так
      return ERoutes.notFound;
    }
  }
};

const withInternal = (Component: React.ComponentType<TEnvironmentProps>) => () => {
  const { mode, supportedLocales, config } = useContext(AppContext);
  const { i18n } = useTranslation();
  const { logout, profile, refresh, status } = useAuth({
    mocked: mode === MODE_MOCKED || mode === MODE_DEBUG,
    forceFulfillProfile: config.features.forceFulfillProfile,
  });

  const routesConfig = useMemo(() => chooseRoutes(routesByStatus(status, mode), status.code), [mode, status]);
  const ready = useMemo(() => status.type !== EAuthStatus.notReady, [status]);

  const onChange = useCallback((tag: string) => {
    ga4LangChange(tag);
    sentryStorage.put('language', tag);
    gaSetLocale(tag);
  }, []);
  const onDetected = useCallback(
    (tag: string) => {
      i18n.changeLanguage(tag);
      sentryStorage.put('language', tag);
      gaSetLocale(tag);
      // once the language is defined
      // gaPageView(tag);
    },
    [i18n],
  );
  useLanguage(ready, onChange, onDetected, supportedLocales, profile?.locale as LocaleE);
  useEffect(() => {
    if (ready) gaSetKeycloakId(profile?.sub || 'undefined');
  }, [ready, profile?.sub]);
  useEffect(() => {
    sentryStorage.put('status', `type: ${status.type}, code: ${status.code}`);
  }, [status]);
  useEffect(() => {
    sentrySetUser(profile ? { id: profile?.sub, email: profile?.email } : null);
  }, [profile]);

  return (
    <AuthContext.Provider value={{ logout, profile, refresh }}>
      <Component showMenu={mode === MODE_MOCKED} ready={ready} routesConfig={routesConfig} />
    </AuthContext.Provider>
  );
};

export default withInternal;
