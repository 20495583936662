import { useCallback } from 'react';

import { useUpdateState } from './helper.hook';

const useCheckbox = <T = boolean>(
  initialState: T | (() => T),
  props:
    | Partial<{
        onChanged: (state: T) => void;
      }>
    | undefined = {},
): [state: T, change: (newValue: T) => void] => {
  const { onChanged } = props;

  const [state, setState] = useUpdateState<T>(initialState);
  const change = useCallback(
    (newValue: T) => {
      setState(newValue);
      onChanged?.(newValue);
    },
    [setState, onChanged],
  );

  return [state, change];
};

export default useCheckbox;
