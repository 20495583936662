import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTE_SECURITY } from '../../constants/routes';
import Page from '../../containers/Page';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { withSlash } from '../../utils/url';
import styles from './Totp.module.scss';
import TotpOff from './TotpOff';
import TotpOn from './TotpOn';

const Totp: React.FC = () => {
  const { t } = useTranslation();
  const { credentials, state } = useStore(['credentials']);

  useOnLoad(Boolean(state.credentials), 'Totp');

  return (
    <Page menu subHead={{ title: t('page.totp.title'), backLink: withSlash(ROUTE_SECURITY) }}>
      <div className={classNames(styles.page_wrapper)}>
        {credentials.totp !== null && credentials.totp.length > 0 && <TotpOn totpCredentials={credentials.totp} />}
        {credentials.totp !== null && credentials.totp.length === 0 && <TotpOff />}
      </div>
    </Page>
  );
};

export default React.memo(Totp);
