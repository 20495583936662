import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Dropdown,
  EInputThemes,
  Input,
  TDropdownOptionValue,
  Text,
  TextAndIconColors,
  TextVariants,
  ValidationTooltipWrapper,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { isObject } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AnswerPatchProfile } from '../../api/answer.types';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import Page from '../../containers/Page/Page';
import { useUpdateState } from '../../hooks/helper.hook';
import useHttp from '../../hooks/http';
import useInput from '../../hooks/input.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { getMonths, isoToObject, objToISO } from '../../utils/date';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { withSlash } from '../../utils/url';
import { validationBirthday } from '../../utils/validation';
import { DropdownOptions } from '../FormFulfillProfile/FormFulfillProfile.types';
import styles from './BirthdayEdit.module.scss';

const BirthdayEdit: React.VFC = () => {
  const { t, i18n } = useTranslation();
  const { requestByName, ready } = useHttp();
  const navigator = useNavigate();
  const { user, update, state } = useStore([]);

  const monthsList: DropdownOptions = useMemo(() => getMonths(i18n.language), [i18n.language]);
  const {
    day: initDay,
    month: initMonth,
    year: initYear,
  } = useMemo(() => isoToObject(user.birthday || ''), [user.birthday]);
  const [day, changeDay, { error: errorDay, setError: setErrorDay }] = useInput<string>(
    (Number.isNaN(initDay) ? '' : initDay).toString(),
    { translate: t },
  );
  const [month, setMonth] = useUpdateState<string>((Number.isNaN(initMonth) ? '' : initMonth).toString());
  const changeMonth = useCallback(
    (value: TDropdownOptionValue | null) => {
      setErrorDay('');
      setMonth(value ? value.toString() : '0');
    },
    [setErrorDay, setMonth],
  );
  const [year, changeYear] = useInput<string>((Number.isNaN(initYear) ? '' : initYear).toString(), {
    onChanged: () => setErrorDay(''),
  });

  const updateBirthday = useCallback(async () => {
    let valid = true;
    if (!validationBirthday(day, month, year)) {
      valid = false;
      setErrorDay('message.errorValidationBirthday');
    }
    if (valid && ready('patchProfile')) {
      const updated: string = objToISO({ day, month, year });
      const fetched = (await requestByName('patchProfile', {
        body: { birthday: updated },
        successMsg: 'message.successSaved',
      })) as AnswerPatchProfile;
      if (isObject(fetched) && fetched.success) {
        ga4Event('mainInfoSaveClick', { placeholders: { element: 'date of birth' } });
        await update('user');
        navigator(withSlash(ROUTE_PERSONAL_DATA));
      }
    }
  }, [day, month, navigator, ready, requestByName, setErrorDay, update, year]);

  const buttonDisabled: boolean = !day || !year || Boolean(errorDay);

  useEffect(() => {
    const listener = listenKeydown('Enter', updateBirthday, buttonDisabled);
    return () => {
      listener();
    };
  }, [updateBirthday, buttonDisabled]);

  useOnLoad(Boolean(state.user), 'BirthdayEdit');

  return (
    <Page menu subHead={{ title: t('page.birthdayEdit.title'), backLink: withSlash(ROUTE_PERSONAL_DATA) }}>
      <div className={classNames(styles.page_wrapper)}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.birthdayEdit.description')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
            {t('page.birthdayEdit.subTitle')}
          </Text>
          <ValidationTooltipWrapper errorMessage={errorDay}>
            <div className={classNames(styles.page_block, styles.common_margin__l, styles.common_row)}>
              <Input
                placeholder={t('field.day')}
                value={day}
                onChange={changeDay}
                theme={errorDay ? EInputThemes.error : EInputThemes.base}
              />
              <div
                className={classNames(styles.this_comboItem, {
                  [styles.this_dropdown__error]: Boolean(errorDay),
                  [styles.this_dropdown__restriction]: true,
                })}
              >
                <Dropdown
                  placeholder={t('field.month')}
                  value={month}
                  options={monthsList}
                  onChange={changeMonth}
                  fluid
                />
              </div>
              <Input
                placeholder={t('field.year')}
                value={year}
                onChange={changeYear}
                theme={errorDay ? EInputThemes.error : EInputThemes.base}
                className={styles.this_comboItem}
              />
            </div>
          </ValidationTooltipWrapper>
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.cancel').toString()}
              type={ButtonTypes.button}
              onClick={() => {
                ga4Event('mainInfoCancelClick', { placeholders: { element: 'date of birth' } });
                navigator(withSlash(ROUTE_PERSONAL_DATA));
              }}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
            />
            <Button
              text={t('action.save').toString()}
              type={ButtonTypes.button}
              onClick={updateBirthday}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default BirthdayEdit;
