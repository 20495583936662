export enum ERoutes {
  // страницы
  fulfill = 'fulfill',
  cabinet = 'cabinet',
  readonly = 'readonly',
  external = 'external',
  // ошибки
  notAuth = 'notAuth',
  serverError = 'serverError',
  serverUnavailable = 'serverUnavailable',
  notFound = 'notFound',
  null = 'null',
  // дебаг
  mock = 'mock',
  debug = 'debug',
}
