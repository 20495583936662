import i18next from 'i18next';

import apiInit from '../api';
import { mockRequests } from '../api/mocks';
import { LOCALE_SUPPORTED } from '../constants/locale';
import { LocaleE } from '../constants/locale.types';
import { MODE_MOCKED, ModeT, withMockModes } from '../constants/mode';
import { consentTools, TConsentToolsVendors } from '../utils/consentTools';
import { gaInit, gaNotSendEvents } from '../utils/ga';
import { gaUpdateConfig, setBusinessName } from '../utils/ga/data';
import { sentryInit } from '../utils/sentry';
import { IConfig } from './config';
import createConfig from './config/Config';
// import {logsEnable} from "library-react-hooks";

interface ICore {
  config: IConfig;
  supportedLocales: Array<LocaleE>;
}

class Core {
  static #instance: ICore;

  private constructor(instance: ICore) {
    Core.#instance = instance;
  }

  static #makeConfig(customConfig?: unknown): IConfig {
    return createConfig(customConfig);
  }

  static #checkInstance() {
    if (!Core.#instance) {
      throw new Error('Called before init');
    }
  }

  static #initTranslations(config: IConfig, supportedLocales: Array<LocaleE>): void {
    // Загрузка данных о компании
    i18next.options.interpolation!.defaultVariables = {
      companyName: config.company.name,
      serviceName: config.company.serviceName,
      copyright: config.company.copyright,
      supportEmail: config.supportEmail,
    };
    // eslint-disable-next-line unicorn/prefer-spread
    const lngs = ['cimode'].concat(...supportedLocales);
    if (config.locales.includes(LocaleE.pt)) lngs.push('pt');
    i18next.options.supportedLngs = lngs;
    i18next.emit('added');

    // Добавляем/перезатираем тексты
    const translations = config.translations;
    if (translations) {
      Object.entries(translations).forEach(([key, value]) => {
        if (supportedLocales.includes(key as LocaleE)) {
          i18next.addResourceBundle(key, 'translation', value, true, true);

          if (key === LocaleE.pt) {
            i18next.addResourceBundle('pt', 'translation', value, true, true);
          }
        }
      });
    }

    const resources = i18next.options.resources;
    if (resources) {
      Object.keys(resources).forEach((item) => {
        if (![...supportedLocales, 'pt'].includes(item)) i18next.removeResourceBundle(item, 'translation');
      });
    }
  }

  static #initIntegrations(config: IConfig): void {
    const googleAnalytics = config.integrations.googleAnalytics;

    if (googleAnalytics) {
      setBusinessName(googleAnalytics.businessName);
      if (googleAnalytics.events) gaUpdateConfig(googleAnalytics.events);
    } else {
      // Отправка событий в ГА все равно вызывается из компонентов, но осуществлять ее не нужно.
      gaNotSendEvents();
    }

    if (config.integrations.consentManager) {
      const vendors: TConsentToolsVendors = [];
      if (googleAnalytics && googleAnalytics.consentManagerId)
        vendors.push({
          name: 'Google Analytics',
          callback: () => gaInit(googleAnalytics.trackers),
          inited: false,
          id: googleAnalytics.consentManagerId,
        });

      consentTools(config.integrations.consentManager.src, config.integrations.consentManager.host, vendors);
    } else {
      // eslint-disable-next-line unicorn/no-lonely-if
      if (googleAnalytics) gaInit(googleAnalytics.trackers);
    }
  }

  static #calculateLocales(locales: Array<LocaleE>): Array<LocaleE> {
    return LOCALE_SUPPORTED.filter((item) => locales.includes(item));
  }

  public static init(mode: ModeT, appVersion?: string, customConfig?: unknown): ICore {
    if (!Core.#instance) {
      window.app = {
        version: appVersion,
      };
      window.passportReferrer = undefined;

      if (withMockModes.includes(mode)) {
        mockRequests();
      }
      apiInit({ mocked: mode === MODE_MOCKED });

      const config = Core.#makeConfig(customConfig);

      if (withMockModes.includes(mode)) {
        // Отправка событий в ГА все равно вызывается из компонентов, но осуществлять ее не нужно.
        gaNotSendEvents();
      } else {
        sentryInit(appVersion);

        Core.#initIntegrations(config);
      }

      const supportedLocales = Core.#calculateLocales(config.locales);
      Core.#initTranslations(config, supportedLocales);

      if (process.env.REACT_APP_IS_LOCAL) {
        // logsEnable();
      }

      new Core({ config, supportedLocales });
    }

    return Core.#instance;
  }

  public static getInstance(): ICore {
    this.#checkInstance();

    return Core.#instance;
  }
}

export default Core;
