import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSessionStr } from '../../utils/date';
import { arrToStr } from '../../utils/string';
import styles from './Session.module.scss';
import { TSessionProps } from './Session.types';

const Session: React.VFC<TSessionProps> = ({ id, clients, ipAddress, lastAccess, start, className }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Text variant={TextVariants.header9} color={TextAndIconColors.labelsSecondary}>
        {t('block.sessions.session') + ' ' + (id + 1)}
      </Text>
      <Text
        variant={TextVariants.body3}
        color={TextAndIconColors.labelsTertiary}
        className={classNames(styles.break, styles.margin__xxs)}
      >
        {arrToStr(clients)}
      </Text>
      <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary} className={styles.margin__s}>
        {t('field.IpAddress') + ' ' + ipAddress}
      </Text>
      <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary} className={styles.margin__xxs}>
        {t('block.sessions.lastLogin') + ' ' + getSessionStr(lastAccess, i18n.language)}
      </Text>
      <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary} className={styles.margin__xxs}>
        {t('block.sessions.firstLogin') + ' ' + getSessionStr(start, i18n.language)}
      </Text>
    </div>
  );
};

export default Session;
