import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Table from '../../components/Table/Table';
import { ETableAction, ETableIcon, ITableList } from '../../components/Table/Table.types';
import { ROUTE_FULL_DELETE_ACCOUNT } from '../../constants/routes';
import styles from './Tables.module.scss';
import { IAccountActionsTable } from './Tables.types';

const styleRules: Record<string, string> = {
  '1': classNames(styles.threeColumnsTable_column1),
  '2': classNames(styles.threeColumnsTable_column2),
  '3': classNames(styles.threeColumnsTable_column3),
};
const AccountActionsTable: React.VFC<IAccountActionsTable> = ({ user, className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteAction = useCallback(() => {
    navigate(ROUTE_FULL_DELETE_ACCOUNT);
  }, [navigate]);
  const toBeDeleted = useMemo(() => Boolean(user.deleteRequestTimestamp), [user.deleteRequestTimestamp]);

  const list = useMemo<ITableList[]>(
    () => [
      {
        data: {
          '1': (
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {toBeDeleted ? t('field.account') : t('field.deleteAccount')}
            </Text>
          ),
          '2': toBeDeleted ? (
            <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
              {user.email}
            </Text>
          ) : (
            ''
          ),
          '3': (
            <Text variant={TextVariants.body3} color={TextAndIconColors.labelsTertiary}>
              {toBeDeleted ? t('status.removalStage') : ''}
            </Text>
          ),
        },
        action: toBeDeleted ? undefined : deleteAction,
      },
    ],
    [deleteAction, t, toBeDeleted, user.email],
  );
  return (
    <Table
      list={list}
      styleRules={styleRules}
      divider
      rowHover
      className={className}
      endIcon={ETableIcon.open}
      actionType={ETableAction.row}
      columns={['1', '2', '3']}
      classNameCells={styles.threeColumnsTable_cells}
    />
  );
};

export default AccountActionsTable;
