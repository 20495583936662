import { Icon, IconSizes, TextAndIconColors } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ReactComponent as IconHand } from '../../assets/icons/hand.svg';
import { ROUTE_PERSONAL_DATA, ROUTE_SECURITY, ROUTE_SERVICES } from '../../constants/routes';
import { STORAGE_MENU_ICON_SHOWN_KEY } from '../../constants/storage';
import AppContext from '../../context/AppContext';
import useLinkActions from '../../hooks/linkActions.hook';
import Storage, { LOCAL_STORAGE } from '../../utils/storage';
import styles from './Menu.module.scss';
import MenuItem from './MenuItem';

const storage = new Storage(LOCAL_STORAGE);

// FIXME
const getGaCurrentPage = (path: string): 'security' | 'products' | 'my_details' | 'undefined' => {
  if (path.indexOf(ROUTE_SECURITY) > 0) return 'security';
  if (path.indexOf(ROUTE_SERVICES) > 0) return 'products';
  if (path.indexOf(ROUTE_PERSONAL_DATA) > 0) return 'my_details';
  return 'undefined';
};

function isEndOfElement(element: HTMLDivElement) {
  return element.offsetWidth + element.scrollLeft + 10 >= element.scrollWidth;
}

const getFirstPath = (link: string): string | undefined => link.split('/').find(Boolean);

const Divider: React.FC = () => (
  <React.Fragment>
    <div className={styles.this_hr}>
      <hr />
    </div>
    <div className={styles.this_vl}></div>
  </React.Fragment>
);

type TMenuProps = {
  callback?: () => void;
};
const Menu: React.VFC<TMenuProps> = ({ callback }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { config } = useContext(AppContext);
  const linkAction = useLinkActions();

  const [showIcon, setShowIcon] = useState<boolean>(!storage.getItem(STORAGE_MENU_ICON_SHOWN_KEY));
  const [showBlur, setShowBlur] = useState<boolean>(false);
  const listRef: React.Ref<HTMLDivElement> = useRef(null);
  const isActive = (item: string): boolean => getFirstPath(item) === getFirstPath(pathname);

  const checkShowBlur = useCallback(() => {
    if (listRef.current && isEndOfElement(listRef.current)) {
      setShowBlur(false);
    } else setShowBlur(true);
  }, [listRef]);
  useEffect(() => {
    checkShowBlur();
  }, [checkShowBlur]);

  const onScroll = () => {
    if (showIcon && listRef.current && listRef.current.scrollWidth > listRef.current.clientWidth) {
      setShowIcon(false);
      storage.setItem(STORAGE_MENU_ICON_SHOWN_KEY, true);
    }
    checkShowBlur();
  };

  const itemClass = (item: string, isFirst: boolean) =>
    classNames(styles.this_item, {
      [styles.this_item__isActive]: isActive(item),
      [styles.this_item__notFirst]: !isFirst,
    });

  const classScrollHelper = classNames(styles.this_scrollHelper, {
    [styles.this_scrollHelper__hide]: !showBlur,
  });

  return (
    <div ref={listRef} className={styles.this_wrapper} onScroll={onScroll}>
      <div className={styles.this_root}>
        {config.sidebarMenu.map((props, id) => {
          if (props === 'divider') return <Divider key={id} />;

          const { text, icon, action } = props;
          return (
            <MenuItem
              key={id}
              text={t(text)}
              icon={icon}
              isActive={isActive(action.link)}
              onClick={() => {
                linkAction(action, {
                  gaProps: { placeholders: { page: getGaCurrentPage(pathname), element: 'undefined' } },
                });
                callback?.();
              }}
              className={itemClass(action.link, id === 0)}
              iconColor={config.styles?.theme.colors?.interfaceAdPrimary}
            />
          );
        })}
      </div>
      <div className={classScrollHelper}>
        {showIcon && (
          <Icon
            IconComponent={IconHand}
            size={IconSizes.l}
            color={TextAndIconColors.labelsTertiary}
            className={styles.this_hand}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(Menu);
