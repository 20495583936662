const alpha: Record<string, string> = {
  backgroundPrimary: '#fff',
  backgroundSecondary: '#fcfbfd',
  backgroundTertiary: '#f7f7fa',
  backgroundQuaternary: '#efeff5',
  backgroundFifth: '#948f98',
  backgroundSixth: '#625c66',
  backgroundSeventh: '#29272b',
  backgroundActionConfirm: '#f2f9ec',
  backgroundActionNotice: '#fcf6ed',
  backgroundActionError: '#fcf0f0',
  backgroundActionBrand: '#f2efff',
  backgroundActionLink: '#eef5fe',
  interfacePrimary: '#5a545f',
  interfaceSecondary: '#6e6873',
  interfaceTertiary: '#8c8691',
  interfaceQuaternary: '#b4aeb9',
  interfaceOnsurface: '#fff',
  interfaceFifth: '#ddd9e0',
  interfaceSixth: '#f3eff5',
  interfaceSeventh: '#f8f6fa',
  interfaceBluePrimary: '#57b9f0',
  interfaceBlueSecondary: '#6bcdff',
  interfaceBlueTertiary: '#43a5dc',
  interfaceBlueQuaternary: '#399bd2',
  interfaceAdPrimary: '#8776e0',
  interfaceAdSecondary: '#9b8af4',
  interfaceAdTertiary: '#7362cc',
  interfaceAdQuaternary: '#6958c2',
  interfaceFocusPrimary: '#f6d83b',
  labelPrimary: '#0b1116',
  labelSecondary: '#333a40',
  labelTertiary: '#5a6065',
  labelQuaternary: '#888c8f',
  labelOnsurface: '#fff',
  labelLink: '#43a5dc',
  labelFifth: '#b5b8bb',
  labelSixth: '#d8dadc',
  labelSeventh: '#eeeff1',
  labelError: '#e47470',
  labelNotice: '#d79735',
  labelConfirm: '#7ec050',
  labelBrand: '#8776e0',
  actionConfirmPrimary: '#7ec050',
  actionConfirmSecondary: '#92d464',
  actionConfirmTertiary: '#6aac3c',
  actionNoticePrimary: '#ebab49',
  actionNoticeSecondary: '#ffbf5d',
  actionNoticeTertiary: '#d79735',
  actionErrorPrimary: '#e47470',
  actionErrorSecondary: '#f88884',
  actionErrorTertiary: '#d0605c',
};

// old key -> alpha key
const comparison: Record<string, string> = {
  actionPromoPrimary: 'actionNoticePrimary',
  actionConfirmPrimary: 'actionConfirmPrimary',
  actionConfirmSecondary: 'actionConfirmSecondary',
  actionConfirmTertiary: 'actionConfirmTertiary',
  actionErrorPrimary: 'actionErrorPrimary',
  actionErrorSecondary: 'actionErrorSecondary',
  actionPromoSecondary: 'actionNoticeSecondary',
  bgPrimary: 'backgroundPrimary',
  bgSecondary: 'backgroundSecondary',
  bgTertiary: 'backgroundTertiary',
  brandSecondary: 'interfaceAdPrimary',
  brandTertiary: 'interfaceAdSecondary',
  brandQuarternary: 'interfaceAdPrimary',
  brandQuinary: 'interfaceAdQuaternary',
  interfacePrimary: 'interfacePrimary',
  interfaceSecondary: 'interfaceSecondary',
  interfaceTertiary: 'interfaceTertiary',
  // interfaceQuaternary: 'interfaceQuaternary',
  interfaceQuaternary: 'interfaceFifth',
  interfaceAccent: 'interfaceAdPrimary',
  interfaceAccentTertiary: 'interfaceAdTertiary',
  interfaceAccentSecondary: 'interfaceAdSecondary',
};

// eslint-disable-next-line unicorn/prefer-object-from-entries
export const redefinition = Object.entries(comparison).reduce((prev, [key, value]) => {
  return alpha[value] ? { ...prev, [key]: alpha[value] } : prev;
}, {});

export const redefinitionCustom = (customAlpha: Record<string, string>) =>
  // eslint-disable-next-line unicorn/prefer-object-from-entries
  Object.entries(comparison).reduce((prev, [key, value]) => {
    return customAlpha[value]
      ? { ...prev, [key]: customAlpha[value] }
      : alpha[value]
        ? { ...prev, [key]: alpha[value] }
        : prev;
  }, {});
