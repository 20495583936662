import { useTimer, useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AnswerDeletePhone, AnswerPostConfirmPhone, AnswerPutPhone } from '../../api/answer.types';
import {
  ROUTE_FULL_PHONE_ADD,
  ROUTE_FULL_PHONE_ADD_CODE,
  ROUTE_FULL_PHONE_ADD_COMPLETE,
  ROUTE_FULL_PHONE_REMOVE_COMPLETE,
} from '../../constants/routes';
import useHttp from '../../hooks/http';
import usePrivatePages, { privatePagesSet } from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { sentryException } from '../../utils/sentry';
import { ALERT_GROUP_EMAIL_CODE, TIMER_NAME_EMAIL_CODE } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, IFlowEmailChange, IFlowPhones, IPhonesData } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';
import { resetSecuritySteps } from '../resetFlows';

const initialParams: IPhonesData['_props'] = {
  phone: '',
  phoneToken: '',
  attemptId: '',
};
const data: IPhonesData = {
  _props: { ...initialParams },
  getProp(name) {
    return this._props[name];
  },
  setProp(name, value) {
    this._props[name] = value;
  },
  reset() {
    this._props = { ...initialParams };
  },
};

const usingPrivatePages: { [K in 'add' | 'code' | 'complete' | 'deleted']: string } = {
  add: ROUTE_FULL_PHONE_ADD,
  code: ROUTE_FULL_PHONE_ADD_CODE,
  complete: ROUTE_FULL_PHONE_ADD_COMPLETE,
  deleted: ROUTE_FULL_PHONE_REMOVE_COMPLETE,
};

export const flowPhonesReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
  data.reset();
};

const useFlowPhones = (): IFlowPhones => {
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { requestByName, ready, errorCode } = useHttp();
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();
  const { update } = useStore([]);
  const { clear } = useToast();
  const { cancelTimer } = useTimer();

  // Ключи текстов
  const [error, setError] = useState<IFlowEmailChange['error']>(null);
  useEffect(() => {
    if (errorCode?.putPhone) {
      setError(errorCode.putPhone);
    }
    if (errorCode?.postConfirmPhone) {
      setError(errorCode.postConfirmPhone);
    }
    if (errorCode?.deletePhone) {
      setError(errorCode.deletePhone);
    }
  }, [errorCode]);

  const clearTimers = useCallback(() => {
    cancelTimer(TIMER_NAME_EMAIL_CODE);
    clear(ALERT_GROUP_EMAIL_CODE);
  }, [cancelTimer, clear]);

  const reset = useCallback(
    async (navigatePage?: string) => {
      resetSecuritySteps();
      data.reset();
      clearTimers();
      await resetNavigate(() => setPages(false), navigatePage);
    },
    [clearTimers, resetNavigate, setPages],
  );

  const addPhone = useCallback(
    async (phone: string, recaptcha: string) => {
      data.setProp('phone', phone);

      const token = flowsData.getToken(EFlowsDataStep.flow);
      if (ready('putPhone') && token) {
        const fetched = (await requestByName('putPhone', {
          actionToken: token,
          recaptcha,
          phone,
        })) as AnswerPutPhone;
        if (isObject(fetched)) {
          data.setProp('phoneToken', fetched.stateToken);
          data.setProp('attemptId', fetched.attemptId);
          data.setProp('phone', fetched.phone);

          setPage(usingPrivatePages.code, { available: true });
          navigate(usingPrivatePages.code);
        }
      } else sentryException('FlowPhones hook: addPhone api is not ready', 'warning');
    },
    [navigate, ready, requestByName, setPage],
  );
  const confirmCode = useCallback(
    async (code: string) => {
      const actionToken = data.getProp('phoneToken');
      const attemptId = data.getProp('attemptId');

      if (!actionToken || !attemptId) {
        sentryException('FlowPhones hook: not saved actionToken or attemptId', 'warning');
        reset();
      }

      if (ready('postConfirmPhone')) {
        const fetched = (await requestByName('postConfirmPhone', {
          actionToken,
          attemptId,
          code,
        })) as AnswerPostConfirmPhone;
        if (isObject(fetched)) {
          update('user');
          setPage(usingPrivatePages.complete, { available: true });
          navigate(usingPrivatePages.complete);
        }
      }
    },
    [navigate, ready, requestByName, reset, setPage, update],
  );
  const removePhone = useCallback(async () => {
    const token = flowsData.getToken(EFlowsDataStep.flow);
    if (ready('deletePhone') && token) {
      const fetched = (await requestByName('deletePhone', {
        actionToken: token,
      })) as AnswerDeletePhone;
      if (isObject(fetched) && fetched.success) {
        update('user');
        setPage(usingPrivatePages.deleted, { available: true });
        navigate(usingPrivatePages.deleted);
      }
    } else sentryException('FlowPhones hook: removePhone api is not ready', 'warning');
  }, [navigate, ready, requestByName, setPage, update]);

  return {
    error,
    reset,
    addPhone,
    confirmCode,
    phone: useMemo(() => data.getProp('phone'), []),
    fullPhone: useMemo(() => flowsData.getProp('fullPhone'), []),
    removePhone,
  };
};

export default useFlowPhones;
