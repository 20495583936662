import base64 from 'base64-js';
import { sha256 } from 'js-sha256';

export function generatePkceChallenge(pkceMethod: string, codeVerifier: string) {
  switch (pkceMethod) {
    // The use of the "plain" method is considered insecure and therefore not supported.
    case 'S256': {
      // hash codeVerifier, then encode as url-safe base64 without padding
      // eslint-disable-next-line no-case-declarations
      const hashBytes = new Uint8Array(sha256.arrayBuffer(codeVerifier));
      return base64.fromByteArray(hashBytes).replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
    }
    default: {
      throw new Error('Invalid value for pkceMethod');
    }
  }
}

function generateRandomData(len: number) {
  // use web crypto APIs if possible
  let array = null;
  const crypto = window.crypto;
  if (crypto && crypto.getRandomValues && window.Uint8Array) {
    array = new Uint8Array(len);
    crypto.getRandomValues(array);
    return array;
  }

  // fallback to Math random
  // eslint-disable-next-line  unicorn/no-new-array
  array = new Array(len);
  for (let j = 0; j < array.length; j++) {
    array[j] = Math.floor(256 * Math.random());
  }
  return array;
}

function generateRandomString(len: number, alphabet: string) {
  const randomData = generateRandomData(len);
  // eslint-disable-next-line  unicorn/no-new-array
  const chars = new Array(len);
  for (let i = 0; i < len; i++) {
    // eslint-disable-next-line  unicorn/prefer-code-point
    chars[i] = alphabet.charCodeAt(randomData[i] % alphabet.length);
  }
  return String.fromCharCode.apply(null, chars);
}

export function generateCodeVerifier(len: number): string {
  return generateRandomString(len, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789');
}
