import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Table from '../../components/Table/Table';
import { ETableAction, ETableIcon, ITableList } from '../../components/Table/Table.types';
import { LocaleE } from '../../constants/locale.types';
import { ROUTE_FULL_BIRTHDAY_EDIT, ROUTE_FULL_COUNTRY_EDIT, ROUTE_FULL_NAME_EDIT } from '../../constants/routes';
import useCountries from '../../hooks/countries.hook';
import { getDateStr } from '../../utils/date';
import { ga4Event } from '../../utils/ga';
import { getNameStr } from '../../utils/string';
import styles from './Tables.module.scss';
import { IMainInfoTable } from './Tables.types';

const styleRules: Record<string, string> = {
  '1': classNames(styles.twoColumnsTable_column1),
  '2': classNames(styles.twoColumnsTable_column2),
};

const MainInfoTable: React.VFC<IMainInfoTable> = ({ user, readonly, className, hideCountry }) => {
  const { t, i18n } = useTranslation();
  const { officialCountryByCode } = useCountries(i18n.language);
  const navigate = useNavigate();

  const nameAction = useCallback(() => {
    ga4Event('mainInfoClick', { placeholders: { element: 'name' } });
    navigate(ROUTE_FULL_NAME_EDIT);
  }, [navigate]);
  const birthdayAction = useCallback(() => {
    ga4Event('mainInfoClick', { placeholders: { element: 'date_of_birth' } });
    navigate(ROUTE_FULL_BIRTHDAY_EDIT);
  }, [navigate]);
  const countryAction = useCallback(() => {
    ga4Event('mainInfoClick', { placeholders: { element: 'country' } });
    navigate(ROUTE_FULL_COUNTRY_EDIT);
  }, [navigate]);

  const textForEmptyFields = t('status.undefined');

  const list = useMemo<ITableList[]>(
    () => [
      {
        data: {
          '1': (
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('page.nameEdit.title')}
            </Text>
          ),
          '2': (
            <div className={classNames(styles.common_cropText)}>
              <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
                {getNameStr(user, i18n.language as LocaleE) || textForEmptyFields}
              </Text>
            </div>
          ),
        },
        action: readonly ? undefined : nameAction,
      },
      {
        data: {
          '1': (
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('page.birthdayEdit.title')}
            </Text>
          ),
          '2': (
            <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
              {user.birthday ? getDateStr(user.birthday, i18n.language as LocaleE) : textForEmptyFields}
            </Text>
          ),
        },
        action: readonly ? undefined : birthdayAction,
      },
      {
        data: {
          '1': (
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('page.countryEdit.title')}
            </Text>
          ),
          '2': (
            <Text variant={TextVariants.header8} color={TextAndIconColors.labelsSecondary}>
              {user.country ? officialCountryByCode(user.country) : textForEmptyFields}
            </Text>
          ),
        },
        action: readonly ? undefined : countryAction,
        hide: hideCountry,
      },
    ],
    [
      birthdayAction,
      countryAction,
      officialCountryByCode,
      i18n.language,
      nameAction,
      readonly,
      t,
      user,
      hideCountry,
      textForEmptyFields,
    ],
  );

  return (
    <Table
      list={list}
      styleRules={styleRules}
      divider
      rowHover
      className={className}
      endIcon={ETableIcon.open}
      actionType={ETableAction.row}
      columns={['1', '2']}
      classNameCells={styles.twoColumnsTable_cells}
    />
  );
};

export default MainInfoTable;
