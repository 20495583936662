import { useCallback } from 'react';

import { useUpdateState } from './helper.hook';

const useSelect = <T = string, V = string | number | null>(
  initialState: T,
  props:
    | Partial<{
        onChanged: (state: T) => void;
      }>
    | undefined = {},
): [state: T, change: (newValue: V) => void] => {
  const { onChanged } = props;

  const [state, setState] = useUpdateState<T>(initialState);
  const change = useCallback(
    (newValue: V) => {
      // @ts-ignore
      const value: T = newValue ? newValue.toString() : initialState;
      setState(value);
      onChanged?.(value);
    },
    [initialState, setState, onChanged],
  );

  return [state, change];
};

export default useSelect;
