import {
  ENotificationsBackgroundColors,
  ENotificationsThemes,
  INotificationsItem,
  Notifications,
} from '@design-system/ui-kit';
import { ToastDataObject, toastSettings, useToast } from 'library-react-hooks';
import React, { useEffect, useState } from 'react';

import styles from './Toast.module.scss';

toastSettings({
  sticky: false,
  duration: 8000,
  duplicate: false,
  types: {
    error: {
      color: ENotificationsThemes.critical,
    },
    warning: {
      color: ENotificationsThemes.warning,
    },
    info: {
      color: ENotificationsThemes.info,
    },
    success: {
      color: ENotificationsThemes.success,
    },
  },
});

const Toast: React.VFC = () => {
  const { clear, on } = useToast();

  const [messages, setMessages] = useState<INotificationsItem[]>([]);

  useEffect(() => {
    on((data: ToastDataObject[]) => {
      setMessages(
        data.map(
          (item) =>
            ({
              title: item.title || '',
              description: item.text || '',
              closable: true,
              onClose: (_) => clear(item.id),
              theme: item.color as ENotificationsThemes,
              id: item.id.toString(),
            }) as INotificationsItem,
        ),
      );
    });
  }, [on, clear]);

  if (messages.length === 0) return null;

  return (
    <Notifications
      className={styles.notification}
      items={messages}
      onItemsChange={() => {}}
      maxVisibleCount={5}
      backgroundColor={ENotificationsBackgroundColors.white}
    />
  );
};

export default React.memo(Toast);
