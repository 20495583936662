import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Checkbox,
  ETextAreaThemes,
  Icon,
  Text,
  TextAndIconColors,
  TextArea,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconWarning } from '../../assets/icons/warning.svg';
import {
  DELETE_REASON_ANOTHER,
  DELETE_REASON_ANOTHER_ACCOUNT,
  DELETE_REASON_HOW_TO_USE,
  DELETE_REASON_NOT_SUIT,
  TDeleteReason,
} from '../../constants/deleteReason';
import Page from '../../containers/Page';
import useCheckbox from '../../hooks/checkbox.hook';
import useInput from '../../hooks/input.hook';
import useFlowDeleteAccount from '../../scenarios/flows/flowDeleteAccount.hook';
import { listenKeydown } from '../../utils/keyboard';
import styles from './DeleteAccountConfirm.module.scss';

const MAX_LENGTH = 255;

const DeleteAccountConfirm: React.FC = () => {
  const { t } = useTranslation();
  const { confirmDeleteAccount, reset } = useFlowDeleteAccount();

  const [howToUse, changeHowToUse] = useCheckbox(false);
  const [anotherAccount, changeAnotherAccount] = useCheckbox(false);
  const [notSuit, changeNotSuit] = useCheckbox(false);
  const [another, changeAnother] = useCheckbox(false);

  const [comment, changeComment] = useInput<string>('');
  const textareaValid = useMemo(() => {
    return Boolean(comment.length <= MAX_LENGTH);
  }, [comment.length]);

  const onSubmit = useCallback(async () => {
    const reasons: TDeleteReason[] = [];
    if (howToUse) reasons.push(DELETE_REASON_HOW_TO_USE);
    if (anotherAccount) reasons.push(DELETE_REASON_ANOTHER_ACCOUNT);
    if (notSuit) reasons.push(DELETE_REASON_NOT_SUIT);
    if (another) reasons.push(DELETE_REASON_ANOTHER);

    if (!textareaValid) return;
    await confirmDeleteAccount(reasons, comment);
  }, [howToUse, anotherAccount, notSuit, another, textareaValid, confirmDeleteAccount, comment]);

  const buttonDisabled: boolean = !(howToUse || anotherAccount || notSuit || another) || !textareaValid;

  useEffect(() => {
    const listener = listenKeydown('Enter', onSubmit, buttonDisabled);
    return () => {
      listener();
    };
  }, [onSubmit, buttonDisabled]);

  return (
    <Page menu subHead={{ title: t('page.deleteAccount.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <div
          className={classNames(
            styles.page_iconBlock,
            styles.page_iconBlock__border,
            styles.page_iconBlock__small,
            styles.this_wrapping,
          )}
        >
          <Icon
            className={classNames(styles.page_iconBlock__icon)}
            IconComponent={IconWarning}
            height={36}
            width={36}
            color={TextAndIconColors.actionPromoSecondary}
          />
          <Text variant={TextVariants.body3} color={TextAndIconColors.labelsPrimary}>
            {t('page.deleteAccount.warning')}
          </Text>
        </div>
        <div className={classNames(styles.page_block, styles.page_block__border, styles.common_margin__m)}>
          <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
            {t('page.deleteAccount.subTitle')}
          </Text>
          <Text
            variant={TextVariants.header9}
            color={TextAndIconColors.labelsSecondary}
            className={classNames(styles.this_choseReason, styles.common_margin__l)}
          >
            {t('page.deleteAccount.chooseReason')}
          </Text>
          <div className={classNames(styles.common_margin__s)}>
            <Checkbox checked={howToUse} onChange={changeHowToUse}>
              <Text variant={TextVariants.body3} color={TextAndIconColors.labelsSecondary}>
                {t('page.deleteAccount.reason.howToUse')}
              </Text>
            </Checkbox>
          </div>
          <div className={classNames(styles.common_margin__s)}>
            <Checkbox checked={anotherAccount} onChange={changeAnotherAccount}>
              <Text variant={TextVariants.body3} color={TextAndIconColors.labelsSecondary}>
                {t('page.deleteAccount.reason.anotherAccount')}
              </Text>
            </Checkbox>
          </div>
          <div className={classNames(styles.common_margin__s)}>
            <Checkbox checked={notSuit} onChange={changeNotSuit}>
              <Text variant={TextVariants.body3} color={TextAndIconColors.labelsSecondary}>
                {t('page.deleteAccount.reason.notSuit')}
              </Text>
            </Checkbox>
          </div>
          <div className={classNames(styles.common_margin__s)}>
            <Checkbox checked={another} onChange={changeAnother}>
              <Text variant={TextVariants.body3} color={TextAndIconColors.labelsSecondary}>
                {t('page.deleteAccount.reason.another')}
              </Text>
            </Checkbox>
          </div>
          <Text
            variant={TextVariants.header9}
            color={TextAndIconColors.labelsSecondary}
            className={classNames(styles.this_choseReason, styles.common_margin__xxl)}
          >
            {t('field.comment')}
          </Text>
          <TextArea
            className={styles.common_margin__xs}
            value={comment}
            onChange={changeComment}
            theme={textareaValid ? ETextAreaThemes.base : ETextAreaThemes.error}
          />
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.cancel').toString()}
              onClick={reset}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
              type={ButtonTypes.button}
            />
            <Button text={t('action.deleteAccount').toString()} onClick={onSubmit} disabled={buttonDisabled} />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(DeleteAccountConfirm);
