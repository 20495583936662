import csLanguages from '@cospired/i18n-iso-languages/langs/cs.json';
import deLanguages from '@cospired/i18n-iso-languages/langs/de.json';
import enLanguages from '@cospired/i18n-iso-languages/langs/en.json';
import esLanguages from '@cospired/i18n-iso-languages/langs/es.json';
import plLanguages from '@cospired/i18n-iso-languages/langs/pl.json';
import ptLanguages from '@cospired/i18n-iso-languages/langs/pt.json';
import ruLanguages from '@cospired/i18n-iso-languages/langs/ru.json';
import ukLanguages from '@cospired/i18n-iso-languages/langs/uk.json';

import { LocaleE } from '../constants/locale.types';

type LanguagesByLocaleT = {
  [K in LocaleE]: Record<string, string>;
};
export type UserLanguagesT = {
  languagesList: LanguagesByLocaleT;
  languageByCode: (code: LocaleE) => string;
};

const languagesList: LanguagesByLocaleT = {
  [LocaleE.en]: {
    ...enLanguages.languages,
    [LocaleE.pt]: enLanguages.languages.pt,
  },
  [LocaleE.ru]: {
    ...ruLanguages.languages,
    [LocaleE.pt]: ruLanguages.languages.pt,
  },
  [LocaleE.de]: {
    ...deLanguages.languages,
    [LocaleE.pt]: deLanguages.languages.pt,
  },
  [LocaleE.es]: {
    ...esLanguages.languages,
    [LocaleE.pt]: esLanguages.languages.pt,
  },
  [LocaleE.pl]: {
    ...plLanguages.languages,
    [LocaleE.pt]: plLanguages.languages.pt,
  },
  [LocaleE.pt]: {
    ...ptLanguages.languages,
    [LocaleE.pt]: ptLanguages.languages.pt,
  },
  [LocaleE.cs]: {
    ...csLanguages.languages,
    [LocaleE.pt]: csLanguages.languages.pt,
  },
  [LocaleE.uk]: {
    ...ukLanguages.languages,
    [LocaleE.pt]: ukLanguages.languages.pt,
  },
};

const ucFirst = (str: string): string => {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
};

const useLanguages = (): UserLanguagesT => {
  return {
    languagesList,
    languageByCode: (code) => ucFirst(languagesList[code][code]),
  };
};

export default useLanguages;
