import { EInfoBoxIcon, InfoBox, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Notifications.module.scss';
import { INotificationDeleted } from './Notifications.types';

const NotificationDeleted: React.VFC<INotificationDeleted> = ({ className, supportEmail }) => {
  const { t } = useTranslation();
  return (
    <InfoBox
      className={className}
      iconName={EInfoBoxIcon.block}
      title={t('block.deleteAccount.title')}
      description={
        <div className={styles.common_noticeContent}>
          <div className={classNames(styles.common_noticeContentItem, styles.common_column)}>
            <Text variant={TextVariants.body4} color={TextAndIconColors.labelsSecondary}>
              {t('block.deleteAccount.description')}
            </Text>
            <Text variant={TextVariants.body4} color={TextAndIconColors.labelsSecondary}>
              {t('block.deleteAccount.forCancel') + ' '}
              <Link
                text={supportEmail}
                href={'mailto:' + supportEmail}
                color={TextAndIconColors.interfaceAccentTertiary}
                variant={TextVariants.body4}
              />
              {'.'}
            </Text>
          </div>
        </div>
      }
    />
  );
};

export default NotificationDeleted;
