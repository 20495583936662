// eslint-disable-next-line import/no-named-as-default
import parsePhoneNumber, {
  CountryCallingCode,
  E164Number,
  getCountries,
  getCountryCallingCode,
} from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';

import { isDayBeforeToday, isValidDate, objToISO } from './date';

const patternEmail =
  // eslint-disable-next-line unicorn/better-regex, no-useless-escape
  /^[\w-]+(\.{0,1}[\w-]+)*[\+]{0,1}(\.{0,1}[\w-]+)*@((?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,})$/;
const patternFirstSymbol = /^[\dA-Za-z]$/;
const patternLastSymbol = /^[A-Za-z]$/;

type TEmailValidation = 0 | 1 | 2;

export const validationEmail = (email: string): TEmailValidation => {
  const emailLength = email.length;

  if (!patternFirstSymbol.test(email[0]) || !patternLastSymbol.test(email[emailLength - 1])) return 1;
  if (!patternEmail.test(email)) return 2;

  return 0;
};

const pattern = /^(\w){3,50}$/;
export const validationUsername = (username: string): boolean => {
  return pattern.test(username);
};

const averageLength = 8;
const secureLength = 12;
const maxLength = 200;
type TSecure = -1 | 0 | 1 | 2 | 3;
export const validationPasswordSecure = (password: string): TSecure => {
  if (!password) return 0;
  if (password.length > maxLength) return -1;
  if (password.length < averageLength) return 1;
  if (password.length < secureLength) return 2;
  return 3;
};
export const validationPassword = (password: string, confirm: string): { secure: TSecure; equals: boolean } => {
  return { secure: validationPasswordSecure(password), equals: password === confirm };
};

// const patternFirstname = new RegExp('^[' + LOCALE_SYMBOLS + ']{1,20}$', 'i');
export const validationFirstname = (firstname: string): boolean => {
  return firstname.length > 0 && firstname.length < 21;
};

// const patternLastname = new RegExp('^[' + LOCALE_SYMBOLS + ']{1,30}$', 'i');
export const validationLastname = (lastname: string): boolean => {
  return lastname.length > 0 && lastname.length < 31;
};

const patternDigits = /^\d+$/;
export const validationBirthday = (day: string, month: string, year: string): boolean => {
  const iso = objToISO({ day, month, year });
  return (
    isValidDate(iso) &&
    isDayBeforeToday(iso) &&
    patternDigits.test(day) &&
    patternDigits.test(year) &&
    Number(year) > 1900
  );
};

export const validationEmailCode = (code: string) => {
  return code.length === 6 && patternDigits.test(code);
};

export const validationPhone = (phone: string, country?: CountryCode): [boolean, E164Number | undefined] => {
  const phoneNumber = parsePhoneNumber(phone.trim(), country);
  return [(phoneNumber?.isPossible() && phoneNumber.isValid()) || false, phoneNumber?.format('E.164')];
};

export const phoneCountries: CountryCode[] = getCountries();
export const countryNormalize = (key: string): CountryCode => {
  const countryKey = key.toUpperCase();
  if (countryKey === 'PT-BR') return 'PT';
  if (countryKey === 'EN') return 'US';
  if (!phoneCountries.includes(countryKey as CountryCode)) return 'US';

  return countryKey as CountryCode;
};
export const phoneCodeByCountry = (country: CountryCode): CountryCallingCode => {
  return getCountryCallingCode(country);
};

export const getPhoneOnlyNumber = (fullPhone: string): string | undefined => {
  const phoneNumber = parsePhoneNumber(fullPhone.trim());
  return phoneNumber?.number.replace(phoneNumber.countryCallingCode, '').replace('+', '');
};
export const getPhoneOnlyCountry = (fullPhone: string): CountryCode => {
  const phoneNumber = parsePhoneNumber(fullPhone.trim());
  return phoneNumber?.country || 'US';
};
