import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUpdateState } from './helper.hook';

type TInputErrorData = {
  key: string;
  options?: Record<string, unknown>;
};
type TInputTranslateFn = (key: TInputErrorData['key'], options?: TInputErrorData['options']) => string;
const defaultT: TInputTranslateFn = (key, _options) => key;
const useInput = <T = string>(
  initialState: T | (() => T),
  props:
    | Partial<{
        onBlurChanged: () => void;
        onChanged: () => void;
        translate: TInputTranslateFn;
        onAutoComplete: () => void;
        convertVal: (newValue: T) => T;
      }>
    | undefined = {},
): [
  state: T,
  change: (newValue: T) => void,
  actions: {
    error: string;
    setError: (key: TInputErrorData['key'], options?: TInputErrorData['options']) => void;
    blur: () => void;
  },
] => {
  const { onBlurChanged, onChanged, translate, onAutoComplete, convertVal } = props;
  const t: TInputTranslateFn = useMemo(() => translate ?? defaultT, [translate]);

  const [state, setState] = useUpdateState(initialState);
  const [errorData, setErrorData] = useState<TInputErrorData>({ key: '' });
  const [error, setError] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);
  const blur = useCallback(() => {
    if (changed) {
      onBlurChanged?.();
      setChanged(false);
    }
  }, [changed, onBlurChanged]);
  const change = useCallback(
    (newValue: T) => {
      setState(convertVal?.(newValue) || newValue);
      setChanged(true);
      setError('');
      setErrorData({ key: '' });
      onChanged?.();
    },
    [setState, convertVal, onChanged],
  );
  const setData = useCallback((key, options) => {
    setErrorData({ key, options });
  }, []);
  useEffect(() => {
    setError(t(errorData.key, errorData.options));
  }, [errorData, t]);
  const [autoCompleted, setAutoCompleted] = useState<boolean>(false);
  useEffect(() => {
    if (!autoCompleted && initialState) {
      onAutoComplete?.();
      setAutoCompleted(true);
    }
  }, [autoCompleted, initialState, onAutoComplete]);

  return [state, change, { error, setError: setData, blur }];
};

export default useInput;
