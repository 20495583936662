import React, { useLayoutEffect, useReducer } from 'react';

import { LoaderComponent } from '../../components/Loader';
import { QUERY_PARAMS_TOKEN } from '../../constants/queryParams';
import useHttp from '../../hooks/http';
import useFlowEmailChange from '../../scenarios/flows/flowEmailChange.hook';
import { getParamsValueByKey } from '../../utils/url';

const ConfirmEmail: React.VFC = () => {
  const { ready } = useHttp();
  const { sendConfirmChange } = useFlowEmailChange();

  const [once, setOnce] = useReducer(() => true, false);

  useLayoutEffect(() => {
    if (ready('postConfirmEmail') && !once) {
      sendConfirmChange(getParamsValueByKey(window.location.href, QUERY_PARAMS_TOKEN) || '');
      setOnce();
    }
  }, [sendConfirmChange, once, ready]);

  return <LoaderComponent />;
};

export default ConfirmEmail;
