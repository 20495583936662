import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInputThemes,
  Input,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { ToastPropsT, useToast } from 'library-react-hooks';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomIcon, { ECustomIconName } from '../../components/CustomIcon';
import { API_ERROR_CODE_SECURITY_FLOW_NOT_PASS } from '../../constants/api';
import { withMockModes } from '../../constants/mode';
import { TOTP_MAX_QUANTITY } from '../../constants/totp';
import Form from '../../containers/Form';
import AppContext from '../../context/AppContext';
import useInput from '../../hooks/input.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { ITotpCredential } from '../../hooks/store/store.types';
import { E2FaAction, EFlowType, useTotpFlowStep } from '../../scenarios';
import { get2FaActionByType } from '../../scenarios/flows.functions';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { separationByQuantity } from '../../utils/sort';
import { validationEmailCode } from '../../utils/validation';
import Device from './Device';
import styles from './Form2Fa.module.scss';

const mockedTotpCredentials: ITotpCredential[] = [
  {
    id: '2e9b2349-34ed-4295-9cab-fe56f96593f7',
    userLabel: '1',
    createdDate: '2023-01-13T10:26:07.716Z',
  },
  {
    id: '2e9b2349-34ed-4295-9cab-fe56f96593f8',
    userLabel: '2',
    createdDate: '2023-01-13T10:26:07.716Z',
  },
];
const mockedAction2FaKey = get2FaActionByType(EFlowType.emailChange);

const getGaTypeByAction = (
  action: E2FaAction | string,
):
  | 'change email'
  | 'delete full account'
  | 'add password'
  | 'change password'
  | 'add phone'
  | 'remove phone'
  | 'verify phone'
  | 'delete session'
  | 'add social'
  | 'delete social'
  | 'delete totp'
  | 'undefined' => {
  switch (action) {
    case E2FaAction.addPassword: {
      return 'add password';
    }
    case E2FaAction.addPhone: {
      return 'add phone';
    }
    case E2FaAction.addSocial: {
      return 'add social';
    }
    case E2FaAction.changeEmail: {
      return 'change email';
    }
    case E2FaAction.changePassword: {
      return 'change password';
    }
    case E2FaAction.remove2Fa: {
      return 'delete totp';
    }
    case E2FaAction.removeAccount: {
      return 'delete full account';
    }
    case E2FaAction.removePhone: {
      return 'remove phone';
    }
    case E2FaAction.removeSessions: {
      return 'delete session';
    }
    case E2FaAction.removeSocial: {
      return 'delete social';
    }
    case E2FaAction.verifyPhone: {
      return 'verify phone';
    }
    default: {
      return 'undefined';
    }
  }
};

const Form2Fa: React.VFC = () => {
  const { t } = useTranslation();
  const { actionToContinue, error: securityError, reset, action2Fa } = useTotpFlowStep();
  const { credentials, user, state } = useStore(['credentials']);
  const { alert } = useToast();
  const { mode, config } = useContext(AppContext);

  const credentialsTotp = useMemo(
    () => (withMockModes.includes(mode) ? mockedTotpCredentials : credentials.totp),
    [credentials.totp, mode],
  );
  const action = useMemo(
    () => (withMockModes.includes(mode) ? t(`page.2fa.action.${mockedAction2FaKey}`) : action2Fa),
    [action2Fa, mode, t],
  );
  const [devices, setDevices] = useState<ITotpCredential[][]>([]);
  useEffect(() => {
    if (credentialsTotp) setDevices(separationByQuantity<ITotpCredential>(credentialsTotp, TOTP_MAX_QUANTITY));
  }, [credentialsTotp]);

  const [code, changeCode, { error: errorCode, setError: setErrorCode }] = useInput<string>('', { translate: t });
  useEffect(() => {
    if (securityError?.code === API_ERROR_CODE_SECURITY_FLOW_NOT_PASS) {
      setErrorCode(securityError.title);
    } else if (securityError?.code) {
      const props: ToastPropsT = { type: 'error', titleData: { key: securityError.title } };
      if (securityError.text) props.textData = { key: securityError.text };
      alert(props);
    }
  }, [alert, securityError, setErrorCode]);
  const [selected, setSelected] = useState<string>(credentialsTotp?.[0].id || '');
  useEffect(() => {
    if (credentialsTotp && credentialsTotp.length > 0) {
      setSelected(credentialsTotp?.[0].id);
    }
  }, [credentialsTotp]);

  const sendCode = useCallback(async () => {
    let valid = true;
    if (!validationEmailCode(code)) {
      valid = false;
      setErrorCode('message.errorValidationEmailCode');
    }

    if (valid) {
      ga4Event('totpFormClick', { placeholders: { element: 'next', type: getGaTypeByAction(action2Fa) } });
      await actionToContinue(code, selected);
    }
  }, [code, setErrorCode, action2Fa, actionToContinue, selected]);

  const onCancel = useCallback(async () => {
    ga4Event('totpFormClick', { placeholders: { element: 'back', type: getGaTypeByAction(action2Fa) } });
    await reset();
  }, [action2Fa, reset]);

  const buttonDisabled: boolean = code.length !== 6 || Boolean(errorCode);

  useEffect(() => {
    const listener = listenKeydown('Enter', sendCode, buttonDisabled);
    return () => {
      listener();
    };
  }, [buttonDisabled, sendCode]);

  const tableClass = (length: number) =>
    classNames(styles.this_table, {
      // @ts-ignore
      [styles[`this_table__count${length}`]]: length,
    });

  useOnLoad(Boolean(state.user && state.credentials), 'Form2Fa');

  return (
    <Form supportEmail={config.supportEmail}>
      <div className={styles.form_wrapper}>
        <div className={styles.form_head}>
          <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary} className={styles.this_head}>
            {t('page.2fa.title')}
          </Text>
          <CustomIcon
            icon={ECustomIconName.userDefault}
            size={80}
            className={styles.common_margin__m}
            color={config.styles?.theme.colors?.interfaceAdPrimary}
          />
          <Text
            variant={TextVariants.header5}
            color={TextAndIconColors.labelsSecondary}
            className={styles.common_margin__m}
          >
            {user.email as string}
          </Text>
        </div>
        {credentialsTotp && credentialsTotp.length > 1 && (
          <div className={classNames(styles.common_column, styles.common_margin__l)}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsSecondary}>
              {t('page.2fa.selectDevice')}
            </Text>
            {devices.map((item, id) => (
              <div className={tableClass(item.length)} key={id}>
                {item.map((otpCredential) => (
                  <Device
                    otpCredential={otpCredential}
                    selected={selected}
                    setSelected={setSelected}
                    key={otpCredential.id}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
        <div className={classNames(styles.common_column, styles.common_margin__l)}>
          <Text variant={TextVariants.body2} color={TextAndIconColors.labelsSecondary}>
            {t('page.2fa.description', { action })}
          </Text>
          <Input
            value={code}
            onChange={changeCode}
            className={styles.common_margin__s}
            placeholder={t('field.applicationCode')}
            theme={errorCode ? EInputThemes.error : EInputThemes.base}
            errorMessage={errorCode}
            autoFocus
          />
        </div>
        <div className={styles.common_buttons}>
          <Button
            text={t('action.back').toString()}
            type={ButtonTypes.button}
            onClick={onCancel}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.labelsSecondary}
          />
          <Button
            text={t('action.next').toString()}
            type={ButtonTypes.button}
            onClick={sendCode}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </Form>
  );
};

export default Form2Fa;
