import { ROUTE_PERSONAL_DATA } from '../constants/routes';
import { withSlash } from '../utils/url';
import { getBestBasePage } from './flows.functions';
import { IFlowsData } from './flows.types';

const initialTokens: IFlowsData['_tokens'] = {
  totp: null,
  emailCode: null,
  flow: null,
};

const initialProps: IFlowsData['_props'] = {
  socialName: '',
  sessionId: '',
  totpId: '',
  fullPhone: '',
};

const flowsData: IFlowsData = {
  _type: null,
  _basePage: withSlash(ROUTE_PERSONAL_DATA),
  _tokens: { ...initialTokens },
  _props: { ...initialProps },
  setFlow(type) {
    this._type = type;
  },
  getType() {
    return this._type;
  },
  setToken(name, token) {
    this._tokens[name] = token;
  },
  getToken(name) {
    return this._tokens[name];
  },
  setProp(name, value) {
    this._props[name] = value;
  },
  getProp(name): string {
    return this._props[name];
  },
  setBasePage(basePage) {
    this._basePage = basePage;
  },
  getBasePage() {
    return this._basePage;
  },
  resetBasePage() {
    this._basePage = getBestBasePage(this._type);
  },
  reset() {
    this._type = null;
    this._tokens = { ...initialTokens };
    this._props = { ...initialProps };
    // this._basePage пусть остается
  },
};

export default flowsData;
