import React, { useLayoutEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoaderComponent } from '../../components/Loader';
import { ROUTE_FULL_PHONE_VIEW, ROUTE_SECURITY } from '../../constants/routes';
import AppContext from '../../context/AppContext';
import useHttp from '../../hooks/http';
import usePrivatePages from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { EFlowType, useStartFlowStep } from '../../scenarios';
import { withSlash } from '../../utils/url';
import ErrorNotFound from '../ErrorNotFound';

const PhoneViewDirect: React.VFC = () => {
  const { ready } = useHttp();
  const { user, state } = useStore([]);
  const { startFlow } = useStartFlowStep();
  const { setPage } = usePrivatePages();
  const { config } = React.useContext(AppContext);
  const navigate = useNavigate();

  const [once, setOnce] = useReducer(() => true, false);
  const [pageNotAvailable, setPageNotAvailable] = useState<boolean>(false);

  React.useEffect(() => {
    if (!config.features.phoneManagement && !once) {
      navigate(withSlash(ROUTE_SECURITY), { relative: 'path' });
      setOnce();
    }
  }, [config.features.phoneManagement, navigate, once]);
  useLayoutEffect(() => {
    if (ready('postSecurityFlow') && !once && state.user && config.features.phoneManagement) {
      if (user.emailVerified && user.phone) {
        setPage(ROUTE_FULL_PHONE_VIEW, { available: true });
        // navigate(ROUTE_FULL_PHONE_VIEW);
      } else if (user.emailVerified) {
        startFlow(EFlowType.addPhone);
      } else {
        setPageNotAvailable(true);
      }
      setOnce();
    }
  }, [once, ready, setPage, startFlow, state, user, config.features.phoneManagement, navigate]);

  if (pageNotAvailable) return <ErrorNotFound />;

  return <LoaderComponent />;
};

export default PhoneViewDirect;
