import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Icon,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as StampIcon } from '../../assets/icons/stamp.svg';
import Page from '../../containers/Page';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { useEmailCodeFlowStep } from '../../scenarios/steps';
import { ga4Event } from '../../utils/ga';
import styles from './EmailView.module.scss';

const EmailView: React.VFC = () => {
  const { t } = useTranslation();
  const { user, state } = useStore([]);
  const { sendEmail, reset } = useEmailCodeFlowStep();

  const nextStep = useCallback(async () => {
    ga4Event('emailViewClick', { placeholders: { element: 'change email' } });
    await sendEmail();
  }, [sendEmail]);

  useOnLoad(Boolean(state.user), 'EmailView');

  return (
    <Page menu subHead={{ title: t('page.emailView.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.emailView.description')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_iconBlock, styles.page_iconBlock__border)}>
          <Icon IconComponent={StampIcon} height={24} width={24} color={TextAndIconColors.interfaceAccentSecondary} />
          <Text variant={TextVariants.header9} color={TextAndIconColors.labelsPrimary} className={styles.this_email}>
            {user.email}
          </Text>
        </div>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__m}>
          {t('page.emailView.notice')}
        </Text>
        <div className={styles.common_buttons}>
          <Button
            text={t('action.back').toString()}
            type={ButtonTypes.button}
            onClick={() => {
              ga4Event('emailViewClick', { placeholders: { element: 'back' } });
              reset();
            }}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.interfaceTertiaryTabs}
          />
          <Button
            text={t('action.changeEmail').toString()}
            type={ButtonTypes.button}
            onClick={nextStep}
            // onClick={() => navigate(ROUTE_FULL_EMAIL_SENT)}
            variant={ButtonVariant.filled}
          />
        </div>
      </div>
    </Page>
  );
};

export default EmailView;
