import { IConfig } from './Config.types';
import defaultConfig from './default.json';
import { validateIConfig } from './type-validation/schema.validator';

export const baseConfig = defaultConfig as IConfig;

const createConfig = (customConfig?: unknown): IConfig => {
  const config: IConfig = validateIConfig(customConfig ?? baseConfig);

  // TODO: желательно при билде ловить ошибки валидвации
  if (config.integrations?.consentManager) {
    // eslint-disable-next-line unicorn/no-lonely-if
    if (config.integrations.googleAnalytics && !config.integrations.googleAnalytics.consentManagerId) {
      throw new Error('Wait for consentManagerId for googleAnalytics');
    }
  }
  // TODO:
  // if (config.products.length === 0 && config.sidebarMenu.find((item) => item.action === 'services')) {
  //   console.warn('kdjnsx');
  // }

  return config;
};

export default createConfig;
