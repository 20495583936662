export enum AvatarSizes {
  s = 's',
  m = 'm',
}

export enum AvatarBackgroundColors {
  backgroundTertiary = 'backgroundTertiary',
  seventh = 'seventh',
}

export interface IAvatarProps {
  /**
   * One of: name, email.
   */
  name?: string;

  /**
   * One of: name, email.
   */
  email?: string;

  initials?: string;

  className?: string;

  size?: AvatarSizes;

  color?: AvatarBackgroundColors;
}
