import { isObject } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AnswerGetAvailableProviders,
  AnswerGetCredentials,
  AnswerGetSessions,
  AnswerGetUser,
} from '../../api/answer.types';
import { checkSupportedProviders, SocialTypesE } from '../../components/Social';
import useHttp from '../http';
import data from './data';
import {
  credentialsInitial,
  identityProvidersInitial,
  sessionsInitial,
  stateInitial,
  userInitial,
} from './store.functions';
import { IDataStore, IStore, TDataState, TStoreError, TStoreTypes } from './store.types';

const useStore = (needs: TStoreTypes[]): IStore => {
  const { requestByName, ready, error } = useHttp();

  const [state, setState] = useState<TDataState>(stateInitial);

  const [storeError, setStoreError] = useState<TStoreError>(null);
  useEffect(() => {
    if (error && Object.keys(error).some((key) => error[key])) {
      setStoreError({ ...error });
    }
  }, [error]);

  // User
  const [user, setUser] = useState<IDataStore['user']>(userInitial);
  const getUser = useCallback(async () => {
    const fetched = (await requestByName('getUser')) as AnswerGetUser;
    if (fetched && typeof fetched === 'object') {
      data.update('user', true, fetched);
    } else data.setState('user', false);
  }, [requestByName]);

  // Credentials
  const [credentials, setCredentials] = useState<IDataStore['credentials']>(credentialsInitial);
  const getCredentials = useCallback(async () => {
    const fetched = (await requestByName('getCredentials')) as AnswerGetCredentials;
    if (fetched && typeof fetched === 'object') {
      data.update('credentials', true, fetched);
    } else data.setState('credentials', false);
  }, [requestByName]);

  // Sessions
  const [sessions, setSessions] = useState<IDataStore['sessions']>(sessionsInitial);
  const getSessions = useCallback(async () => {
    const fetched = (await requestByName('getSessions')) as AnswerGetSessions;
    if (fetched && isObject(fetched)) {
      data.update('sessions', true, fetched.sessions);
    } else data.setState('sessions', false);
  }, [requestByName]);

  // IdentityProviders
  const [identityProviders, setIdentityProviders] = useState<IDataStore['identityProviders']>(identityProvidersInitial);
  const getIdentityProviders = useCallback(async () => {
    const fetched = (await requestByName('getAvailableProviders')) as AnswerGetAvailableProviders;
    if (isObject(fetched)) {
      const supported: SocialTypesE[] = checkSupportedProviders(fetched.identityProviders);
      data.update('identityProviders', true, supported);
    } else data.setState('identityProviders', false);
  }, [requestByName]);

  // Обновления
  useEffect(() => {
    if (needs.includes('user') && ready('getUser') && data.state.user === null) {
      getUser();
    }
    if (needs.includes('credentials') && ready('getCredentials') && data.state.credentials === null) {
      getCredentials();
    }
    if (needs.includes('sessions') && ready('getSessions') && data.state.sessions === null) {
      getSessions();
    }
    if (
      needs.includes('identityProviders') &&
      ready('getAvailableProviders') &&
      data.state.identityProviders === null
    ) {
      getIdentityProviders();
    }
  }, [needs, ready, getCredentials, getSessions, getIdentityProviders, getUser]);
  useEffect(() => {
    const dataClear = data.on((e) => {
      switch (e.type) {
        case 'user': {
          setUser(userInitial);
          break;
        }
        case 'credentials': {
          setCredentials(credentialsInitial);
          break;
        }
        case 'sessions': {
          setSessions(sessionsInitial);
          break;
        }
        case 'identityProviders': {
          setIdentityProviders(identityProvidersInitial);
          break;
        }
        default: {
          break;
        }
      }
      setState(stateInitial);
    });
    return () => dataClear();
  }, []);

  const update: IStore['update'] = useCallback(
    async (type) => {
      if (type === 'user' && ready('getUser')) await getUser();
      if (type === 'credentials' && ready('getCredentials')) await getCredentials();
      if (type === 'sessions' && ready('getSessions')) await getSessions();
      if (type === 'identityProviders' && ready('getAvailableProviders')) await getIdentityProviders();
    },
    [ready, getUser, getCredentials, getSessions, getIdentityProviders],
  );
  const enrich: IStore['enrich'] = useCallback(async (_type, _prop) => {}, []);

  return {
    user: useMemo(() => user, [user]),
    credentials: useMemo(() => credentials, [credentials]),
    sessions: useMemo(() => sessions, [sessions]),
    identityProviders: useMemo(() => identityProviders, [identityProviders]),
    update,
    enrich,
    state,
    storeError,
  };
};

export default useStore;
