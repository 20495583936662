import {
  Button,
  ButtonTypes,
  ButtonVariant,
  EButtonSizes,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../containers/Page';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore, { IStore } from '../../hooks/store';
import SessionsRemoveList from '../../modules/Tables/SessionsRemoveList';
import useFlowSessions from '../../scenarios/flows/flowSessions.hook';
import { findSessionById, sortByLastAccess } from '../../utils/session';
import styles from './SessionsRemovePartial.module.scss';

const SessionsRemovePartial: React.VFC = () => {
  const { t } = useTranslation();
  const { sessions, update } = useStore(['sessions']);
  const { actionToContinueSession, reset, sessionId } = useFlowSessions(update);

  const [list, setList] = useState<IStore['sessions']>([]);
  useEffect(() => {
    if (sessions?.length) {
      setList(sortByLastAccess(sessions)?.slice(1) || null);
    }
  }, [sessions]);

  const session = useMemo(() => (sessionId ? findSessionById(sessions, sessionId) : undefined), [sessionId, sessions]);

  useOnLoad(Boolean(list?.length), 'SessionsRemovePartial');

  return (
    <Page menu subHead={{ title: t('page.sessions.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <div className={classNames(styles.page_block, styles.page_block__border, styles.common_margin__m)}>
          <Text color={TextAndIconColors.labelsSecondary} variant={TextVariants.header5}>
            {t('page.sessionsRemove.subTitle')}
          </Text>
          {session && (
            <div
              className={classNames(
                styles.page_block,
                styles.page_block__smallBorder,
                styles.this_table,
                styles.common_margin__l,
              )}
            >
              <SessionsRemoveList sessions={[session]} />
            </div>
          )}
          <div className={classNames(styles.common_buttons)}>
            <Button
              size={EButtonSizes.l}
              variant={ButtonVariant.outlined}
              text={t('action.cancel').toString()}
              onClick={() => reset()}
              type={ButtonTypes.button}
            />
            <Button
              size={EButtonSizes.l}
              variant={ButtonVariant.filled}
              text={t('action.closeSession').toString()}
              onClick={actionToContinueSession}
              type={ButtonTypes.button}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SessionsRemovePartial;
