import { TPostSecurityFlow } from '../api/answer.types';
import { TApiSecurityFlowActions } from '../constants/api';
import { TDeleteReason } from '../constants/deleteReason';

/**
 * =============================================================
 * Different
 * =============================================================
 */
export type TError = { title: string; text?: string; code: number };

// В значениях ключи из языковых файлов!!!
export enum E2FaAction {
  changeEmail = 'emailChanging',
  addPassword = 'passwordAdding',
  changePassword = 'passwordChanging',
  addSocial = 'socialAdding',
  removeSocial = 'socialDeleting',
  remove2Fa = 'totpDeleting',
  removeAccount = 'fullAccountDeleting',
  removeSessions = 'sessionDeleting',
  addPhone = 'phoneAdding',
  removePhone = 'phoneRemoving',
  verifyPhone = 'phoneVerify',
  addEmail = 'emailAdding',
}

// В значениях ключи из языковых файлов!!!
export enum EEmailAction {
  addPhone = 'phoneAdding',
  removePhone = 'phoneRemoving',
  verifyPhone = 'phoneVerify',
}

/**
 * =============================================================
 * Common data
 * =============================================================
 */
export enum EFlowType {
  deleteAccount = 'deleteAccount',
  emailAdd = 'emailAdd',
  emailChange = 'emailChange',
  passwordAdd = 'passwordAdd',
  passwordChange = 'passwordChange',
  deleteSession = 'deleteSession',
  deleteSessions = 'deleteSessions',
  addSocial = 'addSocial',
  deleteSocial = 'deleteSocial',
  addTotp = 'addTotp',
  deleteTotp = 'deleteTotp',
  resetTotp = 'resetTotp',
  addPhone = 'addPhone',
  removePhone = 'removePhone',
  verifyPhone = 'verifyPhone',
}
export enum EFlowsDataStep {
  totp = 'totp',
  emailCode = 'emailCode',
  flow = 'flow',
}
type TFlowsDataStartProps = 'socialName' | 'sessionId' | 'totpId' | 'fullPhone';
export type TFlowsDataProps = TFlowsDataStartProps;
export interface IFlowsData {
  _type: EFlowType | null;
  _basePage: string;
  _tokens: {
    [K in EFlowsDataStep]: string | null;
  };
  _props: {
    [K in TFlowsDataProps]: string;
  };
  setFlow(type: EFlowType): void;
  getType(): IFlowsData['_type'];
  setProp(name: TFlowsDataProps, value: string): void;
  getProp(name: TFlowsDataProps): string;
  setToken(name: EFlowsDataStep, token: string | null): void;
  getToken(name: EFlowsDataStep): string | null;
  setBasePage(basePage: string): void;
  getBasePage(): string;
  resetBasePage(): void;
  reset(): void;
}

/**
 * =============================================================
 * Flows
 * =============================================================
 */
interface IFlowsBase {
  reset: () => Promise<void>;
}

export interface IFlowDeleteAccount extends IFlowsBase {
  confirmDeleteAccount(reasons: TDeleteReason[], comment: string): Promise<void>;
}

export type TEmailChangeDataProps = 'emailNew' | 'status';
export interface IEmailChangeData {
  _props: {
    [K in TEmailChangeDataProps]: string;
  };
  setProp(name: TEmailChangeDataProps, value: string): void;
  getProp(name: TEmailChangeDataProps): string;
  reset(): void;
}
export interface IFlowEmailChange extends IFlowsBase {
  sendEmailChange(emailNew: string): Promise<void>;
  sendConfirmChange(actionToken: string): Promise<void>;
  sendEmailAdd(email: string): Promise<void>;
  sendConfirmAdd(actionToken: string): Promise<void>;
  error: TError | null;
  emailNew: string;
  status: string;
}

export interface IFlowPassword extends IFlowsBase {
  actionToContinue(currentPassword: string | null, newPassword: string, confirmNewPassword: string): Promise<void>;
  error: TError | null;
}

export interface IFlowSessions extends IFlowsBase {
  actionToContinueSession(): Promise<void>;
  actionToContinueSessions(): Promise<void>;
  sessionId: string;
}

export interface IFlowSocial extends IFlowsBase {
  actionToContinueAdd(): Promise<void>;
  actionToContinueRemove(): Promise<void>;
  socialName: string;
}

export interface IFlowTotp extends IFlowsBase {
  actionToContinueRemove(): void;
}

export type TResetTotpDataProps = 'deviceId' | 'attemptId' | 'email' | 'label';
export interface IResetTotpData {
  _props: {
    [K in TResetTotpDataProps]: string;
  };
  setProp(name: TResetTotpDataProps, value: string): void;
  getProp(name: TResetTotpDataProps): string;
  reset(): void;
}
export interface IFlowResetTotp extends IFlowsBase {
  requestCode(deviceId: string, recaptcha: string): Promise<void>;
  sendCodes(resetCode: string, emailCode: string): Promise<void>;
  error: TError | null;
  email: string;
  label: string;
}

export type TPhonesDataProps = 'phone' | 'phoneToken' | 'attemptId';
export interface IPhonesData {
  _props: {
    [K in TPhonesDataProps]: string;
  };
  setProp(name: TPhonesDataProps, value: string): void;
  getProp(name: TPhonesDataProps): string;
  reset(): void;
}
export interface IFlowPhones extends IFlowsBase {
  addPhone(phone: string, recaptcha: string): Promise<void>;
  confirmCode(code: string): Promise<void>;
  error: TError | null;
  phone: string;
  fullPhone: string;
  removePhone(): Promise<void>;
  reset: (navigatePage?: string) => Promise<void>;
}

/**
 * =============================================================
 * Security hook
 * =============================================================
 */
export type TSecurityPostProps = {
  body: {
    action?: TApiSecurityFlowActions;
    data?:
      | {
          totp: string;
          totpId: string;
        }
      | {
          attemptId: string;
          code: string;
        }
      | unknown;
  };
  headers?: {
    'action-token': string;
  };
};
export type TSecurityAnswer = {
  expired: number;
  actionToken: string;
  step: EFlowsDataStep;
  data?: TPostSecurityFlow['dataFromFlow'];
};
export interface ISecurity {
  requestSecurity(props: TSecurityPostProps): Promise<TSecurityAnswer | undefined | number>;
  error: TError | null;
}

/**
 * =============================================================
 * Mappers
 * =============================================================
 */
export interface IMapperTotp {
  clearTotp(): void;
  goVerifyTotp(expired: number): void;
}
export interface IMapperEmailCode {
  clearEmailCode(): void;
  goVerifyEmailCode(type: EFlowType, expired: number): void;
}
export interface IMapperInputCode {
  clearInputCode(): void;
  goVerifyInputCode(type: EFlowType, expired: number): void;
}
export interface IMapperFlow {
  clearFlow(): void;
  goFlow(type: EFlowType, expired?: number): void;
}
export interface IMapper {
  goNext(type: EFlowType, answer: TSecurityAnswer): void;
  goFlow: IMapperFlow['goFlow'];
  goInputCode: IMapperInputCode['goVerifyInputCode'];
  clear(): void;
}

/**
 * =============================================================
 * Steps
 * =============================================================
 */
interface IBaseFlowStep {
  error: TError | null;
}

export interface IStartFlowStep extends IBaseFlowStep {
  startFlow(type: EFlowType, props?: Partial<IFlowsData['_props']>): Promise<void>;
}

export interface ITotpFlowStep extends IBaseFlowStep {
  actionToContinue(code: string, totpId: string): Promise<void>;
  action2Fa: string;
  reset(): Promise<void>;
}

export interface IEmailCodeFlowStep extends IBaseFlowStep {
  sendEmail(isInputPage?: boolean): Promise<void>;
  confirmCode(code: string): Promise<void>;
  reset(): Promise<void>;
  actionEmail: string;
}
