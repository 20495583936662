import { useTimer, useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback, useMemo } from 'react';

import { AnswerDeleteSession, AnswerDeleteSessions } from '../../api/answer.types';
import { ROUTE_FULL_SESSIONS_REMOVE_ALL, ROUTE_FULL_SESSIONS_REMOVE_PARTIAL } from '../../constants/routes';
import useHttp from '../../hooks/http';
import { privatePagesSet } from '../../hooks/privatePages.hook';
import { IStore } from '../../hooks/store';
import { sentryException } from '../../utils/sentry';
import { ALERT_GROUP_FLOW, TIMER_NAME_FLOW } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, IFlowSessions } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';
import { resetSecuritySteps } from '../resetFlows';

const usingPrivatePages: { [K in 'removeAll' | 'removePartial']: string } = {
  removeAll: ROUTE_FULL_SESSIONS_REMOVE_ALL,
  removePartial: ROUTE_FULL_SESSIONS_REMOVE_PARTIAL,
};

export const flowSessionsReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useFlowSessions = (update: IStore['update']): IFlowSessions => {
  const { requestByName, ready } = useHttp();
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();
  const { clear } = useToast();
  const { cancelTimer } = useTimer();

  const sessionId = useMemo(() => flowsData.getProp('sessionId'), []);

  const reset = useCallback(async () => {
    resetSecuritySteps();
    cancelTimer(TIMER_NAME_FLOW);
    clear(ALERT_GROUP_FLOW);
    await resetNavigate(() => setPages(false));
  }, [cancelTimer, clear, resetNavigate, setPages]);

  const actionToContinueSession = useCallback(async () => {
    const token = flowsData.getToken(EFlowsDataStep.flow);
    if (ready('deleteSession') && token && sessionId) {
      const fetched = (await requestByName('deleteSession', {
        sessionId,
        actionToken: token,
      })) as AnswerDeleteSession;
      if (isObject(fetched) && fetched.success) {
        await update('sessions');
      }
      await reset();
    } else sentryException('FlowSessions hook: deleteSession api is not ready', 'warning');
  }, [ready, sessionId, requestByName, reset, update]);

  const actionToContinueSessions = useCallback(async () => {
    const token = flowsData.getToken(EFlowsDataStep.flow);
    if (ready('deleteSessions') && token) {
      const fetched = (await requestByName('deleteSessions', {
        actionToken: token,
      })) as AnswerDeleteSessions;
      if (isObject(fetched) && fetched.success) {
        await update('sessions');
      }
      await reset();
    } else sentryException('FlowSessions hook: deleteSessions api is not ready', 'warning');
  }, [ready, requestByName, reset, update]);

  return { reset, actionToContinueSession, actionToContinueSessions, sessionId };
};

export default useFlowSessions;
