import { Button, ButtonThemes, ButtonVariant, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import forbiddenImage from '../../assets/images/forbidden.png';
import Page from '../../containers/Page';
import AuthContext from '../../context/AuthContext';
import { sentryException } from '../../utils/sentry';
import styles from './ErrorForbidden.module.scss';

// const forbiddenImage2 = lazy(() => componentLoader(() => import('../../assets/images/forbidden.png')));
// TODO: add to sentry

/**
 * Error 403
 */
const ErrorForbidden: React.FC = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  // const { images } = useImagePreloader();

  useEffect(() => {
    sentryException('Forbidden page', 'warning');
  }, []);

  return (
    <Page>
      <div className={classNames(styles.page_wrapper, styles.this_pageWrapper)}>
        <div className={styles.this_wrapper}>
          <div className={styles.this_content}>
            <Text variant={TextVariants.head0} color={TextAndIconColors.labelsPrimary} className={styles.this_header}>
              {t('page.errorForbidden.title')}
            </Text>
            <Text
              variant={TextVariants.body1}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('page.errorForbidden.description')}
            </Text>
            <Button
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
              className={styles.common_margin__l}
              onClick={() => logout()}
            >
              {t('action.doAuth')}
            </Button>
          </div>
          {/*<img className={styles.page_image} src={images['forbidden.png']?.base64} alt='forbiddenImage' />*/}
          <img className={styles.page_image} src={forbiddenImage} alt='forbiddenImage' />
        </div>
      </div>
    </Page>
  );
};

export default ErrorForbidden;
