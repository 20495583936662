import { EInfoBlockVariant, InfoBlock, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { ReactChild, ReactElement, useContext } from 'react';
import { CSSStyles, makeClasses } from 'react-append-styles';
import { useTranslation } from 'react-i18next';

import Markdown from '../../components/Markdown';
import AppContext from '../../context/AppContext';
import withPageView from '../../hocs/Analytics';
import Footer from '../../modules/Footer/Footer';
import Header from '../../modules/Header';
import styles from './Form.module.scss';

const useClasses = makeClasses<
  'classFooter' | 'classHeader' | 'classForm',
  { footerBackground: string; headerBackground: string; pageBackgroundStyle: CSSStyles }
>((props) => ({
  classFooter: {
    background: props.footerBackground,
  },
  classHeader: {
    background: props.headerBackground,
  },
  classForm: props.pageBackgroundStyle,
}));

type FormPropsNotification = {
  title: string;
  description?: string | ReactElement;
};
export type FormProps = {
  className?: string;
  children: ReactChild;
  user?: boolean;
  supportEmail?: string;
  notification?: FormPropsNotification;
  logoClickable?: boolean;
  notificationComponent?: ReactElement | null;
};

const Form: React.FC<FormProps> = ({
  children,
  user = true,
  supportEmail = '',
  notification,
  logoClickable = true,
  notificationComponent = null,
  className,
}: FormProps) => {
  const { t } = useTranslation();
  const { config } = useContext(AppContext);

  const { classFooter, classHeader, classForm } = useClasses(
    {
      footerBackground: config.styles?.form?.footerBackground || config.styles?.footerBackground || '',
      headerBackground: config.styles?.form?.headerBackground || config.styles?.headerBackground || '',
      pageBackgroundStyle: (config.styles?.form?.pageBackground?.style as CSSStyles) || {},
    },
    Boolean(config.styles),
  );
  const classWrapper = classNames(
    {
      [styles.this_background]: !config.styles?.form?.pageBackground?.style,
      [classForm || '']: classForm,
    },
    className,
  );

  return (
    <div className={classWrapper}>
      <Header className={classHeader} fixed user={user} type={'form'} clickable={logoClickable} />
      <div className={styles.this_main}>
        {notificationComponent && <div className={styles.this_notification}>{notificationComponent}</div>}
        {notification && (
          <InfoBlock
            variant={EInfoBlockVariant.error}
            title={notification.title}
            description={<Markdown linkSettings={{ underline: true }}>{notification.description}</Markdown>}
            className={styles.this_notification}
          />
        )}
        <div className={styles.this_content}>
          <div className={styles.this_center}>
            <div className={styles.this_form}>{children}</div>
            {supportEmail && (
              <div className={classNames(styles.this_support, styles.common_margin__xxl)}>
                <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
                  {t('action.contactSupport') + ': '}
                </Text>
                <Link
                  underline
                  color={TextAndIconColors.labelsPrimary}
                  variant={TextVariants.body2}
                  href={'mailto:' + supportEmail}
                >
                  {supportEmail}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer type={'form'} className={classFooter} />
    </div>
  );
};

export default React.memo(withPageView<FormProps>(Form));
