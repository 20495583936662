import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';

// TODO: разделять цвет для страницы и для форм
const Footer: React.FC<{ type: 'form' | 'page'; className?: string }> = ({ type, className }) => {
  const { t } = useTranslation();

  const classWrapper = classNames(
    styles.this_wrapper,
    {
      [styles.this_wrapper__form]: Boolean(type === 'form'),
    },
    className,
  );

  return (
    <div className={classWrapper}>
      <Text variant={TextVariants.body4} color={TextAndIconColors.interfaceSecondary}>
        {t('footer.text', { year: new Date().getFullYear().toString() })}
      </Text>
    </div>
  );
};

export default React.memo(Footer);
