import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import React from 'react';

import CustomIcon from '../../components/CustomIcon';
import { TConfigIcon } from '../../core/config/common.types';
import styles from './Menu.module.scss';

// @ts-ignore
const colorBrandTertiary = styles.colorBrandTertiary;
// @ts-ignore
const colorInterfaceSecondary = styles.colorInterfaceSecondary;

type TMenuItemProps = {
  icon: TConfigIcon;
  text: string;
  isActive: boolean;
  onClick(): void;
  className?: string;
  iconColor?: string;
};
const MenuItem: React.VFC<TMenuItemProps> = ({ icon, text, isActive, className, onClick, iconColor }) => {
  return (
    <div className={className} onClick={onClick}>
      <CustomIcon
        icon={icon}
        size={24}
        className={styles.common_iconMargin__m}
        color={isActive ? iconColor || colorBrandTertiary : colorInterfaceSecondary}
      />
      <Text
        variant={TextVariants.body2}
        color={isActive ? TextAndIconColors.labelsPrimary : TextAndIconColors.labelsQuaternary}
      >
        {text}
      </Text>
    </div>
  );
};

export default MenuItem;
