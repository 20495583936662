import React from 'react';
import { appendStyles } from 'react-append-styles';
import { CSSObject } from 'tss-react';

import styles from './colors.module.scss';
import { redefinitionCustom } from './redefinition';

// @ts-ignore
const PREFIX = styles.themePrefix || '--uk-kit';

let KEYS: string[] = [];
try {
  // @ts-ignore
  KEYS = JSON.parse('[' + styles.themeColorKeys + ']');
} catch {
  // for alpha
  // KEYS = ['interfaceAdPrimary', 'interfaceAdSecondary', 'interfaceAdTertiary', 'interfaceAdQuaternary', 'labelBrand'];
  KEYS = ['interfaceAdPrimary', 'interfaceAdSecondary', 'interfaceAdTertiary', 'interfaceAdQuaternary', 'labelBrand'];
}

/**
 * @return { --uk-kit-2-colors-backgroundQuaternary:#efeff5; }
 */
const getColorsObj = (config: Record<string, string>, prefix: string, colorKeys: string[]): CSSObject => {
  return Object.fromEntries(
    (Object.keys(config) as Array<keyof typeof config>)
      .filter((key) => colorKeys.includes(key))
      .map((key) => [`${prefix}-colors-${key}`, config[key]]),
  );
};

export const generateColors = (config: Record<string, string>): CSSObject =>
  getColorsObj(redefinitionCustom(config), PREFIX, KEYS);

const useColors = (config?: Record<string, string> | null): void => {
  const refColors = React.useRef<CSSObject>(config ? generateColors(config) : {});
  React.useEffect(() => {
    if (Object.keys(refColors.current).length === 0) return;

    // For alpha div[data-ui-kit-root]
    // @ts-ignore
    const remove = appendStyles({ 'div[data-theme="convertSocialLight"]': refColors.current });
    return () => remove();
  }, []);
};

export default useColors;
