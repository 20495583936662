import { useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback } from 'react';

import { API_ERROR_ACTION_TYPE_LIMIT } from '../../constants/api';
import useHttp from '../../hooks/http';
import { sentryException } from '../../utils/sentry';
import flowsData from '../flows.data';
import { flowsWithSecurity, getActionByType, getBestBasePage } from '../flows.functions';
import { EFlowType, IStartFlowStep, TFlowsDataProps, TSecurityPostProps } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSecurity from '../hooks/security.hook';
import { useMapper } from '../mappers';

const useStartFlowStep = (): IStartFlowStep => {
  const { ready } = useHttp();
  const { requestSecurity, error } = useSecurity();
  const { goNext, goFlow } = useMapper();
  const { alert } = useToast();
  const resetNavigate = useResetNavigate();

  const onError = useCallback(
    async (code: number | undefined, type: EFlowType) => {
      if (!code) sentryException('useStartFlowStep hook: requestFlow unexpected response from the backend', 'warning');
      if (code === API_ERROR_ACTION_TYPE_LIMIT && type === EFlowType.emailChange) {
        alert({
          type: 'warning',
          titleData: {
            key: 'page.emailView.error.title',
          },
          textData: {
            key: 'page.emailView.error.description',
          },
        });
      }
      if (code === API_ERROR_ACTION_TYPE_LIMIT && type === EFlowType.emailAdd) {
        alert({
          type: 'warning',
          titleData: {
            key: 'page.emailVerify.error.title',
          },
          textData: {
            key: 'page.emailVerify.error.description',
          },
        });
      }
      if (code === API_ERROR_ACTION_TYPE_LIMIT && type === EFlowType.addPhone) {
        alert({
          type: 'warning',
          titleData: {
            key: 'page.addPhone.error.title',
          },
          textData: {
            key: 'page.addPhone.error.text',
          },
        });
      }
      if (code === API_ERROR_ACTION_TYPE_LIMIT && type === EFlowType.removePhone) {
        alert({
          type: 'warning',
          titleData: {
            key: 'page.removePhone.error.title',
          },
          textData: {
            key: 'page.removePhone.error.text',
          },
        });
      }
      if (code === API_ERROR_ACTION_TYPE_LIMIT && type === EFlowType.verifyPhone) {
        alert({
          type: 'warning',
          titleData: {
            key: 'page.phoneVerify.error.title',
          },
          textData: {
            key: 'page.phoneVerify.error.text',
          },
        });
      }
      await resetNavigate(() => {});
    },
    [alert, resetNavigate],
  );

  const startFlow = useCallback(
    async (type, props = {}): Promise<void> => {
      flowsData.setFlow(type);
      flowsData.setBasePage(getBestBasePage(type));
      for (const [key, value] of Object.entries(props) as [TFlowsDataProps, string][]) {
        if (value) flowsData.setProp(key, value);
      }

      if (!flowsWithSecurity.has(type)) {
        goFlow(type);
        return;
      }

      if (!ready('postSecurityFlow')) {
        sentryException('useStartFlow hook: postSecurityFlow not ready', 'warning');
        return;
      }

      const action = getActionByType(type);
      const requestProps: TSecurityPostProps = { body: { action } };
      const answer = await requestSecurity(requestProps);
      // TODO: answer -> response

      if (!isObject(answer)) {
        await onError(answer, type);
        return;
      }

      goNext(type, answer);
    },
    [goFlow, goNext, onError, ready, requestSecurity],
  );

  return { startFlow, error };
};

export default useStartFlowStep;
