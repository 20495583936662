import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInputThemes,
  Input,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { ToastPropsT, useToast } from 'library-react-hooks';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { API_ERROR_CODE_EMAIL_ALREADY_USED } from '../../constants/api';
import Page from '../../containers/Page';
import useInput from '../../hooks/input.hook';
import useFlowEmailChange from '../../scenarios/flows/flowEmailChange.hook';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { validationEmail } from '../../utils/validation';
import styles from './EmailEdit.module.scss';

const EmailEdit: React.VFC = () => {
  const { t } = useTranslation();
  const { sendEmailChange, error, reset } = useFlowEmailChange();
  const { alert } = useToast();

  const [email, changeEmail, { error: errorEmail, setError: setErrorEmail }] = useInput<string>('', { translate: t });
  useEffect(() => {
    if (error?.code === API_ERROR_CODE_EMAIL_ALREADY_USED) {
      setErrorEmail(error.title);
    } else if (error?.code) {
      const props: ToastPropsT = { type: 'error', titleData: { key: error.title } };
      if (error.text) props.textData = { key: error.text };
      alert(props);
    }
  }, [alert, error, setErrorEmail]);

  const updateEmail = useCallback(async () => {
    let valid = true;
    const validationEmailResult = validationEmail(email);
    if (validationEmailResult) {
      valid = false;
      setErrorEmail(
        validationEmailResult === 1 ? 'message.errorValidationEmailIncorrectSymbol' : 'message.errorValidationEmail',
      );
    }

    if (valid) {
      ga4Event('emailEditClick', { placeholders: { element: 'next' } });
      await sendEmailChange(email);
    }
  }, [email, sendEmailChange, setErrorEmail]);

  const buttonDisabled: boolean = !email || Boolean(errorEmail);

  useEffect(() => {
    const listener = listenKeydown('Enter', updateEmail, buttonDisabled);
    return () => {
      listener();
    };
  }, [updateEmail, buttonDisabled]);

  return (
    <Page menu subHead={{ title: t('page.emailView.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.emailEdit.description.part1')}
        </Text>
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
          {t('page.emailEdit.description.part2')}
        </Text>
        <div className={classNames(styles.common_margin__m, styles.page_block, styles.page_block__border)}>
          <Text variant={TextVariants.header9} color={TextAndIconColors.labelsSecondary} className={styles.this_email}>
            {t('page.emailEdit.subTitle')}
          </Text>
          <Input
            placeholder={t('field.emailNew')}
            value={email}
            onChange={changeEmail}
            className={classNames(styles.common_margin__l)}
            theme={errorEmail ? EInputThemes.error : EInputThemes.base}
            errorMessage={errorEmail}
            autoFocus
          />
          <div className={styles.common_buttons}>
            <Button
              text={t('action.cancel').toString()}
              type={ButtonTypes.button}
              onClick={() => {
                ga4Event('emailEditClick', { placeholders: { element: 'cancel' } });
                reset();
              }}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
            />
            <Button
              text={t('action.next').toString()}
              type={ButtonTypes.button}
              onClick={updateEmail}
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(EmailEdit);
