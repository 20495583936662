import React from 'react';

// При изменении значения, так же переименовать в стилях
export enum SocialTypesE {
  google = 'google',
  github = 'github',
  facebook = 'facebook',
  linkedin = 'linkedin',
  linkedinNew = 'linkedin-openid-connect',
  instagram = 'instagram',
  tiktok = 'tiktok',
  microsoft = 'microsoft',
  // bitbucket = 'bitbucket', Нет иконки
  twitter = 'twitter',
  gitlab = 'gitlab',
  paypal = 'paypal',
  stackOverflow = 'stack-overflow',
  yandex = 'yandex',
  vk = 'vk',
  ok = 'ok',
  mitgo = 'mitgo',
  // admitad = 'admitad',
  // takeAds = 'takeAds',
  unknown = 'unknown',
  // Contacts ------------------
  phone = 'phone',
  telegram = 'telegram',
  whatsApp = 'whatsApp',
  skype = 'skype',
  icq = 'icq',
}
export type TSocialTypes = `${SocialTypesE}`;

export enum SocialVariantE {
  // Фон прозрачный, иконка цветная, бордер
  var1 = 'var1',
  // Фон прозрачный, иконка серая, бордер
  var2 = 'var2',
  // Фон цветной, иконка белая
  var3 = 'var3',
  // Фон прозрачный, иконка цветная
  var4 = 'var4',
  // Фон белый, иконка цветная, бордер
  var5 = 'var5',
}

export enum SocialSizeE {
  s = 's',
  m = 'm',
}

export type SocialProps = {
  /**
   * Иконка по типу соц. сети
   */
  type: SocialTypesE;

  /**
   * Вариант отображения иконки
   */
  variant: SocialVariantE;

  /**
   * Размер иконки
   */
  size: SocialSizeE;

  /**
   * Кликабальная иконка
   */
  clickable?: boolean;

  /**
   * @param event
   * Событие при клике, при clickable = true
   */
  onClick?: (...props: any) => void;

  /**
   * @param event
   * Событие при наведении
   * @param event
   */
  onMouseOver?: (event: React.MouseEvent<HTMLElement>) => void;

  /**
   * @param event
   * Событие при уыедении мыши
   */
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;

  /**
   * Вариант при наведении, при clickable = true
   */
  hoverVariant?: SocialVariantE;

  /**
   * Вариант при клике, при clickable = true
   */
  clickVariant?: SocialVariantE;

  className?: string;

  src?: string;
};
