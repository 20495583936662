export const intervalListener = (condition: () => boolean, callback: () => void, max = 8, interval = 2000): void => {
  if (condition()) {
    callback();
    return;
  }

  let counter = 0;
  const listen = setInterval(() => {
    if (condition()) {
      callback();
      clearInterval(listen);
    } else if (counter > max) clearInterval(listen);
    counter += 1;
  }, interval);
};
