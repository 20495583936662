import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Icon,
  IconSizes,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconCheckmark } from '../../assets/icons/checkmark.svg';
import { ReactComponent as IconCrossmark } from '../../assets/icons/crossmark.svg';
import Page from '../../containers/Page';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import useFlowEmailChange from '../../scenarios/flows/flowEmailChange.hook';
import styles from './EmailChanged.module.scss';

const EmailChanged: React.VFC = () => {
  const { t } = useTranslation();
  const { user, update, state } = useStore([]);
  const { status, reset } = useFlowEmailChange();

  const success = useMemo(() => status === 'success', [status]);

  useEffect(() => {
    if (success) update('user');
  }, [update, success]);

  useOnLoad(Boolean(state.user), 'EmailChanged');

  return (
    <Page menu subHead={{ title: t('page.emailView.title') }} callback={reset}>
      <div className={classNames(styles.page_wrapper)}>
        <div className={classNames(styles.page_block, styles.page_block__border)}>
          <div className={styles.page_block}>
            {success ? (
              <div className={styles.page_iconBlock}>
                <Icon IconComponent={IconCheckmark} size={IconSizes.l} color={TextAndIconColors.actionConfirmPrimary} />
                <Text
                  variant={TextVariants.header9}
                  color={TextAndIconColors.labelsSecondary}
                  className={styles.this_email}
                >
                  {t('page.emailChanged.subTitle', { email: user.email })}
                </Text>
              </div>
            ) : (
              <div className={styles.page_iconBlock}>
                <Icon IconComponent={IconCrossmark} size={IconSizes.l} color={TextAndIconColors.actionErrorPrimary} />
                <Text
                  variant={TextVariants.header9}
                  color={TextAndIconColors.labelsSecondary}
                  className={styles.this_email}
                >
                  {t('page.emailChanged.subTitleError')}
                </Text>
              </div>
            )}
            {success ? (
              <>
                <Text
                  variant={TextVariants.body2}
                  color={TextAndIconColors.labelsTertiary}
                  className={styles.common_margin__xs}
                >
                  {t('page.emailChanged.description.part1')}
                </Text>
                <Text
                  variant={TextVariants.body2}
                  color={TextAndIconColors.labelsTertiary}
                  className={styles.common_margin__m}
                >
                  {t('page.emailChanged.description.part2')}
                </Text>
              </>
            ) : (
              <Text
                variant={TextVariants.body2}
                color={TextAndIconColors.labelsTertiary}
                className={styles.common_margin__xs}
              >
                {t('page.emailChanged.descriptionError.part1')}
              </Text>
            )}
          </div>
        </div>
        <div className={classNames(styles.common_buttons)}>
          <Button
            text={t('action.toSecurity').toString()}
            type={ButtonTypes.button}
            onClick={() => reset()}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.labelsSecondary}
          />
        </div>
      </div>
    </Page>
  );
};

export default React.memo(EmailChanged);
