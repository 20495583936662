import { isObject } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { AnswerPostSecurityFlow } from '../../api/answer.types';
import useHttp from '../../hooks/http';
import flowsData from '../flows.data';
import { getExpiredFromToken } from '../flows.functions';
import { EFlowsDataStep, ISecurity, TError, TSecurityAnswer, TSecurityPostProps } from '../flows.types';

const useSecurity = (): ISecurity => {
  const { requestByName, errorCode } = useHttp();
  const [error, setError] = useState<TError | null>(null);
  useEffect(() => {
    if (errorCode?.postSecurityFlow) {
      setError(errorCode.postSecurityFlow);
    }
  }, [errorCode.postSecurityFlow]);

  const requestSecurity = useCallback(
    async (props: TSecurityPostProps): Promise<TSecurityAnswer | undefined | number> => {
      const fetched = (await requestByName('postSecurityFlow', props)) as AnswerPostSecurityFlow;
      if (!isObject(fetched)) return Number(fetched);

      if (!fetched.actionToken) return;

      const { actionToken, done, stage, dataFromFlow } = fetched;
      const expired = getExpiredFromToken(actionToken);
      if (done) {
        flowsData.setToken(EFlowsDataStep.flow, actionToken);
        return { step: EFlowsDataStep.flow, actionToken, expired };
      }
      if (stage === 'totp') {
        flowsData.setToken(EFlowsDataStep.totp, actionToken);
        return { step: EFlowsDataStep.totp, actionToken, expired };
      }
      if (stage === 'request-email-code') {
        flowsData.setToken(EFlowsDataStep.emailCode, actionToken);
        return { step: EFlowsDataStep.emailCode, actionToken, expired };
      }
      if (stage === 'check-email-code') {
        // ???
        return { step: EFlowsDataStep.emailCode, actionToken, expired, data: dataFromFlow };
      }
    },
    [requestByName],
  );

  return { requestSecurity, error };
};

export default useSecurity;
