import i18next from 'i18next';

import { LOCALE_SUPPORTED, localeRules } from '../constants/locale';
import { LocaleE } from '../constants/locale.types';

export function formatterLocaleFn(ruleName: unknown, lng: unknown): string {
  if (typeof ruleName !== 'string' || !Object.keys(localeRules).includes(ruleName)) {
    console.error(`Unknown ruleName: ${ruleName}`);
    throw new Error(`Unknown ruleName: ${ruleName}`);
  }
  if (typeof lng !== 'string' || !LOCALE_SUPPORTED.includes(lng as LocaleE)) {
    console.error(`Unknown locale: ${lng}`);
    throw new Error(`Unknown locale: ${lng}`);
  }
  const rule = localeRules[ruleName as keyof typeof localeRules];
  return rule[(lng as LocaleE) === LocaleE.cs || (lng as LocaleE) === LocaleE.uk ? LocaleE.en : (lng as LocaleE)];
}

const formatters = {
  formatterLocale: (ruleName: string, lng: string): string => formatterLocaleFn(ruleName, lng),
};

const checkPlaceholder = (content: string): { state: boolean; start: number; end: number } => {
  const placeholderStart = content.indexOf('{{');
  const placeholderEnd = content.indexOf('}}');
  return !placeholderStart || !placeholderEnd || placeholderEnd - placeholderStart < 4
    ? { state: false, start: 0, end: 0 }
    : {
        state: true,
        start: placeholderStart,
        end: placeholderEnd,
      };
};

// {{'short', formatterLocale}}
export const i18link = (content: string, placeholders?: Record<string, string>): string => {
  if (!content) return content;

  const { state, start, end } = checkPlaceholder(content);
  if (!state) return content;
  const [arg, formatter] = content
    .slice(start + 2, end)
    .split(',')
    .map((item) => item.trim());

  if (!arg) return content;

  let result = arg;
  if (placeholders && Object.keys(placeholders).includes(arg)) {
    result = placeholders[arg];
  }
  if (formatter && Object.keys(formatters).includes(formatter)) {
    result = formatters[formatter as keyof typeof formatters](result.replaceAll("'", ''), i18next.language);
  }

  const contentEnd = checkPlaceholder(content.slice(end + 2)).state
    ? i18link(content.slice(end + 2), placeholders)
    : content.slice(end + 2);
  return content.slice(0, start) + result + contentEnd;
};
