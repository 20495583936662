import moment from 'moment';

import { ISession, IStore } from '../hooks/store';

const compareLastAccess = (a: ISession, b: ISession): number => {
  const isAfter: boolean = moment(a.lastAccess).isAfter(b.lastAccess);
  if (!isAfter) return 1;
  return -1;
};
export const getLastSessions = (sessions: IStore['sessions'], quantity: number): ISession[] => {
  if (!sessions) return [];
  return sessions.sort(compareLastAccess).slice(0, quantity);
};

export const sortByLastAccess = (sessions: IStore['sessions'], max?: number): IStore['sessions'] => {
  if (!sessions) return null;

  const updated = sessions.sort(compareLastAccess);
  const doSlice = max && updated.length - max > 0;
  return doSlice ? updated.slice(0, max) : updated;
};

export const findSessionById = (sessions: IStore['sessions'], id: string): ISession | undefined => {
  return sessions?.find((item) => item.id === id);
};
