import { Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FormEmail.module.scss';

const Notification: React.VFC<{ supportEmail: string }> = ({ supportEmail }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.notification_wrapper}>
      <div className={styles.notification_content}>
        <Text variant={TextVariants.header6} color={TextAndIconColors.labelsSecondary}>
          {t('helper.emailNotArrive.head')}
        </Text>
        <ul className={classNames(styles.common_htmlList, styles.common_margin__s)}>
          <li className={classNames(styles.common_htmlListItem)}>
            <Text variant={TextVariants.body4} color={TextAndIconColors.labelsSecondary}>
              {t('helper.emailNotArrive.wait')}
            </Text>
          </li>
          <li className={classNames(styles.common_htmlListItem)}>
            <Text variant={TextVariants.body4} color={TextAndIconColors.labelsSecondary}>
              {t('helper.emailNotArrive.checkSpamWithDot')}
            </Text>
          </li>
        </ul>
        <Text
          variant={TextVariants.body4}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__s}
        >
          {t('action.orContactSupport') + ' '}
          <Link
            text={supportEmail}
            href={'mailto:' + supportEmail}
            color={TextAndIconColors.interfaceAccentTertiary}
            variant={TextVariants.body4}
          />
          {'.'}
        </Text>
      </div>
    </div>
  );
};

export default Notification;
