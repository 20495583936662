import classNames from 'classnames';
import React from 'react';

import Session from '../../components/Session';
import styles from './Tables.module.scss';
import { ISessionsRemoveList } from './Tables.types';

const SessionsRemoveList: React.VFC<ISessionsRemoveList> = ({ className, sessions }) => {
  return (
    <div className={classNames(styles.list_wrapper, className)}>
      {sessions.map((session, id) => (
        <Session
          key={id}
          id={id}
          clients={session.clients}
          ipAddress={session.ipAddress}
          lastAccess={session.lastAccess}
          start={session.start}
          className={classNames(styles.list_row, {
            [styles.list_row__divider]: Boolean(id !== 0),
            [styles.list_row__last]: Boolean(id + 1 === sessions.length),
          })}
        />
      ))}
    </div>
  );
};

export default SessionsRemoveList;
