import { useLoader, useToast } from 'library-react-hooks';
import { ToastPropsT } from 'library-react-hooks/dist/hooks/toast/toast.types';
import { useCallback, useEffect, useState } from 'react';

import { sentryException } from '../../utils/sentry';
import data from './data';
import { convertQuery } from './http.functions';
import { DataI, IUseHttp } from './http.types';

export const httpInit = (
  props: Partial<
    Pick<
      DataI,
      | 'loader'
      | 'toast'
      | 'tokens'
      | 'requests'
      | 'onError'
      | 'responseFormat'
      | 'blockRepeatWhenPending'
      | 'toastWhenBackendUnavailable'
      | 'mocked'
      | 'mocks'
    >
  >,
) => {
  return data.setInit(props);
};

const successFn = (_props: any) => {
  return true;
};

// TODO: переписать хук, в нем стало слишком много всего
const useHttp = (): IUseHttp => {
  const [error, setError] = useState<IUseHttp['error']>({});
  const [meta, setMeta] = useState<IUseHttp['meta']>({});
  const [errorCode, setErrorCode] = useState<IUseHttp['errorCode']>({});
  const { loaderOn: on, loaderOff: off } = useLoader();
  const { alert: toastAlert } = useToast();

  const [tokens, setTokens] = useState<DataI['tokens']>(data.getTokens());
  useEffect(() => {
    const clear = data.on((e) => {
      if (e.action === 'token') {
        setTokens(data.getTokens());
      }
    });
    return () => clear();
  }, []);
  const alert = useCallback((props: ToastPropsT) => (data.getToast() ? toastAlert(props) : () => {}), [toastAlert]);
  const loaderOn = useCallback(() => (data.getLoader() ? on : () => {}), [on]);
  const loaderOff = useCallback(() => (data.getLoader() ? off : () => {}), [off]);

  const request = useCallback(
    async (props, name?: string) => {
      let { body = null } = props;
      const {
        url,
        method = 'GET',
        headers = {},
        query = null,
        token,
        answer = true,
        toast = true,
        successMsg = '',
        loader = true,
        toastWhenCode = true,
        format = true,
        toastRules = successFn,
      } = props;
      if (data.blockRepeatWhenPending && data.getState(url) === 'pending') {
        return;
      }
      if (token && !tokens[token]) {
        console.error('No token');
        return false;
      }

      setError((prev) => Object.assign({}, prev, { [name || url]: null }));
      data.setState(url, 'pending');
      if (loader) {
        loaderOn();
      }
      if (body) {
        body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }
      if (token) headers.Authorization = `Bearer ${tokens[token]}`;
      let URL = url;
      if (query) URL = `${URL}?${convertQuery(query)}`;
      try {
        const response = await fetch(URL, { method, body, headers });
        if (loader) {
          loaderOff();
        }
        if (!answer) {
          data.setState(url, 'success');
          return true;
        }

        let httpData = await response.json();
        // console.log('httpData', response, httpData);
        if (format) httpData = data.responseFormat(httpData);

        if (!response.ok) {
          data.onError(response);
          setMeta(httpData?._meta || {});

          const messageKey = data.getError(response.status);
          const message = messageKey || response.statusText;
          setError((prev) =>
            Object.assign({}, prev, {
              [name || url]: {
                text: message,
                code: httpData.statusCode || response.status || null,
              },
            }),
          );

          // @ts-ignore
          if (!httpData.errorCode && !httpData?._meta && toast && toastRules(httpData) && !data.errorHandler) {
            const alertProps: ToastPropsT = {
              text: message.toString(),
              type: 'error',
            };
            if (messageKey) {
              alertProps.textData = { key: messageKey };
            }
            alert(alertProps);
            console.error(httpData.message || 'Something went wrong');
          } else if (
            !httpData.errorCode &&
            !httpData?._meta &&
            toast &&
            toastRules(httpData) &&
            typeof data.errorHandler === 'function'
          ) {
            data.setState(url, 'error');
            data.errorHandler(response, message);
          }

          // @ts-ignore
          if (httpData.errorCode) {
            const fromData = data.getErrorCode(httpData.errorCode);
            setErrorCode((prev) =>
              Object.assign({}, prev, {
                [name || url]: {
                  title: fromData?.title || '',
                  text: fromData?.text || '',
                  code: httpData.errorCode || null,
                },
              }),
            );
            if (toast && toastWhenCode) {
              const alertData: ToastPropsT = {
                textData: { key: fromData?.text || '' },
                type: 'error',
                titleData: { key: fromData?.title || '', options: { errorCode: httpData.errorCode || '' } },
              };
              alert(alertData);
            }
            console.error(httpData.message || 'Something went wrong');
            data.setState(url, 'error');
            return httpData.errorCode;
          }
          data.setState(url, 'error');
          return false;
        }

        if (
          (method === 'POST' || method === 'PUT' || method === 'PATCH') &&
          toast &&
          successMsg &&
          toastRules(httpData)
        )
          alert({ textData: { key: successMsg }, type: 'success' });

        data.setState(url, 'success');
        return httpData;
      } catch (error_: any) {
        if (loader) loaderOff();
        const message = 'Something went wrong';
        setError((prev) =>
          Object.assign({}, prev, {
            [name || url]: {
              text: message,
              code: 503,
            },
          }),
        );
        if (toast && data.toastWhenBackendUnavailable && toastRules(error_))
          alert({ text: message.toString(), type: 'error' });
        console.error(error_?.message || message);
        data.setState(url, 'error');
        sentryException('useHttp: Can`t get response from backend', 'warning');
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaderOn, loaderOff, alert, JSON.stringify(tokens)],
  );
  const requestByName = useCallback(
    async (name, props) => {
      const dataToFetch = data.getRequest(name, props);
      if (!dataToFetch) {
        console.error('Request not found');
        return false;
      }
      return request(dataToFetch, name);
    },
    [request],
  );

  const clearError = useCallback(() => {
    setError({});
  }, []);

  return {
    request: (props) => request(props),
    error,
    clearError,
    setToken: useCallback((name, value) => data.setToken(name, value), []),
    setErrors: useCallback((props) => data.setInit({ errors: props }), []),
    requestByName,
    ready: useCallback((requestName) => data.checkReady(requestName), []),
    setErrorHandler: (fn) => (data.errorHandler = fn),
    errorCode,
    setErrorCodes: useCallback((props) => data.setInit({ errorCodes: props }), []),
    setTranslationFn: (fn) => data.setTranslationFn(fn),
    meta,
  };
};

export default useHttp;
