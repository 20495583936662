import React from 'react';

import Avatar from '../Avatar';
import { AvatarBackgroundColors, AvatarSizes } from '../Avatar/Avatar.types';
import Svg from '../Svg';
import { getIcon } from './CustomIcon.functions';
import styles from './CustomIcon.module.scss';
import { ECustomIconName, ICustomIconProps } from './CustomIcon.types';

// @ts-ignore
const interfaceAdPrimary = styles.interfaceAdPrimary;

const isUrl = (icon: ICustomIconProps['icon']): boolean => (icon ? /^(http|https):/.test(icon) : false);
const isSvgUrl = (icon: ICustomIconProps['icon']): boolean => (icon ? /\.svg$/.test(icon) : false);

const CustomIcon: React.VFC<ICustomIconProps> = ({ icon, size, color, className, onClick, alt, initials }) => {
  if (icon === ECustomIconName.userDefault && initials) {
    return (
      <Avatar
        initials={initials}
        className={styles.initials}
        size={AvatarSizes.s}
        color={AvatarBackgroundColors.seventh}
      />
    );
  }

  const IconComponent = getIcon(icon);
  const Base = (
    <IconComponent
      width={size}
      height={size}
      className={className}
      color={color || interfaceAdPrimary}
      onClick={onClick}
    />
  );

  if (!icon) return null;
  if (!isUrl(icon)) return Base;
  if (isSvgUrl(icon))
    return (
      <Svg
        src={icon}
        props={{ width: size, height: size, className, onClick, color: color || interfaceAdPrimary }}
        base={Base}
      />
    );
  return <img src={icon} className={className} alt={alt || 'image'} />;
};

export default CustomIcon;
