import { ELinkTarget, Link, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './Markdown.module.scss';

type TLinkSettings = {
  underline: boolean;
  variant: TextVariants;
  color: TextAndIconColors;
  target: ELinkTarget;
};
type MarkdownProps = {
  children: string | ReactElement | undefined;
  linkSettings?: Partial<TLinkSettings>;
};

const Markdown: React.FC<MarkdownProps> = ({ children, linkSettings = {} }) => {
  const {
    underline = false,
    color = TextAndIconColors.interfaceAccentSecondary,
    variant = TextVariants.body2,
    target = ELinkTarget.blank,
  } = linkSettings;

  if (typeof children !== 'string') return <>{children}</>;
  return (
    <ReactMarkdown
      className={styles.markdown}
      skipHtml
      components={{
        a: (linkProps) => (
          <Link
            text={linkProps.children.toString()}
            href={linkProps.href}
            underline={underline}
            color={color}
            variant={variant}
            target={target}
          />
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
