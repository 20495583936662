export enum EServicesName {
  affiliate = 'Admitad',
  convertSocial = 'ConvertSocial',
  monetize = 'TakeAds',
  getuniq = 'GetUniq',
  wallet = 'Earnings Wallet',
  marketplaceX = 'Marketplace X',
}

export interface IServiceCardProps {
  title: string;
  description: string;
  link: string;
  linkText: string;
  className?: string;
  logoLink?: string | null;
}
export const SERVICES_SUPPORTED: string[] = Object.keys(EServicesName);
export const getServiceKey = (value: EServicesName) =>
  Object.keys(EServicesName)[Object.values(EServicesName).indexOf(value)];
