import { Icon, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';

import { ReactComponent as IconBasket } from '../../assets/icons/basket.svg';
import Social, { SocialSizeE, SocialTypesE, SocialVariantE } from '../../components/Social';
import styles from './DeleteAccount.module.scss';

export type TServiceItemProps = {
  name: string;
  link: string;
  linkText: string;
  className?: string;
  isMobile: boolean;
  icon: string | null;
};
const ServiceItem: React.VFC<TServiceItemProps> = ({ name, link, linkText, isMobile, icon, className }) => {
  return (
    <div className={classNames(styles.serviceItem_wrapper, className)}>
      <div className={styles.serviceItem_content}>
        <Social
          type={SocialTypesE.unknown}
          variant={SocialVariantE.var5}
          size={SocialSizeE.s}
          src={icon || undefined}
        />
        <Text variant={TextVariants.header7} color={TextAndIconColors.labelsSecondary}>
          {name}
        </Text>
      </div>
      <Link underline={false} color={TextAndIconColors.labelsQuaternary} variant={TextVariants.body3} href={link}>
        {isMobile ? (
          <Icon IconComponent={IconBasket} color={TextAndIconColors.interfaceTertiary} width={28} height={28} />
        ) : (
          linkText
        )}
      </Link>
    </div>
  );
};

export default ServiceItem;
