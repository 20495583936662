import Cookie from '../Cookie';

export const GA_COOKIE_NAME = '_ga';

// TODO: добавить в начало get, комменты, ссылка на доку
export const clientId = (): string | undefined => {
  const cookie = Cookie.getCookie(GA_COOKIE_NAME);
  return cookie?.match(/(\d+\.\d+)$/)?.[1];
};

export const referrer = (): string => document.referrer || window.passportReferrer || 'direct none';

// 2022-07-09T14:06:10.512+03:00
export const timeStamp = (): string => {
  const now = new Date();
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const pad = function (num: number) {
    const norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  };
  return (
    now.getFullYear() +
    '-' +
    pad(now.getMonth() + 1) +
    '-' +
    pad(now.getDate()) +
    'T' +
    pad(now.getHours()) +
    ':' +
    pad(now.getMinutes()) +
    ':' +
    pad(now.getSeconds()) +
    '.' +
    pad(now.getMilliseconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
};

export const sessionId = (): string => Date.now() + '.' + Math.random().toString(36).slice(5);

export const hitId = (): string => {
  const now = new Date();
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const pad = function (num: number) {
    const norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  };
  return (
    now.getFullYear() +
    '-' +
    pad(now.getMonth() + 1) +
    '-' +
    pad(now.getDate()) +
    'T' +
    pad(now.getHours()) +
    ':' +
    pad(now.getMinutes()) +
    ':' +
    pad(now.getSeconds()) +
    '.' +
    pad(now.getMilliseconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
};

export const datetimeGreenwich = (): string => {
  const date = new Date();

  // returns UTC (=GMT) Hour of the date
  const hour = date.getUTCHours();

  // returns UTC (=GMT) Minutes of the date
  const minute = date.getUTCMinutes();

  // returns UTC (=GMT) Seconds of the date
  const seconds = date.getUTCSeconds();

  // returns UTC (=GMT) year of the date
  const year = date.getUTCFullYear();

  // returns UTC (=GMT) month (0-11)
  //    0 is January, 11 is December
  const month = date.getUTCMonth();

  // returns UTC (=GMT) day of the month (1-31)
  const day = date.getUTCDate();

  return `${year}-${month + 1}-${day} ${hour}:${minute}:${seconds}`;
};

export const sid = (): string => {
  const match = document.cookie.match('(?:^|;)\\s*_ga_G9HZPK179G=([^;]*)');
  const raw = match ? decodeURIComponent(match[1]) : null;
  return raw?.split('.')[2] || 'undefined';
};

export const urlTags = (): string => {
  if (document.URL.split('?')[1] != undefined) {
    return document.URL.split('?')[1];
  } else if (document.URL.split('#')[1] == undefined) {
    return 'undefined';
  } else {
    return document.URL.split('#')[1];
  }
};
