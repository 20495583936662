import Ajv from 'ajv';

import * as apiTypes from '../Config.types';
import SCHEMA from './schema.json';

const ajv = new Ajv({ removeAdditional: true }).addSchema(SCHEMA, 'SCHEMA');

/**
 * Библиотека https://www.npmjs.com/package/create-validator-ts генерила не под все дефинишины
 * Файл schema.json генерится коммандой из package.json
 */

export function validateProperty<T>(payload: unknown, key: string): T {
  const keyRef = `SCHEMA#/definitions/${key}`;

  /** Schema is defined in {@link {keyRef}} } **/
  const validator = ajv.getSchema(keyRef);
  if (!validator) throw new Error(`Invalid definition: ${keyRef}`);

  const valid = validator(payload);
  if (!valid) {
    const error = new Error(`Invalid ${key}: ` + ajv.errorsText(validator.errors, { dataVar: key }));
    error.name = 'ValidationError';
    throw error;
  }
  return payload as T;
}

export function validateIConfig(payload: unknown): apiTypes.IConfig {
  return validateProperty<apiTypes.IConfig>(payload, 'IConfig');
}
