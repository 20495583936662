import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import flowsData from '../flows.data';

const useResetNavigate = (): ((callback: (...args: any) => void, navigatePage?: string) => Promise<void>) => {
  const navigate = useNavigate();

  return useCallback(
    (callback, navigatePage?: string) => {
      return new Promise((resolve) => {
        flowsData.reset();
        if (navigatePage) {
          navigate(navigatePage);
        } else navigate(flowsData.getBasePage());
        resolve(true);
      }).then(() => {
        callback();
      });
    },
    [navigate],
  );
};

export default useResetNavigate;
