import { createContext } from 'react';

import { TKProfile } from '../hooks/keycloak';

export interface IAuthContext {
  // token: null | string;
  // login(): void;
  logout(redirectUri?: string): Promise<void>;
  // ready: null | boolean;
  profile: TKProfile | null;
  refresh(): void;
  // error: number | null;
}

const AuthContext = createContext<IAuthContext>({
  // token: null,
  // login: () => {},
  logout: (redirectUri?: string) =>
    new Promise((res) => {
      res();
    }),
  // ready: null,
  profile: null,
  refresh: () => {},
  // user: userInitial,
  // userUpdate: (data) => {},
});

export default AuthContext;
