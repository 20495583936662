import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInputThemes,
  Icon,
  Input,
  Link,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { ToastPropsT, useToast } from 'library-react-hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconEmail } from '../../assets/icons/email.svg';
import { API_ERROR_CODE_WRONG_EMAIL_CODE } from '../../constants/api';
import useInput from '../../hooks/input.hook';
import { listenKeydown } from '../../utils/keyboard';
import styles from './EmailCodeModule.module.scss';
import { IEmailCodeModuleProps } from './EmailCodeModule.types';

const EmailCodeModule: React.VFC<IEmailCodeModuleProps> = ({
  className,
  descriptionText,
  onCancel,
  onNext,
  error,
  titleText,
  subTitleText,
  supportEmail,
}) => {
  const { t } = useTranslation();
  const { alert } = useToast();

  const [code, changeCode, { error: errorCode, setError: setErrorCode }] = useInput<string>('', { translate: t });
  useEffect(() => {
    if (error?.code === API_ERROR_CODE_WRONG_EMAIL_CODE) {
      setErrorCode(error.title);
    } else if (error?.code) {
      const props: ToastPropsT = { type: 'error', titleData: { key: error.title } };
      if (error.text) props.textData = { key: error.text };
      alert(props);
    }
  }, [alert, error, setErrorCode]);

  const buttonDisabled: boolean = code.length !== 6 || Boolean(errorCode);

  useEffect(() => {
    const listener = listenKeydown('Enter', () => onNext(code, setErrorCode), buttonDisabled);
    return () => {
      listener();
    };
  }, [onNext, buttonDisabled, code, setErrorCode]);

  return (
    <div className={classNames(styles.page_wrapper, className)}>
      <div className={classNames(styles.page_iconBlock, styles.page_iconBlock__border, styles.page_iconBlock__top)}>
        <Icon
          IconComponent={IconEmail}
          width={24}
          height={24}
          color={TextAndIconColors.interfaceAccentSecondary}
          className={styles.page_iconBlock__icon}
        />
        <Text variant={TextVariants.header9} color={TextAndIconColors.labelsSecondary} className={styles.this_email}>
          {subTitleText}
        </Text>
      </div>
      <div className={classNames(styles.page_block, styles.page_block__border, styles.common_margin__m)}>
        <Text variant={TextVariants.header9} color={TextAndIconColors.labelsSecondary} className={styles.this_email}>
          {titleText}
        </Text>
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsTertiary}
          className={styles.common_margin__xs}
        >
          {descriptionText}
        </Text>
        <Input
          className={styles.common_margin__l}
          placeholder={t('field.code')}
          value={code}
          onChange={changeCode}
          theme={errorCode ? EInputThemes.error : EInputThemes.base}
          errorMessage={errorCode}
          autoFocus
        />
        <div className={styles.common_buttons}>
          <Button
            text={t('action.cancel').toString()}
            type={ButtonTypes.button}
            onClick={onCancel}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.interfaceTertiaryTabs}
          />
          <Button
            text={t('action.confirm').toString()}
            type={ButtonTypes.button}
            onClick={() => onNext(code, setErrorCode)}
            variant={ButtonVariant.filled}
            disabled={buttonDisabled}
          />
        </div>
      </div>
      <div className={classNames(styles.page_darkBlock, styles.common_margin__m)}>
        <Text variant={TextVariants.header9} color={TextAndIconColors.labelsPrimary}>
          {t('helper.emailNotArrive.head')}
        </Text>
        <ul className={classNames(styles.common_htmlList, styles.common_margin__xxs)}>
          <li className={classNames(styles.common_htmlListItem)}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('helper.emailNotArrive.wait')}
            </Text>
          </li>
          <li className={styles.common_htmlListItem}>
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
              {t('helper.emailNotArrive.checkSpamWithDot')}
            </Text>
          </li>
        </ul>
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsTertiary}
          className={styles.common_margin__xs}
        >
          {t('action.orContactSupport') + ' '}
          <Link
            underline={false}
            color={TextAndIconColors.interfaceAccentSecondary}
            variant={TextVariants.body2}
            className={styles.common_margin__xs}
            href={'mailto:' + supportEmail}
          >
            {supportEmail}
          </Link>
          {'.'}
        </Text>
      </div>
    </div>
  );
};

export default EmailCodeModule;
