import React, { useLayoutEffect, useReducer } from 'react';

import { LoaderComponent } from '../../components/Loader';
import useHttp from '../../hooks/http';
import { useEmailCodeFlowStep } from '../../scenarios/steps';

const DeleteAccountSent: React.VFC = () => {
  const { ready } = useHttp();
  const { sendEmail } = useEmailCodeFlowStep();

  const [once, setOnce] = useReducer(() => true, false);

  useLayoutEffect(() => {
    if (ready('postSecurityFlow') && !once) {
      sendEmail();
      setOnce();
    }
  }, [once, ready, sendEmail]);

  return <LoaderComponent />;
};

export default DeleteAccountSent;
