import { CookieSerializeOptions } from 'cookie';

const Cookie = {
  setCookie(name: string, value: string, props: CookieSerializeOptions = {}): void {
    if (Cookie.cookieAvailable()) {
      const options: CookieSerializeOptions = {
        path: '/',
        ...props,
      };
      let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
      (Object.keys(options) as Array<keyof typeof options>).forEach((optionKey) => {
        updatedCookie = `${updatedCookie}; ${optionKey}`;
        const optionValue = options[optionKey];
        if (optionKey === 'expires' && optionValue !== true && optionValue && optionValue instanceof Date) {
          const expires = optionValue.toUTCString();
          updatedCookie = `${updatedCookie}=${expires}`;
        } else if (optionValue !== true) {
          updatedCookie = `${updatedCookie}=${optionValue}`;
        }
      });
      // eslint-disable-next-line unicorn/no-document-cookie
      window.document.cookie = updatedCookie;
    }
  },

  getCookie(name: string): string | undefined {
    if (Cookie.cookieAvailable()) {
      const matches = document.cookie.match(
        new RegExp(`(?:^|; )${name.replaceAll(/([$()*+./?[\\\]^{|}])/g, '\\$1')}=([^;]*)`),
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    return;
  },

  deleteCookie(name: string, props: CookieSerializeOptions = {}): void {
    if (Cookie.cookieAvailable()) {
      Cookie.setCookie(name, '', {
        maxAge: -1,
        path: '/',
        ...props,
      });
    }
  },

  cookieAvailable(): boolean {
    try {
      return 'cookie' in document;
    } catch {
      return false;
    }
  },
};

export default Cookie;
