import React, { useLayoutEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoaderComponent } from '../../components/Loader';
import { ROUTE_FULL_EMAIL_VIEW, ROUTE_SECURITY } from '../../constants/routes';
import useHttp from '../../hooks/http';
import useStore from '../../hooks/store';
import { EFlowType, useStartFlowStep } from '../../scenarios';
import { withSlash } from '../../utils/url';

const EmailVerifyDirect: React.VFC = () => {
  const { ready } = useHttp();
  const { startFlow } = useStartFlowStep();
  const { user } = useStore([]);
  const navigate = useNavigate();

  const [once, setOnce] = useReducer(() => true, false);

  useLayoutEffect(() => {
    if (ready('postSecurityFlow') && !once && user.id && !user.deleteRequestTimestamp && !user.emailVerified) {
      setOnce();
      startFlow(EFlowType.emailAdd);
    } else if (!once && user.id && user.emailVerified && !user.deleteRequestTimestamp) {
      setOnce();
      navigate(ROUTE_FULL_EMAIL_VIEW);
    } else if (!once && user.id && user.deleteRequestTimestamp) {
      setOnce();
      navigate(withSlash(ROUTE_SECURITY));
    }
  }, [navigate, once, ready, startFlow, user]);

  return <LoaderComponent />;
};

export default EmailVerifyDirect;
