import { TFunction } from 'i18next';
import { useTimer, useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AnswerDeletePhone } from '../../api/answer.types';
import { MODE_DEBUG } from '../../constants/mode';
import {
  ROUTE_FULL_DELETE_ACCOUNT_CONFIRM,
  ROUTE_FULL_EMAIL_EDIT,
  ROUTE_FULL_EMAIL_VERIFY,
  ROUTE_FULL_PASSWORD_ADD,
  ROUTE_FULL_PASSWORD_EDIT,
  ROUTE_FULL_PHONE_ADD,
  ROUTE_FULL_PHONE_REMOVE_COMPLETE,
  ROUTE_FULL_RESET_TOTP_CODE,
  ROUTE_FULL_RESET_TOTP_COMPLETE,
  ROUTE_FULL_SESSIONS_REMOVE_ALL,
  ROUTE_FULL_SESSIONS_REMOVE_PARTIAL,
  ROUTE_FULL_SOCIAL_ADD,
  ROUTE_FULL_SOCIAL_REMOVE,
  ROUTE_FULL_TOTP_REMOVE,
} from '../../constants/routes';
import AppContext from '../../context/AppContext';
import { useTime } from '../../hooks/helper.hook';
import useHttp from '../../hooks/http';
import usePrivatePages, { privatePagesSet } from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { sentryException } from '../../utils/sentry';
import { ALERT_GROUP_FLOW, TIMER_NAME_FLOW } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, EFlowType, IMapperFlow } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';

const usingPrivatePages: {
  [K in
    | 'deleteAccountConfirm'
    | 'emailEdit'
    | 'passwordAdd'
    | 'passwordEdit'
    | 'socialAdd'
    | 'socialRemove'
    | 'totpRemove'
    | 'sessionRemove'
    | 'sessionsRemove'
    | 'resetTotpCode'
    | 'resetTotpComplete'
    | 'addPhone'
    | 'removePhone'
    | 'emailVerify']: string;
} = {
  deleteAccountConfirm: ROUTE_FULL_DELETE_ACCOUNT_CONFIRM,
  emailEdit: ROUTE_FULL_EMAIL_EDIT,
  passwordAdd: ROUTE_FULL_PASSWORD_ADD,
  passwordEdit: ROUTE_FULL_PASSWORD_EDIT,
  socialAdd: ROUTE_FULL_SOCIAL_ADD,
  socialRemove: ROUTE_FULL_SOCIAL_REMOVE,
  totpRemove: ROUTE_FULL_TOTP_REMOVE,
  sessionRemove: ROUTE_FULL_SESSIONS_REMOVE_PARTIAL,
  sessionsRemove: ROUTE_FULL_SESSIONS_REMOVE_ALL,
  resetTotpCode: ROUTE_FULL_RESET_TOTP_CODE,
  resetTotpComplete: ROUTE_FULL_RESET_TOTP_COMPLETE,
  addPhone: ROUTE_FULL_PHONE_ADD,
  removePhone: ROUTE_FULL_PHONE_REMOVE_COMPLETE,
  emailVerify: ROUTE_FULL_EMAIL_VERIFY,
  // addPhoneCode: ROUTE_FULL_PHONE_ADD_CODE,
};

export const mapperFlowReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useMapperFlow = (): IMapperFlow => {
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { alert, clear } = useToast();
  const time = useTime();
  const { setTimer, cancelTimer } = useTimer();
  const { mode } = useContext(AppContext);
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();

  // FIXME: костыль
  const { ready, requestByName } = useHttp();
  const { update } = useStore([]);
  const removePhone = useCallback(async () => {
    const token = flowsData.getToken(EFlowsDataStep.flow);
    if (ready('deletePhone') && token) {
      const fetched = (await requestByName('deletePhone', {
        actionToken: token,
      })) as AnswerDeletePhone;
      if (isObject(fetched) && fetched.success) {
        update('user');
        setPage(usingPrivatePages.removePhone, { available: true });
        navigate(usingPrivatePages.removePhone);
      } else sentryException('useMapperFlow hook: removePhone error when remove', 'warning');
    } else sentryException('useMapperFlow hook: removePhone api is not ready', 'warning');
  }, [navigate, ready, requestByName, setPage, update]);

  const onExpiredFlow = useCallback(async () => {
    alert({
      type: 'warning',
      titleData: {
        key: 'message.warningExpirationTitle',
      },
      textData: {
        key: 'message.warningExpirationDescription',
      },
      sticky: true,
    });
    if (mode !== MODE_DEBUG) await resetNavigate(() => setPages(false));
  }, [alert, mode, resetNavigate, setPages]);
  const startTimer = useCallback(
    (expired: number) => {
      alert({
        type: 'info',
        textData: {
          key: 'message.infoExpiration',
          options: { time: (fn: TFunction<'translation', undefined>) => time(expired, { short: true, fn }) },
        },
        group: ALERT_GROUP_FLOW,
      });
      setTimer(expired, { name: TIMER_NAME_FLOW, callback: onExpiredFlow, autoFinish: true });
    },
    [alert, onExpiredFlow, setTimer, time],
  );
  const goFlow = useCallback(
    (type: EFlowType, expired?: number) => {
      switch (type) {
        case EFlowType.deleteAccount: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.deleteAccountConfirm, { available: true });
          navigate(usingPrivatePages.deleteAccountConfirm);
          break;
        }
        case EFlowType.emailChange: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.emailEdit, { available: true });
          navigate(usingPrivatePages.emailEdit);
          break;
        }
        case EFlowType.emailAdd: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.emailVerify, { available: true });
          navigate(usingPrivatePages.emailVerify);
          break;
        }
        case EFlowType.passwordAdd: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.passwordAdd, { available: true });
          navigate(usingPrivatePages.passwordAdd);
          break;
        }
        case EFlowType.passwordChange: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.passwordEdit, { available: true });
          navigate(usingPrivatePages.passwordEdit);
          break;
        }
        case EFlowType.addSocial: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.socialAdd, { available: true });
          navigate(usingPrivatePages.socialAdd);
          break;
        }
        case EFlowType.deleteSocial: {
          setPage(usingPrivatePages.socialRemove, { available: true });
          navigate(usingPrivatePages.socialRemove);
          break;
        }
        case EFlowType.deleteTotp: {
          setPage(usingPrivatePages.totpRemove, { available: true });
          navigate(usingPrivatePages.totpRemove);
          break;
        }
        case EFlowType.deleteSession: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.sessionRemove, { available: true });
          navigate(usingPrivatePages.sessionRemove);
          break;
        }
        case EFlowType.deleteSessions: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.sessionsRemove, { available: true });
          navigate(usingPrivatePages.sessionsRemove);
          break;
        }
        case EFlowType.resetTotp: {
          break;
        }
        case EFlowType.addPhone:
        case EFlowType.verifyPhone: {
          if (expired) startTimer(expired);
          setPage(usingPrivatePages.addPhone, { available: true });
          navigate(usingPrivatePages.addPhone);
          break;
        }
        case EFlowType.removePhone: {
          removePhone();
          break;
        }
        default: {
          navigate(flowsData.getBasePage());
          console.error('useMapperFlow не может найти следующий flow');
          sentryException('useMapperFlow hook: goFlow for next flow undefined', 'warning');
          break;
        }
      }
    },
    [navigate, removePhone, setPage, startTimer],
  );

  // Вызывается при прохождении вперед/назад
  const clearFlow = useCallback(() => {
    setPages(false);
    cancelTimer(TIMER_NAME_FLOW);
    flowsData.setToken(EFlowsDataStep.flow, null);
    clear(ALERT_GROUP_FLOW);
  }, [cancelTimer, clear, setPages]);

  return { goFlow, clearFlow };
};

export default useMapperFlow;
