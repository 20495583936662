import {
  Button,
  ButtonThemes,
  ButtonTypes,
  Icon,
  IconSizes,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { useLoader } from 'library-react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconShieldProtected } from '../../assets/icons/shieldProtected.svg';
import { handleOn } from './Totp.functions';
import styles from './Totp.module.scss';

const TotpOff: React.VFC = () => {
  const { t } = useTranslation();
  const { loaderOn } = useLoader();

  return (
    <div className={classNames(styles.page_block)}>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary}>
        {t('page.totp.description.common')}
      </Text>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__s}>
        {t('page.totp.description.add')}
      </Text>
      <div
        className={classNames(
          styles.page_block,
          styles.page_block__border,
          styles.this_wrapperBlock,
          styles.common_margin__m,
        )}
      >
        <div className={styles.page_iconBlock}>
          <Icon
            IconComponent={IconShieldProtected}
            size={IconSizes.xxl}
            color={TextAndIconColors.actionConfirmSecondary}
          />
          <div className={styles.page_block}>
            <Text variant={TextVariants.body3} color={TextAndIconColors.labelsTertiary}>
              {t('page.totp.title') + ' '}
            </Text>
            <Text variant={TextVariants.body3} color={TextAndIconColors.actionErrorSecondary}>
              {t('status.off').toLowerCase() + '.'}
            </Text>
          </div>
        </div>
        <Button
          type={ButtonTypes.button}
          text={t('action.turnOn').toString()}
          onClick={() => handleOn(loaderOn)}
          className={classNames(styles.common_margin__xs, styles.this_button)}
          theme={ButtonThemes.actionConfirmSecondary}
        />
      </div>
    </div>
  );
};

export default TotpOff;
