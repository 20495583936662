export const separationByQuantity = <T, C = T>(array: Array<T>, quantity: number, complement?: C): (T | C)[][] => {
  const updated: (T | C)[][] = [];
  const count = Number.parseInt((array.length / quantity).toString());

  for (let i = 0; i < count; i++) {
    updated.push(array.slice(i * quantity, i * quantity + quantity));
  }

  if (count * quantity < array.length) {
    updated.push(array.slice(count * quantity));
  }

  if (complement !== undefined) {
    const lack = quantity - (updated.at(-1)?.length || 0);
    for (let i = 0; i < lack; i++) {
      updated.at(-1)?.push(complement);
    }
  }
  return updated;
};
