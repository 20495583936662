import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInputThemes,
  Input,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '../../components/Avatar';
import {
  API_ERROR_RECOVERY_TOTP_USER_CHECK_CODES_RATE_LIMIT,
  API_ERROR_RECOVERY_TOTP_WRONG_CODES,
} from '../../constants/api';
import Form, { FormProps } from '../../containers/Form/Form';
import AppContext from '../../context/AppContext';
import { useTime } from '../../hooks/helper.hook';
import useInput from '../../hooks/input.hook';
import useFlowResetTotp from '../../scenarios/flows/flowResetTotp.hook';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import styles from './ResetTotpCode.module.scss';

const mockedLabel = 'My Device';
const mockedEmail = 'm*****@mail.ru';

const removeSpaces = (value: string) => value.replaceAll(' ', '');

const ResetTotpCode: React.VFC = () => {
  const { t } = useTranslation();
  const { sendCodes, email, label, reset, error } = useFlowResetTotp();
  const time = useTime();
  const { config } = React.useContext(AppContext);

  const [code, changeCode] = useInput<string>('', { convertVal: removeSpaces });
  const [key, changeKey] = useInput<string>('', { convertVal: removeSpaces });

  const submit = useCallback(() => {
    ga4Event('resetTotpCodeClick', { placeholders: { element: 'next' } });
    sendCodes(key, code);
  }, [code, key, sendCodes]);
  const back = useCallback(async () => {
    ga4Event('resetTotpCodeClick', { placeholders: { element: 'back' } });
    await reset();
  }, [reset]);

  const notification: FormProps['notification'] = useMemo(() => {
    if (
      error?.code === API_ERROR_RECOVERY_TOTP_WRONG_CODES ||
      error?.code === API_ERROR_RECOVERY_TOTP_USER_CHECK_CODES_RATE_LIMIT
    ) {
      window.scrollTo(0, 0);
      return { title: t(error.title), description: error.text ? t(error.text) : undefined };
    } else return;
  }, [error, t]);

  useEffect(() => {
    if (notification) {
      changeCode('');
      changeKey('');
    }
  }, [changeCode, changeKey, notification]);

  const buttonDisabled: boolean = code.length !== 6 || key.length !== 8;
  useEffect(() => {
    const listener = listenKeydown('Enter', submit, buttonDisabled);
    return () => {
      listener();
    };
  }, [buttonDisabled, sendCodes, submit]);

  return (
    <Form supportEmail={config.supportEmail} user={false} notification={notification} logoClickable={false}>
      <div className={styles.form_wrapper}>
        <div className={styles.form_head}>
          <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary}>
            {t('page.resetTotp.title')}
          </Text>
        </div>
        <Avatar email={email || mockedEmail} className={styles.common_margin__l} />
        <Text
          variant={TextVariants.body2}
          color={TextAndIconColors.labelsSecondary}
          className={styles.common_margin__l}
        >
          {t('page.resetTotpCode.description', { deviceName: label || mockedLabel })}
        </Text>
        <div className={classNames(styles.this_step, styles.common_margin__l)}>
          <Text variant={TextVariants.header7} color={TextAndIconColors.labelsSecondary}>
            {t('helper.step', { step: '1.' }) + ' '}
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsSecondary}>
              {t('page.resetTotpCode.step1')}
            </Text>
          </Text>
          <Input
            value={code}
            onChange={changeCode}
            className={classNames(styles.this_nativePlaceholder, styles.common_margin__s)}
            placeholder={'123456'}
            theme={EInputThemes.base}
            nativePlaceholder
          />
          <Text
            variant={TextVariants.body2}
            color={TextAndIconColors.labelsQuaternary}
            className={styles.common_margin__s}
          >
            {t('page.resetTotpCode.codeExpiration', { time: time(5 * 60, { short: true }) })}
          </Text>
        </div>
        <div className={classNames(styles.this_step, styles.common_margin__m)}>
          <Text variant={TextVariants.header7} color={TextAndIconColors.labelsSecondary}>
            {t('helper.step', { step: '2.' }) + ' '}
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsSecondary}>
              {t('page.resetTotpCode.step2')}
            </Text>
          </Text>
          <Input
            value={key}
            onChange={changeKey}
            className={classNames(styles.this_nativePlaceholder, styles.common_margin__s)}
            placeholder={'C69DA65B'}
            theme={EInputThemes.base}
            nativePlaceholder
          />
        </div>
        <div className={styles.common_buttons}>
          <Button
            text={t('action.back').toString()}
            type={ButtonTypes.button}
            onClick={back}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.labelsSecondary}
          />
          <Button
            text={t('action.next').toString()}
            type={ButtonTypes.button}
            onClick={submit}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </Form>
  );
};

export default ResetTotpCode;
