import { LocaleE } from '../constants/locale.types';
import { IStore } from '../hooks/store';

export const arrToStr = (arr?: string[] | string, splitter = ', '): string => {
  if (!arr) return '';
  if (typeof arr === 'string') return arr;
  return arr.join(splitter);
};

export const emailMask = (email: string | undefined): string => {
  if (!email) return '';
  const [emailUsername, emailDomain] = email.split('@');
  return `${emailUsername?.[0] || ''}****@${emailDomain || ''}`;
};

export const getNameStr = (user: IStore['user'], locale: LocaleE): string => {
  const fullName: string[] = [];
  if (locale === LocaleE.ru && user.lastName) fullName.push(user.lastName);
  if (user.firstName) fullName.push(user.firstName);
  if (user.middleName) fullName.push(user.middleName);
  if (locale !== LocaleE.ru && user.lastName) fullName.push(user.lastName);
  return arrToStr(fullName, ' ');
};

export const getFirstItem = (arr?: string[] | string): string => {
  if (!arr) return '';
  if (typeof arr === 'string') return arr;
  return arr[0];
};

export const toUpperFirst = (str?: string): string => {
  if (!str) return '';
  return str[0].toUpperCase() + str.slice(1);
};

export const splitByQuantity = (str: string, quantity: number, splitter: string): string => {
  return [...str].reduce(
    (result, item, id) => (id !== 0 && id % quantity === 0 ? result + splitter + item : result + item),
    '',
  );
};

export const phoneMask = (phone: string): string => {
  const first = phone.indexOf('*');
  const last = phone.lastIndexOf('*');
  if (first < 0) return phone;
  return arrToStr(
    [phone.slice(0, first), splitByQuantity(phone.slice(first, last + 1), 3, ' '), phone.slice(last + 1)],
    ' ',
  ).trim();
};

/**
 * @function Replace placeholders
 * @param content
 * @param placeholders
 * @param startTag
 * @param endTag
 */
export const replacer = (
  content: string,
  placeholders: Record<string, unknown>,
  startTag = '{{',
  endTag = '}}',
): string => {
  if (!content || Object.keys(placeholders).length === 0) return content;

  const fileContent = content.split(/\n/);
  Object.keys(placeholders).forEach((key) => {
    const replace = `${startTag}${key}${endTag}`;
    const value = placeholders[key];
    const replaceValue: string = typeof value === 'string' ? value : JSON.stringify(value);
    fileContent.forEach((item, index) => {
      fileContent[index] = item.replaceAll(replace, replaceValue);
    });
  });
  return fileContent.reduce(
    (prev, item, index, array) => (index === array.length - 1 ? prev + item : prev + item + '\n'),
    '',
  );
};
