import { decodeJwt } from 'jose';
import { useTimer } from 'library-react-hooks';
import { useCallback, useMemo, useState } from 'react';

import { sentryException } from '../../utils/sentry';
import { keycloakInit } from './keycloak.functions';
import { IUseKeycloak, TKProfile } from './keycloak.types';

const timerName = 'keycloakUpdateToken';

const useKeycloak = ({ mocked }: { mocked: boolean }): IUseKeycloak => {
  const { setTimer } = useTimer();

  const keycloak = useMemo(() => keycloakInit(mocked), [mocked]);
  const [kToken, setKToken] = useState<string | null | boolean>(null);
  const [kProfile, setKProfile] = useState<null | TKProfile>(null);

  const tokenUpdate = useCallback(
    (status: boolean) => {
      const token: boolean | string = status ? keycloak.token || false : false;
      setKToken(token);

      if (typeof token === 'string') {
        const jwtToken = decodeJwt(token) as TKProfile;
        setKProfile(jwtToken);
        const time = jwtToken.exp - Math.floor(Date.now() / 1000);

        setTimer(time, {
          name: timerName,
          autoFinish: true,
          callback: () =>
            keycloak
              .updateToken(-1)
              .then((kRefreshed) => {
                // console.log('kRefreshed');
                tokenUpdate(kRefreshed);
              })
              .catch((error_: any) => {
                tokenUpdate(false);
                console.error(error_?.message || 'Failed to kRefresh the token, or the session has expired');
                sentryException('Keycloak hook: failed refresh the token, or the session has expired', 'warning');
              }),
        });
      } else sentryException('Keycloak hook: typeof token is not a string', 'warning');
    },
    [keycloak, setTimer],
  );

  const kLogin = useCallback(async () => {
    await keycloak
      .init({
        pkceMethod: 'S256',
        onLoad: 'login-required',
        checkLoginIframe: false,
      })
      .then(async function (authenticated) {
        tokenUpdate(authenticated);
      })
      .catch((error_: any) => {
        tokenUpdate(false);
        console.error(error_?.message || 'Failed to initialize in Keycloak');
        sentryException('Keycloak hook: failed to initialize in Keycloak', 'warning');
      });
  }, [keycloak, tokenUpdate]);
  // const kLogin = useCallback(async () => {
  //   await keycloak.login({ redirectUri: process.env.REACT_APP_URL, locale: LOCALE_DEFAULT });
  // }, []);

  const kLogout = useCallback(
    async (redirectUri?: string) => {
      keycloak.idToken = '';
      await keycloak.logout({ redirectUri });
      setKToken(null);
      setKProfile(null);
    },
    [keycloak],
  );
  const kRefresh = useCallback(async () => {
    setKProfile(null);
    await keycloak
      .updateToken(-1)
      .then((kRefreshed) => {
        tokenUpdate(kRefreshed);
      })
      .catch((error_: any) => {
        tokenUpdate(false);
        console.error(error_?.message || 'Failed to kRefresh the token, or the session has expired');
        sentryException('Keycloak hook: failed refresh the token, or the session has expired', 'warning');
      });
  }, [keycloak, tokenUpdate]);

  return { kToken, kLogout, kLogin, kProfile, kRefresh };
};

export default useKeycloak;
