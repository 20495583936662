import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { API_RECAPTCHA_ACTION_PHONE, API_RECAPTCHA_ACTION_TOTP } from '../../constants/api';
import { headAppend } from '../../utils/documentHtml';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
const SCRIPT_ID = 'google-recaptcha-v3';

const append = (lang: string) => {
  headAppend(`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}&hl=${lang}`, false, false, {
    id: 'google-recaptcha-v3',
  });
};

// https://www.npmjs.com/package/react-google-recaptcha-v3
export const GoogleReCaptcha: React.VFC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const script = document.querySelector(`#${SCRIPT_ID}`);
    if (script) {
      const src = script.getAttribute('src');
      const lang = src?.split('hl=')[1];
      if (lang === i18n.language) return;

      // grecaptcha.reset();
      // script.remove();
      // if (window.Worker) {
      //   const myWorker = new Worker("webworker.js");
      //   console.log('myWorker', myWorker);
      //   // myWorker.terminate();
      // }

      // const component = document.querySelector('.grecaptcha-badge');
      // if (component) component.remove();

      // append(i18n.language);
    } else {
      append(i18n.language);
    }

    return () => {
      const script2 = document.querySelector(`#${SCRIPT_ID}`);
      if (script2) script2.remove();
      const component = document.querySelector('.grecaptcha-badge');
      if (component) component.remove();
    };
  }, [i18n.language]);

  return null;
};

export const ReCapthaExecute = (callback: (token: string) => void, type: 'totp' | 'phone') => {
  let action = API_RECAPTCHA_ACTION_TOTP;
  if (type === 'phone') action = API_RECAPTCHA_ACTION_PHONE;
  grecaptcha.ready(function () {
    grecaptcha.execute(RECAPTCHA_KEY, { action }).then(function (token) {
      callback(token);
    });
  });
};
