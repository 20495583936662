import { Button, ButtonThemes, ButtonVariant, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import notFoundImage from '../../assets/images/notFound.png';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import Page from '../../containers/Page';
import { sentryException } from '../../utils/sentry';
import { withSlash } from '../../utils/url';
import styles from './ErrorNotFound.module.scss';

/**
 * Error 404
 */
const ErrorNotFound: React.FC = () => {
  const navigator = useNavigate();
  const { t } = useTranslation();
  // const { images } = useImagePreloader();

  useEffect(() => {
    sentryException('Not Found page', 'info');
  }, []);

  return (
    <Page>
      <div className={classNames(styles.page_wrapper, styles.this_pageWrapper)}>
        <div className={styles.this_wrapper}>
          <div className={styles.this_content}>
            <Text variant={TextVariants.head0} color={TextAndIconColors.labelsPrimary} className={styles.this_header}>
              {t('page.errorNotFound.title')}
            </Text>
            <Text
              variant={TextVariants.body1}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('page.errorNotFound.description2')}
            </Text>
            <Button
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
              className={styles.common_margin__l}
              onClick={() => navigator(withSlash(ROUTE_PERSONAL_DATA))}
            >
              {t('action.toMain')}
            </Button>
          </div>
          {/*<img className={styles.page_image} src={images['notFound.png']?.base64} alt='notFoundImage' />*/}
          <img className={styles.page_image} src={notFoundImage} alt='notFoundImage' />
        </div>
      </div>
    </Page>
  );
};

export default ErrorNotFound;
