import { useOnceEffect } from 'library-react-hooks';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoaderComponent } from '../../components/Loader';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import AuthContext from '../../context/AuthContext';
import { withSlash } from '../../utils/url';

const Logout: React.VFC = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useOnceEffect(() => {
    const passportLastPath = window.passportLastPath;
    if (passportLastPath) {
      logout(`${process.env.REACT_APP_URL}${passportLastPath}`);
    } else navigate(withSlash(ROUTE_PERSONAL_DATA));
  }, [logout, navigate]);

  return <LoaderComponent />;
};

export default Logout;
