import { Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import { useWindowWidth } from '@react-hook/window-size';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IConfigProduct } from '../../core';
import { i18link } from '../../locales/formatters';
import styles from './DeleteAccount.module.scss';
import ServiceItem from './ServiceItem';

export type TPartialDeleteProps = {
  className?: string;
  products: IConfigProduct[];
};
const PartialDelete: React.VFC<TPartialDeleteProps> = ({ className, products }) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  return (
    <div className={classNames(styles.page_block, styles.page_block__border, className)}>
      <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
        {t('page.accountActions.partial.subTitle')}
      </Text>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__xs}>
        {t('page.accountActions.partial.description1')}
      </Text>
      <Text variant={TextVariants.body2} color={TextAndIconColors.labelsTertiary} className={styles.common_margin__s}>
        {t('page.accountActions.partial.description2')}
      </Text>
      {products.length > 0 && (
        <div className={classNames(styles.page_block, styles.common_margin__l)}>
          {products
            .filter((item) => item.deletingGuide)
            .map(({ name, deletingGuide, icon }, id) => (
              <ServiceItem
                key={id}
                name={t(name)}
                link={i18link(deletingGuide!)}
                linkText={t('helper.guide')}
                className={classNames({
                  [styles.common_margin__xs]: id !== 0,
                })}
                isMobile={windowWidth <= 420}
                icon={icon}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default PartialDelete;
