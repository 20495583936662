import { ELoaderSizes, Loader as UiLoader } from '@design-system/ui-kit';
import classNames from 'classnames';
import { useLoader } from 'library-react-hooks';
import React from 'react';

import styles from './Loader.module.scss';

type TLoaderComponentProps = { active?: boolean };
export const LoaderComponent: React.VFC<TLoaderComponentProps> = ({ active = true }) => (
  <div
    className={classNames(styles.backdrop, {
      [styles.backdrop_active]: active,
    })}
  >
    <div className={classNames(styles.root)}>
      <UiLoader size={ELoaderSizes.l} inline={false} noMargin={false} id={'m-id-loader'} />
    </div>
  </div>
);

const Loader: React.VFC = () => {
  const { on } = useLoader();
  const [loading, setLoading] = React.useState<boolean>(false);
  // useEffectOnLoader((_event, newState) => {
  //   console.log('useEffectOnLoader', newState);
  //   setLoading(newState.active);
  // }, []);
  React.useEffect(() => {
    const clear = on((e) => {
      setLoading(e);
    });
    return () => clear();
  }, [on]);

  // if (!loading) return null;

  return <LoaderComponent active={loading} />;
};

export default React.memo(Loader);
