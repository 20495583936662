import React, { useLayoutEffect, useReducer } from 'react';

import { LoaderComponent } from '../../components/Loader';
import useHttp from '../../hooks/http';
import useStore from '../../hooks/store';
import { EFlowType, useStartFlowStep } from '../../scenarios';

const PasswordEditDirect: React.VFC = () => {
  const { ready } = useHttp();
  const { credentials } = useStore(['credentials']);
  const { startFlow } = useStartFlowStep();

  const [once, setOnce] = useReducer(() => true, false);

  useLayoutEffect(() => {
    if (ready('postSecurityFlow') && !once && credentials.password) {
      startFlow(credentials.password.enabled ? EFlowType.passwordChange : EFlowType.passwordAdd);
      setOnce();
    }
  }, [credentials.password, once, ready, startFlow]);

  return <LoaderComponent />;
};

export default PasswordEditDirect;
