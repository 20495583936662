import { ReactNode } from 'react';

export enum ETableIcon {
  open = 'open',
  delete = 'delete',
}

export enum ETableAction {
  row = 'row',
  icon = 'icon',
}

export interface ITableList {
  data: Record<string, ReactNode | string>;
  action?: () => void;
  hide?: boolean;
}

export type TableProps = {
  /**
   * Список строк. Для клика по всей строке может быть передано action.
   * Например: [{ data: { '1': 'string', '2': 'string' }, action: () => openLink(...someLink) }]
   * Если нулевая колонка - иконки, лучше назвать текстом [data: { 'icon': ReactNode, '1': 'string', '2': 'string' }]
   * '0' как название работает некорректно
   */
  list: ITableList[];

  /**
   * Перечень имен столбцов. (Нужно для порядка)
   */
  columns: string[];

  /**
   * Правила стилей для столбцов по ключам из list. Например: { '1': class, '2': class }
   */
  styleRules?: Record<string, string>;

  /**
   * Разделительные линиии в таблице
   */
  divider?: boolean;

  /**
   * Подсвечивать ли строку при наведении курсора
   */
  rowHover?: boolean;

  /**
   * Дополнительные классы для управляющего компонента
   */
  className?: string;

  /**
   * Дополнительные классы для ячеек
   */
  classNameCells?: string;

  /**
   * Иконка действия для последнего столбца
   */
  endIcon?: ETableIcon;

  /**
   * Если в строке есть возможность клика, то нужно выбрать клик по иконке или по всей строке
   */
  actionType?: ETableAction;
};

export type TEndIcon = {
  type: TableProps['endIcon'];
  action?: () => void;
  active: boolean;
};
