import { Button, ButtonThemes, ButtonVariant, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import error50xImage from '../../assets/images/errors50x.png';
import Page from '../../containers/Page';
import { sentryException } from '../../utils/sentry';
import styles from './ErrorInternalServer.module.scss';

/**
 * Error 500 - 5**
 */
const ErrorInternalServer: React.FC<{ code?: number }> = ({ code }) => {
  const { t } = useTranslation();
  // const { images } = useImagePreloader();

  const errorCode: number = useMemo(() => {
    return code && Number(code) >= 500 && Number(code) < 600 ? Number(code) : 500;
  }, [code]);

  useEffect(() => {
    sentryException('Internal Server Error page', 'warning');
  }, []);

  return (
    <Page>
      <div className={classNames(styles.page_wrapper, styles.this_pageWrapper)}>
        <div className={styles.this_wrapper}>
          <div className={styles.this_content}>
            <Text variant={TextVariants.head0} color={TextAndIconColors.labelsPrimary} className={styles.this_header}>
              {t('page.errorCommon.title', { errorCode })}
            </Text>
            <Text
              variant={TextVariants.body1}
              color={TextAndIconColors.labelsTertiary}
              className={styles.common_margin__xs}
            >
              {t('page.errorCommon.description')}
            </Text>
            <Button
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
              className={styles.common_margin__l}
              onClick={() => location.reload()}
            >
              {t('action.reload')}
            </Button>
          </div>
          {/*<img className={styles.page_image} src={images['errors50x.png']?.base64} alt='internalServerErrorImage' />*/}
          <img className={styles.page_image} src={error50xImage} alt='internalServerErrorImage' />
        </div>
      </div>
    </Page>
  );
};

export default ErrorInternalServer;
