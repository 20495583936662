const VERSION = 'v0';

export const GET_USER_URL = `${process.env.REACT_APP_API_URL}/${VERSION}/profile`;
export const GET_FULFILL_URL = `${process.env.REACT_APP_API_URL}/${VERSION}/profile/fulfill-profile`;
export const GET_CREDENTIALS = `${process.env.REACT_APP_API_URL}/${VERSION}/security/credentials`;
export const GET_AVAILABLE_PROVIDERS = `${process.env.REACT_APP_API_URL}/${VERSION}/profile/available-identity-providers`;
export const GET_SESSIONS = `${process.env.REACT_APP_API_URL}/${VERSION}/security/sessions`;
export const GET_LOGIN_HISTORY = `${process.env.REACT_APP_API_URL}/${VERSION}/security/login-history`;
export const GET_TOTP_ATTEMPT = `${process.env.REACT_APP_API_URL}/${VERSION}/recovery/request-totp-attempt`;

export const POST_FULFILL_URL = `${process.env.REACT_APP_API_URL}/${VERSION}/profile/fulfill`;
export const POST_CONFIRM_EMAIL = `${process.env.REACT_APP_API_URL}/${VERSION}/security/confirm-email`;
export const POST_CONFIRM_PHONE = `${process.env.REACT_APP_API_URL}/${VERSION}/security/confirm-phone`;
// export const POST_CONFIRM_DELETE_SERVICE = `${process.env.REACT_APP_API_URL}/${VERSION}/security/confirm-delete-from-service`;
export const POST_SECURITY_FLOW = `${process.env.REACT_APP_API_URL}/${VERSION}/security/flow`;
export const POST_SOCIAL_LINK = `${process.env.REACT_APP_API_URL}/${VERSION}/profile/social-link`;
export const POST_REMOVE_TOTP = `${process.env.REACT_APP_API_URL}/${VERSION}/recovery/remove-totp`;
export const POST_CONFIRM_ADD_EMAIL = `${process.env.REACT_APP_API_URL}/${VERSION}/security/confirm-add-email`;

export const DELETE_TOTP_CREDENTIAL = `${process.env.REACT_APP_API_URL}/${VERSION}/security/totp-credential`;
export const DELETE_SOCIAL_LINK = `${process.env.REACT_APP_API_URL}/${VERSION}/profile/social-link`;
export const DELETE_ACCOUNT = `${process.env.REACT_APP_API_URL}/${VERSION}/security/delete-account`;
export const DELETE_PHONE = `${process.env.REACT_APP_API_URL}/${VERSION}/security/phone`;
export const DELETE_SESSION = `${process.env.REACT_APP_API_URL}/${VERSION}/security/session`;
export const DELETE_SESSIONS = `${process.env.REACT_APP_API_URL}/${VERSION}/security/sessions`;
// export const DELETE_SERVICE = `${process.env.REACT_APP_API_URL}/${VERSION}/security/delete-from-service`;

export const PUT_LOCALE = `${process.env.REACT_APP_API_URL}/${VERSION}/profile/locale`;
export const PUT_UPDATE_EMAIL = `${process.env.REACT_APP_API_URL}/${VERSION}/profile/email`; // удалить
export const PUT_EMAIL = `${process.env.REACT_APP_API_URL}/${VERSION}/security/email`;
export const PUT_PHONE = `${process.env.REACT_APP_API_URL}/${VERSION}/security/phone`; // + капча
export const PUT_PASSWORD = `${process.env.REACT_APP_API_URL}/${VERSION}/security/password`;
export const PUT_ADD_EMAIL = `${process.env.REACT_APP_API_URL}/${VERSION}/security/add-email`;

export const PATCH_PROFILE = `${process.env.REACT_APP_API_URL}/${VERSION}/profile`;
export const PATCH_CONTACTS = `${process.env.REACT_APP_API_URL}/${VERSION}/profile/contacts`;
