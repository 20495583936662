import { Icon, IconSizes, TextAndIconColors } from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';

import { ReactComponent as ArrowRight } from '../../assets/icons/arrowRight.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import styles from './Table.module.scss';
import { ETableAction, ETableIcon, TableProps, TEndIcon } from './Table.types';

const EndIcon: React.FC<TEndIcon> = ({ type, action, active }: TEndIcon) => {
  if (!active) return null;
  switch (type) {
    case ETableIcon.open: {
      return <Icon IconComponent={ArrowRight} size={IconSizes.l} className={styles.icon} onClick={action} />;
    }
    case ETableIcon.delete: {
      return (
        <Icon
          IconComponent={Cross}
          size={IconSizes.l}
          color={TextAndIconColors.interfacePrimary}
          className={styles.icon}
          onClick={action}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const Table: React.FC<TableProps> = ({
  list,
  styleRules,
  divider,
  rowHover,
  className,
  endIcon,
  actionType,
  columns,
  classNameCells,
}: TableProps) => (
  <div className={classNames(styles.list, className)}>
    {list
      .filter((item) => !item.hide)
      .map(({ data, action = () => {} }, id) => (
        <div
          key={id}
          onClick={() => actionType === ETableAction.row && action()}
          className={classNames(styles.row, {
            [styles.row_hover]: rowHover,
            [styles.row_action]: !!list[id].action && actionType === ETableAction.row,
            [styles.row_divider]: id > 0 && divider,
          })}
        >
          <div className={classNames(styles.cells, classNameCells)}>
            {columns.map((key) =>
              data[key] ? (
                <div key={key} className={styleRules?.[key]}>
                  {data[key]}
                </div>
              ) : null,
            )}
          </div>
          <EndIcon
            type={endIcon}
            action={() => actionType === ETableAction.icon && action()}
            active={!!list[id].action}
          />
        </div>
      ))}
  </div>
);

export default React.memo(Table);
