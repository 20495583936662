import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../containers/Page/Page';
import AppContext from '../../context/AppContext';
import useStore from '../../hooks/store';
import { TSetErrorCode } from '../../modules/EmailCodeModule';
import EmailCodeModule from '../../modules/EmailCodeModule/EmailCodeModule';
import { useEmailCodeFlowStep } from '../../scenarios/steps';
import { validationEmailCode } from '../../utils/validation';

const DeleteAccountCode: React.VFC = () => {
  const { t } = useTranslation();
  const { user } = useStore([]);
  const { confirmCode, error, reset } = useEmailCodeFlowStep();
  const { config } = React.useContext(AppContext);

  const sendCode = useCallback(
    async (code: string, setErrorCode: TSetErrorCode) => {
      let valid = true;
      if (!validationEmailCode(code)) {
        valid = false;
        setErrorCode('message.errorValidationEmailCode');
      }

      if (valid) {
        await confirmCode(code);
      }
    },
    [confirmCode],
  );

  return (
    <Page menu subHead={{ title: t('page.deleteAccount.title') }} callback={reset}>
      <EmailCodeModule
        descriptionText={t('page.deleteAccountCode.description')}
        titleText={t('page.deleteAccountCode.blockTitle', { email: user.email })}
        subTitleText={t('page.deleteAccountCode.subTitle', { email: user.email })}
        error={error}
        onCancel={reset}
        onNext={sendCode}
        supportEmail={config.supportEmail}
      />
    </Page>
  );
};

export default DeleteAccountCode;
