import { TFunction } from 'i18next';
import { useTimer, useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback, useMemo } from 'react';

import { AnswerDeleteSocialLink, AnswerPostSocialLink } from '../../api/answer.types';
import { SocialTypesE } from '../../components/Social';
import { ROUTE_FULL_SOCIAL_ADD, ROUTE_FULL_SOCIAL_REMOVE } from '../../constants/routes';
import useHttp from '../../hooks/http';
import { privatePagesSet } from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { sentryException } from '../../utils/sentry';
import { openLink } from '../../utils/url';
import { ALERT_GROUP_FLOW, TIMER_NAME_FLOW } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, IFlowSocial } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';
import { resetSecuritySteps } from '../resetFlows';

const usingPrivatePages: { [K in 'add' | 'remove']: string } = {
  add: ROUTE_FULL_SOCIAL_ADD,
  remove: ROUTE_FULL_SOCIAL_REMOVE,
};

export const flowSocialReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useFlowSocial = (): IFlowSocial => {
  const { requestByName, ready } = useHttp();
  const { update } = useStore([]);
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();
  const { alert, clear } = useToast();
  const { cancelTimer } = useTimer();

  const socialName = useMemo(() => flowsData.getProp('socialName'), []);

  const reset = useCallback(async () => {
    resetSecuritySteps();
    cancelTimer(TIMER_NAME_FLOW);
    clear(ALERT_GROUP_FLOW);
    await resetNavigate(() => setPages(false));
  }, [cancelTimer, clear, resetNavigate, setPages]);

  const actionToContinueAdd = useCallback(async () => {
    const token = flowsData.getToken(EFlowsDataStep.flow);
    if (ready('postSocialLink') && token) {
      const fetched = (await requestByName('postSocialLink', {
        actionToken: token,
        idp: socialName as SocialTypesE,
      })) as AnswerPostSocialLink;
      if (isObject(fetched) && fetched.redirectUrl) {
        openLink(fetched.redirectUrl);
      }
    } else sentryException('FlowSocial hook: postSocialLink api is not ready', 'warning');
  }, [ready, requestByName, socialName]);

  const actionToContinueRemove = useCallback(async () => {
    const token = flowsData.getToken(EFlowsDataStep.flow);
    if (ready('deleteSocialLink') && token) {
      const fetched = (await requestByName('deleteSocialLink', {
        idp: socialName as SocialTypesE,
        actionToken: token,
      })) as AnswerDeleteSocialLink;
      if (isObject(fetched) && fetched.success) {
        await update('user');
        alert({
          type: 'success',
          textData: {
            key: 'message.successSocialRemove',
            options: { socialName: (fn: TFunction<'translation', undefined>) => fn(`social.${socialName}`) },
          },
        });
      }
      await reset();
    } else sentryException('FlowSocial hook: deleteSocialLink api is not ready', 'warning');
  }, [ready, requestByName, socialName, reset, update, alert]);

  return { reset, actionToContinueAdd, actionToContinueRemove, socialName };
};

export default useFlowSocial;
