import { Dropdown, Input, TDropdownOptionValue, Text } from '@design-system/ui-kit';
import classNames from 'classnames';
import { CountryCallingCode } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useCountries from '../../hooks/countries.hook';
import { arrToStr } from '../../utils/string';
import { countryNormalize, getPhoneOnlyCountry, phoneCodeByCountry, phoneCountries } from '../../utils/validation';
import { getPhoneWithoutCode } from './PhoneInput.functions';
import styles from './PhoneInput.module.scss';
import { IPhoneInput } from './PhoneInput.types';

const PhoneInput: React.VFC<IPhoneInput> = (props) => {
  const { className, disabled = false, fullPhone, changeCountry, value, onChange } = props;
  const { i18n } = useTranslation();
  const { getCountryByCode, getBestCountry } = useCountries(i18n.language);

  const countriesOptions = React.useMemo(
    () =>
      phoneCountries.map((item) => ({
        text: arrToStr([item, `(+${phoneCodeByCountry(item)})`, getCountryByCode(item)], ' '),
        value: item,
      })),
    [getCountryByCode],
  );
  const bestCountry = React.useMemo(() => getBestCountry(), [getBestCountry]);
  // TODO: сообщение об ошибке
  const initialCountry: [CountryCode, CountryCallingCode] = React.useMemo(
    () =>
      fullPhone
        ? [getPhoneOnlyCountry(fullPhone), phoneCodeByCountry(getPhoneOnlyCountry(fullPhone))]
        : [countryNormalize(bestCountry), phoneCodeByCountry(countryNormalize(bestCountry))],
    [bestCountry, fullPhone],
  );
  const [country, setCountry] = React.useState<[CountryCode, CountryCallingCode]>(initialCountry);
  React.useEffect(() => {
    setCountry(initialCountry);
    changeCountry(initialCountry);
  }, [changeCountry, initialCountry]);
  const onCountryChange = React.useCallback(
    (val: TDropdownOptionValue | null) => {
      const newCountry: CountryCode = (val || countryNormalize(bestCountry)) as CountryCode;
      setCountry([newCountry, phoneCodeByCountry(newCountry)]);
      changeCountry([newCountry, phoneCodeByCountry(newCountry)]);
    },
    [bestCountry, changeCountry],
  );

  const phoneCodes = (
    <div className={styles.this_dropdownEnd}>
      <Dropdown
        options={countriesOptions}
        value={country[0]}
        onChange={onCountryChange}
        withSearch
        disabled={disabled}
      />
    </div>
  );

  return (
    <Input
      {...props}
      value={getPhoneWithoutCode(value)}
      onChange={(val) => {
        onChange?.(getPhoneWithoutCode(val) || '');
      }}
      className={classNames(styles.this_phoneInput, className)}
      endContent={phoneCodes}
      startContent={<Text>{'+' + country[1]}</Text>}
    />
  );
};

export default PhoneInput;
