import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { generateLoginUrl } from '../../api/keycloak';
import { ROUTE_PERSONAL_DATA } from '../../constants/routes';
import Form from '../../containers/Form/Form';
import { openLink, withSlash } from '../../utils/url';
import styles from './ResetTotp.module.scss';

const toKeycloakLogin = () =>
  openLink(generateLoginUrl(encodeURIComponent(`${process.env.REACT_APP_URL}${withSlash(ROUTE_PERSONAL_DATA)}`)));

const ResetTotpError: React.VFC<{ code: number; className?: string }> = ({ code = 10_021, className }) => {
  const { t } = useTranslation();

  return (
    <Form
      className={className}
      user={false}
      notification={{
        title: t(`standardErrors.${code.toString()}.title`),
        description: t(`standardErrors.${code.toString()}.text`),
      }}
      logoClickable={false}
    >
      <div className={styles.form_wrapper}>
        <div className={styles.form_head}>
          <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary}>
            {t('page.resetTotp.title')}
          </Text>
        </div>
        <div className={classNames(styles.common_buttons, styles.common_buttons__center)}>
          <Button
            text={t('action.toAuth').toString()}
            type={ButtonTypes.button}
            onClick={toKeycloakLogin}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.labelsSecondary}
          />
        </div>
      </div>
    </Form>
  );
};

export default ResetTotpError;
