import { useToast } from 'library-react-hooks';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoaderComponent } from '../../components/Loader';
import { QUERY_PARAMS_TOTP_QUANTITY } from '../../constants/queryParams';
import { ROUTE_FULL_TOTP } from '../../constants/routes';
import useStore from '../../hooks/store';
import { getParamsValueByKey } from '../../utils/url';

// КК ведет на эту стр. при успешном добавлении и при отмене
const TotpAdd: React.VFC = () => {
  const { alert } = useToast();
  const navigate = useNavigate();
  const { credentials } = useStore(['credentials']);

  const added: boolean | null = useMemo(() => {
    if (credentials.totp === null) return null;
    const recentQuantity = getParamsValueByKey(document.location.href, QUERY_PARAMS_TOTP_QUANTITY);
    if (recentQuantity === null) return credentials.totp.length > 0;
    return credentials.totp.length > Number(recentQuantity);
  }, [credentials.totp]);

  useEffect(() => {
    if (added !== null) {
      if (added) {
        alert({
          type: 'success',
          titleData: { key: 'message.successTotpAdd' },
        });
      }
      navigate(ROUTE_FULL_TOTP);
    }
  }, [added, alert, navigate]);

  return <LoaderComponent />;
};

export default TotpAdd;
