import { useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { API_ERROR_CODE_SECURITY_FLOW_NOT_PASS } from '../../constants/api';
import useHttp from '../../hooks/http';
import { sentryException } from '../../utils/sentry';
import flowsData from '../flows.data';
import { get2FaActionByType } from '../flows.functions';
import { E2FaAction, EFlowsDataStep, ITotpFlowStep, TSecurityPostProps } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSecurity from '../hooks/security.hook';
import useMapper from '../mappers/mapper.hook';

const useTotpFlowStep = (): ITotpFlowStep => {
  const { ready } = useHttp();
  const { requestSecurity, error } = useSecurity();
  const { goNext, clear } = useMapper();
  const { alert } = useToast();
  const { t } = useTranslation();
  const resetNavigate = useResetNavigate();

  const type = useMemo(() => flowsData.getType(), []);
  const action2Fa = useMemo(() => {
    if (!type) return '';
    const actionKey = get2FaActionByType(type);
    if (!actionKey) return '';

    if (actionKey === E2FaAction.removeSocial) {
      const socialName = flowsData.getProp('socialName');
      return t(`page.2fa.action.${actionKey}`, { socialName });
    } else if (actionKey) {
      return t(`page.2fa.action.${actionKey}`);
    }
    return '';
  }, [t, type]);

  const onError = useCallback(
    async (isError = false, code?: number) => {
      if (isError)
        sentryException(
          `useTotpFlowStep hook: requestFlow unexpected response from the backend. Code: ${code}`,
          'warning',
        );
      await resetNavigate(() => clear());
    },
    [clear, resetNavigate],
  );
  const actionToContinue = useCallback(
    async (code: string, totpId) => {
      const actionToken = flowsData.getToken(EFlowsDataStep.totp);
      if (!actionToken || !type) {
        alert({
          type: 'warning',
          titleData: {
            key: 'message.warningExpirationTitle',
          },
          textData: {
            key: 'message.warningExpirationDescription',
          },
        });
        await onError();
        return;
      }

      if (!ready('postSecurityFlow')) {
        sentryException('useTotpFlowStep hook: postSecurityFlow not ready', 'warning');
        return;
      }
      const props: TSecurityPostProps = {
        body: { data: { totp: code, totpId } },
        headers: { 'action-token': actionToken },
      };
      const answer = await requestSecurity(props);

      if (answer === API_ERROR_CODE_SECURITY_FLOW_NOT_PASS) return;
      if (!isObject(answer)) {
        await onError(true, Number(answer));
        return;
      }
      goNext(type, answer);
    },
    [alert, goNext, onError, ready, requestSecurity, type],
  );
  const reset = useCallback(async () => {
    await resetNavigate(() => clear());
  }, [clear, resetNavigate]);

  return { actionToContinue, error, action2Fa, reset };
};

export default useTotpFlowStep;
