import { ReactComponent as MenuBackToIcon } from '../../assets/icons/leftArrow.svg';
import { ReactComponent as MenuExitIcon } from '../../assets/icons/menuExit.svg';
import { ReactComponent as MenuHelpIcon } from '../../assets/icons/menuHelp.svg';
import { ReactComponent as MenuSecurityIcon } from '../../assets/icons/menuSecurity.svg';
import { ReactComponent as MenuServicesIcon } from '../../assets/icons/menuServices.svg';
import { ReactComponent as MenuUserIcon } from '../../assets/icons/menuUser.svg';
import { ReactComponent as Question } from '../../assets/icons/social/question.svg';
import { ReactComponent as UserDefaultIcon } from '../../assets/icons/userDefault.svg';
import { ECustomIconName, ICustomIconProps } from './CustomIcon.types';

export const getIcon = (name: ICustomIconProps['icon']) => {
  switch (name) {
    case ECustomIconName.userDefault: {
      return UserDefaultIcon;
    }
    case ECustomIconName.menuUser: {
      return MenuUserIcon;
    }
    case ECustomIconName.menuSecurity: {
      return MenuSecurityIcon;
    }
    case ECustomIconName.menuServices: {
      return MenuServicesIcon;
    }
    case ECustomIconName.menuHelp: {
      return MenuHelpIcon;
    }
    case ECustomIconName.menuExit: {
      return MenuExitIcon;
    }
    case ECustomIconName.menuBackTo: {
      return MenuBackToIcon;
    }
    default: {
      return Question;
    }
  }
};
