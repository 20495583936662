import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  Icon,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IconQuestion } from '../../assets/icons/menuHelp.svg';
import { MODE_DEBUG } from '../../constants/mode';
import { ROUTE_FULL_DELETE_ACCOUNT, ROUTE_FULL_DELETE_ACCOUNT_ALL, ROUTE_SECURITY } from '../../constants/routes';
import Page from '../../containers/Page/Page';
import AppContext from '../../context/AppContext';
import usePrivatePages from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { EFlowType, useStartFlowStep } from '../../scenarios';
import { withSlash } from '../../utils/url';
import styles from './DeleteAccountAll.module.scss';

const DeleteAccountAll: React.VFC = () => {
  const { t } = useTranslation();
  const { startFlow } = useStartFlowStep();
  const { user } = useStore([]);
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { mode } = useContext(AppContext);

  useLayoutEffect(() => {
    if (user.deleteRequestTimestamp !== undefined && mode !== MODE_DEBUG) {
      navigate(withSlash(ROUTE_SECURITY));
      setPage(ROUTE_FULL_DELETE_ACCOUNT, { available: false });
      setPage(ROUTE_FULL_DELETE_ACCOUNT_ALL, { available: false });
    }
  }, [mode, navigate, setPage, user.deleteRequestTimestamp]);

  const onSubmit = useCallback(async () => {
    await startFlow(EFlowType.deleteAccount);
  }, [startFlow]);

  return (
    <Page
      menu
      subHead={{
        title: t('page.deleteAccount.title'),
        backLink: ROUTE_FULL_DELETE_ACCOUNT,
      }}
    >
      <div className={classNames(styles.page_wrapper)}>
        <div className={classNames(styles.page_block, styles.page_block__border)}>
          <div className={classNames(styles.page_iconBlock, styles.page_iconBlock__top)}>
            <Text variant={TextVariants.header5} color={TextAndIconColors.labelsSecondary}>
              {t('page.deleteAccount.subTitle')}
            </Text>
            <Icon
              className={classNames(styles.page_iconBlock__icon)}
              IconComponent={IconQuestion}
              height={24}
              width={24}
              color={TextAndIconColors.actionErrorSecondary}
            />
          </div>
          <div className={classNames(styles.common_margin__l, styles.common_buttons)}>
            <Button
              text={t('action.cancel').toString()}
              onClick={() => navigate(ROUTE_FULL_DELETE_ACCOUNT)}
              variant={ButtonVariant.outlined}
              theme={ButtonThemes.labelsSecondary}
              type={ButtonTypes.button}
            />
            <Button text={t('action.yes').toString()} onClick={onSubmit} />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default DeleteAccountAll;
