import {
  ROUTE_FULL_2FA,
  ROUTE_FULL_BIRTHDAY_EDIT,
  ROUTE_FULL_CONTACTS,
  ROUTE_FULL_COUNTRY_EDIT,
  ROUTE_FULL_DELETE_ACCOUNT,
  ROUTE_FULL_DELETE_ACCOUNT_ALL,
  ROUTE_FULL_DELETE_ACCOUNT_CODE,
  ROUTE_FULL_DELETE_ACCOUNT_CONFIRM,
  ROUTE_FULL_DELETE_ACCOUNT_DELETED,
  ROUTE_FULL_EMAIL,
  ROUTE_FULL_EMAIL_CHANGED,
  ROUTE_FULL_EMAIL_CODE,
  ROUTE_FULL_EMAIL_EDIT,
  ROUTE_FULL_EMAIL_SENT,
  ROUTE_FULL_EMAIL_VERIFIED,
  ROUTE_FULL_EMAIL_VERIFY,
  ROUTE_FULL_EMAIL_VIEW,
  ROUTE_FULL_FULFILL,
  ROUTE_FULL_NAME_EDIT,
  ROUTE_FULL_PASSWORD,
  ROUTE_FULL_PASSWORD_ADD,
  ROUTE_FULL_PASSWORD_EDIT,
  ROUTE_FULL_PHONE_ADD,
  ROUTE_FULL_PHONE_ADD_CODE,
  ROUTE_FULL_PHONE_ADD_COMPLETE,
  ROUTE_FULL_PHONE_REMOVE_COMPLETE,
  ROUTE_FULL_PHONE_VIEW,
  ROUTE_FULL_RESET_TOTP_CODE,
  ROUTE_FULL_RESET_TOTP_COMPLETE,
  ROUTE_FULL_SESSIONS,
  ROUTE_FULL_SESSIONS_REMOVE_ALL,
  ROUTE_FULL_SESSIONS_REMOVE_PARTIAL,
  ROUTE_FULL_SOCIAL_ADD,
  ROUTE_FULL_TOTP,
  ROUTE_PERSONAL_DATA,
  ROUTE_RESET_TOTP,
  ROUTE_SECURITY,
  ROUTE_SERVICES,
} from '../constants/routes';
import { withSlash } from '../utils/url';

type TPage = { text: string; path: string; withRecaptcha?: boolean };

export const cabinetPages: TPage[] = [
  { text: 'BirthdayEdit', path: ROUTE_FULL_BIRTHDAY_EDIT },
  // { text: 'ConfirmEmail', path: ROUTE_FULL_CONFIRM_EMAIL }, не имеет контента
  // { text: 'ConfirmAddEmail', path: ROUTE_FULL_CONFIRM_ADD_EMAIL }, не имеет контента
  { text: 'Contacts', path: ROUTE_FULL_CONTACTS },
  { text: 'CountryEdit', path: ROUTE_FULL_COUNTRY_EDIT },
  { text: 'DeleteAccount', path: ROUTE_FULL_DELETE_ACCOUNT },
  { text: 'DeleteAccountAll', path: ROUTE_FULL_DELETE_ACCOUNT_ALL },
  { text: 'DeleteAccountCode', path: ROUTE_FULL_DELETE_ACCOUNT_CODE },
  { text: 'DeleteAccountConfirm', path: ROUTE_FULL_DELETE_ACCOUNT_CONFIRM },
  { text: 'DeleteAccountDeleted', path: ROUTE_FULL_DELETE_ACCOUNT_DELETED },
  // { text: 'DeleteAccountSent', path: ROUTE_FULL_DELETE_ACCOUNT_SENT }, не имеет контента
  { text: 'EmailChanged', path: ROUTE_FULL_EMAIL_CHANGED },
  { text: 'EmailCode', path: ROUTE_FULL_EMAIL_CODE },
  { text: 'EmailEdit', path: ROUTE_FULL_EMAIL_EDIT },
  { text: 'EmailSent', path: ROUTE_FULL_EMAIL_SENT },
  { text: 'EmailView', path: ROUTE_FULL_EMAIL_VIEW },
  { text: 'EmailVerify', path: ROUTE_FULL_EMAIL_VERIFY },
  { text: 'EmailVerified', path: ROUTE_FULL_EMAIL_VERIFIED },
  { text: 'ErrorForbidden', path: withSlash(['error', 'notAuth']) },
  { text: 'ErrorInternalServer', path: withSlash(['error', 'serverError']) },
  { text: 'ErrorNotFound', path: withSlash([ROUTE_PERSONAL_DATA, '123']) },
  { text: 'ErrorServiceUnavailable', path: withSlash(['error', 'serverUnavailable']) },
  { text: 'Form2Fa', path: ROUTE_FULL_2FA },
  { text: 'FormFulfillProfile', path: ROUTE_FULL_FULFILL },
  { text: 'FormPassword', path: ROUTE_FULL_PASSWORD },
  { text: 'NameEdit', path: ROUTE_FULL_NAME_EDIT },
  { text: 'PasswordAdd', path: ROUTE_FULL_PASSWORD_ADD },
  { text: 'PasswordEdit', path: ROUTE_FULL_PASSWORD_EDIT },
  { text: 'PersonalData', path: withSlash(ROUTE_PERSONAL_DATA) },
  { text: 'Security', path: withSlash(ROUTE_SECURITY) },
  { text: 'Services', path: withSlash(ROUTE_SERVICES) },
  { text: 'Sessions', path: ROUTE_FULL_SESSIONS },
  { text: 'SessionsRemoveAll', path: ROUTE_FULL_SESSIONS_REMOVE_ALL },
  { text: 'SessionsRemovePartial', path: ROUTE_FULL_SESSIONS_REMOVE_PARTIAL },
  { text: 'SocialAdd', path: ROUTE_FULL_SOCIAL_ADD },
  // { text: 'SocialRemove', path: ROUTE_FULL_SOCIAL_REMOVE }, не имеет контента
  { text: 'Totp', path: ROUTE_FULL_TOTP },
  // { text: 'TotpAdd', path: ROUTE_FULL_TOTP_ADD }, не имеет контента
  // { text: 'TotpRemove', path: ROUTE_FULL_TOTP_REMOVE }, не имеет контента
  { text: 'FormEmail', path: ROUTE_FULL_EMAIL },
  { text: 'PhoneAdd', path: ROUTE_FULL_PHONE_ADD, withRecaptcha: true },
  { text: 'PhoneAddCode', path: ROUTE_FULL_PHONE_ADD_CODE },
  { text: 'PhoneAddSuccess', path: ROUTE_FULL_PHONE_ADD_COMPLETE },
  { text: 'PhoneView', path: ROUTE_FULL_PHONE_VIEW },
  { text: 'PhoneRemoveComplete', path: ROUTE_FULL_PHONE_REMOVE_COMPLETE },
  // { text: 'Logout', path: ROUTE_LOGOUT }, не имеет контента
];

export const externalPages: TPage[] = [
  {
    text: 'ResetTotp',
    path: withSlash(ROUTE_RESET_TOTP) + '?device_id=abbbaca0-f521-4d09-a06d-df149c950655&ui_locales=ru',
    withRecaptcha: true,
  },
  { text: 'ResetTotpCode', path: ROUTE_FULL_RESET_TOTP_CODE },
  { text: 'ResetTotpComplete', path: ROUTE_FULL_RESET_TOTP_COMPLETE },
];

export const pagesConfig: TPage[] = [...cabinetPages, ...externalPages];
