// const stringToColor = (string: string): string => {
//   let hash = 0;
//   let i;
//
//   /* eslint-disable no-bitwise */
//   for (i = 0; i < string.length; i += 1) {
//     hash = string.charCodeAt(i) + ((hash << 5) - hash);
//   }
//
//   let color = '#';
//
//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   /* eslint-enable no-bitwise */
//
//   return color;
// };

const simpleAvatar = (string: string | undefined): string => {
  if (!string) return '';
  return string[0].toUpperCase();
};

export const nameAvatar = (string: string | undefined): string => {
  const reg = new RegExp(/ /);
  if (!string?.match(reg)) return simpleAvatar(string);
  return `${string.split(reg)[0][0]}${string.split(reg)[1][0]}`.toUpperCase();
};

export const emailAvatar = (email: string | undefined): string => {
  const string = email?.split(/@/)[0];
  const reg = new RegExp(/\.|_/);
  if (!string?.match(reg)) return simpleAvatar(string);
  return `${string.split(reg)[0][0]}${string.split(reg)[1][0]}`.toUpperCase();
};
