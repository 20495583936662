import { Icon, IconSizes, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { ReactChild, useCallback, useContext } from 'react';
import { makeClasses } from 'react-append-styles';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IconLeftArrow } from '../../assets/icons/leftArrow.svg';
import AppContext from '../../context/AppContext';
import withPageView from '../../hocs/Analytics';
import Footer from '../../modules/Footer/Footer';
import Header from '../../modules/Header';
import Menu from '../../modules/Menu/Menu';
import styles from './Page.module.scss';

const useClasses = makeClasses<'classFooter' | 'classHeader', { footerBackground: string; headerBackground: string }>(
  (props) => ({
    classFooter: {
      background: props.footerBackground,
    },
    classHeader: {
      background: props.headerBackground,
    },
  }),
);

type PageProps = {
  menu?: boolean;
  subHead?: {
    title: string;
    backLink?: string;
  } | null;
  children: ReactChild;
  callback?: () => void;
};

const Page: React.FC<PageProps> = ({ menu = false, children, subHead = null, callback }: PageProps) => {
  const navigate = useNavigate();
  const { config } = useContext(AppContext);

  const { classFooter, classHeader } = useClasses(
    {
      footerBackground: config.styles?.footerBackground || '',
      headerBackground: config.styles?.headerBackground || '',
    },
    Boolean(config.styles),
  );

  const back = useCallback(() => {
    if (subHead) {
      if (subHead.backLink) navigate(subHead.backLink);
      callback?.();
    }
  }, [navigate, callback, subHead]);

  const mainClass = classNames(styles.this_main, { [styles.subHead]: Boolean(subHead) });
  const menuClass = classNames(styles.this_menu, { [styles.subHead]: Boolean(subHead) });
  const pageClass = classNames(styles.this_page, { [styles.this_page__sub]: Boolean(subHead) });

  return (
    <>
      <div className={styles.this_head}>
        <Header className={classHeader} callback={callback} user type={'page'} />
        {subHead && (
          <div className={styles.this_subHeader}>
            <div className={menuClass} />
            <div className={styles.this_subHeaderContent}>
              <Icon
                className={classNames(styles.common_icon, styles.common_iconMargin__l)}
                onClick={back}
                IconComponent={IconLeftArrow}
                size={IconSizes.l}
                color={TextAndIconColors.interfaceAccentSecondary}
              />
              <Text variant={TextVariants.header4} color={TextAndIconColors.labelsSecondary}>
                {subHead.title}
              </Text>
            </div>
          </div>
        )}
      </div>
      <div className={mainClass}>
        {menu && (
          <div className={menuClass}>
            <Menu callback={callback} />
          </div>
        )}
        <div className={pageClass}>{children}</div>
      </div>
      <Footer className={classFooter} type={'page'} />
    </>
  );
};

export default React.memo(withPageView<PageProps>(Page));
