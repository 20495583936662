import { useTimer, useToast } from 'library-react-hooks';
import { isObject } from 'lodash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AnswerDeleteAccount } from '../../api/answer.types';
import { API_ERROR_ACTION_TYPE_LIMIT, API_ERROR_CODE_ALREADY_SENT_TO_DELETED } from '../../constants/api';
import {
  ROUTE_FULL_DELETE_ACCOUNT_CONFIRM,
  ROUTE_FULL_DELETE_ACCOUNT_DELETED,
  ROUTE_FULL_DELETE_ACCOUNT_SENT,
  ROUTE_SECURITY,
} from '../../constants/routes';
import useHttp from '../../hooks/http';
import usePrivatePages, { privatePagesSet } from '../../hooks/privatePages.hook';
import useStore from '../../hooks/store';
import { sentryException } from '../../utils/sentry';
import { withSlash } from '../../utils/url';
import { ALERT_GROUP_FLOW, TIMER_NAME_FLOW } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, IFlowDeleteAccount } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';
import useSetPages from '../hooks/setPages.hook';
import { resetSecuritySteps } from '../resetFlows';

const usingPrivatePages: { [K in 'sent' | 'confirm' | 'completed']: string } = {
  sent: ROUTE_FULL_DELETE_ACCOUNT_SENT,
  confirm: ROUTE_FULL_DELETE_ACCOUNT_CONFIRM,
  completed: ROUTE_FULL_DELETE_ACCOUNT_DELETED,
};

export const flowDeleteAccountReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useFlowDeleteAccount = (): IFlowDeleteAccount => {
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { requestByName, ready } = useHttp();
  const { update } = useStore([]);
  const setPages = useSetPages(usingPrivatePages);
  const resetNavigate = useResetNavigate();
  const { alert, clear } = useToast();
  const { cancelTimer } = useTimer();

  const clearTimers = useCallback(() => {
    cancelTimer(TIMER_NAME_FLOW);
    clear(ALERT_GROUP_FLOW);
  }, [cancelTimer, clear]);
  const reset = useCallback(async () => {
    resetSecuritySteps();
    clearTimers();
    await resetNavigate(() => setPages(false));
  }, [clearTimers, resetNavigate, setPages]);

  const confirmDeleteAccount = useCallback(
    async (reasons, comment) => {
      const token = flowsData.getToken(EFlowsDataStep.flow);
      if (ready('deleteAccount') && token) {
        const fetched = (await requestByName('deleteAccount', {
          actionToken: flowsData.getToken(EFlowsDataStep.flow),
          reasons,
          comment,
        })) as AnswerDeleteAccount;
        if (isObject(fetched) && fetched.success) {
          clearTimers();
          update('user');
          setPage(usingPrivatePages.completed, { available: true });
          navigate(usingPrivatePages.completed);
          flowsData.setBasePage(withSlash(ROUTE_SECURITY));
        }
        if (fetched === API_ERROR_CODE_ALREADY_SENT_TO_DELETED) {
          await reset();
          return;
        }
        if (fetched === API_ERROR_ACTION_TYPE_LIMIT) {
          alert({
            type: 'warning',
            titleData: {
              key: 'page.emailView.error.title',
            },
            textData: {
              key: 'page.emailView.error.description',
            },
          });
          await reset();
          return;
        }
      } else sentryException('FlowDeleteAccount hook: deleteAccount api is not ready', 'warning');
    },
    [alert, clearTimers, navigate, ready, requestByName, reset, setPage, update],
  );

  return { reset, confirmDeleteAccount };
};

export default useFlowDeleteAccount;
