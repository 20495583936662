import { Icon, IconSizes } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg';
import styles from './DropList.module.scss';
import { DropListProps } from './DropList.types';

const DropList: React.FC<DropListProps> = ({
  children,
  isOpen,
  callback,
  component,
  dropdownClass = '',
  arrow = true,
  className,
}: DropListProps) => {
  const dropRef: React.Ref<HTMLDivElement> = useRef(null);
  const iconRef: React.Ref<HTMLDivElement> = useRef(null);
  const componentRef: React.Ref<HTMLDivElement> = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      // Кли не по компоненту или списку. Если есть иконка, то и не по ней
      if (
        (dropRef.current &&
          !dropRef.current.contains(event.target as Node) &&
          componentRef.current &&
          !componentRef.current.contains(event.target as Node) &&
          !iconRef.current) ||
        (dropRef.current &&
          !dropRef.current.contains(event.target as Node) &&
          iconRef.current &&
          !iconRef.current.contains(event.target as Node) &&
          componentRef.current &&
          !componentRef.current.contains(event.target as Node))
      ) {
        callback();
      }
    };
    if (isOpen) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropRef, isOpen, callback]);

  const arrowIconClass = classNames(styles.arrowIcon, {
    [styles.arrowIcon_rotated]: isOpen,
  });
  const componentClass = classNames(styles.component, {
    [styles.component_withoutArrow]: true,
  });
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div ref={componentRef} className={componentClass} onClick={callback}>
        {component}
      </div>
      {arrow && (
        <div ref={iconRef}>
          <Icon className={arrowIconClass} IconComponent={ArrowDown} size={IconSizes.l} onClick={callback} />
        </div>
      )}
      {isOpen && (
        <div ref={dropRef} className={classNames(styles.backdrop, dropdownClass)}>
          {children}
        </div>
      )}
    </div>
  );
};

export default React.memo(DropList);
