import React, { useLayoutEffect, useReducer } from 'react';

import { LoaderComponent } from '../../components/Loader';
import useHttp from '../../hooks/http';
import useFlowTotp from '../../scenarios/flows/flowTotp.hook';

const TotpRemove: React.VFC = () => {
  const { ready } = useHttp();
  const { actionToContinueRemove } = useFlowTotp();

  const [once, setOnce] = useReducer(() => true, false);
  useLayoutEffect(() => {
    if (!once && ready('deleteTotpCredential')) {
      actionToContinueRemove();
      setOnce();
    }
  }, [actionToContinueRemove, once, ready]);

  return <LoaderComponent />;
};

export default TotpRemove;
