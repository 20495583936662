export type TTimeShorted = { days: number; hours: number; minutes: number; seconds: number };
export const secondsShorted = (sec: number): TTimeShorted => {
  return {
    seconds: sec % 60,
    minutes: Math.floor(sec / 60) % 60,
    hours: Math.floor(sec / (60 * 60)) % 24,
    days: Math.floor(sec / (60 * 60 * 24)),
  };
};

export const timeRound = (props: TTimeShorted): TTimeShorted => {
  // TODO: round
  return props;
};
