import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import {
  ROUTE_2FA,
  ROUTE_ADD,
  ROUTE_ADD_EMAIL,
  ROUTE_ALL,
  ROUTE_BIRTHDAY,
  ROUTE_CHANGED,
  ROUTE_CODE,
  ROUTE_COMPLETE,
  ROUTE_CONFIRM,
  ROUTE_CONTACTS,
  ROUTE_COUNTRY,
  ROUTE_DELETE_ACCOUNT,
  ROUTE_DELETED,
  ROUTE_EDIT,
  ROUTE_EMAIL,
  ROUTE_FORMS,
  ROUTE_FULFILL,
  ROUTE_FULL_2FA,
  ROUTE_FULL_DELETE_ACCOUNT_CODE,
  ROUTE_FULL_DELETE_ACCOUNT_CONFIRM,
  ROUTE_FULL_DELETE_ACCOUNT_DELETED,
  ROUTE_FULL_DELETE_ACCOUNT_SENT,
  ROUTE_FULL_EMAIL,
  ROUTE_FULL_EMAIL_CHANGED,
  ROUTE_FULL_EMAIL_CODE,
  ROUTE_FULL_EMAIL_EDIT,
  ROUTE_FULL_EMAIL_SENT,
  ROUTE_FULL_EMAIL_VERIFIED,
  ROUTE_FULL_EMAIL_VERIFY,
  ROUTE_FULL_EMAIL_VIEW,
  ROUTE_FULL_FULFILL,
  ROUTE_FULL_PASSWORD,
  ROUTE_FULL_PASSWORD_ADD,
  ROUTE_FULL_PASSWORD_EDIT,
  ROUTE_FULL_PHONE_ADD,
  ROUTE_FULL_PHONE_ADD_CODE,
  ROUTE_FULL_PHONE_ADD_COMPLETE,
  ROUTE_FULL_PHONE_REMOVE_COMPLETE,
  ROUTE_FULL_PHONE_VIEW,
  ROUTE_FULL_RESET_TOTP_CODE,
  ROUTE_FULL_RESET_TOTP_COMPLETE,
  ROUTE_FULL_SESSIONS,
  ROUTE_FULL_SESSIONS_REMOVE_ALL,
  ROUTE_FULL_SESSIONS_REMOVE_PARTIAL,
  ROUTE_FULL_SOCIAL_ADD,
  ROUTE_FULL_SOCIAL_REMOVE,
  ROUTE_FULL_TOTP_REMOVE,
  ROUTE_LOGOUT,
  ROUTE_NAME,
  ROUTE_PARTIAL,
  ROUTE_PASSWORD,
  ROUTE_PERSONAL_DATA,
  ROUTE_PHONE,
  ROUTE_REMOVE,
  ROUTE_RESET_TOTP,
  ROUTE_SECURITY,
  ROUTE_SENT,
  ROUTE_SERVICES,
  ROUTE_SESSIONS,
  ROUTE_SOCIAL,
  ROUTE_TOTP,
  ROUTE_VERIFIED,
  ROUTE_VERIFY,
  ROUTE_VIEW,
} from '../constants/routes';
import { PrivateOutlet } from '../hocs/PrivateOutlet';
import BirthdayEdit from '../pages/BirthdayEdit';
import ConfirmAddEmail from '../pages/ConfirmAddEmail';
import ConfirmEmail from '../pages/ConfirmEmail';
import Contacts from '../pages/Contacts';
import CountryEdit from '../pages/CountryEdit';
import DeleteAccount from '../pages/DeleteAccount';
import DeleteAccountAll from '../pages/DeleteAccountAll';
import { DeleteAccountCode } from '../pages/DeleteAccountCode';
import DeleteAccountConfirm from '../pages/DeleteAccountConfirm';
import DeleteAccountDeleted from '../pages/DeleteAccountDeleted';
import DeleteAccountSent from '../pages/DeleteAccountSent';
import EmailChanged from '../pages/EmailChanged';
import EmailCode from '../pages/EmailCode';
import EmailEdit from '../pages/EmailEdit';
import EmailSent from '../pages/EmailSent';
import EmailVerified from '../pages/EmailVerified';
import EmailVerify, { EmailVerifyDirect } from '../pages/EmailVerify';
import EmailView, { EmailViewDirect } from '../pages/EmailView';
import ErrorForbidden from '../pages/ErrorForbidden';
import ErrorInternalServer from '../pages/ErrorInternalServer';
import ErrorNotFound from '../pages/ErrorNotFound';
import ErrorServiceUnavailable from '../pages/ErrorServiceUnavailable';
import Form2Fa from '../pages/Form2Fa';
import FormEmail from '../pages/FormEmail';
import FormFulfillProfile from '../pages/FormFulfillProfile';
import FormPassword from '../pages/FormPassword';
import Logout from '../pages/Logout';
import NameEdit from '../pages/NameEdit';
import PasswordAdd from '../pages/PasswordAdd';
import PasswordEdit, { PasswordEditDirect } from '../pages/PasswordEdit';
import PersonalData from '../pages/PersonalData';
import PhoneAdd from '../pages/PhoneAdd';
import PhoneAddCode from '../pages/PhoneAddCode';
import PhoneAddComplete from '../pages/PhoneAddComplete';
import PhoneRemoveComplete from '../pages/PhoneRemoveComplete';
import PhoneView from '../pages/PhoneView';
import PhoneViewDirect from '../pages/PhoneView/PhoneViewDirect';
import ResetTotp from '../pages/ResetTotp';
import ResetTotpCode from '../pages/ResetTotpCode';
import ResetTotpComplete from '../pages/ResetTotpComplete';
import Security from '../pages/Security/Security';
import Services from '../pages/Services';
import Sessions from '../pages/Sessions';
import SessionsRemoveAll from '../pages/SessionsRemoveAll';
import SessionsRemovePartial from '../pages/SessionsRemovePartial';
import SocialAdd from '../pages/SocialAdd';
import SocialRemove from '../pages/SocialRemove';
import Totp from '../pages/Totp/Totp';
import TotpAdd from '../pages/TotpAdd';
import TotpRemove from '../pages/TotpRemove';
import { withSlash } from '../utils/url';

export const cabinetConfig: RouteObject[] = [
  {
    path: ROUTE_PERSONAL_DATA,
    children: [
      {
        index: true,
        element: <PersonalData />,
      },
      {
        path: ROUTE_NAME,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
          },
          {
            path: ROUTE_EDIT,
            element: <NameEdit />,
          },
        ],
      },
      {
        path: ROUTE_BIRTHDAY,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
          },
          {
            path: ROUTE_EDIT,
            element: <BirthdayEdit />,
          },
        ],
      },
      {
        path: ROUTE_COUNTRY,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
          },
          {
            path: ROUTE_EDIT,
            element: <CountryEdit />,
          },
        ],
      },
      {
        path: ROUTE_SOCIAL,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
          },
          {
            path: ROUTE_ADD,
            element: <PrivateOutlet path={ROUTE_FULL_SOCIAL_ADD} />,
            children: [
              {
                path: '',
                element: <SocialAdd />,
              },
            ],
          },
          {
            path: ROUTE_REMOVE,
            element: <PrivateOutlet path={ROUTE_FULL_SOCIAL_REMOVE} />,
            children: [
              {
                path: '',
                element: <SocialRemove />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_CONTACTS,
        element: <Contacts />,
      },
    ],
  },
  {
    path: ROUTE_SECURITY,
    children: [
      {
        index: true,
        element: <Security />,
      },
      {
        path: ROUTE_EMAIL,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_SECURITY) }),
          },
          {
            path: ROUTE_VIEW,
            element: <PrivateOutlet path={ROUTE_FULL_EMAIL_VIEW} direct={<EmailViewDirect />} />,
            children: [
              {
                path: '',
                element: <EmailView />,
              },
            ],
          },
          {
            path: ROUTE_SENT,
            element: <PrivateOutlet path={ROUTE_FULL_EMAIL_SENT} />,
            children: [
              {
                path: '',
                element: <EmailSent />,
              },
            ],
          },
          {
            path: ROUTE_CODE,
            element: <PrivateOutlet path={ROUTE_FULL_EMAIL_CODE} />,
            children: [
              {
                path: '',
                element: <EmailCode />,
              },
            ],
          },
          {
            path: ROUTE_EDIT,
            element: <PrivateOutlet path={ROUTE_FULL_EMAIL_EDIT} />,
            children: [
              {
                path: '',
                element: <EmailEdit />,
              },
            ],
          },
          {
            path: ROUTE_CHANGED,
            element: <PrivateOutlet path={ROUTE_FULL_EMAIL_CHANGED} />,
            children: [
              {
                path: '',
                element: <EmailChanged />,
              },
            ],
          },
          {
            path: ROUTE_VERIFY,
            element: <PrivateOutlet path={ROUTE_FULL_EMAIL_VERIFY} direct={<EmailVerifyDirect />} />,
            children: [
              {
                path: '',
                element: <EmailVerify />,
              },
            ],
          },
          {
            path: ROUTE_VERIFIED,
            element: <PrivateOutlet path={ROUTE_FULL_EMAIL_VERIFIED} />,
            children: [
              {
                path: '',
                element: <EmailVerified />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_TOTP,
        children: [
          {
            index: true,
            element: <Totp />,
          },
          {
            path: ROUTE_REMOVE,
            element: <PrivateOutlet path={ROUTE_FULL_TOTP_REMOVE} />,
            children: [
              {
                path: '',
                element: <TotpRemove />,
              },
            ],
          },
          {
            path: ROUTE_ADD,
            element: <TotpAdd />,
          },
        ],
      },
      {
        path: ROUTE_PASSWORD,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_SECURITY) }),
          },
          {
            path: ROUTE_ADD,
            element: <PrivateOutlet path={ROUTE_FULL_PASSWORD_ADD} />,
            children: [
              {
                path: '',
                element: <PasswordAdd />,
              },
            ],
          },
          {
            path: ROUTE_EDIT,
            element: <PrivateOutlet path={ROUTE_FULL_PASSWORD_EDIT} direct={<PasswordEditDirect />} />,
            children: [
              {
                path: '',
                element: <PasswordEdit />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_DELETE_ACCOUNT,
        children: [
          {
            index: true,
            element: <DeleteAccount />,
          },
          {
            path: ROUTE_ALL,
            element: <DeleteAccountAll />,
          },
          {
            path: ROUTE_SENT,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_SENT} />,
            children: [
              {
                path: '',
                element: <DeleteAccountSent />,
              },
            ],
          },
          {
            path: ROUTE_CODE,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_CODE} />,
            children: [
              {
                path: '',
                element: <DeleteAccountCode />,
              },
            ],
          },
          {
            path: ROUTE_CONFIRM,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_CONFIRM} />,
            children: [
              {
                path: '',
                element: <DeleteAccountConfirm />,
              },
            ],
          },
          {
            path: ROUTE_DELETED,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_DELETED} />,
            children: [
              {
                path: '',
                element: <DeleteAccountDeleted />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_SESSIONS,
        children: [
          {
            index: true,
            element: <Sessions />,
          },
          {
            path: ROUTE_REMOVE,
            index: false,
            // element: <Navigate to={ROUTE_FULL_SESSIONS} />,
            children: [
              {
                path: ROUTE_ALL,
                element: <PrivateOutlet path={ROUTE_FULL_SESSIONS_REMOVE_ALL} />,
                children: [
                  {
                    path: '',
                    element: <SessionsRemoveAll />,
                  },
                ],
              },
              {
                path: ROUTE_PARTIAL,
                element: <PrivateOutlet path={ROUTE_FULL_SESSIONS_REMOVE_PARTIAL} />,
                children: [
                  {
                    path: '',
                    element: <SessionsRemovePartial />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTE_SENT,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_SENT} />,
            children: [
              {
                path: '',
                element: <DeleteAccountSent />,
              },
            ],
          },
          {
            path: ROUTE_CODE,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_CODE} />,
            children: [
              {
                path: '',
                element: <DeleteAccountCode />,
              },
            ],
          },
          {
            path: ROUTE_CONFIRM,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_CONFIRM} />,
            children: [
              {
                path: '',
                element: <DeleteAccountConfirm />,
              },
            ],
          },
          {
            path: ROUTE_DELETED,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_DELETED} />,
            children: [
              {
                path: '',
                element: <DeleteAccountDeleted />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_PHONE,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_SECURITY) }),
          },
          {
            path: ROUTE_ADD,
            children: [
              {
                path: '',
                element: <PrivateOutlet path={ROUTE_FULL_PHONE_ADD} />,
                children: [
                  {
                    path: '',
                    element: <PhoneAdd />,
                  },
                ],
              },
              {
                path: ROUTE_CODE,
                element: <PrivateOutlet path={ROUTE_FULL_PHONE_ADD_CODE} />,
                children: [
                  {
                    path: '',
                    element: <PhoneAddCode />,
                  },
                ],
              },
              {
                path: ROUTE_COMPLETE,
                element: <PrivateOutlet path={ROUTE_FULL_PHONE_ADD_COMPLETE} />,
                children: [
                  {
                    path: '',
                    element: <PhoneAddComplete />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTE_VIEW,
            element: <PrivateOutlet path={ROUTE_FULL_PHONE_VIEW} direct={<PhoneViewDirect />} />,
            children: [
              {
                path: '',
                element: <PhoneView />,
              },
            ],
          },
          {
            path: ROUTE_REMOVE,
            children: [
              {
                path: ROUTE_COMPLETE,
                element: <PrivateOutlet path={ROUTE_FULL_PHONE_REMOVE_COMPLETE} />,
                children: [
                  {
                    path: '',
                    element: <PhoneRemoveComplete />,
                  },
                ],
              },
              // {
              //   index: true,
              //   Component: () => Navigate({ to: ROUTE_FULL_PHONE_VIEW }),
              // },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_SERVICES,
    element: <Services />,
  },
  {
    path: ROUTE_LOGOUT,
    element: <Logout />,
  },
  {
    path: ROUTE_FORMS,
    children: [
      {
        index: true,
        Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
      },
      {
        path: ROUTE_PASSWORD,
        element: <PrivateOutlet path={ROUTE_FULL_PASSWORD} />,
        children: [
          {
            path: '',
            element: <FormPassword />,
          },
        ],
      },
      {
        path: ROUTE_2FA,
        element: <PrivateOutlet path={ROUTE_FULL_2FA} />,
        children: [
          {
            path: '',
            element: <Form2Fa />,
          },
        ],
      },
      {
        path: ROUTE_EMAIL,
        element: <PrivateOutlet path={ROUTE_FULL_EMAIL} />,
        children: [
          {
            path: '',
            element: <FormEmail />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_CONFIRM,
    children: [
      {
        index: true,
        Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
      },
      {
        path: ROUTE_EMAIL,
        element: <ConfirmEmail />,
      },
      {
        path: ROUTE_ADD_EMAIL,
        element: <ConfirmAddEmail />,
      },
    ],
  },
  {
    path: ROUTE_FULL_FULFILL,
    Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
  },
  {
    path: '/',
    Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
  },
  {
    path: '*',
    element: <ErrorNotFound />,
  },
];

export const cabinetReadonlyConfig: RouteObject[] = [
  {
    path: ROUTE_PERSONAL_DATA,
    element: <PersonalData />,
    children: [
      {
        path: ROUTE_SOCIAL,
        index: false,
        children: [
          {
            path: ROUTE_ADD,
            element: <PrivateOutlet path={ROUTE_FULL_SOCIAL_ADD} />,
            children: [
              {
                path: '',
                element: <SocialAdd />,
              },
            ],
          },
          {
            path: ROUTE_REMOVE,
            element: <PrivateOutlet path={ROUTE_FULL_SOCIAL_REMOVE} />,
            children: [
              {
                path: '',
                element: <SocialRemove />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_SECURITY,
    element: <Security />,
    children: [
      {
        path: ROUTE_TOTP,
        element: <Totp />,
        children: [
          {
            path: ROUTE_REMOVE,
            element: <PrivateOutlet path={ROUTE_FULL_TOTP_REMOVE} />,
            children: [
              {
                path: '',
                element: <TotpRemove />,
              },
            ],
          },
          {
            path: ROUTE_ADD,
            element: <TotpAdd />,
          },
        ],
      },
      {
        path: ROUTE_PASSWORD,
        index: false,
        children: [
          {
            path: ROUTE_ADD,
            element: <PrivateOutlet path={ROUTE_FULL_PASSWORD_ADD} />,
            children: [
              {
                path: '',
                element: <PasswordAdd />,
              },
            ],
          },
          {
            path: ROUTE_EDIT,
            element: <PrivateOutlet path={ROUTE_FULL_PASSWORD_EDIT} direct={<PasswordEditDirect />} />,
            children: [
              {
                path: '',
                element: <PasswordEdit />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_DELETE_ACCOUNT,
        element: <DeleteAccount />,
        children: [
          {
            path: ROUTE_ALL,
            element: <DeleteAccountAll />,
          },
          {
            path: ROUTE_SENT,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_SENT} />,
            children: [
              {
                path: '',
                element: <DeleteAccountSent />,
              },
            ],
          },
          {
            path: ROUTE_CODE,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_CODE} />,
            children: [
              {
                path: '',
                element: <DeleteAccountCode />,
              },
            ],
          },
          {
            path: ROUTE_CONFIRM,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_CONFIRM} />,
            children: [
              {
                path: '',
                element: <DeleteAccountConfirm />,
              },
            ],
          },
          {
            path: ROUTE_DELETED,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_DELETED} />,
            children: [
              {
                path: '',
                element: <DeleteAccountDeleted />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_SESSIONS,
        element: <Sessions />,
        children: [
          {
            path: ROUTE_REMOVE,
            index: false,
            // element: <Navigate to={ROUTE_FULL_SESSIONS} />,
            children: [
              {
                path: ROUTE_ALL,
                element: <PrivateOutlet path={ROUTE_FULL_SESSIONS_REMOVE_ALL} />,
                children: [
                  {
                    path: '',
                    element: <SessionsRemoveAll />,
                  },
                ],
              },
              {
                path: ROUTE_PARTIAL,
                element: <PrivateOutlet path={ROUTE_FULL_SESSIONS_REMOVE_PARTIAL} />,
                children: [
                  {
                    path: '',
                    element: <SessionsRemovePartial />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTE_SENT,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_SENT} />,
            children: [
              {
                path: '',
                element: <DeleteAccountSent />,
              },
            ],
          },
          {
            path: ROUTE_CODE,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_CODE} />,
            children: [
              {
                path: '',
                element: <DeleteAccountCode />,
              },
            ],
          },
          {
            path: ROUTE_CONFIRM,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_CONFIRM} />,
            children: [
              {
                path: '',
                element: <DeleteAccountConfirm />,
              },
            ],
          },
          {
            path: ROUTE_DELETED,
            element: <PrivateOutlet path={ROUTE_FULL_DELETE_ACCOUNT_DELETED} />,
            children: [
              {
                path: '',
                element: <DeleteAccountDeleted />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_SERVICES,
    element: <Services />,
  },
  {
    path: ROUTE_LOGOUT,
    element: <Logout />,
  },
  {
    path: ROUTE_FORMS,
    index: false,
    children: [
      {
        path: ROUTE_2FA,
        element: <PrivateOutlet path={ROUTE_FULL_2FA} />,
        children: [
          {
            path: '',
            element: <Form2Fa />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_FULL_FULFILL,
    Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
  },
  {
    path: '*',
    Component: ErrorNotFound,
  },
];

export const externalConfig: RouteObject[] = [
  {
    path: ROUTE_RESET_TOTP,
    children: [
      {
        index: true,
        Component: ResetTotp,
      },
      {
        path: ROUTE_CODE,
        element: <PrivateOutlet path={ROUTE_FULL_RESET_TOTP_CODE} />,
        children: [
          {
            path: '',
            element: <ResetTotpCode />,
          },
        ],
      },
      {
        path: ROUTE_COMPLETE,
        element: <PrivateOutlet path={ROUTE_FULL_RESET_TOTP_COMPLETE} />,
        children: [
          {
            path: '',
            element: <ResetTotpComplete />,
          },
        ],
      },
      {
        path: '*',
        Component: () => Navigate({ to: withSlash(ROUTE_RESET_TOTP) }),
      },
    ],
  },
  {
    path: '*',
    Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
  },
];

export const fulfillConfig: RouteObject[] = [
  {
    path: ROUTE_FORMS,
    children: [
      {
        index: true,
        Component: () => Navigate({ to: ROUTE_FULL_FULFILL }),
      },
      {
        path: ROUTE_FULFILL,
        Component: FormFulfillProfile,
      },
      {
        path: '*',
        Component: () => Navigate({ to: ROUTE_FULL_FULFILL }),
      },
    ],
  },
  {
    path: ROUTE_LOGOUT,
    element: <Logout />,
  },
  {
    path: '*',
    Component: () => Navigate({ to: ROUTE_FULL_FULFILL }),
  },
];

export const notAuthConfig: RouteObject[] = [
  {
    path: '*',
    Component: ErrorForbidden,
  },
];

export const notFoundConfig: RouteObject[] = [
  {
    path: '*',
    Component: ErrorNotFound,
  },
];

export const serverErrorConfig: RouteObject[] = [
  {
    path: '*',
    Component: ErrorInternalServer,
  },
];

export const serverUnavailableConfig: RouteObject[] = [
  {
    path: '*',
    Component: ErrorServiceUnavailable,
  },
];

export const mockConfig: RouteObject[] = [
  {
    path: ROUTE_PERSONAL_DATA,
    children: [
      {
        Component: PersonalData,
        index: true,
      },
      {
        path: ROUTE_NAME,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
          },
          {
            path: ROUTE_EDIT,
            Component: NameEdit,
          },
        ],
      },
      {
        path: ROUTE_BIRTHDAY,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
          },
          {
            path: ROUTE_EDIT,
            Component: BirthdayEdit,
          },
        ],
      },
      {
        path: ROUTE_COUNTRY,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
          },
          {
            path: ROUTE_EDIT,
            Component: CountryEdit,
          },
        ],
      },
      {
        path: ROUTE_SOCIAL,
        children: [
          // {
          //   index: true,
          //   Component: () => Navigate({ to: withSlash(ROUTE_PERSONAL_DATA) }),
          // },
          // FIXME
          {
            path: ROUTE_ADD,
            Component: SocialAdd,
          },
          {
            path: ROUTE_REMOVE,
            Component: SocialRemove,
          },
        ],
      },
      {
        path: ROUTE_CONTACTS,
        Component: Contacts,
      },
    ],
  },
  {
    path: ROUTE_SECURITY,
    children: [
      {
        index: true,
        Component: Security,
      },
      {
        path: ROUTE_EMAIL,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_SECURITY) }),
          },
          {
            path: ROUTE_VIEW,
            Component: EmailView,
          },
          {
            path: ROUTE_SENT,
            Component: EmailSent,
          },
          {
            path: ROUTE_CODE,
            Component: EmailCode,
          },
          {
            path: ROUTE_EDIT,
            Component: EmailEdit,
          },
          {
            path: ROUTE_CHANGED,
            Component: EmailChanged,
          },
          {
            path: ROUTE_VERIFY,
            Component: EmailVerify,
          },
          {
            path: ROUTE_VERIFIED,
            Component: EmailVerified,
          },
        ],
      },
      {
        path: ROUTE_TOTP,
        children: [
          {
            index: true,
            Component: Totp,
          },
          {
            path: ROUTE_REMOVE,
            Component: TotpRemove,
          },
          {
            path: ROUTE_ADD,
            Component: TotpAdd,
          },
        ],
      },
      {
        path: ROUTE_PASSWORD,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_SECURITY) }),
          },
          {
            path: ROUTE_ADD,
            Component: PasswordAdd,
          },
          {
            path: ROUTE_EDIT,
            Component: PasswordEdit,
          },
        ],
      },
      {
        path: ROUTE_DELETE_ACCOUNT,
        children: [
          {
            index: true,
            Component: DeleteAccount,
          },
          {
            path: ROUTE_ALL,
            Component: DeleteAccountAll,
          },
          {
            path: ROUTE_SENT,
            Component: DeleteAccountSent,
          },
          {
            path: ROUTE_CODE,
            Component: DeleteAccountCode,
          },
          {
            path: ROUTE_CONFIRM,
            Component: DeleteAccountConfirm,
          },
          {
            path: ROUTE_DELETED,
            Component: DeleteAccountDeleted,
          },
        ],
      },
      {
        path: ROUTE_SESSIONS,
        children: [
          {
            index: true,
            Component: Sessions,
          },
          {
            path: ROUTE_REMOVE,
            children: [
              {
                index: true,
                Component: () => Navigate({ to: ROUTE_FULL_SESSIONS }),
              },
              {
                path: ROUTE_ALL,
                Component: SessionsRemoveAll,
              },
              {
                path: ROUTE_PARTIAL,
                Component: SessionsRemovePartial,
              },
            ],
          },
          {
            path: ROUTE_SENT,
            Component: DeleteAccountSent,
          },
          {
            path: ROUTE_CODE,
            Component: DeleteAccountCode,
          },
          {
            path: ROUTE_CONFIRM,
            Component: DeleteAccountConfirm,
          },
          {
            path: ROUTE_DELETED,
            Component: DeleteAccountDeleted,
          },
        ],
      },
      {
        path: ROUTE_PHONE,
        children: [
          {
            index: true,
            Component: () => Navigate({ to: withSlash(ROUTE_SECURITY) }),
          },
          {
            path: ROUTE_ADD,
            children: [
              {
                index: true,
                Component: PhoneAdd,
              },
              {
                path: ROUTE_CODE,
                Component: PhoneAddCode,
              },
              {
                path: ROUTE_COMPLETE,
                Component: PhoneAddComplete,
              },
            ],
          },
          {
            path: ROUTE_VIEW,
            Component: PhoneView,
          },
          {
            path: ROUTE_REMOVE,
            children: [
              {
                index: true,
                Component: () => Navigate({ to: ROUTE_FULL_PHONE_VIEW }),
              },
              {
                path: ROUTE_COMPLETE,
                Component: PhoneRemoveComplete,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_SERVICES,
    Component: Services,
  },
  {
    path: ROUTE_LOGOUT,
    element: <Logout />,
  },
  {
    path: ROUTE_FORMS,
    children: [
      {
        index: true,
        Component: () => Navigate({ to: '/' }),
      },
      {
        path: ROUTE_PASSWORD,
        Component: FormPassword,
      },
      {
        path: ROUTE_2FA,
        Component: Form2Fa,
      },
      {
        path: ROUTE_FULFILL,
        Component: FormFulfillProfile,
      },
      {
        path: ROUTE_EMAIL,
        Component: FormEmail,
      },
    ],
  },
  {
    path: ROUTE_CONFIRM,
    children: [
      {
        index: true,
        Component: () => Navigate({ to: '/' }),
      },
      {
        path: ROUTE_EMAIL,
        Component: ConfirmEmail,
      },
      {
        path: ROUTE_ADD_EMAIL,
        Component: ConfirmAddEmail,
      },
    ],
  },
  {
    path: ROUTE_RESET_TOTP,
    children: [
      {
        index: true,
        Component: ResetTotp,
      },
      {
        path: ROUTE_CODE,
        Component: ResetTotpCode,
      },
      {
        path: ROUTE_COMPLETE,
        Component: ResetTotpCode,
      },
      {
        path: '*',
        Component: () => Navigate({ to: withSlash(ROUTE_RESET_TOTP) }),
      },
    ],
  },
  {
    path: 'error',
    children: [
      {
        index: true,
        Component: ErrorNotFound,
      },
      {
        path: 'notAuth',
        Component: ErrorForbidden,
      },
      {
        path: 'serverError',
        Component: ErrorInternalServer,
      },
      {
        path: 'serverUnavailable',
        Component: ErrorServiceUnavailable,
      },
    ],
  },
  {
    path: '*',
    Component: ErrorNotFound,
  },
];
