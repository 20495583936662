import { isObject } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AnswerGetTotpAttempt, AnswerPostRemoveTotp } from '../../api/answer.types';
import { QUERY_PARAMS_OTP_DEVICE_ID } from '../../constants/queryParams';
import { ROUTE_FULL_RESET_TOTP_CODE, ROUTE_FULL_RESET_TOTP_COMPLETE, ROUTE_RESET_TOTP } from '../../constants/routes';
import useHttp from '../../hooks/http';
import usePrivatePages, { privatePagesSet } from '../../hooks/privatePages.hook';
import { sentryException } from '../../utils/sentry';
import { withSlash } from '../../utils/url';
import { IFlowResetTotp, IResetTotpData } from '../flows.types';
import useSetPages from '../hooks/setPages.hook';

const initialParams: IResetTotpData['_props'] = {
  deviceId: '',
  attemptId: '',
  email: '',
  label: '',
};
const data: IResetTotpData = {
  _props: { ...initialParams },
  getProp(name) {
    return this._props[name];
  },
  setProp(name, value) {
    this._props[name] = value;
  },
  reset() {
    this._props = { ...initialParams };
  },
};

const usingPrivatePages: { [K in 'code' | 'complete']: string } = {
  code: ROUTE_FULL_RESET_TOTP_CODE,
  complete: ROUTE_FULL_RESET_TOTP_COMPLETE,
};

export const flowResetTotpReset = (): void => {
  Object.values(usingPrivatePages).forEach((item) => privatePagesSet(item, { available: false }));
};

const useFlowResetTotp = (): IFlowResetTotp => {
  const navigate = useNavigate();
  const { requestByName, ready, errorCode } = useHttp();
  const setPages = useSetPages(usingPrivatePages);
  const { setPage } = usePrivatePages();

  // Ключи текстов
  const [error, setError] = useState<IFlowResetTotp['error']>(null);
  useEffect(() => {
    if (errorCode?.getTotpAttempt) {
      setError(errorCode.getTotpAttempt);
    }
    if (errorCode?.postRemoveTotp) {
      setError(errorCode.postRemoveTotp);
    }
  }, [errorCode]);

  const reset = useCallback(async () => {
    const deviceId = data.getProp('deviceId');
    data.reset();
    navigate(`${withSlash(ROUTE_RESET_TOTP)}?${QUERY_PARAMS_OTP_DEVICE_ID}=${deviceId}`);
    setPages(false);
  }, [navigate, setPages]);

  const requestCode = useCallback(
    async (deviceId: string, recaptcha: string) => {
      data.setProp('deviceId', deviceId);
      if (ready('getTotpAttempt') && deviceId && recaptcha) {
        const fetched = (await requestByName('getTotpAttempt', {
          totpId: deviceId,
          recaptcha: recaptcha,
        })) as AnswerGetTotpAttempt;
        if (isObject(fetched)) {
          data.setProp('attemptId', fetched.attemptId);
          data.setProp('email', fetched.email);
          data.setProp('label', fetched.label);
          setPage(usingPrivatePages.code, { available: true });
          navigate(usingPrivatePages.code);
        }
      } else sentryException('Reset totp: requestCode is not ready', 'warning');
    },
    [navigate, ready, requestByName, setPage],
  );

  const sendCodes = useCallback(
    async (resetCode: string, emailCode: string) => {
      const deviceId = data.getProp('deviceId');
      const attemptId = data.getProp('attemptId');
      const key = resetCode.toLowerCase();
      if (ready('postRemoveTotp') && deviceId && attemptId) {
        const fetched = (await requestByName('postRemoveTotp', {
          resetCode: key,
          totpId: deviceId,
          attemptId,
          emailCode,
        })) as AnswerPostRemoveTotp;
        if (isObject(fetched)) {
          setPage(usingPrivatePages.complete, { available: true });
          navigate(usingPrivatePages.complete);
        }
      } else sentryException('Reset totp: sendCodes is not ready', 'warning');
    },
    [navigate, ready, requestByName, setPage],
  );

  return {
    reset,
    requestCode,
    error,
    sendCodes,
    email: useMemo(() => data.getProp('email'), []),
    label: useMemo(() => data.getProp('label'), []),
  };
};

export default useFlowResetTotp;
