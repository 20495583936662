import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnswerPutLocale } from '../api/answer.types';
import { localeNormalize } from '../constants/locale';
import { LocaleE } from '../constants/locale.types';
import { QUERY_PARAMS_LOCALE } from '../constants/queryParams';
import { STORAGE_LOCALE_KEY } from '../constants/storage';
import { updateDomLang } from '../utils/documentHtml';
import Storage, { LOCAL_STORAGE, SESSION_STORAGE } from '../utils/storage';
import { getParamsValueByKey } from '../utils/url';
import useHttp from './http';

const localStorage = new Storage(LOCAL_STORAGE);
const sessionStorage = new Storage(SESSION_STORAGE);

// При разлогинивании нужно почистить sessionStorage
export const cleanForcedLocale = () => {
  sessionStorage.removeItem(STORAGE_LOCALE_KEY);
};

// Принудительное переключение интерфейса паспорта на определенную локаль (без отправки в КК)
const getForced = (): LocaleE | undefined => {
  const paramsLocale = getParamsValueByKey(window.location.href, QUERY_PARAMS_LOCALE);
  if (paramsLocale) {
    const locale = localeNormalize(paramsLocale);
    sessionStorage.setItem(STORAGE_LOCALE_KEY, locale);
    return locale;
  } else {
    const sessionLocale = sessionStorage.getItem(STORAGE_LOCALE_KEY);
    if (sessionLocale) {
      return localeNormalize(sessionLocale);
    }
  }
};
const forcedLocale = getForced();

export const getSaved = (): LocaleE => {
  const localLocale = localStorage.getItem(STORAGE_LOCALE_KEY);
  return localeNormalize(localLocale);
};

const getBest = (supportedLocales: Array<LocaleE>): LocaleE => {
  if (forcedLocale) return forcedLocale;
  if (typeof navigator !== 'undefined') {
    const iso2LanguageLike = navigator.language.split('-')[0].toLowerCase();
    return supportedLocales.includes(iso2LanguageLike as LocaleE) ? (iso2LanguageLike as LocaleE) : getSaved();
  }
  return getSaved();
};

const save = (tag: string): void => {
  updateDomLang(tag);
  localStorage.setItem(STORAGE_LOCALE_KEY, tag);
};

const useLanguage = (
  ready: boolean,
  onChange: (tag: string) => void,
  onDetected: (tag: string) => void,
  supportedLocales: Array<LocaleE>,
  locale?: LocaleE,
  sendUpdate = true,
): { currentTag: LocaleE | null } => {
  const { i18n } = useTranslation();
  const { requestByName } = useHttp();

  const updateLocale = useCallback(
    async (tag: string) => {
      (await requestByName('putLocale', { tag })) as AnswerPutLocale;
    },
    [requestByName],
  );

  const [detected, setDetected] = useReducer(() => true, false);
  const [current, setCurrent] = useState<LocaleE>(() => forcedLocale ?? getSaved());
  useEffect(() => {
    if (forcedLocale && !detected) {
      updateDomLang(forcedLocale);
      onDetected(forcedLocale);
      setDetected();
      return;
    }
    if (ready && !detected) {
      if (locale && !supportedLocales.includes(locale)) {
        if (sendUpdate) updateLocale(current);
        save(current);
        onDetected(current);
        setDetected();
        return;
      }

      if (!locale) {
        const best = getBest(supportedLocales);
        setCurrent(best);
        if (sendUpdate) updateLocale(best);
        save(best);
        onDetected(best);
        setDetected();
        return;
      }

      setCurrent(locale);
      save(locale);
      onDetected(locale);
      setDetected();
    }
  }, [locale, ready, detected, current, updateLocale, onDetected, sendUpdate, supportedLocales]);

  const [listened, setListened] = useReducer(() => true, false);
  useEffect(() => {
    if (detected && !listened) {
      i18n.on('languageChanged', (tag) => {
        if (sendUpdate) updateLocale(tag);
        save(tag);
        sessionStorage.removeItem(STORAGE_LOCALE_KEY);
        onChange?.(tag);
      });
      setListened();
    }
  }, [i18n, detected, updateLocale, listened, onChange, sendUpdate]);

  return {
    currentTag: current,
  };
};

export default useLanguage;
