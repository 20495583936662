import { useCallback, useMemo } from 'react';

import usePrivatePages from '../../hooks/privatePages.hook';

const useSetPages = (usingPrivatePages: Record<string, string>): ((available: boolean) => void) => {
  const { setPage } = usePrivatePages();
  const pages = useMemo(() => usingPrivatePages, [usingPrivatePages]);

  return useCallback(
    (available: boolean) => {
      Object.values(pages).forEach((item) => setPage(item, { available }));
    },
    [pages, setPage],
  );
};

export default useSetPages;
