import { Icon, IconSizes, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import DropList from '../DropList/DropList';
import styles from './Language.module.scss';
import { LanguageProps } from './Language.types';

const Language: React.FC<LanguageProps> = ({
  currentTag,
  getLanguageByCode,
  supportedList,
  onCheck,
  dropdownClass,
}: LanguageProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <DropList
      isOpen={isOpen}
      callback={onClose}
      component={
        <Text variant={TextVariants.body2} color={TextAndIconColors.labelsMenuSecondary}>
          {currentTag.toLocaleUpperCase()}
        </Text>
      }
      dropdownClass={classNames(styles.languageDropdown, dropdownClass)}
    >
      <div className={styles.list}>
        {supportedList.map((item, id) => (
          <div
            key={id}
            className={classNames(styles.item, { [styles.item_checked]: item === currentTag })}
            onClick={() => {
              onCheck(item);
              setIsOpen(false);
            }}
          >
            {item === currentTag && (
              <Icon
                IconComponent={CheckIcon}
                color={TextAndIconColors.brandTertiary}
                size={IconSizes.m}
                className={styles.icon}
              />
            )}
            <Text key={id} variant={TextVariants.body3} color={TextAndIconColors.labelsSecondary}>
              {getLanguageByCode(item)}
            </Text>
          </div>
        ))}
      </div>
    </DropList>
  );
};

export default React.memo(Language);
