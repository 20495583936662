export enum ECustomIconName {
  userDefault = '$userDefault',
  menuUser = '$menuUser',
  menuSecurity = '$menuSecurity',
  menuServices = '$menuServices',
  menuHelp = '$menuHelp',
  menuExit = '$menuExit',
  menuBackTo = '$menuBackTo',
}
export type TCustomIconName = `${ECustomIconName}`;

/**
 * Ссылка на иконку/картинку
 * @minLength 1
 * @pattern ^(http|https)
 */
type ICustomIconLink = string;

export interface ICustomIconProps {
  icon: TCustomIconName | ICustomIconLink | null;
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
  alt?: string;
  initials?: string;
}
