import { withSlash } from '../utils/url';

// Common actions
export const ROUTE_VIEW = 'view';
export const ROUTE_EDIT = 'edit';
export const ROUTE_SENT = 'sent';
export const ROUTE_CHANGED = 'changed';
export const ROUTE_CONFIRM = 'confirm';
// TODO: deleted - completed (changed - completed)
export const ROUTE_DELETED = 'deleted';
export const ROUTE_ADD = 'add';
export const ROUTE_REMOVE = 'remove';
export const ROUTE_ALL = 'all';
export const ROUTE_PARTIAL = 'partial';
export const ROUTE_COMPLETE = 'complete';

// Common routes
export const ROUTE_CODE = 'code';
export const ROUTE_PASSWORD = 'password';
export const ROUTE_EMAIL = 'email';

// Personal Data
export const ROUTE_PERSONAL_DATA = 'personal-data';
export const ROUTE_NAME = 'name';
export const ROUTE_FULL_NAME_EDIT = withSlash([ROUTE_PERSONAL_DATA, ROUTE_NAME, ROUTE_EDIT]);
export const ROUTE_BIRTHDAY = 'birthday';
export const ROUTE_FULL_BIRTHDAY_EDIT = withSlash([ROUTE_PERSONAL_DATA, ROUTE_BIRTHDAY, ROUTE_EDIT]);
export const ROUTE_COUNTRY = 'country';
export const ROUTE_FULL_COUNTRY_EDIT = withSlash([ROUTE_PERSONAL_DATA, ROUTE_COUNTRY, ROUTE_EDIT]);
export const ROUTE_SOCIAL = 'social';
export const ROUTE_FULL_SOCIAL_ADD = withSlash([ROUTE_PERSONAL_DATA, ROUTE_SOCIAL, ROUTE_ADD]);
export const ROUTE_FULL_SOCIAL_REMOVE = withSlash([ROUTE_PERSONAL_DATA, ROUTE_SOCIAL, ROUTE_REMOVE]);
export const ROUTE_CONTACTS = 'contacts';
export const ROUTE_FULL_CONTACTS = withSlash([ROUTE_PERSONAL_DATA, ROUTE_CONTACTS]);

// Security
export const ROUTE_SECURITY = 'security';
export const ROUTE_FULL_EMAIL_VIEW = withSlash([ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_VIEW]);
export const ROUTE_FULL_EMAIL_EDIT = withSlash([ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_EDIT]);
export const ROUTE_FULL_EMAIL_CODE = withSlash([ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_CODE]);
export const ROUTE_FULL_EMAIL_SENT = withSlash([ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_SENT]);
export const ROUTE_VERIFY = 'verify';
export const ROUTE_FULL_EMAIL_VERIFY = withSlash([ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_VERIFY]);
export const ROUTE_VERIFIED = 'verified';
export const ROUTE_FULL_EMAIL_VERIFIED = withSlash([ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_VERIFIED]);
export const ROUTE_FULL_EMAIL_CHANGED = withSlash([ROUTE_SECURITY, ROUTE_EMAIL, ROUTE_CHANGED]);
export const ROUTE_FULL_PASSWORD_ADD = withSlash([ROUTE_SECURITY, ROUTE_PASSWORD, ROUTE_ADD]);
export const ROUTE_FULL_PASSWORD_EDIT = withSlash([ROUTE_SECURITY, ROUTE_PASSWORD, ROUTE_EDIT]);
export const ROUTE_TOTP = 'totp';
export const ROUTE_FULL_TOTP = withSlash([ROUTE_SECURITY, ROUTE_TOTP]);
export const ROUTE_FULL_TOTP_REMOVE = withSlash([ROUTE_SECURITY, ROUTE_TOTP, ROUTE_REMOVE]);
export const ROUTE_FULL_TOTP_ADD = withSlash([ROUTE_SECURITY, ROUTE_TOTP, ROUTE_ADD]);
export const ROUTE_DELETE_ACCOUNT = 'delete-account';
export const ROUTE_FULL_DELETE_ACCOUNT = withSlash([ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT]);
export const ROUTE_FULL_DELETE_ACCOUNT_ALL = withSlash([ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_ALL]);
export const ROUTE_FULL_DELETE_ACCOUNT_SENT = withSlash([ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_SENT]);
export const ROUTE_FULL_DELETE_ACCOUNT_CODE = withSlash([ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_CODE]);
export const ROUTE_FULL_DELETE_ACCOUNT_CONFIRM = withSlash([ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_CONFIRM]);
export const ROUTE_FULL_DELETE_ACCOUNT_DELETED = withSlash([ROUTE_SECURITY, ROUTE_DELETE_ACCOUNT, ROUTE_DELETED]);
export const ROUTE_SESSIONS = 'sessions';
export const ROUTE_FULL_SESSIONS = withSlash([ROUTE_SECURITY, ROUTE_SESSIONS]);
export const ROUTE_FULL_SESSIONS_REMOVE_ALL = withSlash([ROUTE_SECURITY, ROUTE_SESSIONS, ROUTE_REMOVE, ROUTE_ALL]);
export const ROUTE_FULL_SESSIONS_REMOVE_PARTIAL = withSlash([
  ROUTE_SECURITY,
  ROUTE_SESSIONS,
  ROUTE_REMOVE,
  ROUTE_PARTIAL,
]);
export const ROUTE_PHONE = 'phone';
export const ROUTE_FULL_PHONE_ADD = withSlash([ROUTE_SECURITY, ROUTE_PHONE, ROUTE_ADD]);
export const ROUTE_FULL_PHONE_ADD_CODE = withSlash([ROUTE_SECURITY, ROUTE_PHONE, ROUTE_ADD, ROUTE_CODE]);
export const ROUTE_FULL_PHONE_ADD_COMPLETE = withSlash([ROUTE_SECURITY, ROUTE_PHONE, ROUTE_ADD, ROUTE_COMPLETE]);
export const ROUTE_FULL_PHONE_VIEW = withSlash([ROUTE_SECURITY, ROUTE_PHONE, ROUTE_VIEW]);
export const ROUTE_FULL_PHONE_REMOVE_COMPLETE = withSlash([ROUTE_SECURITY, ROUTE_PHONE, ROUTE_REMOVE, ROUTE_COMPLETE]);

// Services
export const ROUTE_SERVICES = 'services';

// Logout
export const ROUTE_LOGOUT = 'logout';

// Forms
export const ROUTE_FORMS = 'forms';
export const ROUTE_FULFILL = 'fulfill-profile';
export const ROUTE_FULL_FULFILL = withSlash([ROUTE_FORMS, ROUTE_FULFILL]);
export const ROUTE_FULL_PASSWORD = withSlash([ROUTE_FORMS, ROUTE_PASSWORD]);
export const ROUTE_2FA = '2fa';
export const ROUTE_FULL_2FA = withSlash([ROUTE_FORMS, ROUTE_2FA]);
export const ROUTE_FULL_EMAIL = withSlash([ROUTE_FORMS, ROUTE_EMAIL]);

// Blank pages
export const ROUTE_FULL_CONFIRM_EMAIL = withSlash([ROUTE_CONFIRM, ROUTE_EMAIL]);
export const ROUTE_ADD_EMAIL = 'add-email';
export const ROUTE_FULL_CONFIRM_ADD_EMAIL = withSlash([ROUTE_CONFIRM, ROUTE_ADD_EMAIL]);

// External pages
export const ROUTE_RESET_TOTP = 'reset-totp';
export const ROUTE_FULL_RESET_TOTP_CODE = withSlash([ROUTE_RESET_TOTP, ROUTE_CODE]);
export const ROUTE_FULL_RESET_TOTP_COMPLETE = withSlash([ROUTE_RESET_TOTP, ROUTE_COMPLETE]);

export const ROUTE_EXTERNAL_LIST = [ROUTE_RESET_TOTP];
