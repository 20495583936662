import { Icon, IconSizes, Link, Text, TextAndIconColors, TextVariants } from '@design-system/ui-kit';
import { useWindowWidth } from '@react-hook/window-size';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Globe } from '../../assets/icons/globe.svg';
import Table from '../../components/Table/Table';
import { ITableList } from '../../components/Table/Table.types';
import { ISession } from '../../hooks/store';
import { getSessionStr } from '../../utils/date';
import { getLastSessions } from '../../utils/session';
import { arrToStr } from '../../utils/string';
import styles from './Tables.module.scss';
import { ISessionsInfoTable } from './Tables.types';

type TListItemProps = {
  divider: boolean;
  action: (id: string) => void;
  isCurrent: boolean;
  counter: number;
  session: ISession;
};
const ListItem: React.VFC<TListItemProps> = ({ divider, action, isCurrent, counter, session }) => {
  const { t, i18n } = useTranslation();

  const wrapperClass = classNames(styles.list_row, {
    [styles.list_row__divider]: divider,
  });
  return (
    <div className={wrapperClass}>
      <div className={styles.sessionsInfoList_session}>
        <Text variant={TextVariants.header9} color={TextAndIconColors.labelsSecondary}>
          {t('block.sessions.session') + ' ' + counter}
        </Text>
        {isCurrent ? (
          <Text variant={TextVariants.header8} color={TextAndIconColors.actionConfirmTertiary}>
            {t('status.currentSession')}
          </Text>
        ) : (
          <Link
            variant={TextVariants.body2}
            color={TextAndIconColors.interfaceAccentSecondary}
            onClick={() => action(session.id)}
            underline={false}
          >
            {t('action.complete')}
          </Link>
        )}
      </div>
      <div className={styles.sessionsInfoList_clients}>
        <Text variant={TextVariants.body3} color={TextAndIconColors.labelsTertiary} className={styles.common_cropText}>
          {arrToStr(session.clients)}
        </Text>
      </div>
      <div className={styles.sessionsInfoList_bottom}>
        <Text
          variant={TextVariants.body3}
          color={TextAndIconColors.labelsQuaternary}
          className={styles.sessionsInfoList_ip}
        >
          {t('field.IpAddress') + ' ' + session?.ipAddress}
        </Text>
        <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary}>
          {t('block.sessions.lastLogin') + ' ' + getSessionStr(session?.lastAccess, i18n.language)}
        </Text>
      </div>
    </div>
  );
};

const SessionsInfoList: React.VFC<ISessionsInfoTable> = ({ sessions, deleteSession, className }) => {
  return (
    <div className={classNames(styles.list_wrapper, className)}>
      {sessions.map((item, id) => (
        <ListItem
          key={id}
          divider={Boolean(id !== 0)}
          action={deleteSession}
          isCurrent={Boolean(id === 0)}
          counter={id + 1}
          session={item}
        />
      ))}
    </div>
  );
};

const styleRules: Record<string, string> = {
  icon: classNames(styles.listTable_column0),
  '1': classNames(styles.listTable_column1),
  '2': classNames(styles.listTable_column2),
  '3': classNames(styles.listTable_column3),
};

const SessionsTable: React.VFC<ISessionsInfoTable> = ({ sessions, deleteSession, className }) => {
  const { t, i18n } = useTranslation();

  const list = useMemo<ITableList[]>(
    () =>
      sessions.map((session, id) => ({
        data: {
          icon: (
            <div className={styles.sessionsInfo_icon}>
              <Icon IconComponent={Globe} size={IconSizes.l} color={TextAndIconColors.interfaceTertiary} />
            </div>
          ),
          '1': (
            <div className={styles.sessionsInfo_firstColumn}>
              <Text variant={TextVariants.header9} color={TextAndIconColors.labelsSecondary}>
                {t('block.sessions.session') + ' ' + (id + 1)}
              </Text>
              <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary}>
                {t('field.IpAddress') + ' ' + session?.ipAddress}
              </Text>
            </div>
          ),
          '2': (
            <div className={styles.sessionsInfo_secondColumn}>
              <Text
                variant={TextVariants.body3}
                color={TextAndIconColors.labelsTertiary}
                className={styles.common_cropText}
              >
                {arrToStr(session.clients)}
              </Text>
              <Text variant={TextVariants.body3} color={TextAndIconColors.labelsQuaternary}>
                {t('block.sessions.lastLogin') + ' ' + getSessionStr(session?.lastAccess, i18n.language)}
              </Text>
            </div>
          ),
          '3':
            id === 0 ? (
              <Text variant={TextVariants.header8} color={TextAndIconColors.actionConfirmTertiary}>
                {t('status.currentSession')}
              </Text>
            ) : (
              <Link
                variant={TextVariants.body2}
                color={TextAndIconColors.labelsTertiary}
                onClick={() => deleteSession(session.id)}
                underline={false}
              >
                {t('action.complete')}
              </Link>
            ),
        },
      })),
    [deleteSession, i18n.language, sessions, t],
  );

  return <Table list={list} styleRules={styleRules} divider className={className} columns={['icon', '1', '2', '3']} />;
};

const SessionsInfoTable: React.VFC<ISessionsInfoTable> = ({ sessions, deleteSession, className }) => {
  const windowWidth = useWindowWidth();

  const lastSessions = useMemo(() => getLastSessions(sessions, 3), [sessions]);

  if (windowWidth > 1240)
    return <SessionsTable className={className} sessions={lastSessions} deleteSession={deleteSession} />;

  return <SessionsInfoList className={className} sessions={lastSessions} deleteSession={deleteSession} />;
};

export default SessionsInfoTable;
