import { TFunction } from 'i18next';
import { useTimer, useToast } from 'library-react-hooks';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { MODE_DEBUG } from '../../constants/mode';
import { ROUTE_FULL_2FA } from '../../constants/routes';
import AppContext from '../../context/AppContext';
import { useTime } from '../../hooks/helper.hook';
import usePrivatePages, { privatePagesSet } from '../../hooks/privatePages.hook';
import { ALERT_GROUP_TOTP, TIMER_NAME_TOTP } from '../flows.constants';
import flowsData from '../flows.data';
import { EFlowsDataStep, IMapperTotp } from '../flows.types';
import useResetNavigate from '../hooks/resetNavigate.hook';

export const mapperTotpReset = (): void => {
  privatePagesSet(ROUTE_FULL_2FA, { available: false });
};

const useMapperTotp = (): IMapperTotp => {
  const navigate = useNavigate();
  const { setPage } = usePrivatePages();
  const { alert, clear } = useToast();
  const time = useTime();
  const { setTimer, cancelTimer } = useTimer();
  const { mode } = useContext(AppContext);
  const resetNavigate = useResetNavigate();

  const onExpiredTotp = useCallback(async () => {
    alert({
      type: 'warning',
      titleData: {
        key: 'message.warningExpirationTitle',
      },
      textData: {
        key: 'message.warningExpirationDescription',
      },
      sticky: true,
    });
    if (mode !== MODE_DEBUG) await resetNavigate(() => setPage(ROUTE_FULL_2FA, { available: false }));
  }, [alert, mode, resetNavigate, setPage]);
  const goVerifyTotp = useCallback(
    (expired: number) => {
      alert({
        type: 'info',
        textData: {
          key: 'message.infoExpiration',
          options: { time: (fn: TFunction<'translation', undefined>) => time(expired, { short: true, fn }) },
        },
        group: ALERT_GROUP_TOTP,
      });
      setTimer(expired, { name: TIMER_NAME_TOTP, callback: onExpiredTotp, autoFinish: true });
      setPage(ROUTE_FULL_2FA, { available: true });
      navigate(ROUTE_FULL_2FA);
    },
    [alert, navigate, onExpiredTotp, setPage, setTimer, time],
  );

  // Вызывается при прохождении вперед/назад
  const clearTotp = useCallback(() => {
    setPage(ROUTE_FULL_2FA, { available: false });
    cancelTimer(TIMER_NAME_TOTP);
    flowsData.setToken(EFlowsDataStep.totp, null);
    clear(ALERT_GROUP_TOTP);
  }, [cancelTimer, clear, setPage]);

  return { clearTotp, goVerifyTotp };
};

export default useMapperTotp;
