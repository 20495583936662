import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LOCALE_DEFAULT, LOCALE_SUPPORTED } from '../constants/locale';
import { LocaleE } from '../constants/locale.types';
import { getSaved } from '../hooks/language.hook';
import { sentryException } from '../utils/sentry';
import csAddons from './cs/addons.json';
import csTranslation from './cs/translation.json';
import deAddons from './de/addons.json';
import deTranslation from './de/translation.json';
import enAddons from './en/addons.json';
import enTranslation from './en/translation.json';
import esAddons from './es/addons.json';
import esTranslation from './es/translation.json';
import { formatterLocaleFn } from './formatters';
import plAddons from './pl/addons.json';
import plTranslation from './pl/translation.json';
import ptAddons from './pt/addons.json';
import ptTranslation from './pt/translation.json';
import ruAddons from './ru/addons.json';
import ruTranslation from './ru/translation.json';
import ukAddons from './uk/addons.json';
import ukTranslation from './uk/translation.json';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next)
  // .use(LanguageDetector) 'i18next-browser-languagedetector'
  .init({
    // compatibilityJSON: 'v2',
    fallbackLng: LOCALE_DEFAULT,
    lng: getSaved(),
    load: 'currentOnly',
    // initImmediate: false,
    supportedLngs: [...LOCALE_SUPPORTED, 'pt'],
    nonExplicitSupportedLngs: true,
    resources: {
      [LocaleE.ru]: {
        translation: { ...ruTranslation, ...ruAddons },
      },
      [LocaleE.en]: {
        translation: { ...enTranslation, ...enAddons },
      },
      [LocaleE.de]: {
        translation: { ...deTranslation, ...deAddons },
      },
      [LocaleE.es]: {
        translation: { ...esTranslation, ...esAddons },
      },
      [LocaleE.pl]: {
        translation: { ...plTranslation, ...plAddons },
      },
      [LocaleE.cs]: {
        translation: { ...csTranslation, ...csAddons },
      },
      [LocaleE.uk]: {
        translation: { ...ukTranslation, ...ukAddons },
      },
      pt: {
        translation: { ...ptTranslation, ...ptAddons },
      },
    },
    debug: false,
    interpolation: {
      escapeValue: false,
      // alwaysFormat: true,
      defaultVariables: {
        companyName: 'Mitgo',
        serviceName: 'Mitgo\u00A0ID',
      },
    },
    react: {
      useSuspense: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
    },
    missingInterpolationHandler: (text: string) => {
      sentryException(`I18n interpolation value is undefined: ${text}`, 'warning');
      // console.log('missingInterpolationHandler', text, value);
    },
  })
  // eslint-disable-next-line unicorn/prefer-top-level-await
  .then((_value) => {
    i18n.services.formatter?.add('formatterLocale', formatterLocaleFn);
    i18n.services.formatter?.addCached('specialformat', (lng, options) => {
      const formatter = new Intl.NumberFormat(lng, options);
      return (val) => formatter.format(val);
    });
  });

export { default } from 'i18next';
