import {
  Button,
  ButtonThemes,
  ButtonTypes,
  ButtonVariant,
  EInputThemes,
  Icon,
  Input,
  Text,
  TextAndIconColors,
  TextVariants,
} from '@design-system/ui-kit';
import classNames from 'classnames';
import { ToastPropsT, useToast } from 'library-react-hooks';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconKeyHole } from '../../assets/icons/keyHole.svg';
import { API_ERROR_CODE_SECURITY_FLOW_NOT_PASS, API_ERROR_CODE_WRONG_EMAIL_CODE } from '../../constants/api';
import { withMockModes } from '../../constants/mode';
import Form from '../../containers/Form';
import AppContext from '../../context/AppContext';
import { useOnceEffect } from '../../hooks/helper.hook';
import useInput from '../../hooks/input.hook';
import useOnLoad from '../../hooks/onLoad.hook';
import useStore from '../../hooks/store';
import { EEmailAction, EFlowType } from '../../scenarios';
import { getEmailActionByType } from '../../scenarios/flows.functions';
import useEmailCodeFlowStep from '../../scenarios/steps/emailCodeFlowStep.hook';
import { ga4Event } from '../../utils/ga';
import { listenKeydown } from '../../utils/keyboard';
import { validationEmailCode } from '../../utils/validation';
import styles from './FormEmail.module.scss';
import Notification from './Notification';

const mockedActionEmailKey = getEmailActionByType(EFlowType.addPhone);

const getGaActionByType = (
  action: EEmailAction | string,
): 'add phone' | 'remove phone' | 'verify phone' | 'undefined' => {
  switch (action) {
    case EEmailAction.addPhone: {
      return 'add phone';
    }
    case EEmailAction.removePhone: {
      return 'remove phone';
    }
    case EEmailAction.verifyPhone: {
      return 'verify phone';
    }
    default: {
      return 'undefined';
    }
  }
};

const FormEmail: React.VFC = () => {
  const { t } = useTranslation();
  const { actionEmail, reset, confirmCode, error, sendEmail } = useEmailCodeFlowStep();
  const { mode, config } = useContext(AppContext);
  const { alert } = useToast();
  const { user, state } = useStore([]);

  useOnceEffect(() => {
    if (!withMockModes.includes(mode)) sendEmail(true);
  });

  const [code, changeCode, { error: errorCode, setError: setErrorCode }] = useInput<string>('', { translate: t });
  useEffect(() => {
    if (error?.code === API_ERROR_CODE_WRONG_EMAIL_CODE || error?.code === API_ERROR_CODE_SECURITY_FLOW_NOT_PASS) {
      setErrorCode(error.title);
    } else if (error?.code) {
      const props: ToastPropsT = { type: 'error', titleData: { key: error.title } };
      if (error.text) props.textData = { key: error.text };
      alert(props);
    }
  }, [alert, error, setErrorCode]);
  const sendCode = useCallback(async () => {
    let valid = true;
    if (!validationEmailCode(code)) {
      valid = false;
      setErrorCode('message.errorValidationEmailCode');
    }

    if (valid) {
      ga4Event('emailFormClick', { placeholders: { element: 'next', type: getGaActionByType(actionEmail) } });
      await confirmCode(code);
    }
  }, [actionEmail, code, confirmCode, setErrorCode]);
  const onCancel = useCallback(async () => {
    ga4Event('emailFormClick', { placeholders: { element: 'back', type: getGaActionByType(actionEmail) } });
    await reset();
  }, [actionEmail, reset]);

  const action = useMemo(
    () =>
      withMockModes.includes(mode)
        ? t(`page.emailForm.action.${mockedActionEmailKey}`)
        : t(`page.emailForm.action.${actionEmail}`),
    [actionEmail, mode, t],
  );

  const buttonDisabled = code.length !== 6;

  useEffect(() => {
    const listener = listenKeydown('Enter', sendCode, buttonDisabled);
    return () => {
      listener();
    };
  }, [sendCode, buttonDisabled]);

  useOnLoad(Boolean(state.user), 'FormEmail');

  return (
    <Form logoClickable notificationComponent={<Notification supportEmail={config.supportEmail} />}>
      <div className={styles.form_wrapper}>
        <div className={styles.form_head}>
          <Text variant={TextVariants.head3} color={TextAndIconColors.labelsSecondary} className={styles.this_head}>
            {t('page.emailForm.title')}
          </Text>
        </div>
        <div className={classNames(styles.common_column, styles.common_margin__l)}>
          <div className={styles.this_iconBlock}>
            <Icon IconComponent={IconKeyHole} width={50} height={50} color={TextAndIconColors.brandTertiary} />
            <Text variant={TextVariants.body2} color={TextAndIconColors.labelsSecondary}>
              {t('page.emailForm.subTitle', { email: user.email })}
            </Text>
          </div>
          <Text
            variant={TextVariants.body2}
            color={TextAndIconColors.labelsQuaternary}
            className={styles.common_margin__l}
          >
            {t('page.emailForm.description', { action })}
          </Text>
          <Input
            value={code}
            onChange={changeCode}
            className={styles.common_margin__m}
            placeholder={t('field.emailCode')}
            theme={errorCode ? EInputThemes.error : EInputThemes.base}
            errorMessage={errorCode}
            autoFocus
          />
        </div>
        <div className={styles.common_buttons}>
          <Button
            text={t('action.cancel').toString()}
            type={ButtonTypes.button}
            onClick={onCancel}
            variant={ButtonVariant.outlined}
            theme={ButtonThemes.labelsSecondary}
          />
          <Button
            text={t('action.confirm').toString()}
            type={ButtonTypes.button}
            onClick={sendCode}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </Form>
  );
};

export default FormEmail;
